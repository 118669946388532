import {
  CommonQuestion,
  CommonQuestionMetadata,
  CommonQuestionResult,
  CommonSession,
  CommonSessionResult,
} from '@codidae/common-types';
import HTTPClient from 'utils/HTTPClient';

export function createSessionFromChildren(childrenId: number | string): Promise<CommonSession> {
  return HTTPClient.post(`/session/from/children/${childrenId}`).then((data) => data.data);
}

export function createOfflineSessionFromChildren(childrenId: number | string): Promise<CommonQuestion[]> {
  return HTTPClient.post(`/session/from/children/${childrenId}/offline`).then((data) => data.data);
}

export function createSessionFromChapter({
  childrenId,
  chapterId,
}: {
  childrenId: number;
  chapterId: number;
}): Promise<CommonSession> {
  return HTTPClient.post('/session/from/chapter', {
    chapterId,
    childrenId,
  }).then((data) => data.data);
}

export function getSession(sessionId): Promise<CommonSession> {
  return HTTPClient.get(`/session/${sessionId}`).then((data) => data.data);
}

export function getNextQuestion({ sessionId }): Promise<CommonQuestion> {
  return HTTPClient.get(`/session/${sessionId}/question`).then((data) => data.data);
}

export function answerQuestion({ sessionId, answer }): Promise<CommonQuestionResult> {
  return HTTPClient.post(`/session/${sessionId}/answer`, { answer }).then((data) => data.data);
}

export function revealAnswer({ sessionId }): Promise<CommonQuestion> {
  return HTTPClient.post(`/session/${sessionId}/getAnswer`).then((data) => data.data);
}

export function getSessionResult(sessionId): Promise<CommonSessionResult> {
  return HTTPClient.get(`/session/${sessionId}/result`).then((data) => data.data);
}

export function createSessionFromSession(sessionId): Promise<CommonSession> {
  return HTTPClient.post(`/session/from/session/${sessionId}`).then((data) => data.data);
}

export const getQuestionMetadata = (questionId: number): Promise<CommonQuestionMetadata> =>
  HTTPClient.get(`/question/${questionId}/metadata`).then((data) => data.data);
