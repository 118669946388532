import Altus, { AltusType } from 'components/altus/altus';
import Card from 'components/card/card';
import OnboardingHeader from 'components/onboarding-header/onboarding-header';
import OnboardingLayout from 'components/onboarding-layout/onboarding-layout';
import Page from 'components/page/page';
import { useNavigate } from 'react-router-dom';
import Flex from 'components/flex/flex';
import styles from './onboarding-choice.module.scss';

/* eslint-disable-next-line */
export interface OnboardingChoiceProps {
  title?: string;
  choices: {
    altusType: AltusType;
    title: string;
    link: string;
    onClick?: () => void;
  }[];
  fontSize?: number;
  backHref?: string;
  back?: boolean;
}

export function OnboardingChoice({
  choices,
  back,
  backHref,
  fontSize,
  title,
}: OnboardingChoiceProps) {
  const navigate = useNavigate();

  return (
    <Page>
      <OnboardingHeader
        // eslint-disable-next-line consistent-return
        onClick={() => {
          if (back) return navigate(-1);
          navigate(backHref || '/');
        }}
      />
      <OnboardingLayout
        compact
        title={<b style={{ fontSize: fontSize || 30 }}>{title}</b>}
        canNext={false}
      >
        <Flex
          direction="column"
          align="center"
          justify="center"
          gap={20}
          width="100%"
          height="100%"
        >
          {choices.map((choice, index) => (
            <Card
              key={index}
              onClick={() => {
                if (choice.onClick) choice.onClick();
                navigate(choice.link);
              }}
              style={{
                width: '100%',
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '15px 0',
              }}
              className={`${styles.card} ${styles[choice.altusType.includes('cutted') ? 'cutted' : '']}`}
            >
              <Altus type={choice.altusType} width="100%" />
              <p className={styles.title}>{choice.title}</p>
            </Card>
          ))}
        </Flex>
      </OnboardingLayout>
    </Page>
  );
}

export default OnboardingChoice;
