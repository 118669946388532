import { Preferences } from '@capacitor/preferences';
import { CommonDayParameters, CommonStudent, CommonUser } from '@codidae/common-types';
import { useQueries } from '@tanstack/react-query';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { identify } from 'segment';
import { getChildren, getCurrentParentalControlSettings } from 'services/childrens';
import { getProfile } from 'services/profile';
import { getMySubscription } from 'services/subscription';

interface UserContextProps {
  user?: CommonUser | null;
  getSubscriptionStatus: (childId: number) => 'loading' | 'premium' | 'freemium';
  userSubscriptionStatus: 'loading' | 'premium' | 'freemium';
  subscriptionChildCount: number;
  refetchSubscription: VoidFunction;
  child?: CommonStudent;
  parentalControlSettings?: CommonDayParameters & {
    contentFilterLevel: number;
    preventUninstall: boolean;
  };
  updateChildId: VoidFunction;
}

const UserContext = createContext<UserContextProps>({
  getSubscriptionStatus: () => 'loading',
  userSubscriptionStatus: 'loading',
  refetchSubscription() {},
  updateChildId() {},
  subscriptionChildCount: 0,
});

interface UserProviderProps {
  children: React.ReactNode;
}

export default function UserProvider({ children }: UserProviderProps) {
  const [childId, setChildrenId] = useState<number | null>();

  const updateChildId = () => {
    Preferences.get({ key: 'child_mode' }).then(({ value }) => {
      if (value && value !== 'null') setChildrenId(parseInt(value, 10));
      else setChildrenId(null);
    });
  };

  useEffect(() => {
    updateChildId();
  }, []);

  const [
    { data: profile },
    { data: dataSubscription, isSuccess: isSuccessSubscription, refetch: refetchSubscription },
    { data: child },
    { data: parentalControlSettings },
  ] = useQueries({
    queries: [
      {
        queryKey: ['getProfile'],
        queryFn: () => getProfile(),
      },
      { queryKey: ['getMySubscription'], queryFn: getMySubscription, staleTime: 3600000 },
      {
        queryKey: ['children', childId],
        enabled: !!childId,
        queryFn: () => getChildren(childId),
      },
      {
        queryKey: ['getCurrentParentalControlSettings', childId],
        enabled: !!childId,
        queryFn: () => getCurrentParentalControlSettings(childId),
        staleTime: 60 * 1000,
      },
    ],
  });

  const userSubscriptionStatus = useMemo<UserContextProps['userSubscriptionStatus']>(() => {
    let subscriptionStatus: ReturnType<UserContextProps['getSubscriptionStatus']> = 'loading';
    if (isSuccessSubscription) {
      subscriptionStatus = dataSubscription ? 'premium' : 'freemium';
    }
    return subscriptionStatus;
  }, [dataSubscription, isSuccessSubscription]);
  const checkSubscribed = useCallback(
    (id: number) => {
      let subscriptionStatus: ReturnType<UserContextProps['getSubscriptionStatus']> = 'loading';
      if (isSuccessSubscription) {
        subscriptionStatus = dataSubscription.subscribedChildren?.includes(id) ? 'premium' : 'freemium';
      }
      return subscriptionStatus;
    },
    [dataSubscription, isSuccessSubscription],
  );

  useEffect(() => {
    if (!profile || childId === undefined || userSubscriptionStatus === 'loading') return;
    identify(
      profile.email,
      profile?.phone,
      childId,
      userSubscriptionStatus === 'premium',
      childId ? checkSubscribed(childId) === 'premium' : null,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, childId, userSubscriptionStatus]);

  return (
    <UserContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        user: profile,
        getSubscriptionStatus: checkSubscribed,
        userSubscriptionStatus,
        refetchSubscription,
        child,
        parentalControlSettings,
        updateChildId,
        subscriptionChildCount: dataSubscription?.childrenCount ?? 0,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export function useUserContext() {
  return useContext(UserContext);
}

export { UserProvider };
