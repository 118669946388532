import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  RadialLinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import Flex from 'components/flex/flex';
import Page from 'components/page/page';
import ParentStatsChapters from 'components/parent-stats-chapters/parent-stats-chapters';
import ParentStatsProgress from 'components/parent-stats-progress/parent-stats-progress';
import TabBar from 'components/tab-bar/tab-bar';
import Tabs from 'components/tabs/tabs';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  RadialLinearScale,
  Title,
  Tooltip,
  Legend,
);

/* eslint-disable-next-line */
export interface ChildrenStatsProps {}

export function ChildrenStats(props: ChildrenStatsProps) {
  const [currentTab, setCurrentTab] = useState('progress');
  const { id } = useParams();

  return (
    <Page parentMode>
      <Flex
        direction="column"
        justify="start"
        align="center"
        width="100%"
        height="100%"
      >
        <Flex
          direction="column"
          gap={10}
          pad={20}
          width="100%"
          height="100%"
          style={{
            overflowY: 'scroll',
            paddingBottom: 50,
            paddingTop: 20,
          }}
        >
          <Tabs
            currentTab={currentTab}
            tabs={[
              { title: 'Progression', slug: 'progress' },
              { title: 'Chapitres', slug: 'chapters' },
            ]}
            changeTab={setCurrentTab}
          />
          {currentTab === 'progress' && <ParentStatsProgress childrenId={id} />}
          {currentTab === 'chapters' && <ParentStatsChapters childrenId={id} />}
        </Flex>
        <TabBar childrenId={id} />
      </Flex>
    </Page>
  );
}

export default ChildrenStats;
