import Flex from 'components/flex/flex';
import Altus, { AltusType } from 'components/altus/altus';
import styles from './loading-screen.module.scss';

/* eslint-disable-next-line */
export interface LoadingScreenProps {
  text?: string;
  altus?: AltusType;
}

export function LoadingScreen({ altus, text }: LoadingScreenProps) {
  return (
    <Flex
      align="center"
      justify="center"
      direction="column"
      height="100%"
      width="100%"
    >
      <Altus width={150} type={altus || 'default'} />
      <div className={styles.title}>{text || 'Chargement...'}</div>
    </Flex>
  );
}

export default LoadingScreen;
