import { useQueries } from '@tanstack/react-query';
import Altus from 'assets/altus/pin.svg';
import LoadingScreen from 'components/loading-screen/loading-screen';
import L from 'leaflet';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { getChildren } from 'services/childrens';

const AltusIcon = new L.Icon({
  iconUrl: Altus,
  iconSize: [50, 50],
  iconAnchor: [30, 40],
  popupAnchor: [0, -40],
});

export default function ChildMap({
  childId,
  dragging = true,
  className,
}: {
  childId: string;
  dragging?: boolean;
  className?: string;
}) {
  const [{ data: children }] = useQueries({
    queries: [
      {
        queryKey: ['children', childId],
        queryFn: () => getChildren(childId),
        refetchInterval: 60000,
        staleTime: 60000,
      },
    ],
  });

  if (children === undefined) {
    return <LoadingScreen />;
  }

  const latitute = children?.location?.latitude ?? 0;
  const longitude = children?.location?.longitude ?? 0;

  return (
    <div style={{ height: '100%', width: '100%' }} className={className}>
      <MapContainer
        center={[latitute, longitude]}
        zoom={18}
        attributionControl={false}
        scrollWheelZoom={false}
        dragging={dragging}
        style={{
          height: '100%',
        }}
        zoomControl={false}
      >
        <TileLayer url="https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png" />
        <Marker position={[latitute, longitude]} icon={AltusIcon}>
          <Popup closeButton={false}>
            <b>{children?.firstname}</b> est ici
            <br />
            Actualisé à {children?.lastLocationUpdate?.toLocaleTimeString('fr-FR')} le{' '}
            {children?.lastLocationUpdate?.toLocaleDateString('fr-FR')}
          </Popup>
        </Marker>
      </MapContainer>
    </div>
  );
}
