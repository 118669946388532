import { CommonStudent } from '@codidae/common-types';
import { useUserContext } from 'contexts/user-context';
import { useNavigate } from 'react-router-dom';
import styles from './children-selection-icon.module.scss';

/* eslint-disable-next-line */
export interface ChildrenSelectionIconProps {
  child: CommonStudent;
  disabled?: boolean;
  onClick?: VoidFunction;
}

export function ChildrenSelectionIcon({ child, disabled, onClick }: ChildrenSelectionIconProps) {
  const navigate = useNavigate();
  const { getSubscriptionStatus } = useUserContext();

  return (
    <button
      className={`${styles.child} ${disabled ? styles.disabled : ''}`}
      onClick={() => {
        if (disabled) {
          const confirm = window.confirm("Veuillez changer d'abonnement pour ajouter plus d'enfants");
          if (confirm) {
            navigate('/help');
          }
        } else if (onClick) {
          onClick();
        } else {
          navigate(`/children/${child.id}`);
        }
      }}
      type="button"
    >
      <div className={styles['icon']}>
        <div>{child.firstname.slice(0, 2)}</div>
        {getSubscriptionStatus(child.id) === 'premium' && <div className={styles.plus}>Plus</div>}
      </div>
      <div className={styles['name']}>{child.firstname}</div>
    </button>
  );
}

export default ChildrenSelectionIcon;
