import { CommonBoolean, CommonLogin, CommonSuccess } from '@codidae/common-types';
import HTTPClient from '../utils/HTTPClient';

export function register({ email, pinCode, promoCode, phone, childName }): Promise<CommonLogin> {
  return HTTPClient.post('/user/register/parent', {
    email: email.toLowerCase(),
    firstname: '',
    lastname: '',
    fields: [],
    newsletter: false,
    pinCode,
    promoCode,
    phone,
    childName,
  }).then((data) => data.data);
}

export const preregister = ({ email, phone }): Promise<CommonSuccess> =>
  HTTPClient.post('/user/preregister', {
    email,
    phone,
  }).then((data) => data.data);

export function login({ email, password }): Promise<CommonLogin> {
  return HTTPClient.post('/auth/login', {
    username: email.toLowerCase(),
    password,
  }).then((data) => data.data);
}

export function validatePinCode({ pinCode }): Promise<CommonBoolean> {
  return HTTPClient.post('/user/pin/validate', {
    pinCode,
  }).then((data) => data.data);
}

export function forgotPassword({ email }): Promise<CommonSuccess> {
  return HTTPClient.post('/user/forgot-password', {
    email,
  }).then((data) => data.data);
}

export function sendParentInvite({ email, childName }: { email: string; childName: string }): Promise<CommonSuccess> {
  return HTTPClient.post('/user/auth/invite-parent', {
    email,
    childName,
  });
}

export function getLoginToken(): Promise<string> {
  return HTTPClient.get('/user/auth/child').then((data) => data.data);
}

export const loginWithToken = ({ email, token }): Promise<CommonLogin> =>
  HTTPClient.post('/user/auth/child', {
    email,
    token,
  }).then((data) => data.data);

export function getLoginPairCode({ childrenId }: { childrenId: string }): Promise<string> {
  return HTTPClient.get(`/user/auth/pair-code?childrenId=${childrenId}`).then((data) => data.data);
}

export const loginWithPairCode = ({
  code,
}): Promise<
  CommonLogin & {
    children: number;
  }
> =>
  HTTPClient.post('/user/auth/pair-code', {
    code,
  }).then((data) => data.data);

export const sendLoginCode = ({ email }): Promise<CommonSuccess> =>
  HTTPClient.post('/user/parent/send-login-code', {
    email,
  }).then((data) => data.data);

export const loginWithCode = ({ email, code }): Promise<CommonLogin> =>
  HTTPClient.post('/user/parent/login-with-code', {
    email,
    code,
  }).then((data) => data.data);

export const loginWithFirebaseToken = ({ token }): Promise<CommonLogin> =>
  HTTPClient.post('/user/parent/login-firebase-token', {
    token,
  }).then((data) => data.data);

export const preregisterWithFirebaseToken = ({ token }): Promise<CommonSuccess> =>
  HTTPClient.post('/user/preregister-with-token', {
    token,
  }).then((data) => data.data);
