import { CommonQuestion, CommonQuestionMetadata } from '@codidae/common-types';
import Flex from 'components/flex/flex';
import LoadingScreen from 'components/loading-screen/loading-screen';
import MultipleChoiceQuestion from 'components/multiple-choice-question/multiple-choice-question';

/* eslint-disable-next-line */
export interface DisplayQuestionProps {
  question: CommonQuestion;
  onAnswer: (answer) => void;
  loading?: boolean;
  metadata?: CommonQuestionMetadata;
  sessionAvailableAnswers: number;
  revealAnswer: VoidFunction;
  answerData?: any;
}

const questionTypeToComponent = {
  'multiple-choice': MultipleChoiceQuestion,
};

export function DisplayQuestion({
  onAnswer,
  question,
  loading,
  metadata,
  sessionAvailableAnswers,
  revealAnswer,
  answerData,
}: DisplayQuestionProps) {
  const Component = questionTypeToComponent[question.type];

  if (!Component) {
    return <LoadingScreen />;
  }
  return (
    <Flex height="100%" width="100%">
      <Component
        question={question}
        onAnswer={onAnswer}
        loading={loading}
        metadata={metadata}
        sessionAvailableAnswers={sessionAvailableAnswers}
        revealAnswer={revealAnswer}
        answerData={answerData}
      />
    </Flex>
  );
}

export default DisplayQuestion;
