import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { Preferences } from '@capacitor/preferences';
import { useQuery } from '@tanstack/react-query';
import heartIcon from 'assets/icons/heart.svg';
import { useStatusBarHeight } from 'hooks/use-status-bar-height';
import { useEffect, useState } from 'react';
import { MdClose } from 'react-icons/md';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getChildren } from 'services/childrens';
import BlockerPlugin from 'utils/BlockerPlugin';
import styles from './session-header.module.scss';

/* eslint-disable-next-line */
export interface SessionHeaderProps {
  earnedTime: number;
  lifes: number;
  questionNumber: number;
  totalQuestions: number;
}

export function SessionHeader({ earnedTime, lifes, questionNumber, totalQuestions }: SessionHeaderProps) {
  const navigate = useNavigate();
  const [childrenId, setChildrenId] = useState(0);
  const { data: children } = useQuery({
    queryKey: ['children', childrenId],
    enabled: !!childrenId,
    queryFn: () => getChildren(childrenId),
  });
  const [searchParams] = useSearchParams();
  const unlockSession = searchParams.get('unlock') === 'true';
  const statusBarHeight = useStatusBarHeight();

  useEffect(() => {
    Preferences.get({ key: 'child_mode' }).then(({ value }) => {
      if (value && value !== 'null') setChildrenId(parseInt(value, 10));
    });
  }, []);

  // eslint-disable-next-line consistent-return
  const leaveQuiz = () => {
    if (!unlockSession && !childrenId) return navigate(-1);
    if (!unlockSession && !!childrenId) return navigate('/dashboard');
    const leave = window.confirm(
      `Es-tu sûr de vouloir quitter le quiz ?\n\nTu perdras tes vies, et tu débloqueras ${earnedTime} minutes !`,
    );
    if (leave) {
      if (earnedTime > 0) BlockerPlugin.addMinutesToMonitoring({ minutes: earnedTime });
      Preferences.set({
        key: 'last_out_of_lifes_session',
        value: Date.now().toString(),
      });
      navigate('/dashboard', {
        replace: true,
      });
    }
  };

  const progress = (questionNumber / totalQuestions) * 100 + 5;
  return (
    <div
      className={styles.sessionHeader}
      style={{
        paddingTop: 20 + statusBarHeight,
      }}
    >
      <div className={styles.left}>
        <button
          type="button"
          onClick={() => {
            leaveQuiz();
          }}
          onTouchStart={() => {
            Haptics.impact({ style: ImpactStyle.Light });
          }}
          onTouchEnd={() => {
            Haptics.impact({ style: ImpactStyle.Light });
          }}
        >
          <MdClose />
        </button>
      </div>
      <div className={styles.center}>
        <div className={styles.progress}>
          <div
            className={styles.progressValue}
            style={{
              width: `${progress > 100 ? 100 : progress}%`,
            }}
          />
        </div>
        {children && (
          <div className={styles.progressText}>
            {earnedTime} minutes {earnedTime > 1 ? 'gagnées' : 'gagnée'}
          </div>
        )}
      </div>
      <div className={styles.right}>
        {children && (
          <>
            <img src={heartIcon} alt="heart" className={styles.heartIcon} />
            <div className={styles.lifes}>{lifes}</div>
          </>
        )}
      </div>
    </div>
  );
}

export default SessionHeader;
