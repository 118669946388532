import InfoTooltip from 'components/info-tooltip/info-tooltip';
import { MdEdit } from 'react-icons/md';
import Picker, { PickerValue } from 'react-mobile-picker';
import styles from './time-picker.module.scss';

/* eslint-disable-next-line */
export interface TimePickerProps {
  options: { hours: string[]; minutes: string[] };
  onChange?: (minutes: number) => void;
  placeholder?: string;
  value: number;
  labeled?: boolean;
  tooltipInfo?: React.ReactNode;
  editMode?: boolean;
  onEdit: () => void;
  displayValue?: (value: number) => string;
}

export function TimePicker({
  options,
  value,
  labeled,
  onChange,
  placeholder,
  tooltipInfo,
  editMode,
  onEdit,
  displayValue,
}: TimePickerProps) {
  const nameToDisplay = (name: string, plurial: boolean) => {
    if (name === 'hours') {
      return `heure${plurial ? 's' : ''}`;
    }
    return `minute${plurial ? 's' : ''}`;
  };

  return (
    <div className={styles.inputWrapper}>
      {labeled && (
        <span className={styles.label}>
          {placeholder}
          {tooltipInfo && <InfoTooltip>{tooltipInfo}</InfoTooltip>}
        </span>
      )}
      <div className={styles.select}>
        {editMode ? (
          <Picker
            height={160}
            value={{
              hours: String(Math.floor(value / 60)),
              minutes: String(value % 60),
            }}
            onChange={(v: PickerValue) => {
              if (!v.hours || !v.minutes) return;
              const time = Number(v.hours) * 60 + Number(v.minutes);
              onChange?.(time);
            }}
          >
            {Object.keys(options).map((name) => (
              <Picker.Column key={name} name={name}>
                {options[name].map((option) => (
                  <Picker.Item key={option} value={option}>
                    {option} {nameToDisplay(name, option !== '1' && option !== '0')}
                  </Picker.Item>
                ))}
              </Picker.Column>
            ))}
          </Picker>
        ) : (
          <div className={styles.editTime}>
            <div className={styles.time} onClick={() => onEdit()}>
              {displayValue ? (
                displayValue(value)
              ) : (
                <>
                  {Math.floor(value / 60)} heure
                  {Math.floor(value / 60) > 1 ? 's' : ''} et {value % 60} minute
                  {value % 60 > 1 ? 's' : ''}
                </>
              )}
            </div>
            <div onClick={() => onEdit()} className={styles.editIcon}>
              <MdEdit width={20} height={20} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default TimePicker;
