import { CommonStudent } from '@codidae/common-types';
import { useMutation, useQueries } from '@tanstack/react-query';
import AddChapterPage from 'components/add-chapter-page/add-chapter-page';
import AddChapterButton from 'components/add-chapter/add-chapter';
import AltusTalk from 'components/altus-talk/altus-talk';
import ChapterCard from 'components/chapter-card/chapter-card';
import Flex from 'components/flex/flex';
import Loader from 'components/loader/loader';
import PaywallText from 'components/paywall-text/paywall-text';
import { useUserContext } from 'contexts/user-context';
import { useState } from 'react';
import { SegmentEventType, track } from 'segment';
import { getChapters } from 'services/chapters';
import { deleteChildrenFocus, getChildren, updateChildrenFocus } from 'services/childrens';

/* eslint-disable-next-line */
export interface ChildrenChapterSelectionProps {
  student: CommonStudent;
}

export function ChildrenChapterSelection({ student }: ChildrenChapterSelectionProps) {
  const deleteChildrenFocusMutation = useMutation({
    mutationFn: deleteChildrenFocus,
    onSuccess: () => {
      refetch();
    },
  });
  const updateChildrenFocusMutation = useMutation({
    mutationFn: updateChildrenFocus,
    onSuccess: () => {
      refetch();
    },
  });
  const [addChapter, setAddChapter] = useState(false);
  const [{ data: children, isLoading, refetch }, { data: chapters, isLoading: chaptersLoading }] = useQueries({
    queries: [
      {
        queryKey: ['children', student.id],
        queryFn: () => getChildren(student.id),
      },
      {
        queryKey: ['chapters'],
        queryFn: getChapters,
        staleTime: 24 * 3600000 * 21,
        gcTime: 24 * 3600000 * 21,
      },
    ],
  });
  const { getSubscriptionStatus } = useUserContext();

  if (addChapter) {
    return (
      <AddChapterPage
        onClose={() => {
          setAddChapter(false);
          refetch();
        }}
        childrenId={student.id}
      />
    );
  }
  return (
    <Flex
      direction="column"
      justify="start"
      align="center"
      width="100%"
      height="100%"
      style={{
        paddingBottom: 90,
      }}
    >
      {getSubscriptionStatus(student.id) === 'freemium' && <PaywallText />}
      <Flex
        direction="column"
        gap={15}
        pad={20}
        width="100%"
        style={{
          overflowY: 'auto',
          flexGrow: 1,
        }}
      >
        {isLoading ||
          (chaptersLoading && (
            <div style={{ margin: 'auto' }}>
              <Loader />
            </div>
          ))}
        {chapters && !children?.focusedChapters?.length && (
          <AltusTalk>Choisis les chapitres sur lesquels tu veux te concentrer</AltusTalk>
        )}
        {chapters &&
          children?.focusedChapters?.map((chapter, index) => {
            const parent = chapters.find((c) => c.id === chapter.parent);

            if (!parent) return null;
            return (
              <ChapterCard
                key={index}
                chapter={parent}
                childrenId={Number(student.id)}
                selected={chapter.chapter}
                limitTimestamp={chapter.limit}
                isSelected
                currentIndex={index}
                refetch={refetch}
                onDelete={() => {
                  track(SegmentEventType.REMOVE_CHAPTER);
                  deleteChildrenFocusMutation.mutate({
                    id: student.id,
                    index,
                  });
                }}
                onChange={(value) => {
                  updateChildrenFocusMutation.mutate({
                    id: student.id,
                    index,
                    chapters: value,
                  });
                }}
              />
            );
          })}
      </Flex>
      <AddChapterButton
        onClick={() => {
          setAddChapter(true);
        }}
      />
    </Flex>
  );
}

export default ChildrenChapterSelection;
