import { CommonStudent } from '@codidae/common-types';
import { useQueries } from '@tanstack/react-query';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  RadialLinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import AnsweredQuestionsChart from 'components/answered-questions-chart/answered-questions-chart';
import Flex from 'components/flex/flex';
import InfoTooltip from 'components/info-tooltip/info-tooltip';
import ScreenUsageChart from 'components/screen-usage-chart/screen-usage-chart';
import StatsNumber from 'components/stats-number/stats-number';
import StatsSessionResult from 'components/stats-session-result/stats-session-result';
import { useEffect, useState } from 'react';
import { getChildrenSessionResults, getDailyUsages } from 'services/childrens';
import BlockerPlugin from 'utils/BlockerPlugin';
import { defaultTimePeriod } from 'utils/date';
import styles from './children-stats.module.scss';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  RadialLinearScale,
  Title,
  Tooltip,
  Legend,
);

/* eslint-disable-next-line */
export interface ChildrenStatsProps {
  children: CommonStudent;
}

export function ChildrenStats({ children: child }: ChildrenStatsProps) {
  const { id } = child;
  const [{ data: results }, { data: dailyUsages }] = useQueries({
    queries: [
      {
        queryKey: ['results', id],
        queryFn: () => getChildrenSessionResults(id),
      },
      {
        queryKey: ['dailyUsages', id],
        queryFn: () => getDailyUsages(id),
      },
    ],
  });
  const [dailyUsage, setDailyUsage] = useState(0);

  useEffect(() => {
    BlockerPlugin.getDailyUsage().then((res) => {
      setDailyUsage(res.usage);
    });
  }, []);

  const todaySessions = results?.filter(
    (result) =>
      new Date(result.createdAt).getDate() === new Date().getDate() &&
      new Date(result.createdAt).getMonth() === new Date().getMonth() &&
      new Date(result.createdAt).getFullYear() === new Date().getFullYear(),
  );

  return (
    <Flex direction="column" justify="start" align="center" width="100%" height="100%">
      <Flex
        direction="column"
        gap={10}
        pad={20}
        width="100%"
        height="100%"
        style={{
          overflowY: 'scroll',
          paddingBottom: 50,
          paddingTop: 20,
        }}
      >
        <Flex gap={10} width="100%" style={{ marginBottom: 10 }}>
          <StatsNumber
            number={todaySessions?.length ?? 0}
            text="quiz aujourd'hui"
            tooltipInfo={<div className={styles.textToolTip}>Nombre de quiz effectué aujourd&apos;hui.</div>}
          />
          <StatsNumber
            number={dailyUsage}
            text="minutes de temps d'écran aujourd'hui"
            tooltipInfo={<div className={styles.textToolTip}>Temps de divertissement aujourd&apos;hui.</div>}
          />
        </Flex>
        {!!results?.length && (
          <AnsweredQuestionsChart results={results} timeRangeName="cette semaine" timePeriod={defaultTimePeriod} />
        )}
        <div style={{ height: 20 }} />
        {!!dailyUsages?.length && (
          <ScreenUsageChart dailyUsages={dailyUsages} timeRangeName="cette semaine" timePeriod={defaultTimePeriod} />
        )}
        <div style={{ height: 40 }} />
        {!!results?.length && (
          <div className={styles.inputWrapper}>
            <span className={styles.label}>
              <div style={{ fontSize: 14, fontWeight: 500 }}>Mes derniers quiz</div>
              <InfoTooltip>
                <div className={styles.textToolTip}>Nombre de bonnes réponses sur le nombre total de questions.</div>
              </InfoTooltip>
            </span>
          </div>
        )}
        {results
          ?.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
          .filter((q) => q.answeredQuestions.length > 0)
          .slice(0, 7)
          .map((result) => <StatsSessionResult key={result.id} result={result} />)}
      </Flex>
      <div style={{ height: 70 }} />
    </Flex>
  );
}

export default ChildrenStats;
