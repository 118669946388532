import { Haptics, ImpactStyle } from '@capacitor/haptics';
import chevronLeft from 'assets/icons/back-chevron-left-dark.svg';
import { useStatusBarHeight } from 'hooks/use-status-bar-height';
import { useNavigate } from 'react-router-dom';
import styles from './onboarding-header.module.scss';

/* eslint-disable-next-line */
export interface OnboardingHeaderProps {
  href?: string;
  onClick?: () => void;
  back?: boolean;
  percentage?: number;
}

export function OnboardingHeader({ href, onClick, back, percentage }: OnboardingHeaderProps) {
  const navigate = useNavigate();
  const statusBarHeight = useStatusBarHeight();

  const handleClick = () => {
    if (onClick) {
      onClick();
    } else if (href) {
      navigate(href);
    } else if (back) {
      navigate(-1);
    }
  };

  return (
    <div
      className={styles.onboardingHeader}
      style={{
        paddingTop: 15 + statusBarHeight,
      }}
    >
      {onClick ? (
        <button
          onClick={handleClick}
          type="button"
          onTouchStart={() => {
            Haptics.impact({ style: ImpactStyle.Light });
          }}
          onTouchEnd={() => {
            Haptics.impact({ style: ImpactStyle.Light });
          }}
        >
          <img src={chevronLeft} alt="Back" />
        </button>
      ) : (
        <div className={styles.space} />
      )}
      {percentage !== undefined && (
        <div className={styles.progressBar}>
          <div
            className={styles.progress}
            style={{
              width: `${percentage + 5 > 100 ? 100 : percentage + 5}%`,
            }}
          />
        </div>
      )}
      <div className={styles.space} />
    </div>
  );
}

export default OnboardingHeader;
