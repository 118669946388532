import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { Preferences } from '@capacitor/preferences';
import { CommonLogin } from '@codidae/common-types';
import { useMutation } from '@tanstack/react-query';
import AltusTalk from 'components/altus-talk/altus-talk';
import BackHeader from 'components/back-header/back-header';
import Button from 'components/button/button';
import Flex from 'components/flex/flex';
import InputPin from 'components/input-pin/input-pin';
import InputText from 'components/input-text/input-text';
import OrSeparator from 'components/or-separator/or-separator';
import Page from 'components/page/page';
import { useRef, useState } from 'react';
import { BsGoogle } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { SegmentEventType, track } from 'segment';
import BlockerPlugin from 'utils/BlockerPlugin';
import { loginWithCode, loginWithFirebaseToken, sendLoginCode } from '../../services/auth';

/* eslint-disable-next-line */
export interface MailLoginProps {}

export function MailLogin(props: MailLoginProps) {
  const navigate = useNavigate();
  const [codeSent, setCodeSent] = useState(false);

  const onLogin = async (data: CommonLogin) => {
    if (data.access_token.length) {
      await Preferences.set({
        key: 'access_token',
        value: data.access_token,
      });
      BlockerPlugin.metaInAppEvent({ eventName: 'login' });
      await Preferences.set({
        key: 'child_mode',
        value: null,
      });
      track(SegmentEventType.LOGIN, {
        email: emailRef.current,
      });
      navigate('/dashboard');
    }
  };

  const loginWithCodeMutation = useMutation({
    mutationFn: loginWithCode,
    onSuccess: onLogin,
  });
  const loginWithFirebaseTokenMutation = useMutation({
    mutationFn: loginWithFirebaseToken,
    onSuccess: onLogin,
  });
  const sendLoginCodeMutation = useMutation({
    mutationFn: sendLoginCode,
    onSuccess: async (data) => {
      if (data.success) {
        setCodeSent(true);
      }
    },
    onError: () => {
      alert("Votre email n'est pas valide");
    },
  });
  const emailRef = useRef<string>();
  const codeRef = useRef<string>();

  const submitSendCode = () => {
    if (!emailRef.current) return;
    sendLoginCodeMutation.mutate({
      email: emailRef.current,
    });
  };

  return (
    <Page>
      <Flex
        direction="column"
        justify="space-between"
        align="center"
        width="100%"
        height="100%"
        style={{ paddingTop: 100, overflow: 'auto' }}
      >
        <BackHeader title="Se connecter à Altus" back />
        <Flex direction="column" gap={30} pad={20} width="100%">
          <AltusTalk type={loginWithCodeMutation.isError ? 'sad' : 'hi'}>
            {codeSent ? 'Renseignez le code reçu sur votre boîte mail' : 'Rentrez votre email pour vous connecter'}
          </AltusTalk>
          <Flex direction="column" gap={10} width="100%">
            {!codeSent ? (
              <InputText
                placeholder="Votre email"
                type="email"
                onChange={(v) => {
                  emailRef.current = v;
                }}
              />
            ) : (
              <InputPin
                onChange={(v) => {
                  codeRef.current = v;
                }}
                title="Code de connexion"
              />
            )}
          </Flex>
        </Flex>
        <Flex direction="column" gap={20} pad={20} width="100%" align="center">
          <Button
            onClick={() => {
              if (!codeSent) {
                submitSendCode();
              } else {
                loginWithCodeMutation.mutate({
                  email: emailRef.current,
                  code: codeRef.current,
                });
              }
            }}
            disabled={loginWithCodeMutation.isPending || sendLoginCodeMutation.isPending}
            loading={loginWithCodeMutation.isPending || sendLoginCodeMutation.isPending}
          >
            {codeSent ? 'SE CONNECTER' : 'ENVOYER LE CODE'}
          </Button>
          <OrSeparator />
          <Button
            onClick={async () => {
              FirebaseAuthentication.signInWithGoogle().then(async () => {
                loginWithFirebaseTokenMutation.mutate({
                  token: (await FirebaseAuthentication.getIdToken()).token,
                });
              });
              FirebaseAuthentication.signOut();
            }}
            disabled={loginWithFirebaseTokenMutation.isPending}
            loading={loginWithFirebaseTokenMutation.isPending}
          >
            <BsGoogle /> SE CONNECTER AVEC GOOGLE
          </Button>
        </Flex>
      </Flex>
    </Page>
  );
}

export default MailLogin;
