import { Haptics, ImpactStyle } from '@capacitor/haptics';
import chevronLeft from 'assets/icons/back-chevron-left.svg';
import { useStatusBarHeight } from 'hooks/use-status-bar-height';
import { useNavigate } from 'react-router-dom';
import { newShade } from 'utils/colors';
import styles from './back-header.module.scss';

/* eslint-disable-next-line */
export interface BackHeaderProps {
  title?: React.ReactNode;
  href?: string;
  onClick?: () => void;
  icon?: React.ReactNode;
  back?: boolean;
  action?: React.ReactNode;
  backgroundColor?: string;
  hideButton?: boolean;
}

export function BackHeader({ onClick, href, back, action, backgroundColor, icon, title, hideButton }: BackHeaderProps) {
  const navigate = useNavigate();
  const statusBarHeight = useStatusBarHeight();

  const handleClick = () => {
    if (onClick) {
      onClick();
    } else if (href) {
      navigate(href);
    } else if (back) {
      navigate(-1);
    }
  };

  return (
    <div
      className={styles.backHeader}
      style={{
        paddingTop: 15 + statusBarHeight,
        backgroundColor,
        borderBottom: backgroundColor ? `${newShade(backgroundColor, -30)} solid 2px` : undefined,
      }}
    >
      {!hideButton ? (
        <button
          type="button"
          onClick={handleClick}
          onTouchStart={() => {
            Haptics.impact({ style: ImpactStyle.Light });
          }}
          onTouchEnd={() => {
            Haptics.impact({ style: ImpactStyle.Light });
          }}
          style={{
            backgroundColor: backgroundColor ? newShade(backgroundColor, -50) : undefined,
          }}
        >
          {icon || <img src={chevronLeft} alt="Back" />}
        </button>
      ) : (
        <div className={styles.space} />
      )}
      <div className={styles.title}>{title}</div>
      <div className={styles.space}>{action}</div>
    </div>
  );
}

export default BackHeader;
