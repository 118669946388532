import { CommonDailyUsage } from '@codidae/common-types';
import InfoTooltip from 'components/info-tooltip/info-tooltip';
import PremiumBlurLocker from 'components/premium-blur-locker/premium-blur-locker';
import { Bar } from 'react-chartjs-2';
import { getChartLabel, getDaysFromTimePeriod } from 'utils/date';
import styles from './screen-usage-chart.module.scss';

/* eslint-disable-next-line */
export interface ScreenUsageChartProps {
  dailyUsages: CommonDailyUsage[];
  locked?: boolean;
  timeRangeName: string;
  timePeriod: { start: Date; end: Date };
}

export function ScreenUsageChart({ dailyUsages, locked, timeRangeName, timePeriod }: ScreenUsageChartProps) {
  const groupBy = timePeriod.end.getTime() - timePeriod.start.getTime() < 1000 * 60 * 60 * 24 * 33 ? 1 : 30;
  return (
    <>
      <div className={styles.inputWrapper}>
        <span className={styles.label}>
          <div style={{ fontSize: 14, fontWeight: 500 }}>
            Temps d&apos;écran {timeRangeName.toLowerCase()} (en minutes)
          </div>
          <InfoTooltip>
            <div className={styles.textToolTip}>
              Fréquence du temps d&apos;écran passé sur ton téléphone par jour (uniquement sur les applications
              sélectionnées).
            </div>
          </InfoTooltip>
        </span>
      </div>
      <PremiumBlurLocker locked={locked}>
        <Bar
          key={timePeriod.start.getTime() + timePeriod.end.getTime()}
          data={{
            labels: getDaysFromTimePeriod(timePeriod)
              .map((date) => getChartLabel(timePeriod, date))
              .filter((_, index) => index % groupBy === 0),
            datasets: [
              {
                label: 'Temps',
                data: getDaysFromTimePeriod(timePeriod)
                  .filter((_, index) => index % groupBy === 0)
                  .map((date, index) => {
                    const firstUsage = dailyUsages?.findIndex(
                      (use) =>
                        new Date(use.createdAt).getDate() === date.getDate() &&
                        new Date(use.createdAt).getMonth() === date.getMonth() &&
                        new Date(use.createdAt).getFullYear() === date.getFullYear(),
                    );
                    const usages = dailyUsages?.slice(firstUsage, firstUsage + groupBy);
                    return usages?.reduce((acc, use) => acc + use.usage, 0);
                  }),
                backgroundColor: '#55a4f7',
                borderRadius: 5,
              },
            ],
          }}
          options={{
            plugins: {
              title: {
                display: false,
              },
              legend: {
                display: false,
              },
              tooltip: {
                callbacks: {
                  title: (tooltipItem) => ``,
                  label: (tooltipItem) => `${tooltipItem.parsed.y} minutes`,
                },
              },
            },
          }}
        />
      </PremiumBlurLocker>
    </>
  );
}

export default ScreenUsageChart;
