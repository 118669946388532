import React from 'react';
import Page from 'components/page/page';
import Button from 'components/button/button';
import Flex from 'components/flex/flex';
import BackHeader from 'components/back-header/back-header';
import AltusTalk from 'components/altus-talk/altus-talk';

/* eslint-disable-next-line */
export interface BlockerProps {}

export function BlockerNotChildPhone(props: BlockerProps) {
  return (
    <Page>
      <Flex
        direction="column"
        justify="space-between"
        align="center"
        width="100%"
        height="100%"
        style={{ paddingTop: 100 }}
      >
        <BackHeader title="Paramétrage du bloqueur" back />
        <AltusTalk>
          Mince ! Installes l&apos;application sur le téléphone de votre enfant pour pouvoir installer le bloqueur.
        </AltusTalk>
        <Flex direction="column" gap={10} pad={20} width="100%">
          <Button outline href="/dashboard">
            RETOUR
          </Button>
        </Flex>
      </Flex>
    </Page>
  );
}

export default BlockerNotChildPhone;
