import AltusTalk from 'components/altus-talk/altus-talk';
import Button from 'components/button/button';
import Flex from 'components/flex/flex';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Preferences } from '@capacitor/preferences';
import styles from './parent-warning-popup.module.scss';

/* eslint-disable-next-line */
export interface ParentWarningPopupProps {
  onClose: () => void;
}

export function ParentWarningPopup({ onClose }: ParentWarningPopupProps) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const handleSwitch = async () => {
    const confirm = window.confirm(
      'Ce téléphone est-il bien celui de votre enfant ?',
    );
    if (confirm) {
      await Preferences.set({ key: 'phone_owner', value: 'child' });
      searchParams.set('from_parent', 'false');
      navigate('/dashboard');
      setTimeout(() => {
        window.location.reload();
      }, 100);
    }
  };

  return (
    <div className={styles.wrapper} onClick={() => onClose()}>
      <div className={styles.popup} onClick={(e) => e.stopPropagation()}>
        <AltusTalk type="question" backgroundColor="white" width={100}>
          <b>
            Bloquer ce
            <br />
            téléphone ?
          </b>
          <br />
          <br />
          Vous basculez en mode enfant. Si vous êtes simplement curieux
          n’installez pas le bloqueur.
        </AltusTalk>
        <div style={{ height: 50 }} />
        <Flex direction="column" gap={15} align="center" width="100%">
          <Button color="dark" onClick={() => handleSwitch()}>
            CONFIGURER LE BLOQUEUR
          </Button>
          <Button
            onClick={() => {
              onClose();
              Preferences.set({ key: 'phone_owner', value: 'parent' });
            }}
          >
            VISITER L&apos;ESPACE ENFANT
          </Button>
          <Button
            outline
            onClick={async () => {
              const childrenId = await Preferences.get({ key: 'child_mode' });
              Preferences.set({ key: 'phone_owner', value: 'parent' });
              Preferences.set({ key: 'child_mode', value: 'null' });
              navigate(`/children/${childrenId.value}`);
            }}
          >
            QUITTER
          </Button>
        </Flex>
      </div>
    </div>
  );
}

export default ParentWarningPopup;
