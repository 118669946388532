import { App as CapacitorApp } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';
import { useMutation, useQueries, useQuery } from '@tanstack/react-query';
import ChildTabBar from 'components/child-tab-bar/child-tab-bar';
import ChildrenChapterSelection from 'components/children-chapter-selection/children-chapter-selection';
import ChildrenHeader from 'components/children-header/children-header';
import ChildrenHome from 'components/children-home/children-home';
import ChildrenSettings from 'components/children-settings/children-settings';
import ChildrenStats from 'components/children-stats/children-stats';
import ChildrenWorkedChapters from 'components/children-worked-chapters/children-worked-chapters';
import LoadingScreen from 'components/loading-screen/loading-screen';
import LoginStreakPopup from 'components/login-streak-popup/login-streak-popup';
import LogoutRangePage from 'components/logout-range-page/logout-range-page';
import Page from 'components/page/page';
import ParentWarningPopup from 'components/parent-warning-popup/parent-warning-popup';
import config from 'config';
import { useUserContext } from 'contexts/user-context';
import useDisableBackButton from 'hooks/disable-back-button';
import { createContext, useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getChildren, getDailyPoints, pushDailyUsage, updateChildrenPlatform } from 'services/childrens';
import BlockerPlugin from 'utils/BlockerPlugin';
import { nowInLogoutRange } from 'utils/date';
import { registerPushNotifications } from 'utils/notifications';

/* eslint-disable-next-line */
export interface ChildrenDashboardProps {
  childrenId: string;
}

export const ChildrenHeaderContext = createContext(null);

export type ChildrenDashboardTab = 'home' | 'progression' | 'chapters' | 'stats' | 'settings';

export function ChildrenDashboard({ childrenId }: ChildrenDashboardProps) {
  useDisableBackButton();
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState<ChildrenDashboardTab>('home');
  const { parentalControlSettings } = useUserContext();
  const [{ data, isLoading, refetch, isSuccess }] = useQueries({
    queries: [
      {
        queryKey: ['children', childrenId],
        queryFn: () => getChildren(childrenId),
      },
    ],
  });

  useEffect(() => {
    Preferences.set({ key: 'API_URL', value: config.api_baseurl });
  }, []);

  useEffect(() => {
    Preferences.get({ key: 'phone_owner' }).then((ownerRes) => {
      if (ownerRes.value === 'parent') return;
      BlockerPlugin.propagateParentalControlSettings(parentalControlSettings);
    });
  }, [parentalControlSettings]);

  useEffect(() => {
    if (isSuccess) handleChildLogic();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);
  const [parentWarningPopup, setParentWarningPopup] = useState(false);
  const { data: dataDailyPoints, refetch: refetchDailyPoints } = useQuery({
    queryKey: ['getDailyPoints'],
    queryFn: () => getDailyPoints(childrenId),
  });
  const updateChildrenPlatformMutation = useMutation({
    mutationFn: updateChildrenPlatform,
  });
  const pushDailyUsageMutation = useMutation({ mutationFn: pushDailyUsage });
  const [headerColor, setHeaderColor] = useState<string>(null);
  const [searchParams] = useSearchParams();
  const [blockerConfigured, setBlockerConfigured] = useState<boolean>();

  const handleChildLogic = useCallback(() => {
    if (!data) return;
    Preferences.get({ key: 'blockerConfigured' }).then((blockerRes) => {
      if (blockerRes.value !== null) setBlockerConfigured(blockerRes.value === 'true');
      Preferences.get({ key: 'phone_owner' }).then((ownerRes) => {
        if (blockerRes.value === null && ownerRes.value === 'child' && searchParams.get('from_parent') !== 'true') {
          if (Capacitor.getPlatform() === 'ios') {
            navigate(`/blocker/ios/${data.id}`);
          } else {
            navigate(`/blocker/android/${data.id}`);
          }
        } else {
          registerPushNotifications(data.id);

          BlockerPlugin.getDailyUsage().then((res) => {
            pushDailyUsageMutation.mutate({
              id: data.id,
              dailyUsage: res.usage,
            });
          });
          if (ownerRes.value === 'child' && data && data.phonePlatform !== Capacitor.getPlatform()) {
            updateChildrenPlatformMutation.mutate({
              id: data.id,
              platform: Capacitor.getPlatform(),
            });
          }
        }
      });
    });
  }, [data, navigate, pushDailyUsageMutation, updateChildrenPlatformMutation, searchParams]);

  useEffect(() => {
    if (data?.lockTime) {
      Preferences.set({
        key: 'lock_time',
        value: data?.lockTime?.toString(),
      });
    } else {
      Preferences.get({ key: 'lock_time' }).then((res) => {
        if (res.value === null) {
          Preferences.set({
            key: 'lock_time',
            value: '15',
          });
        }
      });
    }

    Preferences.set({
      key: 'maximumDailyLockTime',
      value: '90',
    });

    if (data && data.id) {
      handleChildLogic();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childrenId]);

  useEffect(() => {
    Preferences.get({ key: 'phone_owner' }).then((ownerRes) => {
      if (ownerRes.value === 'parent') {
        setParentWarningPopup(true);
      }
    });

    CapacitorApp.addListener('appStateChange', (event) => {
      if (event.isActive) {
        refetch();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (searchParams.get('from_parent') === 'true') {
      setParentWarningPopup(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <LoadingScreen />;
  }
  if (data && data.isLogoutRangeEnabled && nowInLogoutRange(data.logoutRangeMorning, data.logoutRangeEvening)) {
    return <LogoutRangePage />;
  }
  return (
    <ChildrenHeaderContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        color: headerColor,
        setColor: setHeaderColor,
      }}
    >
      <Page>
        {currentTab === 'home' && (
          <>
            <ChildrenHeader children={data} />
            <ChildrenHome children={data} blockerConfigured={blockerConfigured} />
          </>
        )}
        {currentTab === 'progression' && (
          <>
            <ChildrenHeader children={data} />
            <ChildrenWorkedChapters children={data} />
          </>
        )}
        {currentTab === 'chapters' && <ChildrenChapterSelection student={data} />}
        {currentTab === 'stats' && <ChildrenStats children={data} />}
        {currentTab === 'settings' && <ChildrenSettings children={data} />}
        <ChildTabBar currentTab={currentTab} setCurrentTab={setCurrentTab} />
        {dataDailyPoints > 1 && (
          <LoginStreakPopup
            points={dataDailyPoints}
            onClose={() => {
              refetchDailyPoints();
              refetch();
            }}
          />
        )}
        {parentWarningPopup && <ParentWarningPopup onClose={() => setParentWarningPopup(false)} />}
      </Page>
    </ChildrenHeaderContext.Provider>
  );
}

export default ChildrenDashboard;
