import AuthEmailParentPage from 'components/auth-email-parent/auth-email-parent';
import BlockerDashboard from 'components/blocker-dashboard/blocker-dashboard';
import HelpPage from 'components/help-page/help-page';
import LoginPairCode from 'components/login-pair-code/login-pair-code';
import NotFoundPage from 'components/not-found-page/not-found-page';
import OnboardingChoiceDevice from 'components/onboarding-choice-device/onboarding-choice-device';
import OnboardingChoiceInstalled from 'components/onboarding-choice-installed/onboarding-choice-installed';
import OnboardingChoiceLoginRegister from 'components/onboarding-choice-login-register/onboarding-choice-login-register';
import OnboardingChoiceLoginType from 'components/onboarding-choice-login-type/onboarding-choice-login-type';
import OnboardingQRCode from 'components/onboarding-qrcode/onboarding-qrcode';
import PairChildPhone from 'components/pair-child-phone/pair-child-phone';
import QRCodeAuth from 'components/qrcode-auth/qrcode-auth';
import TokenAuth from 'components/token-auth/token-auth';
import { createBrowserRouter } from 'react-router-dom';
import BlockerAndroid from 'views/blocker/android';
import BlockerNotChildPhone from 'views/blocker/blocker-not-child-phone';
import BlockerIos from 'views/blocker/ios';
import Children from 'views/children/_id';
import ChildrenChapters from 'views/children/chapters/_id';
import CreateChildren from 'views/children/create';
import ChildrenMap from 'views/children/map/_id';
import ParentalControlSettings from 'views/children/parental-control/_id';
import Session from 'views/children/session/_id';
import OfflineSession from 'views/children/session/offline';
import ChildrenSettings from 'views/children/settings/_id';
import ChildrenStats from 'views/children/stats/_id';
import Dashboard from 'views/dashboard';
import ForgotPassword from 'views/forgot-password';
import Index from 'views/index';
import MailLogin from 'views/login/mail';
import MailRegister from 'views/register/mail';
import Settings from 'views/settings';
import SubscriptionSettings from 'views/settings/subscription';
import Subscribe from 'views/subscribe/subscribe';
import Subscribe1 from 'views/subscribe/subscribe-1';
import Subscribe2 from 'views/subscribe/subscribe-2';
import SubscribeOffers from 'views/subscribe/subscribe-offers';
import SubscribeSuccessPage from 'views/subscribe/subscribe-success';
import Support from 'views/support';

export const router = createBrowserRouter([
  // Index
  {
    path: '/',
    element: <Index />,
    errorElement: <NotFoundPage />,
  },
  // Auth
  {
    path: '/login/mail',
    element: <MailLogin />,
    errorElement: <NotFoundPage />,
  },
  {
    path: '/login/pair-code',
    element: <LoginPairCode />,
    errorElement: <NotFoundPage />,
  },
  {
    path: '/register/mail',
    element: <MailRegister />,
    errorElement: <NotFoundPage />,
  },
  {
    path: '/forgot-password',
    element: <ForgotPassword />,
    errorElement: <NotFoundPage />,
  },
  {
    path: '/auth/pair/:id',
    element: <PairChildPhone />,
    errorElement: <NotFoundPage />,
  },
  {
    path: '/auth/qr/parent',
    element: <QRCodeAuth />,
    errorElement: <NotFoundPage />,
  },
  {
    path: '/auth/email/parent',
    element: <AuthEmailParentPage />,
    errorElement: <NotFoundPage />,
  },
  {
    path: '/auth/token/:token',
    element: <TokenAuth />,
    errorElement: <NotFoundPage />,
  },
  // Dashboard
  {
    path: '/dashboard',
    element: <Dashboard />,
    errorElement: <NotFoundPage />,
  },
  {
    path: '/settings',
    element: <Settings />,
    errorElement: <NotFoundPage />,
  },
  {
    path: '/settings/subscription',
    element: <SubscriptionSettings />,
    errorElement: <NotFoundPage />,
  },
  // Children
  {
    path: '/children/create',
    element: <CreateChildren />,
    errorElement: <NotFoundPage />,
  },
  {
    path: '/children/session/:id',
    element: <Session />,
    errorElement: <NotFoundPage />,
  },
  {
    path: '/children/session/offline',
    element: <OfflineSession />,
    errorElement: <NotFoundPage />,
  },
  {
    path: '/children/chapters/:id',
    element: <ChildrenChapters />,
    errorElement: <NotFoundPage />,
  },
  {
    path: '/children/stats/:id',
    element: <ChildrenStats />,
    errorElement: <NotFoundPage />,
  },
  {
    path: '/children/settings/:id',
    element: <ChildrenSettings />,
    errorElement: <NotFoundPage />,
  },
  {
    path: '/children/map/:id',
    element: <ChildrenMap />,
    errorElement: <NotFoundPage />,
  },
  {
    path: '/children/parental-control/:id',
    element: <ParentalControlSettings />,
    errorElement: <NotFoundPage />,
  },
  {
    path: '/children/:id',
    element: <Children />,
    errorElement: <NotFoundPage />,
  },
  // Support
  {
    path: '/support',
    element: <Support />,
    errorElement: <NotFoundPage />,
  },
  // Blocker
  {
    path: '/blocker/:id',
    element: <BlockerDashboard />,
    errorElement: <NotFoundPage />,
  },
  {
    path: '/blocker/not-child-phone',
    element: <BlockerNotChildPhone />,
    errorElement: <NotFoundPage />,
  },
  {
    path: '/blocker/ios/:id',
    element: <BlockerIos />,
    errorElement: <NotFoundPage />,
  },
  {
    path: '/blocker/android/:id',
    element: <BlockerAndroid />,
    errorElement: <NotFoundPage />,
  },
  // Onboarding
  {
    path: '/onboarding/choice/device',
    element: <OnboardingChoiceDevice />,
    errorElement: <NotFoundPage />,
  },
  {
    path: '/onboarding/choice/installed',
    element: <OnboardingChoiceInstalled />,
    errorElement: <NotFoundPage />,
  },
  {
    path: '/onboarding/choice/login-type',
    element: <OnboardingChoiceLoginType />,
    errorElement: <NotFoundPage />,
  },
  {
    path: '/onboarding/choice/login-register',
    element: <OnboardingChoiceLoginRegister />,
    errorElement: <NotFoundPage />,
  },
  {
    path: '/onboarding/qr-code',
    element: <OnboardingQRCode />,
    errorElement: <NotFoundPage />,
  },
  {
    path: '/subscribe/',
    element: <Subscribe />,
    errorElement: <NotFoundPage />,
  },
  {
    path: '/subscribe/arguments/1',
    element: <Subscribe1 />,
    errorElement: <NotFoundPage />,
  },
  {
    path: '/subscribe/arguments/2',
    element: <Subscribe2 />,
    errorElement: <NotFoundPage />,
  },
  {
    path: '/subscribe/offers',
    element: <SubscribeOffers />,
    errorElement: <NotFoundPage />,
  },
  {
    path: '/subscribe/success',
    element: <SubscribeSuccessPage />,
    errorElement: <NotFoundPage />,
  },
  {
    path: '/help',
    element: <HelpPage />,
    errorElement: <NotFoundPage />,
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
]);
