import axios from 'axios';
import { Preferences } from '@capacitor/preferences';
import config from '../config';

const HTTPClient = axios.create({
  baseURL: config.api_baseurl,
  headers: { ...config.default_headers },
});

const onResponseSuccess = (response) => response;

const onResponseFail = async (error) => {
  if (error.response && error.response.status === 401) {
    const accessToken = await Preferences.get({
      key: 'access_token',
    });
    if (accessToken.value) {
      await Preferences.clear();
      if (typeof window !== 'undefined') {
        window.open('/login/mail', '_self');
      }
    }
  }
  return Promise.reject(error.response);
};

HTTPClient.interceptors.request.use(
  async (c) => {
    const { value: token } = await Preferences.get({ key: 'access_token' });
    if (token) c.headers.Authorization = `Bearer ${token}`;
    return c;
  },
  (error) => Promise.reject(error),
);

HTTPClient.interceptors.response.use(onResponseSuccess, onResponseFail);

export default HTTPClient;
