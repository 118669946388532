import { CommonTimeRequest } from '@codidae/common-types';
import HTTPClient from 'utils/HTTPClient';

export async function getTimeRequests(): Promise<CommonTimeRequest[]> {
  return HTTPClient.get<CommonTimeRequest[]>('/time-request').then((data) =>
    data.data.map((tr) => ({ ...tr, createdAt: new Date(tr.createdAt) })),
  );
}

export async function createTimeRequest({ studentId }: { studentId: number }): Promise<CommonTimeRequest> {
  return HTTPClient.post<CommonTimeRequest>(`/time-request/${studentId}`).then((data) => ({
    ...data.data,
    createdAt: new Date(data.data.createdAt),
  }));
}

/**
 * if (awaredTime == 0) then parent has denied request.
 */
export async function answerTimeRequest({
  awardedTime,
  requestId,
}: {
  requestId: number;
  awardedTime: number;
}): Promise<CommonTimeRequest> {
  return HTTPClient.patch<CommonTimeRequest>(`/time-request/answer/${requestId}`, {
    awardedTime,
  }).then((data) => ({ ...data.data, createdAt: new Date(data.data.createdAt) }));
}
