import offline from 'assets/icons/offline.svg';
import plusIcon from 'assets/icons/plus.svg';
import Flex from 'components/flex/flex';
import { useOnlineContext } from 'contexts/online-context';
import { useMemo } from 'react';
import styles from './add-new-button.module.scss';

/* eslint-disable-next-line */
export interface AddNewButtonProps {
  onClick?: () => void;
  text: string;
  requiresConnection?: boolean;
}

export function AddNewButton({
  text,
  onClick,
  requiresConnection,
}: AddNewButtonProps) {
  const { isOnline } = useOnlineContext();
  const blockedOffline = useMemo(
    () => requiresConnection && !isOnline,
    [requiresConnection, isOnline],
  );

  const click = () => {
    if (blockedOffline) {
      window.alert(
        'Veuillez vous connecter à internet pour effectuer cette action.',
      );
      return;
    }
    onClick();
  };

  return (
    <Flex
      direction="column"
      gap={10}
      align="center"
      justify="center"
      className={styles.addNewButton}
    >
      <button
        onClick={click}
        type="button"
        className={blockedOffline ? styles.blocked : undefined}
      >
        <img src={plusIcon} alt="Plus" />
        {blockedOffline && (
          <div className={styles.offlineOverlay}>
            <img src={offline} alt="Hors ligne" />
          </div>
        )}
      </button>
      <div className={styles.text}>{text}</div>
    </Flex>
  );
}

export default AddNewButton;
