import AltusTalk from 'components/altus-talk/altus-talk';
import Button from 'components/button/button';
import styles from './login-streak-popup.module.scss';

/* eslint-disable-next-line */
export interface LoginStreakPopupProps {
  points: number;
  onClose: () => void;
}

export function LoginStreakPopup({ onClose, points }: LoginStreakPopupProps) {
  return (
    <div className={styles.wrapper} onClick={() => onClose()}>
      <div className={styles.popup} onClick={(e) => e.stopPropagation()}>
        <AltusTalk type="animated_fire" backgroundColor="white">
          Bravo ! Cela fait {points} jours d&apos;affilés que tu te connectes à
          Altus !
        </AltusTalk>
        <div style={{ height: 50 }} />
        <Button onClick={() => onClose()}>CONTINUER</Button>
      </div>
    </div>
  );
}

export default LoginStreakPopup;
