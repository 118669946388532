import AltusPlus from 'components/altus-plus/altus-plus';
import Button from 'components/button/button';
import EnterPinView from 'components/enter-pin-view/enter-pin-view';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SegmentEventType, track } from 'segment';
import styles from './paywall-text.module.scss';

export default function PaywallText() {
  const [parentQRCodePopup, setParentQRCodePopup] = useState(false);
  const navigate = useNavigate();

  if (parentQRCodePopup) {
    return (
      <EnterPinView
        onValidate={() => {
          navigate('/subscribe');
        }}
        onCancel={() => setParentQRCodePopup(false)}
      />
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.textWrapper}>
        <div className={styles.text}>
          Débloquez <br />
          cette fonctionnalité avec
        </div>
        <AltusPlus />
      </div>
      <Button
        className={styles.ctaButton}
        onClick={() => {
          setParentQRCodePopup(true);
          track(SegmentEventType.CLICK_CHILD_PAYWALL);
        }}
      >
        EN SAVOIR PLUS
      </Button>
    </div>
  );
}
