import InfoTooltip from 'components/info-tooltip/info-tooltip';
import styles from './stats-number.module.scss';

/* eslint-disable-next-line */
export interface StatsNumberProps {
  number: number | string;
  text: string;
  tooltipInfo?: React.ReactNode;
  className?: string;
  small?: boolean;
}

export function StatsNumber({ number, text, tooltipInfo, className, small }: StatsNumberProps) {
  return (
    <div className={`${styles['container']} ${className ?? ''} ${small ? styles.small : ''}`}>
      <div className={styles['container2']}>
        <div className={styles['number']}>{number}</div>
        {tooltipInfo && <InfoTooltip>{tooltipInfo}</InfoTooltip>}
      </div>
      <div className={styles['text']}>{text}</div>
    </div>
  );
}

export default StatsNumber;
