import { Preferences } from '@capacitor/preferences';
import Button, { ButtonColor } from 'components/button/button';
import usePaywallRedirect from 'hooks/paywall-redirect';
import { useEffect, useState } from 'react';
import { BiBook, BiChart, BiCog, BiHome, BiLock, BiShield } from 'react-icons/bi';
import { useLocation, useNavigate } from 'react-router-dom';
import { SegmentEventType, track } from 'segment';
import styles from './tab-bar.module.scss';

/* eslint-disable-next-line */
export interface TabBarProps {
  childrenId: string;
}

export function TabBar({ childrenId }: TabBarProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const [onboarding, setOnboarding] = useState<boolean>(false);

  useEffect(() => {
    const checkOnboarding = async () => {
      const phoneOwner = await Preferences.get({ key: 'onboarding' });
      setOnboarding(phoneOwner.value === 'true');
    };

    checkOnboarding();
  });

  const redirectPaywall = usePaywallRedirect(0.02);
  const navigateTrack = (path: string) => {
    track(SegmentEventType.DASHBOARD_TAB, {
      tab: path,
    });
    navigate(path);

    redirectPaywall();
  };

  const getOnboardingStep = (): {
    id: string;
    title: string;
    text: string;
    icon: JSX.Element;
    next?: string;
    end?: boolean;
    color: string;
    buttonColor?: ButtonColor;
  } => {
    track(SegmentEventType.DASHBOARD_ONBOARDING_STEP, {
      step: location.pathname,
    });
    switch (location.pathname) {
      case `/children/${childrenId}`:
        return {
          id: 'home',
          title: 'Tableau de bord',
          text: 'Ayez un apperçu des progrès de votre enfant.',
          icon: <BiHome />,
          next: `/children/chapters/${childrenId}`,
          color: '#7555f7',
          buttonColor: 'primary',
        };
      case `/children/chapters/${childrenId}`:
        return {
          id: 'chapters',
          title: 'Matières',
          text: 'Séléctionnez les matières à travailler.',
          icon: <BiBook />,
          next: `/children/stats/${childrenId}`,
          color: '#FF9254',
          buttonColor: 'orange',
        };
      case `/children/stats/${childrenId}`:
        return {
          id: 'stats',
          title: 'Statistiques',
          text: 'Suivez les progrès de votre enfant.',
          icon: <BiLock />,
          next: `/children/parental-control/${childrenId}`,
          color: '#53D670',
          buttonColor: 'green',
        };
      case `/children/parental-control/${childrenId}`:
        return {
          id: 'parental-control',
          title: 'Contrôle parental',
          text: 'Configurez le contrôle parental.',
          icon: <BiShield />,
          next: `/children/settings/${childrenId}`,
          color: '#55a4f7',
          buttonColor: 'primary',
        };
      case `/children/settings/${childrenId}`:
        return {
          id: 'settings',
          title: 'Paramètres',
          text: 'Configurez les paramètres généraux.',
          icon: <BiChart />,
          next: `/children/${childrenId}`,
          end: true,
          color: '#26436F',
          buttonColor: 'dark',
        };
      default:
        return null;
    }
  };

  const onboardingStep = onboarding && getOnboardingStep();
  return (
    <>
      {onboarding && onboardingStep && (
        <div className={styles.onboarding}>
          <div
            className={`${styles.popup} ${styles[onboardingStep.id]}`}
            style={{
              color: onboardingStep.color,
              borderColor: onboardingStep.color,
            }}
          >
            {onboardingStep.icon}
            <div className={styles.title}>{onboardingStep.title}</div>
            <div className={styles.text}>{onboardingStep.text}</div>
            <Button
              color={onboardingStep.buttonColor}
              onClick={() => {
                if (onboardingStep.end) {
                  Preferences.set({
                    key: 'onboarding',
                    value: 'false',
                  });
                }
                navigate(onboardingStep.next);
              }}
            >
              {onboardingStep.end ? "C'EST PARTI !" : 'SUIVANT'}
            </Button>
          </div>
        </div>
      )}
      <div className={styles.bar}>
        <BiHome
          onClick={() => navigateTrack(`/children/${childrenId}`)}
          className={location.pathname === `/children/${childrenId}` ? styles.active : ''}
        />
        <BiBook
          onClick={() => navigateTrack(`/children/chapters/${childrenId}`)}
          className={location.pathname === `/children/chapters/${childrenId}` ? styles.active : ''}
        />
        <BiChart
          onClick={() => navigateTrack(`/children/stats/${childrenId}`)}
          className={location.pathname === `/children/stats/${childrenId}` ? styles.active : ''}
        />
        <BiShield
          onClick={() => navigateTrack(`/children/parental-control/${childrenId}`)}
          className={location.pathname === `/children/parental-control/${childrenId}` ? styles.active : ''}
        />
        <BiCog
          onClick={() => navigateTrack(`/children/settings/${childrenId}`)}
          className={location.pathname === `/children/settings/${childrenId}` ? styles.active : ''}
        />
      </div>
    </>
  );
}

export default TabBar;
