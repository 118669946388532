import info from 'assets/icons/info.svg';
import { useCallback, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './info-tooltip.module.scss';

export default function InfoTooltip({ children }: { children?: React.ReactNode }) {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const getScrollParent = useCallback((node) => {
    if (node == null) {
      return null;
    }

    if (node.scrollHeight > node.clientHeight) {
      return node;
    }
    return getScrollParent(node.parentNode);
  }, []);

  return (
    <>
      <div onClick={() => setOpen(true)} ref={ref} id="info-tooltip">
        <img src={info} alt="info" width={16} height={16} />
      </div>
      {open && (
        <>
          <div className={styles.tooltipBg} onClick={() => setOpen(false)} />
          <div
            className={styles.tooltip}
            style={{
              top: (ref.current?.getBoundingClientRect().top ?? 0) + 24,
            }}
          >
            <div
              className={styles.tooltipTriangle}
              style={{
                left: (ref.current?.getBoundingClientRect().left ?? 0) - 24,
              }}
            />
            {children}
            <div>
              <Link to="/help/" className={styles.tooltipLink}>
                En savoir plus
              </Link>
            </div>
          </div>
        </>
      )}
    </>
  );
}
