import styles from './dialog.module.scss';

export default function Dialog({
  open,
  onClose,
  children,
}: {
  open: boolean;
  onClose: VoidFunction;
  children?: React.ReactNode;
}) {
  if (!open) return null;
  return (
    <div className={styles.dialogWrapper}>
      <div className={styles.background} onClick={onClose} />

      <div className={styles.content}>{children}</div>
    </div>
  );
}
