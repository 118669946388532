import { CommonSkillField } from '@codidae/common-types';
import { useQueries } from '@tanstack/react-query';
import AltusTalk from 'components/altus-talk/altus-talk';
import FieldsRadarChart from 'components/fields-radar-chart/fields-radar-chart';
import Select from 'components/select/select';
import StatsWorkedChapter from 'components/stats-worked-chapter/stats-worked-chapter';
import { useCallback, useMemo, useState } from 'react';
import { getChildrenWorkedChapters } from 'services/childrens';

/* eslint-disable-next-line */
export interface ParentStatsChaptersProps {
  childrenId: string;
}

export function ParentStatsChapters({ childrenId }: ParentStatsChaptersProps) {
  const [{ data: workedChapters }] = useQueries({
    queries: [
      {
        queryKey: ['workedChapters', childrenId],
        queryFn: () => getChildrenWorkedChapters(childrenId),
        staleTime: 3600000 * 3,
      },
    ],
  });
  const [fieldFilter, setFieldFilter] = useState<number | null>(null);

  const getFieldAverageElo = useCallback(
    (fieldId: number) => {
      const fieldChapters = workedChapters?.filter((chapter) => chapter.chapter.field.id === fieldId);

      const totalElo = fieldChapters?.map((c) => c.elo).reduce((acc, chapter) => acc + chapter);

      return totalElo / (fieldChapters?.length ?? 1);
    },
    [workedChapters],
  );

  const workedFields = useMemo(() => {
    const fields = [] as (CommonSkillField & { elo: number })[];
    workedChapters?.forEach((chapter) => {
      if (!fields.find((field) => field.id === chapter.chapter.field.id)) {
        fields.push({
          ...chapter.chapter.field,
          elo: getFieldAverageElo(chapter.chapter.field.id),
        });
      }
    });
    return fields;
  }, [getFieldAverageElo, workedChapters]);

  const diffifultChapters = useMemo(
    () =>
      workedChapters
        ?.filter((chapter) => !fieldFilter || chapter.chapter.field.id === fieldFilter)
        ?.filter((chapter) => chapter.elo < 400 && chapter.tryCount > 100)
        ?.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()),
    [workedChapters, fieldFilter],
  );

  return (
    <>
      {workedFields?.length > 0 && <FieldsRadarChart workedFields={workedFields} />}
      <Select
        options={[
          {
            value: null,
            label: 'Toutes les matières',
          },
          ...workedFields.map((field) => ({
            value: String(field.id),
            label: field.name,
          })),
        ]}
        defaultValue={null}
        placeholder="Filtrer par matière"
        onChange={(value) => setFieldFilter(Number(value))}
        labeled
      />
      {!workedChapters?.length && (
        <>
          <div />
          <AltusTalk variant="small" type="hard">
            Je n&apos;ai pas encore reçu de donnée pour vous afficher des statistiques ! Revenez ici dans quelques
            heures
          </AltusTalk>
        </>
      )}
      {!!diffifultChapters?.length && (
        <div style={{ fontSize: 14, fontWeight: 500, marginTop: 30 }}>Les chapitres en difficulté</div>
      )}
      {diffifultChapters
        ?.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime())
        .map((chapter) => <StatsWorkedChapter key={chapter.id} workedChapter={chapter} />)}
      {!!workedChapters?.length && (
        <div style={{ fontSize: 14, fontWeight: 500, marginTop: 30 }}>Les chapitres travaillés</div>
      )}
      {workedChapters
        ?.filter((chapter) => !fieldFilter || chapter.chapter.field.id === fieldFilter)
        .filter((chapter) => !diffifultChapters?.find((c) => c.id === chapter.id))
        ?.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime())
        .map((chapter) => <StatsWorkedChapter key={chapter.id} workedChapter={chapter} />)}
    </>
  );
}

export default ParentStatsChapters;
