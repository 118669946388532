import { CommonChapter, CommonEducationalCycle, CommonSkillField } from '@codidae/common-types';
import HTTPClient from 'utils/HTTPClient';

export function getClassLevels(): Promise<CommonEducationalCycle[]> {
  return HTTPClient.get('/educational-cycle/class-levels').then((data) => data.data);
}

export function getFields(): Promise<CommonSkillField[]> {
  return HTTPClient.get('/skill/field').then((data) => data.data);
}

export function getChapters(): Promise<CommonChapter[]> {
  return HTTPClient.get('/chapter').then((data) => data.data);
}
