import { Preferences } from '@capacitor/preferences';
import { useQuery } from '@tanstack/react-query';
import LoadingScreen from 'components/loading-screen/loading-screen';
import SessionOverUi from 'components/session-over/session-over-ui';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getSession, getSessionResult } from 'services/session';

/* eslint-disable-next-line */
export interface SessionOverProps {
  sessionId: string;
  earnedTime: number;
  outOfLifes?: boolean;
}

export function SessionOver({ earnedTime, sessionId, outOfLifes }: SessionOverProps) {
  const { data, isSuccess } = useQuery({
    queryKey: ['getSessionResult', sessionId],
    queryFn: () => getSessionResult(sessionId),
  });
  const { data: session, isSuccess: isSuccessSession } = useQuery({
    queryKey: ['getSession', sessionId],
    queryFn: () => getSession(sessionId),
  });
  const [searchParams] = useSearchParams();

  const unlockSession = searchParams.get('unlock') === 'true';
  const parentSession = searchParams.get('parent') === 'true';

  useEffect(() => {
    if (outOfLifes && unlockSession) {
      Preferences.set({
        key: 'last_out_of_lifes_session',
        value: Date.now().toString(),
      });
    }
  }, [outOfLifes, unlockSession]);

  if (!isSuccess || !isSuccessSession) {
    return <LoadingScreen />;
  }

  return (
    <SessionOverUi
      answeredQuestions={session.answeredQuestions.length}
      correctAnswered={data.correctAnswered}
      earnedTime={earnedTime}
      exerciceCount={session.exercices.length}
      unlockSession={unlockSession}
      outOfLives={outOfLifes}
      parentSession={parentSession}
    />
  );
}

export default SessionOver;
