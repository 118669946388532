import { Capacitor } from '@capacitor/core';
import { CapacitorPurchases, Offering } from '@capgo/capacitor-purchases';
import { CommonUser } from '@codidae/common-types';
import oneChildren from 'assets/subscription/1.svg';
import twoChildrens from 'assets/subscription/2.svg';
import threeChildrens from 'assets/subscription/3.svg';
import fourChildrens from 'assets/subscription/4.svg';
import SubscriptionBackHeader from 'components/altus-plus/subscription-back-header';
import Flex from 'components/flex/flex';
import Grid from 'components/grid/grid';
import SubscriptionButton from 'components/subscription-button/subscription-button';
import SubscriptionTimeButton from 'components/subscription-time-button/subscription-time-button';
import config from 'config';
import { useUserContext } from 'contexts/user-context';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SegmentEventType, track } from 'segment';
import styles from './subscription.module.scss';

/* eslint-disable-next-line */
export interface SubscriptionProps {
  onSubscription: () => void;
  user: CommonUser;
}

export function Subscription({ onSubscription, user }: SubscriptionProps) {
  const [offering, setOffering] = useState<Offering>(null);
  const [offerings, setOfferings] = useState<{ [key: string]: Offering }>(null);
  const [childrenCount, setChildren] = useState(-1);
  const navigate = useNavigate();
  const timer = useRef(null);
  const { refetchSubscription } = useUserContext();

  const getOfferings = useCallback(async () => {
    if (Capacitor.getPlatform() === 'ios') {
      await CapacitorPurchases.setup({
        apiKey: config.apple_rc_key,
        appUserID: user.id.toString(),
      });
    } else if (Capacitor.getPlatform() === 'android') {
      await CapacitorPurchases.setup({
        apiKey: config.google_rc_key,
        appUserID: user.id.toString(),
      });
    }

    const { offerings: offers } = await CapacitorPurchases.getOfferings();
    if (offers.all !== null) {
      setOfferings(offers.all);
      setOffering(offers['default']);
    }
  }, [user.id]);

  useEffect(() => {
    CapacitorPurchases.addListener('purchasesUpdate', (r) => {
      timer.current = setInterval(() => {
        onSubscription();
      }, 500);
    });
    getOfferings();

    return () => {
      clearInterval(timer.current);
    };
  }, [getOfferings, onSubscription]);

  useEffect(() => {
    if (offerings) {
      if (childrenCount <= 1) {
        setOffering(offerings['default']);
      } else if (childrenCount === 2) {
        setOffering(offerings['default-2']);
      } else if (childrenCount === 3) {
        setOffering(offerings['default-3']);
      } else if (childrenCount === 4) {
        setOffering(offerings['default-4']);
      }
    }
  }, [childrenCount, offerings]);

  useEffect(() => {
    track(SegmentEventType.SUBSCRIBE_CHILDREN_COUNT, {
      childrenCount,
    });
  }, [childrenCount]);

  const nbChildren = user.childrens.length ?? 0;

  return (
    <div className={styles.background}>
      <Flex direction="column" align="center" width="100%" height="100%" pad="20px" style={{ gap: '8px' }}>
        <SubscriptionBackHeader
          onBack={
            childrenCount >= 0
              ? () => setChildren(-1)
              : () => {
                  track(SegmentEventType.EXIT_PAYMENT_FLOW);
                  navigate(-4);
                }
          }
        />
        {childrenCount < 0 && (
          <div className={`${styles.subscriptionText} ${styles.marginAuto}`}>
            Vos enfants apprennent <br />
            <b>2.9x</b> fois plus vite avec
            <br /> Altus Plus
          </div>
        )}
        {childrenCount >= 0 ? (
          <Flex direction="column" gap={10} width="100%">
            {/* {JSON.stringify(offerings)} */}
            {offering?.monthly && (
              <SubscriptionTimeButton
                onClick={() => {
                  CapacitorPurchases.purchasePackage({
                    identifier: offering.monthly.identifier,
                    offeringIdentifier: offering.monthly.offeringIdentifier,
                  }).then(() => {
                    refetchSubscription();
                    navigate('/subscribe/success');
                    track(SegmentEventType.PURCHASE_SUBSCRIPTION, {
                      type: 'monthly',
                      childrenCount,
                    }).catch(() => {
                      track(SegmentEventType.CANCEL_PURCHASE_SUBSCRIPTION, {
                        type: 'annual',
                        childrenCount,
                      });
                    });
                  });
                }}
                time="Mensuel"
                realPrice={undefined}
                price={offering.monthly.product.price}
                per="mois"
                promo={undefined}
              />
            )}
            {offering?.threeMonth && (
              <SubscriptionTimeButton
                onClick={() => {
                  CapacitorPurchases.purchasePackage({
                    identifier: offering.threeMonth.identifier,
                    offeringIdentifier: offering.threeMonth.offeringIdentifier,
                  })
                    .then(() => {
                      refetchSubscription();
                      track(SegmentEventType.PURCHASE_SUBSCRIPTION, {
                        type: 'quarterly',
                        childrenCount,
                      });
                      navigate('/subscribe/success');
                    })
                    .catch(() => {
                      track(SegmentEventType.CANCEL_PURCHASE_SUBSCRIPTION, {
                        type: 'annual',
                        childrenCount,
                      });
                    });
                }}
                time="Trimestriel"
                realPrice={offering.threeMonth.product.price}
                price={offering.threeMonth.product.price / 3 - 0.01}
                per="trimestre"
                promo={25}
              />
            )}
            {offering?.annual && (
              <SubscriptionTimeButton
                onClick={() => {
                  CapacitorPurchases.purchasePackage({
                    identifier: offering.annual.identifier,
                    offeringIdentifier: offering.annual.offeringIdentifier,
                  })
                    .then(() => {
                      refetchSubscription();
                      track(SegmentEventType.PURCHASE_SUBSCRIPTION, {
                        type: 'annual',
                        childrenCount,
                      });
                      navigate('/subscribe/success');
                    })
                    .catch(() => {
                      track(SegmentEventType.CANCEL_PURCHASE_SUBSCRIPTION, {
                        type: 'annual',
                        childrenCount,
                      });
                    });
                }}
                time="Annuel"
                realPrice={offering.annual.product.price}
                price={offering.annual.product.price / 12 - 0.01}
                per="an"
                promo={60}
              />
            )}
          </Flex>
        ) : (
          <div className={styles.offerWrapper}>
            {nbChildren > 1 && (
              <div className={styles.lockedOfferExplanation}>
                Certaines offres sont bloquées. <br />
                Veuillez supprimer un ou plusieurs profils de votre compte pour les débloquer.
              </div>
            )}
            <Grid columns={2} rows={2} width="100%" gap={15}>
              <SubscriptionButton
                backgroundHeight={50}
                onClick={() => setChildren(1)}
                background={oneChildren}
                minPrice={3.99}
                count="1 enfant"
                disabled={nbChildren > 1}
              />
              <SubscriptionButton
                backgroundHeight={50}
                onClick={() => setChildren(2)}
                background={twoChildrens}
                minPrice={5.99}
                count="2 enfants"
                disabled={nbChildren > 2}
              />
              <SubscriptionButton
                backgroundHeight={100}
                onClick={() => setChildren(3)}
                background={threeChildrens}
                minPrice={7.99}
                count="3 enfants"
                disabled={nbChildren > 3}
              />
              <SubscriptionButton
                backgroundHeight={100}
                onClick={() => setChildren(4)}
                background={fourChildrens}
                minPrice={9.99}
                count="4 enfants"
              />
            </Grid>
          </div>
        )}
      </Flex>
    </div>
  );
}

export default Subscription;
