import BackHeader from 'components/back-header/back-header';
import FaqQuestion from 'components/faq-question/faq-question';
import Flex from 'components/flex/flex';
import InputText from 'components/input-text/input-text';
import Page from 'components/page/page';
import TutorialCard from 'components/tutorial-card/tutorial-card';
import FAQ from 'content/faq.json';
import tutorialsObject from 'content/tutorials.json';
import usePaywallRedirect from 'hooks/paywall-redirect';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styles from './help-page.module.scss';

/* eslint-disable-next-line */
export interface HelpPageProps {}

export function HelpPage(props: HelpPageProps) {
  const [search, setSearch] = useState<string>('');
  const { tutorials, text_tutorials: textTutorials } = tutorialsObject;
  const [searchParams] = useSearchParams();
  const defaultTutorial = useMemo(() => searchParams.get('tutorial_id') ?? null, [searchParams]);
  const navigate = useNavigate();
  const redirectPaywall = usePaywallRedirect(0.1, true);

  useEffect(() => {
    document.getElementsByClassName('focused-tutorial')?.item(0)?.scrollIntoView();
  }, []);

  return (
    <Page parentMode>
      <Flex
        direction="column"
        justify="space-between"
        align="center"
        width="100%"
        height="100vh"
        style={{ paddingTop: 67 }}
      >
        <BackHeader
          onClick={() => {
            if (!redirectPaywall()) {
              navigate(-1);
            }
          }}
          title="FAQ et tutoriels"
        />
        <Flex
          direction="column"
          gap={10}
          pad={20}
          width="100%"
          style={{ flexGrow: 1, overflowY: 'scroll' }}
          height="100%"
        >
          <InputText placeholder="Rechercher" onChange={(v) => setSearch(v)} value={search} />
          <Flex direction="column" gap={10} width="100%">
            <Flex direction="column" gap={10} width="100%">
              {tutorials.filter((tutorial) => tutorial.name.toLowerCase().includes(search.toLowerCase())).length >
                0 && <div className={styles.categoryName}>Tutoriels</div>}
              {tutorials
                .filter((tutorial) => tutorial.name.toLowerCase().includes(search.toLowerCase()))
                .map((tutorial) => (
                  <TutorialCard tutorial={tutorial} key={tutorial.name} />
                ))}
              {textTutorials.map((question) => (
                <FaqQuestion
                  question={question}
                  key={question.id}
                  defaultOpen={defaultTutorial === question.id}
                  className={defaultTutorial === question.id ? 'focused-tutorial' : undefined}
                />
              ))}
            </Flex>
            {FAQ.categories.map((category) => (
              <Flex direction="column" gap={10} key={category.name} width="100%">
                {category.questions.filter((question) => question.question.toLowerCase().includes(search.toLowerCase()))
                  .length > 0 && <div className={styles.categoryName}>{category.name}</div>}
                {category.questions
                  .filter((question) => question.question.toLowerCase().includes(search.toLowerCase()))
                  .map((question) => (
                    <FaqQuestion question={question} key={question.question} />
                  ))}
              </Flex>
            ))}
          </Flex>
        </Flex>
      </Flex>
    </Page>
  );
}

export default HelpPage;
