import { CommonSkillField } from '@codidae/common-types';
import selectedIcon from 'assets/icons/selected.svg';
import { getColorFromFieldId } from 'utils/colors';
import { getIconFromFieldId } from 'utils/fieldIcons';
import styles from './field-card.module.scss';

/* eslint-disable-next-line */
export interface FieldCardProps {
  field: CommonSkillField;
  toggleSelect: () => void;
  selected: boolean;
}

export function FieldCard({ field, toggleSelect, selected }: FieldCardProps) {
  return (
    <div
      className={styles.card}
      style={{
        backgroundColor: getColorFromFieldId(field.id),
        boxShadow: `0px 4px 0px  0px ${getColorFromFieldId(field.id)}66`,
      }}
    >
      <div
        className={styles.header}
        onClick={() => {
          toggleSelect();
        }}
      >
        <div className={styles.title}>
          <div className={styles.icon}>{getIconFromFieldId(field.id)}</div>
          <div>{field?.name}</div>
          {/* {limitTimestamp !== undefined && (
            <div className={styles.limit}>
              {field?.field ? `${field?.field?.name} - ` : ''}
              {field.cycles?.length !== 0 && (
                <>{field.cycles?.map((cycle) => cycle.name).join(', ')} </>
              )}
            </div>
          )} */}
        </div>
        <div
          className={`${styles.select} ${selected ? styles.selected : ''}`}
          onClick={() => {
            toggleSelect();
          }}
        >
          <img src={selectedIcon} alt="Selected" />
        </div>
      </div>
    </div>
  );
}

export default FieldCard;
