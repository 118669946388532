import { App as CapacitorApp } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { createContext, useContext, useEffect, useState } from 'react';
import BlockerPlugin from 'utils/BlockerPlugin';

interface AndroidRedirectionContextProps {
  redirected: boolean;
  resetRedirected: () => void;
}

const AndroidRedirectionContext = createContext<AndroidRedirectionContextProps>(
  {
    redirected: false,
    resetRedirected() {},
  },
);

interface AndroidRedirectionProviderProps {
  children: React.ReactNode;
}

export default function AndroidRedirectionProvider({
  children,
}: AndroidRedirectionProviderProps) {
  const isAndroid = Capacitor.getPlatform() === 'android';
  const [redirected, setRedirected] = useState(false);

  useEffect(() => {
    if (isAndroid)
      CapacitorApp.addListener('appStateChange', (event) => {
        if (event.isActive) {
          BlockerPlugin.checkIsRedirected().then((data) => {
            setRedirected(data.redirected);
            console.log('redirected?', data.redirected);
          });
        }
      });
  }, [isAndroid]);

  if (!isAndroid) return <>{children}</>;
  return (
    <AndroidRedirectionContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        redirected,
        resetRedirected() {
          setRedirected(false);
        },
      }}
    >
      {children}
    </AndroidRedirectionContext.Provider>
  );
}

export function useAndroidRedirectionContext() {
  return useContext(AndroidRedirectionContext);
}

export { AndroidRedirectionProvider };
