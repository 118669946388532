import { Haptics, ImpactStyle } from '@capacitor/haptics';
import offline from 'assets/icons/offline.svg';
import { useOnlineContext } from 'contexts/online-context';
import { useMemo } from 'react';
import styles from './card.module.scss';

/* eslint-disable-next-line */
export interface CardProps {
  style?: React.CSSProperties;
  children: React.ReactNode;
  onClick?: () => void;
  className?: string;
  requiresConnection?: boolean;
}

export function Card({ className, onClick, style, children, requiresConnection }: CardProps) {
  const { isOnline } = useOnlineContext();

  const blockedOffline = useMemo(() => requiresConnection && !isOnline, [requiresConnection, isOnline]);

  const clickFct = () => {
    if (onClick && !blockedOffline) onClick();
    else if (onClick) window.alert('Veuillez vous connecter à internet pour effectuer cette action.');
  };

  return (
    <div
      className={`${styles.card} ${className ?? ''} ${
        onClick ? styles.clickable : ''
      } ${blockedOffline ? styles.blocked : ''}`}
      style={style}
      onClick={clickFct}
      onTouchStart={() => {
        if (!onClick) return;
        Haptics.impact({ style: ImpactStyle.Light });
      }}
      onTouchEnd={() => {
        if (!onClick) return;
        Haptics.impact({ style: ImpactStyle.Light });
      }}
    >
      {children}
      {blockedOffline && (
        <div className={styles.offlineOverlay}>
          <img src={offline} alt="Hors ligne" />
        </div>
      )}
    </div>
  );
}

export default Card;
