import AltusTalk from 'components/altus-talk/altus-talk';
import BackHeader from 'components/back-header/back-header';
import Button from 'components/button/button';
import Flex from 'components/flex/flex';
import InputText from 'components/input-text/input-text';
import Page from 'components/page/page';
import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { createTicket } from 'services/tickets';

/* eslint-disable-next-line */
export interface SupportProps {}

export function Support() {
  const navigate = useNavigate();
  const createTicketMutation = useMutation({
    mutationFn: createTicket,
    onSuccess: () => {
      navigate(-1);
    },
  });
  const [subject, setSubject] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [params] = useSearchParams();
  const isChild = params.get('child') === 'true';

  return (
    <Page parentMode={!isChild}>
      <Flex
        direction="column"
        justify="space-between"
        align="center"
        width="100%"
        height="100%"
        style={{ paddingTop: 100 }}
      >
        <BackHeader back title="Support" />
        <Flex direction="column" gap={20} pad={20} width="100%">
          <AltusTalk type="question">
            Un problème ? Une question ? Un bug ?
            {!isChild && (
              <>
                <br />
                <br />
                <Link to="/help">Consultez notre FAQ et nos tutoriels</Link>
              </>
            )}
          </AltusTalk>
          <InputText placeholder="Sujet" onChange={(v) => setSubject(v)} />
          <InputText placeholder="Description" onChange={(v) => setDescription(v)} multiline />
        </Flex>
        <Flex direction="column" gap={20} pad={20} width="100%">
          <Button
            color="primary"
            onClick={() => {
              createTicketMutation.mutate({
                reason: `TICKET ${isChild ? 'ENFANT' : 'PARENT'}: ${subject}`,
                description,
                meta: {
                  questionId: 123,
                },
                type: 'support',
              });
            }}
            disabled={!subject || !description}
          >
            ENVOYER
          </Button>
        </Flex>
      </Flex>
    </Page>
  );
}

export default Support;
