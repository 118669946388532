import OnboardingChoice from 'components/onboarding-choice/onboarding-choice';

/* eslint-disable-next-line */
export interface OnboardingChoiceLoginTypeProps {}

export function OnboardingChoiceLoginType(props: OnboardingChoiceLoginTypeProps) {
  return (
    <OnboardingChoice
      back
      choices={[
        {
          altusType: 'fill_ids_cutted',
          title: 'Rentrer mes identifiants',
          link: '/login/mail',
        },
        {
          altusType: 'connect_phones',
          title: "Entrer un code d'appairage",
          link: '/login/pair-code',
        },
      ]}
    />
  );
}

export default OnboardingChoiceLoginType;
