import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';
import { useQuery } from '@tanstack/react-query';
import AltusTalk from 'components/altus-talk/altus-talk';
import BackHeader from 'components/back-header/back-header';
import Button from 'components/button/button';
import Flex from 'components/flex/flex';
import Page from 'components/page/page';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getChildren } from 'services/childrens';

/* eslint-disable-next-line */
export interface BlockerDashboardProps {}

export function BlockerDashboard(props: BlockerDashboardProps) {
  const { id } = useParams();
  const [phoneConfigured, setPhoneConfigured] = useState(false);
  const [phoneOwner, setPhoneOwner] = useState<'parent' | 'child' | null>(null);
  const navigate = useNavigate();
  const { data: children } = useQuery({
    queryKey: ['children', id],
    queryFn: () => getChildren(id),
  });

  useEffect(() => {
    const checkPhoneConfigured = async () => {
      const { value } = await Preferences.get({ key: 'blockerConfigured' });
      if (value === 'true') {
        setPhoneConfigured(true);
      }
    };

    checkPhoneConfigured();

    const checkPhoneOwner = async () => {
      const po = await Preferences.get({ key: 'phone_owner' });
      if (po.value === 'parent' || po.value === 'child') {
        setPhoneOwner(po.value);
      }
    };

    checkPhoneOwner();
  }, []);

  if (phoneConfigured) {
    return (
      <Page>
        <Flex direction="column" justify="space-between" align="center" width="100%" height="100%">
          <BackHeader title="Téléphone configuré" href={`/children/${id}`} />
          <div />
          <AltusTalk>Téléphone configuré !</AltusTalk>
          <Flex direction="column" gap={10} pad={20} width="100%">
            <Button
              onClick={async () => {
                if (Capacitor.getPlatform() === 'ios') {
                  navigate(`/blocker/ios/${id}`);
                } else {
                  navigate(`/blocker/android/${id}`);
                }
              }}
            >
              RECONFIGURER LE BLOQUEUR
            </Button>
            <Button
              outline
              onClick={async () => {
                navigate('/dashboard');
              }}
            >
              RETOUR
            </Button>
          </Flex>
          {/* <TabBar childrenId={id} /> */}
        </Flex>
      </Page>
    );
  }
  return (
    <Page>
      <Flex direction="column" justify="space-between" align="center" width="100%" height="100%">
        <BackHeader
          title="Configurer le bloqueur"
          onClick={async () => {
            const { value } = await Preferences.get({ key: 'blockerConfigured' });
            if (value === null) {
              await Preferences.set({ key: 'blockerConfigured', value: 'false' });
            }
            navigate('/dashboard');
          }}
        />
        <div />
        <AltusTalk>
          {phoneOwner === 'parent'
            ? "Vous devez installer l'application sur le téléphone de votre enfant pour pouvoir configurer le bloqueur !"
            : 'Configures le bloqueur pour commencer à utiliser Altus !'}
        </AltusTalk>
        {phoneOwner === 'child' ? (
          <Flex direction="column" gap={10} pad={20} width="100%">
            <Button
              outline
              onClick={async () => {
                if (Capacitor.getPlatform() === 'ios') {
                  navigate(`/blocker/ios/${id}`);
                } else {
                  navigate(`/blocker/android/${id}`);
                }
              }}
            >
              CONFIGURER LE BLOQUEUR
            </Button>
          </Flex>
        ) : (
          <Flex direction="column" gap={10} pad={20} width="100%">
            <Button
              outline
              onClick={async () => {
                await Preferences.set({
                  key: 'phone_owner',
                  value: 'child',
                });
                window.location.reload();
              }}
            >
              C&apos;EST LE TÉLÉPHONE DE {children?.firstname?.toUpperCase()}
            </Button>
          </Flex>
        )}
        {/* <TabBar childrenId={id} /> */}
      </Flex>
    </Page>
  );
}

export default BlockerDashboard;
