import AltusPlus from 'components/altus-plus/altus-plus';
import { useNavigate } from 'react-router-dom';
import { SegmentEventType, track } from 'segment';
import styles from './premium-blur-locker.module.scss';

/* eslint-disable-next-line */
export interface PremiumBlurLockerProps {
  locked?: boolean;
  children?: React.ReactNode;
}

export function PremiumBlurLocker({ locked, children }: PremiumBlurLockerProps) {
  const navigate = useNavigate();
  return (
    <div className={styles.wrapper}>
      <div className={`${styles.locker} ${locked ? styles.locked : ''}`}>{children}</div>
      {locked && (
        <div
          className={styles.lockerOverlay}
          onClick={() => {
            track(SegmentEventType.CLICK_PARENT_STATS_PAYWALL);
            navigate('/subscribe');
          }}
        >
          <div className={styles.textWrapper}>
            <div className={styles.text}>
              Débloquez <br />
              cette fonctionnalité avec
            </div>
            <AltusPlus variant="light" />
          </div>
        </div>
      )}
    </div>
  );
}

export default PremiumBlurLocker;
