import OnboardingChoice from 'components/onboarding-choice/onboarding-choice';

/* eslint-disable-next-line */
export interface OnboardingChoiceLoginRegisterProps {}

export function OnboardingChoiceLoginRegister(
  props: OnboardingChoiceLoginRegisterProps
) {
  return (
    <OnboardingChoice
      back
      choices={[
        {
          altusType: 'builder',
          title: 'Créer un compte',
          link: '/register/mail',
        },
        {
          altusType: 'look_at_key',
          title: 'M’Identifier',
          link: '/onboarding/choice/login-type',
        },
      ]}
    />
  );
}

export default OnboardingChoiceLoginRegister;
