import AltusTalk from 'components/altus-talk/altus-talk';
import Button from 'components/button/button';
import Flex from 'components/flex/flex';
import Page from 'components/page/page';
import { useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { getLoginToken } from 'services/auth';
import { getProfile } from 'services/profile';
import styles from './qrcode-auth.module.scss';

/* eslint-disable-next-line */
export interface QRCodeAuthProps {
  simple?: boolean;
  childrenId?: number;
}

export function QRCodeAuth({ simple, childrenId }: QRCodeAuthProps) {
  const BASE_URL = process.env.REACT_APP_APP_BASEURL;
  const [token, setToken] = useState<string>('');
  const { id } = useParams();
  const getTokenMutation = useMutation({
    mutationFn: getLoginToken,
    onSuccess: (data) => {
      setToken(data);
    },
  });
  const { data: profile } = useQuery({
    queryKey: ['getProfile'],
    queryFn: getProfile,
  });

  useEffect(() => {
    getTokenMutation.mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (simple) {
    return (
      <>
        {token && profile && (
          <QRCode
            value={`${BASE_URL}/auth/token/${token}?email=${profile?.email}${
              id || childrenId ? `&childrenId=${id ?? childrenId}` : ''
            }`}
            style={{ width: '100%', height: 'auto' }}
          />
        )}
      </>
    );
  }
  return (
    <Page>
      <Flex
        direction="column"
        justify="space-between"
        align="center"
        width="100%"
        height="100%"
        style={{ paddingTop: 68, overflowY: 'scroll' }}
      >
        <AltusTalk type="green_phone">
          {token && profile && (
            <QRCode
              value={`${BASE_URL}/auth/token/${token}?email=${profile?.email}${
                id || childrenId ? `&childrenId=${id ?? childrenId}` : ''
              }`}
            />
          )}
        </AltusTalk>
        <Flex direction="column" gap={5} pad={0} width="100%">
          <div
            style={{
              textAlign: 'center',
              width: '90%',
              margin: 'auto',
              fontSize: 20,
              fontWeight: 600,
            }}
          >
            {id
              ? 'Pour vous connecter à Altus sur le téléphone de votre enfant'
              : 'Pour vous connecter à Altus sur votre téléphone'}{' '}
            scannez ce QR code
            <div className={styles.subtext}>
              C’est à vous de configurer le bloqueur une fois le QRCode scanné
            </div>
          </div>
        </Flex>
        <Flex direction="column" gap={10} pad={20} width="100%">
          <Button href={-1}>RETOUR</Button>
        </Flex>
      </Flex>
    </Page>
  );
}

export default QRCodeAuth;
