import { Preferences } from '@capacitor/preferences';
import { useMutation } from '@tanstack/react-query';
import AltusTalk from 'components/altus-talk/altus-talk';
import BackHeader from 'components/back-header/back-header';
import Button from 'components/button/button';
import Flex from 'components/flex/flex';
import InputPin from 'components/input-pin/input-pin';
import Page from 'components/page/page';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SegmentEventType, track } from 'segment';
import { loginWithPairCode } from 'services/auth';
import BlockerPlugin from 'utils/BlockerPlugin';

/* eslint-disable-next-line */
export interface LoginPairCodeProps {}

export function LoginPairCode(props: LoginPairCodeProps) {
  const [pairCode, setPairCode] = useState<string>('');
  const navigate = useNavigate();
  const loginWithPairCodeMutation = useMutation({
    mutationFn: loginWithPairCode,
    onSuccess: async (data) => {
      if (data.access_token.length) {
        await Preferences.set({
          key: 'access_token',
          value: data.access_token,
        });
        BlockerPlugin.metaInAppEvent({ eventName: 'login' });
        await Preferences.set({
          key: 'child_mode',
          value: data.children ? data.children.toString() : null,
        });
        if (data.children) {
          await Preferences.set({
            key: 'phone_owner',
            value: 'child',
          });
        }
        track(SegmentEventType.LOGIN_WITH_PAIR_CODE);
        navigate('/dashboard');
      }
    },
  });
  return (
    <Page>
      <Flex
        direction="column"
        justify="space-between"
        align="center"
        width="100%"
        height="100%"
        style={{ paddingTop: 100 }}
      >
        <BackHeader title="Entrer un code d'appairage" back />
        <Flex direction="column" gap={30} pad={20} width="100%">
          <AltusTalk type="hi">Renseignez votre code d&apos;appairage pour vous connecter</AltusTalk>
          <Flex direction="column" gap={10} width="100%">
            <InputPin onChange={(v) => setPairCode(v)} title="Code d'appairage" />
          </Flex>
        </Flex>
        <Flex direction="column" gap={20} pad={20} width="100%">
          <Button
            onClick={() => {
              loginWithPairCodeMutation.mutate({
                code: pairCode,
              });
            }}
            disabled={pairCode.length !== 6 || loginWithPairCodeMutation.isPending}
          >
            SE CONNECTER
          </Button>
        </Flex>
      </Flex>
    </Page>
  );
}

export default LoginPairCode;
