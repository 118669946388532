import OnboardingChoice from 'components/onboarding-choice/onboarding-choice';

/* eslint-disable-next-line */
export interface OnboardingChoiceInstalledProps {}

export function OnboardingChoiceInstalled(
  props: OnboardingChoiceInstalledProps,
) {
  return (
    <OnboardingChoice
      title="Altus est-il déjà installé sur le téléphone d'un parent ?"
      backHref="/onboarding/choice/device"
      fontSize={20}
      choices={[
        {
          altusType: 'green_phone',
          title: 'Oui',
          link: '/onboarding/choice/login-type',
        },
        {
          altusType: 'red_phone',
          title: 'Non',
          link: '/onboarding/choice/login-register',
        },
      ]}
    />
  );
}

export default OnboardingChoiceInstalled;
