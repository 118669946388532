import { useMutation, useQueryClient } from '@tanstack/react-query';
import AltusPlus from 'components/altus-plus/altus-plus';
import Button from 'components/button/button';
import Checkbox from 'components/checkbox/checkbox';
import ChildrenSelectionIcon from 'components/children-selection-icon/children-selection-icon';
import Flex from 'components/flex/flex';
import Page from 'components/page/page';
import { useUserContext } from 'contexts/user-context';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { updatePremiumChildren } from 'services/subscription';
import styles from './subscription.module.scss';

export default function SubscriptionSettings() {
  const { user, userSubscriptionStatus, getSubscriptionStatus, subscriptionChildCount } = useUserContext();
  const navigate = useNavigate();
  const [selectedChildren, setSelectedChildren] = useState<number[]>();

  useEffect(() => {
    if (userSubscriptionStatus === 'freemium')
      navigate('/', {
        replace: true,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSubscriptionStatus]);

  useEffect(() => {
    setSelectedChildren(
      user.childrens.filter((child) => getSubscriptionStatus(child.id) === 'premium').map((c) => c.id),
    );
  }, [getSubscriptionStatus, user]);

  const queryClient = useQueryClient();

  const updatePremiumChildrenMutation = useMutation({
    mutationFn: updatePremiumChildren,
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ['getMySubscription'],
      });
      toast(`Les profils ont été mis à jour`, {
        icon: '✅',
      });
      navigate(-1);
    },
  });

  const saveDisabled = selectedChildren?.length !== subscriptionChildCount;

  return (
    <Page>
      <Flex direction="column" align="center" justify="space-between" height="100%" style={{ overflow: 'auto' }}>
        <div className={styles.title}>
          Choississez les profils qui vont bénéficier de
          <div className={styles.plus}>
            <AltusPlus variant="light" />
          </div>
        </div>
        <Flex width="100%" direction="column" style={{ marginTop: 40 }} justify="center">
          {user.childrens?.map((child) => (
            <Flex key={child.id} width="100%" justify="center" align="center">
              <Checkbox
                onChange={() => {
                  if (selectedChildren?.includes(child.id))
                    setSelectedChildren(selectedChildren?.filter((id) => child.id !== id));
                  else setSelectedChildren([...(selectedChildren ?? []), child.id]);
                }}
                checked={selectedChildren?.includes(child.id) ?? false}
              />
              <ChildrenSelectionIcon
                child={child}
                onClick={() => {
                  if (selectedChildren?.includes(child.id))
                    setSelectedChildren(selectedChildren?.filter((id) => child.id !== id));
                  else setSelectedChildren([...(selectedChildren ?? []), child.id]);
                }}
              />
            </Flex>
          ))}
        </Flex>

        <Flex direction="column" width="100%" pad="20px" gap={20}>
          <Flex direction="column" width="100%" align="center" gap={4}>
            <div className={`${styles.error} ${saveDisabled ? '' : styles.hidden}`}>
              Veuillez séléctionner {subscriptionChildCount} profil{subscriptionChildCount > 1 ? 's' : ''}
            </div>
            <Button
              disabled={saveDisabled}
              onClick={() => {
                updatePremiumChildrenMutation.mutate({
                  studentIds: selectedChildren!,
                });
              }}
              loading={updatePremiumChildrenMutation.isPending}
            >
              SAUVEGARDER
            </Button>
          </Flex>
          <Button outline href={-1}>
            ANNULER
          </Button>
        </Flex>
      </Flex>
    </Page>
  );
}
