/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-bitwise */
export type UIColor = 'purple' | 'pink' | 'green' | 'yellow' | 'red' | 'orange';

export const getColorFromFieldId = (fieldId: number) => {
  switch (fieldId) {
    case 23:
      return '#ff9d4e';
    case 29:
      return '#5c5cff';
    case 36:
      return '#ff855c';
    case 37:
      return '#ffcc52';
    case 42:
      return '#5f98cd';
    case 43:
      return '#fc9e5e';
    case 44:
      return '#8fbf6d';
    case 45:
      return '#ff8e6e';
    case 46:
      return '#f76c9e';
    case 51:
      return '#ff6384';
    case 28:
      return '#ff5c5c';
    case 34:
      return '#3bd3c3';
    case 41:
      return '#fd6e77';
    case 47:
      return '#4db6ac';
    case 48:
      return '#ff7c43';
    case 49:
      return '#a37aff';
    case 50:
      return '#c870a0';
    default:
      return '#55a4f7';
  }
};

export const newShade = (hexColor, magnitude) => {
  hexColor = hexColor.replace(`#`, ``);
  if (hexColor.length === 6) {
    const decimalColor = parseInt(hexColor, 16);
    let r = (decimalColor >> 16) + magnitude;
    r > 255 && (r = 255);
    r < 0 && (r = 0);
    let g = (decimalColor & 0x0000ff) + magnitude;
    g > 255 && (g = 255);
    g < 0 && (g = 0);
    let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
    b > 255 && (b = 255);
    b < 0 && (b = 0);
    return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
  }
  return hexColor;
};
