import { CommonWorkedChapter } from '@codidae/common-types';
import { getProgressFromElo } from 'utils/elo_convertion';
import styles from './stats-worked-chapter.module.scss';

/* eslint-disable-next-line */
export interface StatsWorkedChapterProps {
  workedChapter: CommonWorkedChapter;
}

export function StatsWorkedChapter({ workedChapter }: StatsWorkedChapterProps) {
  return (
    <div className={styles.workedChapter}>
      <div className={styles.title}>{workedChapter.chapter.name}</div>
      <div className={styles.date}>{new Date(workedChapter.createdAt).toLocaleDateString()}</div>
      <div className={styles.progress}>
        <div
          className={styles.progressValue}
          style={{
            width: `${getProgressFromElo(workedChapter.elo, workedChapter.tryCount)}%`,
          }}
        />
      </div>
    </div>
  );
}

export default StatsWorkedChapter;
