import { App } from '@capacitor/app';
import AppList from 'components/application-list/application-list';
import Checkbox from 'components/checkbox/checkbox';
import Flex from 'components/flex/flex';
import InputText from 'components/input-text/input-text';
import { useEffect, useMemo, useState } from 'react';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import { Application } from 'views/blocker/android';
import styles from './android-application-list.module.scss';

export interface AndroidCategoryListProps {
  applications: Application[];
  blockedApplications: Application[];
  blockApplication: (application: Application) => void;
  blockCategory: (category: number) => void;
  unblockApplication: (application: Application) => void;
  unblockCategory: (category: number) => void;

  selectedCategory: number | undefined;
  setSelectedCategory: (category: number | undefined) => void;
}

type CategoryContent = { category: number; title: string; apps: Application[] };

const getEmptyCategories: () => CategoryContent[] = () => [
  {
    category: 0,
    title: 'Jeux vidéo',
    apps: [],
  },
  {
    category: 4,
    title: 'Réseaux sociaux / Navigateurs',
    apps: [],
  },
  {
    category: 2,
    title: 'Lecteurs vidéo',
    apps: [],
  },
  {
    category: 7,
    title: 'Productivité',
    apps: [],
  },
  {
    category: 1,
    title: 'Musique et audio',
    apps: [],
  },
  {
    category: 3,
    title: 'Images',
    apps: [],
  },
  {
    category: 5,
    title: 'Actualités',
    apps: [],
  },
  {
    category: 6,
    title: 'Cartes et navigation',
    apps: [],
  },
  {
    category: 8,
    title: 'Accessibilité',
    apps: [],
  },
  {
    category: -1,
    title: 'Autres',
    apps: [],
  },
];

function AndroidCategoryList({
  applications,
  blockApplication,
  blockCategory,
  blockedApplications,
  unblockApplication,
  unblockCategory,
  selectedCategory,
  setSelectedCategory,
}: AndroidCategoryListProps) {
  const categories = useMemo(
    () =>
      applications
        .reduce(
          (
            acc: { category: number; title: string; apps: Application[] }[],
            application,
          ) => {
            acc
              .find((cat) => cat.category === application.category)
              ?.apps.push(application);
            return acc;
          },
          getEmptyCategories(),
        )
        .filter((cat) => cat.apps.length > 0),
    [applications],
  );

  if (typeof selectedCategory === 'number') {
    const category = categories.find(
      (cat) => cat.category === selectedCategory,
    );
    const nbBlocked = category.apps.filter((app) =>
      blockedApplications.some((a) => a.package === app.package),
    ).length;
    const blocked = nbBlocked === category.apps.length;
    return (
      <>
        <div className={styles.category} key={category.category}>
          <button
            className={styles.backButton}
            onClick={() => setSelectedCategory(undefined)}
            type="button"
          >
            <BiChevronLeft className={styles.chevronRight} />
            Retour
          </button>
          <div className={styles.title}>
            <Checkbox
              onChange={() =>
                blocked
                  ? unblockCategory(category.category)
                  : blockCategory(category.category)
              }
              checked={blocked}
              intermidiate={category.apps.some((app) =>
                blockedApplications.some((a) => a.package === app.package),
              )}
              className={styles.checkbox}
            />
            <button
              className={styles.categoryButton}
              onClick={() =>
                blocked
                  ? unblockCategory(category.category)
                  : blockCategory(category.category)
              }
              type="button"
            >
              <h3>{category.title}</h3>
              <p>
                {nbBlocked} / {category.apps.length}
              </p>
            </button>
          </div>
        </div>
        <AppList
          applications={category.apps}
          blockApplication={blockApplication}
          blockedApplications={blockedApplications}
          unblockApplication={unblockApplication}
        />
      </>
    );
  }

  return (
    <>
      {categories.map((category) => {
        const nbBlocked = category.apps.filter((app) =>
          blockedApplications.some((a) => a.package === app.package),
        ).length;
        const blocked = nbBlocked === category.apps.length;
        return (
          <div className={styles.category} key={category.category}>
            <div className={styles.title}>
              <Checkbox
                className={styles.checkbox}
                onChange={() =>
                  blocked
                    ? unblockCategory(category.category)
                    : blockCategory(category.category)
                }
                checked={blocked}
                intermidiate={category.apps.some((app) =>
                  blockedApplications.some((a) => a.package === app.package),
                )}
              />
              <button
                type="button"
                className={styles.categoryButton}
                onClick={() => setSelectedCategory(category.category)}
              >
                <h3>{category.title}</h3>
                <p>
                  {nbBlocked} / {category.apps.length}
                </p>
                <BiChevronRight className={styles.chevronRight} />
              </button>
            </div>
          </div>
        );
      })}
    </>
  );
}

/* eslint-disable-next-line */
export interface AndroidApplicationListProps {
  applications: Application[];
  setBlockedApplications: (blockedApplications: Application[]) => void;
  blockedApplications: Application[];
  search: string;
  setSearch: (search: string) => void;
  selectedCategory: number | undefined;
  setSelectedCategory: (category: number | undefined) => void;
}

export function AndroidApplicationList({
  applications,
  search,
  setSearch,
  setSelectedCategory,
  selectedCategory,
  ...props
}: AndroidApplicationListProps) {
  const [blockedApplications, setBlockedApplications] = useState<Application[]>(
    [],
  );
  // const [filterChecked, setFilterChecked] = useState<boolean>(false);

  useEffect(() => {
    props.setBlockedApplications(blockedApplications);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockedApplications]);

  useEffect(() => {
    if (props.blockedApplications)
      setBlockedApplications(props.blockedApplications);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const blockApplication = (application: Application) => {
    setBlockedApplications([...blockedApplications, application]);
  };

  const unblockApplication = (application: Application) => {
    setBlockedApplications(
      blockedApplications.filter(
        (blockedApplication) =>
          blockedApplication.package !== application.package,
      ),
    );
  };

  const blockCategory = (category: number) => {
    setBlockedApplications(
      applications.filter((app) => {
        if (
          blockedApplications.find(
            (blockedApp) => blockedApp.package === app.package,
          )
        ) {
          return true;
        }
        return app.category === category;
      }),
    );
  };

  const unblockCategory = (category: number) => {
    setBlockedApplications(
      blockedApplications.filter(
        (application) => application.category !== category,
      ),
    );
  };

  useEffect(() => {
    if (typeof selectedCategory !== 'number' && !search.length) return () => null;
    const listener = App.addListener('backButton', () => {
      setSelectedCategory(undefined);
      setSearch('')
    });
    return () => listener.remove();
  });

  return (
    <Flex direction="column" width="100%" pad={20} gap={20}>
      <Flex direction="row" width="100%" gap={20} align="center">
        <InputText
          placeholder="Rechercher une app..."
          onChange={(value) => setSearch(value)}
          value={search}
        />
        <div
          style={{
            flexGrow: 1,
          }}
        />
      </Flex>
      <Flex
        direction="column"
        width="100%"
        style={{
          paddingBottom: 100,
        }}
      >
        {search.length ? (
          <AppList
            applications={applications.filter((application) =>
              application.name.toLowerCase().includes(search.toLowerCase()),
            )}
            blockedApplications={blockedApplications}
            blockApplication={blockApplication}
            unblockApplication={unblockApplication}
          />
        ) : (
          <AndroidCategoryList
            applications={applications}
            blockedApplications={blockedApplications}
            blockApplication={blockApplication}
            blockCategory={blockCategory}
            unblockApplication={unblockApplication}
            unblockCategory={unblockCategory}
            setSelectedCategory={setSelectedCategory}
            selectedCategory={selectedCategory}
          />
        )}
      </Flex>
    </Flex>
  );
}

export default AndroidApplicationList;
