import { motion } from 'framer-motion';
import { useRef, useState } from 'react';
import styles from './collapsable-popin.module.scss';

export default function CollapsablePopin({
  children,
  title,
  className,
  minimumSize = 30,
}: {
  children?: React.ReactNode;
  title: React.ReactNode;
  className?: string;
  minimumSize?: number;
}) {
  const [y, setY] = useState(0);
  const [placeHolderHeight, setPlaceholderHeight] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  return (
    <div>
      <motion.div
        style={{
          width: '100%',
        }}
        animate={{
          height: placeHolderHeight,
        }}
      />
      <motion.div
        initial={{
          transform: 'translateY(100%)',
        }}
        animate={{
          transform: 'translateY(0)',
        }}
        className={styles.wrapper}
      >
        <motion.div className={`${styles.card} ${className ?? ''}`} animate={{ y }} ref={ref}>
          <motion.div
            className={styles.slidingHandle}
            onPan={(e, info) => {
              const min = (ref.current?.getBoundingClientRect().height ?? -Infinity) - minimumSize;
              setY(Math.min(Math.max(0, y + info.delta.y), min));
            }}
            onPanEnd={() => {
              const min = (ref.current?.getBoundingClientRect().height ?? -Infinity) - minimumSize;
              if (Math.abs(y - 0) < Math.abs(y - min)) {
                setY(0);
                setPlaceholderHeight(0);
              } else {
                setY(min);
                setPlaceholderHeight(minimumSize);
              }
            }}
          >
            <div className={styles.rect} />
          </motion.div>
          <div>{title}</div>
          <div>{children}</div>
        </motion.div>
      </motion.div>
    </div>
  );
}
