import SubscribeButtons from 'components/altus-plus/subscribe-buttons';
import SubscriptionBackHeader from 'components/altus-plus/subscription-back-header';
import Flex from 'components/flex/flex';
import { useMemo } from 'react';
import { FaGraduationCap, FaSchool } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { SegmentEventType, track } from 'segment';
import styles from './subscription.module.scss';

const POINTS: {
  title: string;
  text: string;
  icon: React.ReactNode;
}[] = [
  {
    title: 'Choix des chapitres',
    // text: `Votre enfant devient acteur de son apprentissage en sélectionnant les chapitre qu'il voit en cours`,
    text: `Votre enfant devient acteur de son apprentissage : sélectionnez les chapitres actuels et renforcez chaque leçon`,
    icon: <FaSchool />,
  },
  {
    title: '100 000+ questions',
    text: 'Challengez votre enfant avec notre base de données qui contient plus de 100 000 questions',
    icon: <FaGraduationCap />,
  },
];

export default function SubscriptionInitial() {
  const navigate = useNavigate();
  const points = useMemo(() => {
    const indexes = [Math.floor(Math.random() * POINTS.length)];

    while (indexes.length < 2) {
      const newIndex = Math.floor(Math.random() * POINTS.length);
      if (newIndex !== indexes[0]) indexes.push(newIndex);
    }
    return indexes.map((i) => POINTS[i]);
  }, []);

  return (
    <div className={styles.background}>
      <Flex direction="column" align="center" width="100%" height="100%" pad="20px" justify="space-between">
        <SubscriptionBackHeader
          onBack={() => {
            navigate(-1);
            track(SegmentEventType.EXIT_PAYMENT_FLOW);
          }}
        />
        <img src="/altus-stars.svg" alt="Altus" />
        <div className={styles.subscriptionText}>
          Vos enfants apprennent <br />
          <b>2.9x</b> fois plus vite avec
          <br /> Altus Plus
        </div>
        <div className={styles.roundedBox}>
          {points.map((point) => (
            <div className={styles.point} key={point.title}>
              <div className={styles.icon}>{point.icon}</div>
              <div className={styles.text}>
                <div className={styles.title}>{point.title}</div>
                <div className={styles.description}>{point.text}</div>
              </div>
            </div>
          ))}
        </div>
        <SubscribeButtons
          trackingEvent={SegmentEventType.PAYMENT_ARGUMENTS_INITIAL}
          trackingProperties={{
            point1: points[0],
            point2: points[1],
          }}
          backAmount={-1}
          nextHref="/subscribe/arguments/1"
          nextText="ESSAYER GRATUITEMENT"
          backDisabledOnLoad
        />
      </Flex>
    </div>
  );
}
