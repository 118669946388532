import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { CommonQuestionResult } from '@codidae/common-types';
import Button from 'components/button/button';
import CollapsablePopin from 'components/collapsable-popin/collapsable-popin';
import ReportPopup from 'components/report-popup/report-popup';
import SessionEarnedTime from 'components/session-earned-time/session-earned-time';
import config from 'config';
import { useUserContext } from 'contexts/user-context';
import { useState } from 'react';
import { IoWarningOutline } from 'react-icons/io5';
import styles from './correct-answer.module.scss';

/* eslint-disable-next-line */
export interface CorrectAnswerProps {
  result: CommonQuestionResult;
  next: () => void;
  earnedTime: number;
  loading?: boolean;
  streak?: number;
}

const ENCOURAGEMENT_PHRASES = [
  <>{'Bravo ! '}</>,
  <>
    Continue comme ça !<br />
  </>,
  <>{'Incroyable ! '}</>,
  <>{'Impressionant ! '}</>,
  <>{'Wow ! '}</>,
  <>{'Exceptionnel ! '}</>,
  <>{'Sensationnel ! '}</>,
  <>{'Super ! '}</>,
  <>{'Génial ! '}</>,
  <>{'Merveilleux ! '}</>,
];

export function CorrectAnswer({ earnedTime, next, result, loading, streak }: CorrectAnswerProps) {
  const [report, setReport] = useState<boolean>(false);

  const { parentalControlSettings } = useUserContext();

  if (report) {
    return (
      <ReportPopup
        onClose={() => {
          setReport(false);
        }}
        question={result.question}
      />
    );
  }
  const hasStreakBonus = (streak ?? 0) >= config.min_streak_count;
  const earnedTimeBase = parentalControlSettings?.earnedTimeBase ?? 1;
  const timeEarned = earnedTimeBase + (hasStreakBonus ? 1 : 0);

  return (
    <CollapsablePopin
      minimumSize={80}
      className={styles.correction}
      title={
        <div className={styles.header}>
          <span>Bravo !</span>
          <button
            type="button"
            onClick={() => {
              setReport(true);
            }}
            onTouchStart={() => {
              Haptics.impact({ style: ImpactStyle.Light });
            }}
            onTouchEnd={() => {
              Haptics.impact({ style: ImpactStyle.Light });
            }}
          >
            <IoWarningOutline />
          </button>
        </div>
      }
    >
      <div className={styles.text}>{result.correction}</div>
      <div className={styles.earnedTimeWrapper}>
        {hasStreakBonus && (
          <div className={styles.streak}>
            {ENCOURAGEMENT_PHRASES[Math.floor(Math.random() * ENCOURAGEMENT_PHRASES.length)]}
            {streak}
            {` d'affilés !`}
          </div>
        )}

        <div className={styles.earnedTime}>{earnedTime > 0 && <SessionEarnedTime time={timeEarned} />}</div>
      </div>
      <Button color="green" onClick={() => next()} loading={loading} delay={1000}>
        QUESTION SUIVANTE
      </Button>
    </CollapsablePopin>
  );
}

export default CorrectAnswer;
