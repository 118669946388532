import Rive from '@rive-app/react-canvas';
import AltusAsleep from 'assets/altus/asleep.svg';
import AltusBuilder from 'assets/altus/builder.svg';
import AltusChildPhone from 'assets/altus/child_phone.svg';
import AltusCloud from 'assets/altus/cloud.svg';
import AltusConfeti from 'assets/altus/confeti.svg';
import AltusConnectPhone from 'assets/altus/connect_phones.svg';
import AltusDefault from 'assets/altus/default.svg';
import AltusFillIdsCutted from 'assets/altus/fill_ids_cutted.svg';
import AltusGreenPhone from 'assets/altus/green_phone.svg';
import AltusHappy from 'assets/altus/happy.svg';
import AltusHi from 'assets/altus/hi.svg';
import AltusLootAtKey from 'assets/altus/look_at_key.svg';
import AltusHard from 'assets/altus/hard.svg';
import AltusParentPhone from 'assets/altus/parent_phone.svg';
import AltusQRCode from 'assets/altus/qr_code.svg';
import AltusQRCodeCutted from 'assets/altus/qr_code_cutted.svg';
import AltusQuestion from 'assets/altus/question.svg';
import AltusRedPhone from 'assets/altus/red_phone.svg';
import AltusSad from 'assets/altus/sad.svg';
import AltusSoluceFR from 'assets/altus/soluce_fr.svg';
import AltusAskForTime from 'assets/altus/ask_for_time.svg';
import { memo } from 'react';
import styles from './altus.module.scss';

export type AltusType =
  | 'default'
  | 'happy'
  | 'sad'
  | 'hi'
  | 'question'
  | 'builder'
  | 'asleep'
  | 'look_at_key'
  | 'qr_code'
  | 'animated_hi'
  | 'animated_sad'
  | 'animated_fire'
  | 'child_phone'
  | 'cloud'
  | 'confeti'
  | 'connect_phones'
  | 'fill_ids_cutted'
  | 'green_phone'
  | 'hard'
  | 'parent_phone'
  | 'qr_code_cutted'
  | 'red_phone'
  | 'soluce_fr'
  | 'ask_for_time';

/* eslint-disable-next-line */
export interface AltusProps {
  width?: number | string;
  type?: AltusType;
  backgroundColor?: string;
}

const AnimatedAltusComponent = memo((props: { animation: string }) => (
  <Rive src="/altus.riv" animations={[props.animation]} />
));

export function Altus({ backgroundColor, type, width }: AltusProps) {
  const getImageFromType = () => {
    switch (type) {
      case 'default':
        return AltusDefault;
      case 'happy':
        return AltusHappy;
      case 'sad':
        return AltusSad;
      case 'hi':
        return AltusHi;
      case 'question':
        return AltusQuestion;
      case 'builder':
        return AltusBuilder;
      case 'asleep':
        return AltusAsleep;
      case 'look_at_key':
        return AltusLootAtKey;
      case 'qr_code':
        return AltusQRCode;
      case 'child_phone':
        return AltusChildPhone;
      case 'cloud':
        return AltusCloud;
      case 'confeti':
        return AltusConfeti;
      case 'connect_phones':
        return AltusConnectPhone;
      case 'fill_ids_cutted':
        return AltusFillIdsCutted;
      case 'green_phone':
        return AltusGreenPhone;
      case 'hard':
        return AltusHard;
      case 'parent_phone':
        return AltusParentPhone;
      case 'qr_code_cutted':
        return AltusQRCodeCutted;
      case 'red_phone':
        return AltusRedPhone;
      case 'soluce_fr':
        return AltusSoluceFR;
      case 'ask_for_time':
        return AltusAskForTime;
      default:
        return AltusDefault;
    }
  };

  // if window size is smaller than 768px, set width to 100%
  if (!!window && window.innerHeight < 768 && typeof width === 'number' && width > 120) {
    width = 120;
  }

  if (type && type.includes('animated')) {
    return (
      <div
        className={`${styles.altusAnimated} altus-illustration`}
        style={{
          width: width ?? '200px',
          height: width ?? '200px',
          transform: 'scale(1.6)',
          position: 'relative',
        }}
      >
        <AnimatedAltusComponent animation={type.replace('animated_', '')} />
      </div>
    );
  }
  return (
    <div className={`${styles.altus} altus-illustration`}>
      <img src={getImageFromType()} alt="Altus" width={width ?? 'auto'} />
    </div>
  );
}

export default Altus;
