import Checkbox from 'components/checkbox/checkbox';
import { Application } from 'views/blocker/android';
import styles from './application-list.module.scss';

export default function AppList({
  applications,
  blockedApplications,
  blockApplication,
  unblockApplication,
}: {
  applications: Application[];
  blockedApplications: Application[];
  blockApplication: (application: Application) => void;
  unblockApplication: (application: Application) => void;
}) {
  return (
    <>
      {applications.map((application) => (
        <div key={application.package} className={styles.application}>
          <div className={styles.onOff}>
            <Checkbox
              className={styles.checkbox}
              checked={
                !!blockedApplications.find(
                  (blockedApplication) =>
                    blockedApplication.package === application.package,
                )
              }
              onChange={(isChecked) => {
                if (isChecked) {
                  blockApplication(application);
                } else {
                  unblockApplication(application);
                }
              }}
            />
          </div>
          <img src={`data:image/png;base64, ${application.icon}`} alt="" />
          <div className={styles.name}>{application.name}</div>
        </div>
      ))}
    </>
  );
}
