import Grid from 'components/grid/grid';
import { useState } from 'react';
import checkedIcon from 'assets/icons/checked.svg';
import { useWindowSize } from '@uidotdev/usehooks';
import styles from './onboarding-select.module.scss';

/* eslint-disable-next-line */
export interface OnboardingSelectProps {
  options: string[];
}

export function OnboardingSelect({ options }: OnboardingSelectProps) {
  const [selected, setSelected] = useState<number[]>([]);
  const size = useWindowSize();

  return (
    <Grid columns={1} gap={size.height > 800 ? 20 : 10}>
      {options.map((option, index) => (
        <div
          key={index}
          className={`${styles.option} ${selected.includes(index) ? styles.selected : ''}`}
          onClick={() => {
            if (selected.includes(index)) {
              setSelected(selected.filter((i) => i !== index));
            } else {
              setSelected([...selected, index]);
            }
          }}
        >
          <div>{option}</div>
          <div
            className={`${styles.check} ${selected.includes(index) ? styles.selected : ''}`}
          >
            <img src={checkedIcon} alt="Checked" />
          </div>
        </div>
      ))}
    </Grid>
  );
}

export default OnboardingSelect;
