const config = {
  version: '1.2.5',
  api_baseurl: process.env.REACT_APP_API_BASEURL,
  default_headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  max_children_per_account: 4,
  accepted_cycles: [47, 48, 49, 50, 5, 6, 7, 8, 10, 11, 12],
  apple_rc_key: process.env.REACT_APP_APPLE_RC_KEY,
  google_rc_key: process.env.REACT_APP_GOOGLE_RC_KEY,
  time_after_out_of_time: 2,
  positive_quiz_questions_amount: 10,
  min_streak_count: 3,
  positive_quiz_extra_minutes: 2,
  max_daily_time: {
    hours: ['0', '1', '2', '3', '4', '5'],
    minutes: ['0', '10', '20', '30', '40', '50'],
  },
  initial_daily_time: {
    hours: ['0', '1', '2'],
    minutes: ['0', '5', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'],
  },
  logout_range_time: {
    hours: Array.from({ length: 24 }, (_, i) => i.toString()),
    minutes: ['0', '10', '20', '30', '40', '50'],
  },
  request_time: {
    hours: ['0', '1'],
    minutes: ['0', '5', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'],
  },
};

export default config;
