import Button from 'components/button/button';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SegmentEventType, track } from 'segment';
import styles from './altus-plus.module.scss';

export default function SubscribeButtons({
  backAmount,
  nextHref,
  nextText,
  trackingEvent,
  trackingProperties,
  backDisabledOnLoad,
}: {
  backAmount: number;
  nextHref: string;
  nextText: string;
  trackingEvent?: SegmentEventType;
  trackingProperties?: object;
  backDisabledOnLoad?: boolean;
}) {
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(backDisabledOnLoad);

  useEffect(() => {
    if (!disabled) return () => {};
    const timeout = setTimeout(() => {
      setDisabled(false);
    }, 1000);
    return () => clearTimeout(timeout);
  }, [disabled]);

  return (
    <div className={styles.subscribeButtons}>
      <Button
        onClick={() => {
          navigate(nextHref);
          if (trackingEvent) track(trackingEvent, trackingProperties);
        }}
      >
        {nextText}
      </Button>
      <Button text onClick={() => navigate(backAmount)} style={disabled ? { pointerEvents: 'none' } : undefined}>
        PASSER
      </Button>
    </div>
  );
}
