const XP_CONSTANT = 0.05;
const XP_POWER = 1.5;

export const getLoginStreakPoints = (streak: number) => {
  if (streak === 1)
    return 50;
  if (streak === 2)
    return 100;
  if (streak >= 3)
    return 200;
  return 0;
}

export const getPointsFromHomework = () => 100

export const getLevelFromXp = (xp: number) => Math.floor(xp**(1 / XP_POWER) * XP_CONSTANT) + 1

export const getXpFromLevel = (level: number) => Math.floor(((level - 1) / XP_CONSTANT)**XP_POWER)

export const getLevelProgression = (xp: number) => {
  const level = getLevelFromXp(xp);
  const levelXp = getXpFromLevel(level);
  const nextLevelXp = getXpFromLevel(level + 1);
  return (xp - levelXp) / (nextLevelXp - levelXp);
}

export const getLevelXp = (xp: number) => {
  const level = getLevelFromXp(xp);
  const levelXp = getXpFromLevel(level);
  return xp - levelXp;
}

export const getNextLevelXp = (xp: number) => {
  const level = getLevelFromXp(xp);
  const nextLevelXp = getXpFromLevel(level + 1);
  return nextLevelXp - xp;
}
