import {
  CommonDailyUsage,
  CommonDayParameters,
  CommonSessionResult,
  CommonStudent,
  CommonSuccess,
  CommonUser,
  CommonWeekParameters,
  CommonWorkedChapter,
} from '@codidae/common-types';
import HTTPClient from '../utils/HTTPClient';

export function getChildrens(): Promise<CommonUser> {
  return HTTPClient.get('/parent/childrens').then((data) => data.data);
}

export function createChildren({
  firstname,
  lastname,
  cycleId,
  lockTime,
  isLogoutRangeEnabled,
  logoutRangeMorning,
  logoutRangeEvening,
  contentFilterLevel,
  preventUninstall,
  maxDailyTime,
}: {
  firstname?: string;
  lastname?: string;
  cycleId?: number;
  lockTime?: number;
  isLogoutRangeEnabled?: boolean;
  logoutRangeMorning?: number;
  logoutRangeEvening?: number;
  contentFilterLevel?: number;
  preventUninstall?: boolean;
  maxDailyTime?: number;
}): Promise<CommonStudent> {
  return HTTPClient.post('/parent/children', {
    firstname,
    lastname,
    cycleId,
    lockTime,
    isLogoutRangeEnabled,
    logoutRangeMorning,
    logoutRangeEvening,
    contentFilterLevel,
    preventUninstall,
    maxDailyTime,
  }).then((data) => data.data);
}

export function editChildren({
  id,
  firstname,
  cycleId,
  lockTime,
  isLogoutRangeEnabled,
  logoutRangeMorning,
  logoutRangeEvening,
  contentFilterLevel,
  preventUninstall,
  maxDailyTime,
  initialDailyTime,
}: {
  id: string;
  firstname?: string;
  cycleId?: number;
  lockTime?: number;
  isLogoutRangeEnabled?: boolean;
  logoutRangeMorning?: number;
  logoutRangeEvening?: number;
  contentFilterLevel?: number;
  preventUninstall?: boolean;
  maxDailyTime?: number;
  initialDailyTime?: number;
}): Promise<CommonSuccess> {
  return HTTPClient.patch(`/parent/children/${id}`, {
    firstname,
    cycleId,
    lockTime,
    isLogoutRangeEnabled,
    logoutRangeMorning,
    logoutRangeEvening,
    contentFilterLevel,
    preventUninstall,
    maxDailyTime,
    initialDailyTime,
  }).then((data) => data.data);
}

export function deleteChildren(id): Promise<CommonSuccess> {
  return HTTPClient.delete(`/parent/children/${id}`).then((data) => data.data);
}

export function getChildren(id): Promise<CommonStudent> {
  return HTTPClient.get(`/parent/children/${id}`).then<CommonStudent>((data) => ({
    ...data.data,
    lastLocationUpdate: data.data.lastLocationUpdate ? new Date(data.data.lastLocationUpdate) : undefined,
    lastOfflineSession: data.data.lastOfflineSession ? new Date(data.data.lastOfflineSession) : undefined,
  }));
}

export async function addChildrenFocus({ id, chapterIds, limit, parentId }): Promise<CommonSuccess> {
  const data = await HTTPClient.post(`/parent/children/${id}/focus`, {
    chapterIds,
    parentId,
    limit,
  });
  return data.data;
}

export async function setChildrenFocus({ id, chapterIds, limit }): Promise<CommonSuccess> {
  const data = await HTTPClient.patch(`/parent/children/${id}/focus`, {
    chapterIds,
    limit,
  });
  return data.data;
}

export function deleteChildrenFocus({ id, index }): Promise<CommonSuccess> {
  return HTTPClient.delete(`/parent/children/${id}/focus/${index}`).then((data) => data.data);
}

export function updateChildrenFocus({ id, index, chapters }): Promise<CommonSuccess> {
  return HTTPClient.patch(`/parent/children/${id}/focus/${index}`, {
    chapterIds: chapters,
  }).then((data) => data.data);
}

export function getChildrenSessionResults(id): Promise<CommonSessionResult[]> {
  return HTTPClient.get(`/parent/children/${id}/sessions`).then((data) => data.data);
}

export function getChildrenWorkedChapters(id): Promise<CommonWorkedChapter[]> {
  return HTTPClient.get(`/parent/children/${id}/worked-chapters`).then((data) => data.data);
}

export function getDailyPoints(id): Promise<number> {
  return HTTPClient.get(`/student/me/gamification/daily/${id}`).then((data) => data.data);
}

export function updateChildrenPlatform({ id, platform }: { id: number; platform: string }): Promise<CommonSuccess> {
  return HTTPClient.patch(`/parent/children/${id}/platform`, {
    platform,
  }).then((data) => data.data);
}

export function updatePlatform({ platform }: { platform: string }): Promise<CommonSuccess> {
  return HTTPClient.patch(`/parent/platform`, {
    platform,
  }).then((data) => data.data);
}

export function updateOnboardingForm({
  betterScoolResult,
  makeHimResponsible,
  shieldFromInapropriat,
  reducTime,
  lestConflics,
}: {
  betterScoolResult: boolean;
  makeHimResponsible: boolean;
  shieldFromInapropriat: boolean;
  reducTime: boolean;
  lestConflics: boolean;
}): Promise<CommonSuccess> {
  return HTTPClient.patch(`/parent/onboardingform/update`, {
    betterScoolResult,
    makeHimResponsible,
    shieldFromInapropriat,
    reducTime,
    lestConflics,
  }).then((data) => data.data);
}

export async function pushDailyUsage({ id, dailyUsage }: { id: number; dailyUsage: number }): Promise<CommonSuccess> {
  if (Number.isNaN(dailyUsage) || Number.isNaN(id)) return Promise.resolve({ success: false });
  const data = await HTTPClient.post(`/parent/children/${id}/usage`, {
    usage: dailyUsage,
  });
  return data.data;
}

export const getDailyUsages = (id: number): Promise<CommonDailyUsage[]> =>
  HTTPClient.get(`/parent/children/${id}/usage`).then((data) => data.data);

export const setChildrenFields = ({ id, fields }: { id: number; fields: number[] }): Promise<CommonSuccess> =>
  HTTPClient.post(`/parent/fields/${id}`, {
    fields,
  }).then((data) => data.data);

export const getYersterdayHardestSession = (id: string): Promise<CommonSessionResult> =>
  HTTPClient.get(`/parent/children/${id}/hardest-session`).then((data) => data.data);

export const updateWeekControlParameters = ({
  id,
  weekSettings,
  contentFilterLevel,
  preventUninstall,
}: {
  id: string;
  weekSettings: Partial<CommonWeekParameters>;
  contentFilterLevel: number;
  preventUninstall: boolean;
}): Promise<CommonSuccess> =>
  HTTPClient.patch(`/parent/children/${id}/parental-control-settings`, {
    weekSettings,
    contentFilterLevel,
    preventUninstall,
  }).then((data) => data.data);

export const getCurrentParentalControlSettings = (
  id: number | string,
): Promise<
  CommonDayParameters & {
    contentFilterLevel: number;
    preventUninstall: boolean;
  }
> => HTTPClient.get(`/parent/children/${id}/parental-control-settings`).then((data) => data.data);

export const sendParentalCode = (): Promise<CommonSuccess> =>
  HTTPClient.post(`/parent/parental-code`).then((data) => data.data);
