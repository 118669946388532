import { useWindowSize } from '@uidotdev/usehooks';
import approved from 'assets/welcome/approved.png';
import Altus from 'components/altus/altus';
import Loader from 'components/loader/loader';
import OnboardingLayout from 'components/onboarding-layout/onboarding-layout';
import OnboardingSelect from 'components/onboarding-select/onboarding-select';
import OnboardingTestimonial from 'components/onboarding-testimonial/onboarding-testimonial';
import styles from './onboarding-explanations.module.scss';

/* eslint-disable-next-line */
export interface OnboardingExplanationsProps {
  step: number;
  onClick: () => void;
}

export function OnboardingExplanations({
  step,
  onClick,
}: OnboardingExplanationsProps) {
  const size = useWindowSize();
  if (step === 1) {
    return (
      <OnboardingLayout
        canNext
        onClick={onClick}
        title={
          <span>
            Les écrans sont conçus pour <b>captiver continuellement</b>{' '}
            l&apos;attention
          </span>
        }
        subtitle={
          <span>
            Créant une <b>addiction</b> similaire aux machines à sous.
          </span>
        }
      >
        <div />
      </OnboardingLayout>
    );
  }
  if (step === 2) {
    return (
      <OnboardingLayout
        canNext
        onClick={onClick}
        title={
          <span>
            <span style={{ fontWeight: 700, fontSize: 45 }}>4 heures,</span>
            <br />
            <br />
            <span style={{ fontSize: 25 }}>
              C&apos;est le <b>temps moyen</b> passé par les enfants sur le
              smartphone <b>chaque jour</b>.
            </span>
          </span>
        }
        subtitle={
          <span>
            Soit l’équivalent de <b>1 an et demi</b> d&apos;affilé avant leur{' '}
            <b>18 ans</b>.
          </span>
        }
      >
        <div />
      </OnboardingLayout>
    );
  }
  if (step === 3) {
    return (
      <OnboardingLayout
        canNext
        onClick={onClick}
        title={
          <span>
            Que souhaitez-vous pour <b>votre enfant</b> ?
            <br />
            <span style={{ fontSize: 'small', fontStyle: 'italic' }}>
              (Vous pouvez sélectionner plusieurs options)
            </span>
          </span>
        }
      >
        <OnboardingSelect
          options={[
            'Que ses résultats scolaires s’améliorent',
            'Le responsabiliser face à l’utilisation de l’écran',
            'Le protéger du contenu inapproprié',
            'Réduire son temps d’écrans',
            'Moins de conflits autour de l’utilisation du téléphone',
          ]}
        />
      </OnboardingLayout>
    );
  }
  if (step === 4) {
    return (
      <OnboardingLayout
        canNext
        onClick={onClick}
        title={
          <span>
            <b>Bonne nouvelle !</b>
          </span>
        }
        subtitle={<span>Vous pouvez le faire grâce à Altus</span>}
      >
        <div className={styles.center}>
          <Altus type="soluce_fr" />
        </div>
        <div
          style={{
            fontSize: 26,
            fontWeight: 400,
            textAlign: 'left',
            marginTop: 30,
            color: '#1A72C6',
          }}
        >
          Altus transforme le <b>temps</b> que vos <b>enfants</b> passent sur
          leur <b>smartphone</b> pour le transformer en <b>apprentissage</b> !
        </div>
      </OnboardingLayout>
    );
  }
  if (step === 5) {
    return (
      <OnboardingLayout
        canNext
        onClick={onClick}
        title={
          <span>
            ...le transformer en <b>apprentissage</b> !
          </span>
        }
      >
        <div className={styles.center}>
          <Altus type="confeti" />
        </div>
      </OnboardingLayout>
    );
  }
  if (step === 6) {
    return (
      <OnboardingLayout
        canNext
        onClick={onClick}
        title={
          <span>
            <b>Comment ça marche ?</b>
          </span>
        }
        subtitle={
          <span>
            Pour accéder à ses <b>apps favorites</b>, votre enfant répond à des{' '}
            <b>quiz éducatifs</b>.
          </span>
        }
      >
        <div className={styles.center}>
          {/* <Altus type="loop_1" width={size.height > 800 ? 230 : 150} /> */}
          <div className={styles.text} style={{ marginTop: 20, width: '80%' }}>
            <b>Étape 1</b>: Votre enfant s’amuse sur son smartphone
            <br />
          </div>
        </div>
      </OnboardingLayout>
    );
  }
  if (step === 7) {
    return (
      <OnboardingLayout
        canNext
        onClick={onClick}
        title={
          <span>
            <b>Comment ça marche ?</b>
          </span>
        }
        subtitle={
          <span>
            Pour accéder à ses <b>apps favorites</b>, votre enfant répond à des{' '}
            <b>quiz éducatifs</b>.
          </span>
        }
      >
        <div className={styles.center}>
          {/* <Altus type="loop_2" width={size.height > 800 ? 230 : 150} /> */}
          <div className={styles.text} style={{ marginTop: 20, width: '80%' }}>
            <b>Étape 2</b>: Au bout d’un certain temps, Altus bloque ses
            applications
          </div>
        </div>
      </OnboardingLayout>
    );
  }
  if (step === 8) {
    return (
      <OnboardingLayout
        canNext
        onClick={onClick}
        title={
          <span>
            <b>Comment ça marche ?</b>
          </span>
        }
        subtitle={
          <span>
            Pour accéder à ses <b>apps favorites</b>, votre enfant répond à des{' '}
            <b>quiz éducatifs</b>.
          </span>
        }
      >
        <div className={styles.center}>
          {/* <Altus type="loop_3" width={size.height > 800 ? 230 : 150} /> */}
          <div className={styles.text} style={{ marginTop: 20, width: '80%' }}>
            <b>Étape 3</b>: Pour débloquer ses apps, l’enfant répond à un quiz
            éducatif
          </div>
        </div>
      </OnboardingLayout>
    );
  }
  if (step === 9) {
    return (
      <OnboardingLayout
        canNext
        onClick={onClick}
        title={
          <span>
            <b>+ 100k exercices du CE1 à la Terminale </b>
          </span>
        }
        subtitle={
          <span>
            Mini-révisions <b>personnalisées</b> avec l’IA
          </span>
        }
      >
        <div className={styles.center}>
          {/* <Altus type='loop_3' /> */}
          <div className={styles.text}>
            <b>Co-créé</b> par + de <b>80 enseignants</b> et{' '}
            <b>neuro-scientifiques</b>
          </div>
          <img src={approved} style={{ width: '90%', marginTop: 30 }} alt="" />
        </div>
      </OnboardingLayout>
    );
  }
  if (step === 10) {
    return (
      <OnboardingLayout
        canNext
        onClick={onClick}
        compact={size.height < 800}
        title={
          <span>
            Les conflits lié aux <b>écrans</b>, sont-ils <b>fréquents</b> ?
          </span>
        }
      >
        <OnboardingSelect
          options={[
            'Oui, tous les jours',
            'Oui, au moins une fois par semaine',
            'Non, rarement',
            'Non, jamais',
          ]}
        />
      </OnboardingLayout>
    );
  }
  if (step === 11) {
    return (
      <OnboardingLayout
        canNext
        onClick={onClick}
        title={
          <span>
            <b>Vous n&apos;êtes pas seul !</b>
          </span>
        }
        subtitle={
          <span>
            <b>49,4% des familles</b> se heurtent à des conflits liés aux écrans{' '}
            <b>plusieurs fois par semaine</b>.
            <br />
            <br />
            <br />
            <b>70% d’enfants admettent</b> passer <b>trop de temps</b> sur leur{' '}
            <b>téléphone</b>.
          </span>
        }
      >
        <OnboardingTestimonial
          message={
            <span>
              S&apos;ils ne me surveillaient pas, je passerais encore plus de
              temps sur mon téléphone.
            </span>
          }
          name="Thibault, 13 ans"
        />
      </OnboardingLayout>
    );
  }
  if (step === 12) {
    return (
      <OnboardingLayout
        canNext
        onClick={onClick}
        title={
          <span>
            <b>Pause quiz</b>
          </span>
        }
        subtitle={
          <span>
            En instaurant des <b>pauses, Altus encourage</b> un usage plus{' '}
            <b>réfléchi</b> des écrans.
          </span>
        }
      >
        <div className={styles.center}>
          <Altus type="cloud" />
          <div className={styles.text} style={{ marginTop: 30, width: '80%' }}>
            <b>Altus diminue</b> le temps d’écran de <b>51 minutes</b> en
            moyenne
          </div>
        </div>
      </OnboardingLayout>
    );
  }
  if (step === 13) {
    return (
      <OnboardingLayout
        canNext={false}
        title={
          <span style={{ fontSize: 30 }}>
            <b>Une petite seconde...</b>
          </span>
        }
      >
        <OnboardingTestimonial
          message={
            <span>
              Altus a réellement changé la dynamique familiale.
              <br />
              <br />
              Plus de conflits autour des écrans.
              <br />
              Je recommande vivement !
            </span>
          }
          name="Marie, mère de 2 enfants Tom et Sarah, 11 et 14 ans"
        />
        <div style={{ marginTop: 30 }} />
        <div className={styles.center}>
          <Loader />
          <div className={styles.text} style={{ marginTop: 30, width: '90%' }}>
            <b>Altus</b> analyse vos informations pour <b>personnaliser</b>{' '}
            votre <b>expérience</b>
          </div>
        </div>
      </OnboardingLayout>
    );
  }
  return null;
}

export default OnboardingExplanations;
