import { CommonQuestion } from '@codidae/common-types';
import Flex from 'components/flex/flex';
import AltusTalk from 'components/altus-talk/altus-talk';
import Button from 'components/button/button';
import { useMutation } from '@tanstack/react-query';
import { createTicket } from 'services/tickets';
import { useState } from 'react';
import { MdClose } from 'react-icons/md';
import styles from './report-popup.module.scss';

/* eslint-disable-next-line */
export interface ReportPopupProps {
  onClose: () => void;
  question: CommonQuestion;
}

export function ReportPopup({ onClose, question }: ReportPopupProps) {
  const [selectedReason, setSelectedReason] = useState('');
  const [description, setDescription] = useState('');

  const createTicketMutation = useMutation({
    mutationFn: createTicket,
    onSuccess: () => {
      alert('Votre signalement a été enregistré. Merci pour votre aide !');
      onClose();
    },
    onError: (error) => {
      alert(
        'Une erreur est survenue lors du signalement. Veuillez réessayer plus tard.',
      );
    },
  });

  const submitReport = () => {
    createTicketMutation.mutate({
      reason: selectedReason,
      description,
      type: 'report',
      meta: {
        questionId: question.id,
      },
    });
  };

  const renderReasonButton = (reason, label) => (
    <Button
      outline
      color={selectedReason === reason ? 'yellow' : 'primary'}
      onClick={() => setSelectedReason(reason)}
    >
      {label}
    </Button>
  );

  return (
    <div
      style={{
        backgroundColor: 'white',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 100,
        overflow: 'auto',
      }}
    >
      <div className={styles.popupContainer}>
        <button className={styles.closeButton} onClick={onClose} type="button">
          <MdClose />
        </button>
        <Flex
          pad={20}
          direction="column"
          justify="space-between"
          align="center"
          height="100%"
        >
          <div className={styles.altusTalkWrapper}>
            {' '}
            ,
            <AltusTalk type="question" variant="small">
              Quel est le problème avec cette question ?
            </AltusTalk>
          </div>
          <div className={styles.explanation}>
            En signalant un problème, vous nous aidez à améliorer la qualité de
            nos questions.
          </div>
          <Flex
            direction="column"
            gap={15}
            style={{ paddingBottom: 15, paddingTop: 15 }}
            align="center"
            width="100%"
          >
            {renderReasonButton('chapter', 'CHAPITRE INCORRECT')}
            {renderReasonButton('correction', 'CORRECTION INCORRECTE')}
            {renderReasonButton('display', "PROBLÈME D'AFFICHAGE")}
            {renderReasonButton('level', 'DIFFICULTÉ INAPPROPRIÉE')}
            {renderReasonButton('other', 'AUTRE')}

            {selectedReason && (
              <textarea
                className={styles.descriptionInput}
                placeholder="Détails (facultatif)"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            )}
            <Button onClick={submitReport}>REPORTER</Button>
          </Flex>
        </Flex>
      </div>
    </div>
  );
}

export default ReportPopup;
