import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { CommonQuestionResult } from '@codidae/common-types';
import Button from 'components/button/button';
import CollapsablePopin from 'components/collapsable-popin/collapsable-popin';
import ReportPopup from 'components/report-popup/report-popup';
import SessionLoseLife from 'components/session-lose-life/session-lose-life';
import { useState } from 'react';
import { IoWarningOutline } from 'react-icons/io5';
import styles from './incorrect-answer.module.scss';

/* eslint-disable-next-line */
export interface IncorrectAnswerProps {
  result: CommonQuestionResult;
  earnedTime: number;
  next: () => void;
  loading?: boolean;
}

export function IncorrectAnswer({ earnedTime, next, result, loading }: IncorrectAnswerProps) {
  const [report, setReport] = useState<boolean>(false);

  if (report) {
    return (
      <ReportPopup
        onClose={() => {
          setReport(false);
        }}
        question={result.question}
      />
    );
  }

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.loseLifeWrapper}>
          <SessionLoseLife />
        </div>
      </div>

      <CollapsablePopin
        minimumSize={80}
        title={
          <div className={styles.header}>
            <span>Incorrect</span>
            <button
              type="button"
              onClick={() => {
                setReport(true);
              }}
              onTouchStart={() => {
                Haptics.impact({ style: ImpactStyle.Light });
              }}
              onTouchEnd={() => {
                Haptics.impact({ style: ImpactStyle.Light });
              }}
            >
              <IoWarningOutline />
            </button>
          </div>
        }
        className={styles.correction}
      >
        <div className={styles.text}>{result.correction}</div>
        <Button color="red" onClick={() => next()} loading={loading} delay={3000}>
          QUESTION SUIVANTE
        </Button>
      </CollapsablePopin>
    </>
  );
}

export default IncorrectAnswer;
