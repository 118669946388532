import AltusTalk from 'components/altus-talk/altus-talk';
import Button from 'components/button/button';
import Flex from 'components/flex/flex';
import Page from 'components/page/page';
import SessionEarnedTime from 'components/session-earned-time/session-earned-time';
import config from 'config';
import Confetti from 'react-confetti';
import { useNavigate } from 'react-router-dom';
import BlockerPlugin from 'utils/BlockerPlugin';
import styles from './session-over.module.scss';

export default function SessionOverUi({
  unlockSession,
  correctAnswered,
  exerciceCount,
  earnedTime,
  outOfLives,
  parentSession,
  answeredQuestions,
}: {
  unlockSession: boolean;
  correctAnswered: number;
  exerciceCount: number;
  earnedTime: number;
  outOfLives?: boolean;
  parentSession?: boolean;
  answeredQuestions: number;
}) {
  const acedQuiz = correctAnswered && correctAnswered === exerciceCount;
  const navigate = useNavigate();
  const canUnlock = unlockSession && correctAnswered > 0;
  const finishedQuiz = answeredQuestions === exerciceCount && canUnlock;

  const getAltusTalk = () => {
    if (!unlockSession) {
      return <div />;
    }
    if (finishedQuiz) {
      return (
        <>
          Tu as répondu correctement à{' '}
          <b>
            {correctAnswered} questions sur {exerciceCount}
          </b>{' '}
          ! Bravo !
          <br />
          <br />
          Tu as gagné {earnedTime} minutes de temps d&apos;écran !
        </>
      );
    }

    return (
      <>
        Tu n&apos;as plus de vies ! Tu as répondu correctement à{' '}
        <b>
          {correctAnswered} questions sur {exerciceCount}
        </b>
        {unlockSession ? (
          <>
            <br />
            <br />
            {canUnlock
              ? `Tu as gagné ${earnedTime} minutes de temps d'écran...`
              : "Tu n'as pas assez de bonnes réponses pour débloquer tes applications."}
          </>
        ) : (
          <div />
        )}
      </>
    );
  };

  return (
    <Page
      variants={{
        initial: {
          y: '100vh',
        },
        in: {
          y: 0,
        },
        out: {
          y: '-100vh',
        },
      }}
      transition={{
        type: 'tween',
      }}
    >
      <Flex pad={20} direction="column" justify="space-between" align="center" height="100%">
        <div />
        {outOfLives ? (
          <AltusTalk type={finishedQuiz ? 'confeti' : 'sad'}>{getAltusTalk()}</AltusTalk>
        ) : (
          <AltusTalk type={canUnlock ? 'happy' : 'sad'}>
            {acedQuiz ? (
              <>Tu es arrivé au bout du quiz avec 100% de bonnes réponses ! Bravo !</>
            ) : (
              <>
                Tu as répondu correctement à{' '}
                <b>
                  {correctAnswered} questions sur {exerciceCount}
                </b>{' '}
                !
              </>
            )}
            {unlockSession && (
              <>
                <br />
                Tu as gagné <b>{earnedTime} minutes</b> de temps d&apos;écran !
                <br />
              </>
            )}
            {finishedQuiz && (
              <>
                Et <b>{config.positive_quiz_extra_minutes} minutes en plus</b> pour être allé jusqu&apos;au bout !
              </>
            )}
          </AltusTalk>
        )}
        {!parentSession && <SessionEarnedTime time={earnedTime} final />}
        {finishedQuiz && (
          <div className={styles.finishedQuizBonus}>
            <div className={styles.text}>Bonus fin de quiz</div>
            <div className={styles.earnedTime}>
              {!parentSession && <SessionEarnedTime time={config.positive_quiz_extra_minutes} />}
            </div>
          </div>
        )}
        {((correctAnswered > 3 && correctAnswered === answeredQuestions) || finishedQuiz) && <Confetti />}
        {earnedTime > 0 ? (
          <Button
            onClick={async () => {
              try {
                if (unlockSession) {
                  BlockerPlugin.addMinutesToMonitoring({
                    minutes: earnedTime + (finishedQuiz ? config.positive_quiz_extra_minutes : 0),
                  });
                }
                navigate('/dashboard', {
                  replace: true,
                });
              } catch (e) {
                console.log(e);
              }
            }}
          >
            {unlockSession ? 'DÉBLOQUER MES APPS !' : 'QUITTER'}
          </Button>
        ) : (
          <Button
            onClick={() => {
              if (parentSession) navigate(-1);
              else navigate('/dashboard');
            }}
          >
            {parentSession ? 'QUITTER' : 'REFAIRE UN QUIZ !'}
          </Button>
        )}
      </Flex>
    </Page>
  );
}
