import styles from './tutorial-card.module.scss';

/* eslint-disable-next-line */
export interface TutorialCardProps {
  tutorial: {
    name: string;
    link: string;
    thumbnail: string;
  };
}

export function TutorialCard({ tutorial }: TutorialCardProps) {
  return (
    <div className={styles.tutorialCard}>
      <a href={tutorial.link} target="_blank" rel="noreferrer" className={styles.link}>
        <div className={styles.thumbnail} style={{ backgroundImage: `url(${tutorial.thumbnail})` }} />
        <div className={styles.infos}>
          <div className={styles.name}>{tutorial.name}</div>
          <div className={styles.cta}>Par Alina - Voir le tutoriel</div>
        </div>
      </a>
    </div>
  );
}

export default TutorialCard;
