import SubscribeButtons from 'components/altus-plus/subscribe-buttons';
import SubscriptionBackHeader from 'components/altus-plus/subscription-back-header';
import Flex from 'components/flex/flex';
import { FaBell, FaCheck, FaLockOpen } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { SegmentEventType, track } from 'segment';
import styles from './subscription.module.scss';

export default function Subscription2() {
  const navigate = useNavigate();

  return (
    <div className={styles.background}>
      <Flex direction="column" align="center" width="100%" height="100%" pad="20px" justify="space-between">
        <SubscriptionBackHeader
          onBack={() => {
            track(SegmentEventType.EXIT_PAYMENT_FLOW);
            navigate(-3);
          }}
        />
        <div className={styles.subscriptionText}>
          Comment votre essai
          <br /> fonctionne t’il ?
        </div>

        <div className={styles.trialTimeframe}>
          <div className={styles.gradient} />
          <div className={styles.icons}>
            <FaLockOpen />
            <FaBell />
            <FaCheck />
          </div>
          <div className={styles.texts}>
            <div>
              <div className={styles.title}>Aujourd’hui</div>
              <div>Explorez toutes les fonctionnalités sans frais.</div>
            </div>
            <div>
              <div className={styles.title}>Jour 12</div>
              <div>Un rappel sera envoyé avant la fin de l’essai.</div>
            </div>
            <div>
              <div className={styles.title}>Jour 14</div>
              <div>Vous êtes prélevé, l’abonnement est annulable à tout moment.</div>
            </div>
          </div>
        </div>
        <SubscribeButtons
          nextHref="/subscribe/offers"
          backAmount={-3}
          nextText="ESSAYER POUR 0€"
          trackingEvent={SegmentEventType.PAYMENT_ARGUMENTS_2}
        />
      </Flex>
    </div>
  );
}
