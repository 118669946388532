import { Preferences } from '@capacitor/preferences';
import { useMutation, useQueries, useQueryClient } from '@tanstack/react-query';
import Button from 'components/button/button';
import Flex from 'components/flex/flex';
import InputText from 'components/input-text/input-text';
import Select from 'components/select/select';
import config from 'config';
import usePaywallRedirect from 'hooks/paywall-redirect';
import { useEffect, useState } from 'react';
import { BiQr } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { SegmentEventType, track } from 'segment';
import { getClassLevels } from 'services/chapters';
import { deleteChildren, editChildren, getChildren } from 'services/childrens';

/* eslint-disable-next-line */
export interface ParentSettingsGeneralProps {
  childId: string;
}

export function ParentSettingsGeneral({ childId }: ParentSettingsGeneralProps) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [{ data: children }, { data: cycles }] = useQueries({
    queries: [
      {
        queryKey: ['children', childId],
        queryFn: () => getChildren(childId),
      },
      {
        queryKey: ['cycles'],
        queryFn: getClassLevels,
        staleTime: 24 * 3600000 * 21,
        gcTime: 24 * 3600000 * 21,
      },
    ],
  });
  const redirectPaywall = usePaywallRedirect(0.15);
  const editProfileMutation = useMutation({
    mutationFn: editChildren,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['children', childId],
      });
      navigate(`/children/${childId}`);
      redirectPaywall();
    },
  });
  const deleteProfileMutation = useMutation({
    mutationFn: deleteChildren,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getProfile'],
      });
      navigate('/dashboard');
    },
  });
  const [firstname, setFirstname] = useState(children?.firstname ?? '');
  const [cycle, setCycle] = useState<number>(children?.cycle?.id || null);

  useEffect(() => {
    if (!children) return;
    setFirstname(children?.firstname);
    setCycle(children.cycle.id);
  }, [children]);

  const switchAsChild = async () => {
    await Preferences.set({
      key: 'child_mode',
      value: childId,
    });
    track(SegmentEventType.SWITCH_CHILD_MODE, {
      children_id: childId,
    });

    navigate('/dashboard?from_parent=true', {
      replace: true,
    });
  };
  return (
    <Flex direction="column" justify="space-between" align="center" width="100%" height="100%">
      <Flex direction="column" gap={10} width="100%">
        <InputText
          labeled
          placeholder="Prénom"
          defaultValue={children?.firstname}
          onChange={(v) => {
            setFirstname(v.slice(0, 15));
          }}
          value={firstname}
          key={children?.firstname}
        />
        {children?.cycle?.id && cycles && (
          <Select
            labeled
            onChange={(v) => setCycle(Number(v))}
            options={
              cycles
                ? cycles
                    .filter((c) => config.accepted_cycles.includes(c.id))
                    .map((c) => ({
                      label: c.name,
                      value: String(c.id),
                    }))
                : []
            }
            placeholder="Son niveau scolaire"
            defaultValue={String(children?.cycle?.id)}
          />
        )}
        <div style={{ height: 20 }} />
        <Button
          onClick={() => {
            editProfileMutation.mutate({
              id: childId,
              firstname: firstname ?? children.firstname,
              cycleId: cycle ?? children.cycle.id,
            });
          }}
          loading={editProfileMutation.isPending}
        >
          VALIDER LES CHANGEMENTS
        </Button>
      </Flex>
      <Flex direction="column" gap={10} width="100%">
        <Flex direction="column" gap={10} width="100%" style={{ marginTop: 30 }}>
          <Button href={`/auth/pair/${childId}`} color="dark" outline>
            <BiQr /> CONNECTER MON ENFANT
          </Button>
          <Button href={`/auth/email/parent?childName=${children?.firstname}`} color="dark" outline>
            CONNECTER UN PARENT
          </Button>
          <Button
            onClick={async () => {
              if (window.confirm(`Attention ! Êtes-vous sur de vouloir basculer ce téléphone en mode enfant ?`)) {
                await Preferences.set({ key: 'phone_owner', value: 'child' });
                track(SegmentEventType.SWITCH_CHILD_MODE);
                switchAsChild();
              }
            }}
            color="dark"
            outline
          >
            BASCULER EN MODE ENFANT
          </Button>
          <Button href="/support" color="dark" outline>
            UN PROBLÈME AVEC L&apos;APP ?
          </Button>
          <div style={{ height: 20 }} />
          <Button
            color="red"
            outline
            onClick={() => {
              if (window.confirm(`Êtes-vous sur de vouloir supprimer le profile de ${children.firstname} ?`)) {
                deleteProfileMutation.mutate(childId);
              }
            }}
          >
            SUPPRIMER LE PROFIL
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default ParentSettingsGeneral;
