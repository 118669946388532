import AltusTalk from 'components/altus-talk/altus-talk';
import Button from 'components/button/button';
import Flex from 'components/flex/flex';
import Page from 'components/page/page';
import Confetti from 'react-confetti';
import { useNavigate } from 'react-router-dom';

export default function SubscriptionSuccess() {
  const navigate = useNavigate();

  return (
    <Page
      variants={{
        initial: {
          y: '100vh',
        },
        in: {
          y: 0,
        },
        out: {
          y: '-100vh',
        },
      }}
      transition={{
        type: 'tween',
      }}
    >
      <Flex pad={20} direction="column" justify="space-between" align="center" height="100%">
        <div />
        <AltusTalk type="confeti">
          Votre abonnement est désormais actif !
          <br /> <br />
          {`Toute l'équipe Altus vous remercie !`}
        </AltusTalk>
        <Confetti />
        <Button onClick={() => navigate('/dashboard')}>{`PROFITER D'ALTUS PLUS`}</Button>
      </Flex>
    </Page>
  );
}
