import quote from 'assets/icons/quote.svg';
import styles from './onboarding-testimonial.module.scss';

/* eslint-disable-next-line */
export interface OnboardingTestimonialProps {
  message?: React.ReactNode;
  name?: string;
}

export function OnboardingTestimonial({
  message,
  name,
}: OnboardingTestimonialProps) {
  return (
    <div className={styles.testimonial}>
      <img src={quote} alt="quote" className={styles.quote} />
      <div className={styles.content}>
        {message && <div className={styles.message}>{message}</div>}
        {name && <div className={styles.name}>{name}</div>}
      </div>
    </div>
  );
}

export default OnboardingTestimonial;
