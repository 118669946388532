import { BiCompass, BiMath, BiWorld } from "react-icons/bi";
import { BsFillPatchQuestionFill, BsPeople } from "react-icons/bs";
import { GiFrance, GiGreekTemple, GiHumanPyramid, GiPlantsAndAnimals } from "react-icons/gi";
import { MdHistoryEdu, MdOutlineBiotech } from "react-icons/md";
import { SlChemistry } from "react-icons/sl";
import spainFlag from "assets/flags/spain.svg";
import germanyFlag from "assets/flags/germany.svg";
import ukFlag from "assets/flags/uk.svg";
import { RiEnglishInput } from "react-icons/ri";

export const getIconFromFieldId = (fieldId: number) => {
  switch (fieldId) {
    case 23:
      return <GiFrance />;
    case 29:
      return <div>EMC</div>;
    case 36:
      return <SlChemistry />;
    case 37:
      return <GiPlantsAndAnimals />;
    case 42:
      return <img src={ukFlag} alt="spain flag" />;
    case 43:
      return <img src={spainFlag} alt="uk flag" />;
    case 44:
      return <img src={germanyFlag} alt="germany flag" />;
    case 45:
      return <MdHistoryEdu />;
    case 46:
      return <BiWorld />;
    case 51:
      return <BsFillPatchQuestionFill />;
    case 28:
      return <BiMath />;
    case 34:
      return <MdOutlineBiotech />;
    case 41:
      return <BsPeople />;
    case 47:
      return <BiCompass />;
    case 48:
      return <GiHumanPyramid />;
    case 49:
      return <RiEnglishInput />;
    case 50:
      return <GiGreekTemple />;
    default:
      return null;
  }
}