import { useMutation } from '@tanstack/react-query';
import AltusTalk from 'components/altus-talk/altus-talk';
import Button from 'components/button/button';
import Flex from 'components/flex/flex';
import InputText from 'components/input-text/input-text';
import Page from 'components/page/page';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { sendParentInvite } from 'services/auth';

export default function AuthEmailParentPage() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const inviteParentMutation = useMutation({
    mutationFn: sendParentInvite,
    onSuccess: (data) => {
      alert(`Le mail d'invitation a bien été envoyé!`);
      navigate(-1);
    },
  });
  const [email, setEmail] = useState('');
  const childName = searchParams.get('childName');

  return (
    <Page parentMode>
      <Flex height="100%" justify="space-between" direction="column" pad={20}>
        <Flex direction="column" justify="center" width="100%" style={{ marginTop: 40 }}>
          <AltusTalk>
            {`Ici, vous pouvez inviter un parent à utiliser Altus et partager l'accès aux profils de vos enfants. Il suffit d'entrer son adresse mail !`}
          </AltusTalk>
        </Flex>
        <Flex width="100%" direction="column">
          <InputText labeled placeholder="Adresse mail" onChange={setEmail} value={email} />
        </Flex>
        <Flex width="100%" direction="column" gap={20}>
          <Button
            disabled={!email}
            onClick={() =>
              inviteParentMutation.mutate({
                email,
                childName,
              })
            }
            loading={inviteParentMutation.isPending}
          >
            INVITER
          </Button>
          <Button href={-1} outline>
            RETOUR
          </Button>
        </Flex>
      </Flex>
    </Page>
  );
}
