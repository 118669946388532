// import React, { useState, useEffect } from "react";
import React from 'react';
import Flex from 'components/flex/flex';
import styles from './circular-progress-bar.module.scss';

export interface CircularProgressBarProps {
  value: number;
  max: number;
  size: number;
  strokeWidth: number;
  children: React.ReactNode;
}

export function CircularProgressBar({ value, max, size, strokeWidth, children }: CircularProgressBarProps) {
  const radius = (size - strokeWidth) / 2;
  const circumference = radius * 2 * Math.PI;
  const progress = value / max + 1;
  const offset = circumference * (1 - progress);

  return (
    <Flex className={styles.progressBar} justify="center" style={{ margin: '0 auto' }}>
      <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
        <circle cx={size / 2} cy={size / 2} r={radius} fill="none" stroke="#c7b7ff" strokeWidth={strokeWidth} />
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          fill="none"
          stroke="#352670"
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={(offset ?? 0).toString()}
          strokeLinecap="round"
          // transform="rotate(-45 {size / 2} {size / 2})"
        />
      </svg>
      <div className={styles.text}>{children}</div>
    </Flex>
  );
}

export default CircularProgressBar;
