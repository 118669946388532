/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Preferences } from '@capacitor/preferences';
import screentimeImage from 'assets/screentime.jpg';
import AltusTalk from 'components/altus-talk/altus-talk';
import Button from 'components/button/button';
import Flex from 'components/flex/flex';
import OnboardingHeader from 'components/onboarding-header/onboarding-header';
import Page from 'components/page/page';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SegmentEventType, track } from 'segment';
import BlockerPlugin from 'utils/BlockerPlugin';
import socialNetworkIcon from 'assets/icons/ios/social-networks.svg';
import gamesIcon from 'assets/icons/ios/games.svg';
import entertainmentIcon from 'assets/icons/ios/entertainment.svg';
import allAppsIcon from 'assets/icons/ios/all.svg';
import RangeProgressBar from 'components/range-progress-bar/range-progress-bar';
import { useQuery } from '@tanstack/react-query';
import { getChildren } from 'services/childrens';

/* eslint-disable-next-line */
export interface BlockerProps {}

const StepLayout = ({
  canNext,
  onClick,
  children,
  text,
  background,
  secondaryButton,
}: {
  canNext: boolean;
  onClick: () => void;
  children: React.ReactNode;
  text?: string;
  background?: string;
  secondaryButton?: React.ReactNode;
}) => (
  <Flex
    direction="column"
    justify="space-between"
    align="center"
    width="100%"
    height="100%"
    style={{ paddingTop: 75, overflowY: 'scroll', backgroundColor: background }}
  >
    <Flex direction="column" gap={20} pad={20} width="100%">
      {children}
    </Flex>
    <Flex direction="column" gap={10} pad={20} width="100%">
      <Button onClick={onClick} disabled={!canNext}>
        {text || 'SUIVANT'}
      </Button>
      {secondaryButton}
    </Flex>
  </Flex>
);

export function BlockerIos(props: BlockerProps) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [step, setStep] = useState(0);
  const { data: children } = useQuery({
    queryKey: ['children', id],
    queryFn: () => getChildren(id),
  });

  useEffect(() => {
    BlockerPlugin.isScreenTimeEnabled().then((res) => {
      if (res.enabled) {
        setStep(1);
      }
    });
    track(SegmentEventType.BLOCKER_CONFIGURATION_STARTED, {
      children_id: id,
    });
  }, [id]);

  const setPhoneAsConfigured = async () => {
    await BlockerPlugin.turnOnBlocker();
    await Preferences.set({
      key: 'blockerConfigured',
      value: 'true',
    });
    track(SegmentEventType.BLOCKER_CONFIGURATION_COMPLETED, {
      children_id: id,
    });
    navigate('/dashboard');
  };

  const askPermission = async () => {
    await BlockerPlugin.askScreenTimePermission().then(async () => {
      setStep(1);
    });
  };

  const selectEntertainmentApps = async () => {
    await BlockerPlugin.entertainmentAppsSelector().then(async () => {
      setStep(2);
    });
  };

  const selectAllApps = async () => {
    await BlockerPlugin.allAppsSelector().then(async () => {
      setStep(3);
    });
  };

  const askLocationPermission = async () => {
    await BlockerPlugin.askForLocationPermission().then(async () => {
      setStep(4);
    });
  };

  const TOTAL_STEP = 4;
  return (
    <Page>
      <Flex direction="column" justify="space-between" align="center" width="100%" height="100%">
        <OnboardingHeader
          percentage={(step / TOTAL_STEP) * 100}
          onClick={() => (step > 0 ? setStep(step - 1) : navigate('/dashboard'))}
        />
        {step === 0 && (
          <StepLayout canNext onClick={askPermission} text="J'AI COMPRIS">
            <AltusTalk>
              Cliquez sur continuer quand cet écran s&apos;affiche: <br />
              <img
                src={screentimeImage}
                onClick={askPermission}
                style={{ width: '100%', marginTop: 20, marginBottom: -5 }}
                alt="Screentime"
              />
            </AltusTalk>
          </StepLayout>
        )}
        {step === 1 && (
          <StepLayout canNext onClick={selectEntertainmentApps} text="C'EST PARTI">
            <div
              style={{
                fontSize: 30,
                fontWeight: 800,
                textAlign: 'center',
                width: '100%',
                color: '#031D3D',
              }}
            >
              MODE JOUR
            </div>
            {children?.isLogoutRangeEnabled && (
              <RangeProgressBar
                hour={new Date().getHours() * 60 + new Date().getMinutes()}
                progress1={children?.logoutRangeMorning ?? 420}
                progress2={children?.logoutRangeEvening ?? 1140}
              />
            )}
            <div style={{ height: 20 }} />
            <AltusTalk variant="small" type="green_phone">
              <div style={{ marginBottom: 10 }}>
                Sélectionnez les <b>catégories</b> suivantes:
              </div>
              <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                - <img src={entertainmentIcon} alt="Entertainment" width={20} /> <span>Divertissement</span>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                - <img src={socialNetworkIcon} alt="Social Networks" width={20} /> <span>Réseaux sociaux</span>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                - <img src={gamesIcon} alt="Games" width={20} /> <span>Jeux</span>
              </div>
              {/* <b>&quot;Divertissement&quot;</b>,{' '}
              <b>&quot;Réseaux sociaux&quot;</b>, et <b>&quot;Jeux&quot;</b>{' '}
              puis cliquez sur &quot;Sauvegarder&quot; */}
            </AltusTalk>
          </StepLayout>
        )}
        {step === 2 && (
          <StepLayout canNext onClick={selectAllApps} text="C'EST PARTI" background="#031D3D">
            <div
              style={{
                fontSize: 30,
                fontWeight: 800,
                textAlign: 'center',
                width: '100%',
                color: '#F0F7FF',
              }}
            >
              MODE NUIT
            </div>
            {children?.isLogoutRangeEnabled && (
              <RangeProgressBar
                hour={new Date().getHours() * 60 + new Date().getMinutes()}
                progress1={children?.logoutRangeEvening ?? 420}
                progress2={children?.logoutRangeMorning ?? 1140}
                darkMode
              />
            )}
            <div style={{ height: 20 }} />
            <AltusTalk variant="small" type="red_phone">
              <div style={{ marginBottom: 10 }}>
                Sélectionnez <b>toutes les applications</b> puis cliquez sur &quot;Sauvegarder&quot;
              </div>
              <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                - <img src={allAppsIcon} alt="All Apps" width={20} /> <span>Toutes les applications</span>
              </div>
            </AltusTalk>
          </StepLayout>
        )}
        {step === 3 && (
          <StepLayout
            canNext
            onClick={askLocationPermission}
            secondaryButton={
              <Button
                outline
                onClick={() => {
                  setStep(4);
                }}
              >
                NE PAS ACTIVER
              </Button>
            }
            text="ACTIVER"
          >
            <AltusTalk>
              Souhaitez vous activer la <b>géolocalisation</b> pour le téléphone ?
              <br />
              <br />
              Vous serez en mesure de voir la <b>localisation</b> en direct de votre enfant
            </AltusTalk>
          </StepLayout>
        )}
        {step === 4 && (
          <StepLayout canNext onClick={setPhoneAsConfigured} text="ACCÉDER À MON ESPACE">
            <AltusTalk>Le téléphone est configuré !</AltusTalk>
          </StepLayout>
        )}
      </Flex>
    </Page>
  );
}

export default BlockerIos;
