import { useMutation, useQuery } from '@tanstack/react-query';
import Altus from 'components/altus/altus';
import Button from 'components/button/button';
import Card from 'components/card/card';
import Dialog from 'components/dialog/dialog';
import Flex from 'components/flex/flex';
import { useEffect, useState } from 'react';
import { createTimeRequest, getTimeRequests } from 'services/time-request';
import styles from './children-home.module.scss';

export default function TimeRequestButton({ childId }: { childId: number }) {
  const requestTimeMutation = useMutation({
    mutationFn: createTimeRequest,
    onSuccess() {
      setDialog(false);
      refetch();
    },
  });
  const { data: timeRequests, refetch } = useQuery({
    queryFn: () => getTimeRequests(),
    queryKey: ['getTimeRequests'],
    refetchInterval: 30000,
    staleTime: 30000,
  });
  const [dialog, setDialog] = useState(false);

  const [timeRequestAvailableIn, setTimeRequestAvailableIn] = useState<number | null>(null);
  useEffect(() => {
    if (!timeRequests?.length) {
      setTimeRequestAvailableIn(null);
      return;
    }
    const timeReq = timeRequests.find((tr) => tr.student.id === childId && typeof tr.awardedMinutes !== 'number');

    if (!timeReq) {
      setTimeRequestAvailableIn(null);
      return;
    }
    setTimeRequestAvailableIn(3600000 - (Date.now() - timeReq.createdAt.getTime()));
  }, [childId, timeRequests]);

  useEffect(() => {
    if (timeRequestAvailableIn !== null) {
      const timeout = setTimeout(() => {
        if (timeRequestAvailableIn > 1000) setTimeRequestAvailableIn(timeRequestAvailableIn - 1000);
        else {
          setTimeRequestAvailableIn(null);
          refetch();
        }
      }, 1000);
      return () => clearTimeout(timeout);
    }
    return () => {};
  }, [refetch, timeRequestAvailableIn]);

  return (
    <>
      {timeRequestAvailableIn === null && (
        <Card
          style={{ width: '100%', padding: 20, flexShrink: 0 }}
          className={styles.askForTime}
          onClick={() => setDialog(true)}
        >
          <Altus type="ask_for_time" width={120} />
          <div className={styles.text}>DEMANDER DU TEMPS SUPPLÉMENTAIRE</div>
        </Card>
      )}
      {timeRequestAvailableIn !== null && (
        <Card style={{ width: '100%', padding: 20, flexShrink: 0 }} className={styles.askForTime}>
          <Altus type="ask_for_time" width={80} />
          <div className={styles.text}>
            TEMPS RESTANT AVANT LA PROCHAINE DEMANDE:
            <p>
              {Math.floor(timeRequestAvailableIn / 60000)}:
              {Math.floor((timeRequestAvailableIn % 60000) / 1000)
                .toString()
                .padStart(2, '0')}
            </p>
          </div>
        </Card>
      )}
      <Dialog open={dialog} onClose={() => setDialog(false)}>
        <h4>Demander du temps</h4>
        <p>{`Tu n'as plus de temps disponible aujourd'hui !`}</p>
        <p>
          Demande du temps à ton parent, ceci va lui envoyer une notification, et il aura une heure pour répondre à ta
          demande !
        </p>
        <Flex direction="column" gap={10}>
          <Button onClick={() => setDialog(false)} outline>
            ANNULER
          </Button>
          <Button
            loading={requestTimeMutation.isPending}
            onClick={() => requestTimeMutation.mutateAsync({ studentId: childId })}
          >
            DEMANDER DU TEMPS
          </Button>
        </Flex>
      </Dialog>
    </>
  );
}
