import { Preferences } from '@capacitor/preferences';
import { useUserContext } from 'contexts/user-context';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SegmentEventType, track } from 'segment';

export default function usePaywallRedirect(chance: number, backNavigation?: boolean) {
  const navigate = useNavigate();
  const { userSubscriptionStatus } = useUserContext();
  const [halfChance, setHalfChance] = useState(false);
  const actualChance = useMemo(() => (halfChance ? chance / 4 : chance), [chance, halfChance]);

  useEffect(() => {
    Preferences.get({
      key: 'lastRedirect',
    }).then(({ value }) => {
      if (new Date(value).getTime() + 3600000 * 8) setHalfChance(true);
    });
  }, []);

  return () => {
    if (userSubscriptionStatus === 'freemium' && Math.random() < actualChance) {
      track(SegmentEventType.RANDOM_PAYWALL_REDIRECTION);
      navigate('/subscribe', {
        replace: backNavigation,
      });
      Preferences.set({
        key: 'lastRedirect',
        value: new Date().toISOString(),
      });
      return true;
    }
    return false;
  };
}
