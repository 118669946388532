import Button from 'components/button/button';
import Flex from 'components/flex/flex';

/* eslint-disable-next-line */
export interface OnboardingLayoutProps {
  canNext: boolean;
  onClick?: () => void;
  children: React.ReactNode;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  nextLabel?: string;
  compact?: boolean;
  noHeader?: boolean;
  noPadding?: boolean;
}

export function OnboardingLayout({
  canNext,
  onClick,
  children,
  title,
  subtitle,
  nextLabel,
  compact,
  noPadding,
  noHeader,
}: OnboardingLayoutProps) {
  let paddingTop = 80;
  if (noHeader) {
    paddingTop = 10;
  } else if (compact) {
    paddingTop = 70;
  } else if (noPadding) {
    paddingTop = 0;
  }
  return (
    <Flex
      direction="column"
      justify="space-between"
      align="center"
      width="100%"
      height="100%"
      style={{
        paddingTop,
        overflowY: 'scroll',
      }}
    >
      <div>
        {title && (
          <div
            style={{
              fontSize: 30,
              fontWeight: 400,
              textAlign: 'left',
              marginLeft: 25,
              marginRight: 25,
              color: '#1A72C6',
            }}
          >
            {title}
          </div>
        )}
        {subtitle && (
          <div
            style={{
              fontSize: 18,
              fontWeight: 400,
              textAlign: 'left',
              marginLeft: 25,
              marginTop: 20,
              marginRight: 50,
              color: '#1A72C6',
            }}
          >
            {subtitle}
          </div>
        )}
      </div>
      <Flex direction="column" gap={20} pad={noPadding ? 0 : 25} width="100%" height={compact ? '100%' : 'auto'}>
        {children}
      </Flex>
      {onClick && (
        <Flex direction="column" gap={20} pad={20} width="100%">
          <Button onClick={onClick} disabled={!canNext}>
            {nextLabel || 'SUIVANT'}
          </Button>
        </Flex>
      )}
    </Flex>
  );
}

export default OnboardingLayout;
