import { App } from '@capacitor/app';
import { useEffect } from 'react';

export default function useDisableBackButton() {
  useEffect(() => {
    const listener = App.addListener('backButton', () => {});
    return () => {
      listener.remove();
    };
  }, []);
}
