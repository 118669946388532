import Altus from 'components/altus/altus';
import Flex from 'components/flex/flex';
import RangeProgressBar from 'components/range-progress-bar/range-progress-bar';
import StatsNumber from 'components/stats-number/stats-number';
import useChildDayStats from 'utils/childDayStats';

import { useUserContext } from 'contexts/user-context';
import styles from './children-day-recap.module.scss';

export interface ChildrenDayRecapProps {
  childrenId: string;
}

export function ChildrenDayRecap({ childrenId }: ChildrenDayRecapProps) {
  const now = new Date();
  const { parentalControlSettings, child } = useUserContext();

  const { todaySessions, workedMinutes, usage } = useChildDayStats(childrenId);
  const logoutRangeEvening =
    parentalControlSettings?.isLogoutRangeEnabled ?? false ? parentalControlSettings.logoutRangeEvening : 24 * 60;
  return (
    <Flex
      direction="column"
      height="100%"
      width="100%"
      align="center"
      justify="space-between"
      pad={20}
      gap={20}
      style={{ overflowY: 'scroll' }}
    >
      <p className={styles.textTitle}>
        L’appareil de {child?.firstname} est désactivé jusqu’à {Math.floor(logoutRangeEvening / 60)}h
        {logoutRangeEvening % 60 > 0 && logoutRangeEvening % 60}
      </p>
      <Altus type="asleep" width={120} />
      <p className={styles.textTitle}>
        {Math.floor(usage / 60) > 0 && (
          <span className={styles.textTitle}>
            <span className={styles.textTitleNbr}>{Math.floor(usage / 60)}</span>h
          </span>
        )}
        <span className={styles.textTitleNbr}>{Math.floor(usage % 60)}</span>
        min
        <p className={styles.textSubtitle}>de temps de divertissement aujourd&apos;hui</p>
      </p>
      <Flex gap={20} width="100%" direction="column">
        <RangeProgressBar
          progress1={
            parentalControlSettings?.isLogoutRangeEnabled ?? false ? parentalControlSettings?.logoutRangeMorning : 0
          }
          progress2={logoutRangeEvening}
          hour={now.getHours() * 60 + now.getMinutes()}
        />
        <Flex gap={10} width="100%" style={{ marginBottom: 10 }}>
          <StatsNumber number={todaySessions?.length ?? 0} text="quiz aujourd'hui" />
          <StatsNumber
            number={(Number.isNaN(workedMinutes) ? 0 : workedMinutes)?.toFixed(0) ?? 0}
            text="minutes de travail aujourd'hui"
          />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default ChildrenDayRecap;
