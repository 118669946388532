import { BiBook, BiChart, BiCog, BiHome } from 'react-icons/bi';
import { PiPathBold } from 'react-icons/pi';
import { ChildrenDashboardTab } from 'views/children/dashboard';
import styles from './child-tab-bar.module.scss';

/* eslint-disable-next-line */
export interface ChildTabBarProps {
  currentTab: ChildrenDashboardTab;
  setCurrentTab: (tab: ChildrenDashboardTab) => void;
}

export function ChildTabBar({ currentTab, setCurrentTab }: ChildTabBarProps) {
  return (
    <div className={styles.bar}>
      <BiHome
        onClick={() => setCurrentTab('home')}
        className={currentTab === 'home' ? styles.active : ''}
      />
      <PiPathBold
        onClick={() => setCurrentTab('progression')}
        className={currentTab === 'progression' ? styles.active : ''}
      />
      <BiBook
        onClick={() => setCurrentTab('chapters')}
        className={currentTab === 'chapters' ? styles.active : ''}
      />
      <BiChart
        onClick={() => setCurrentTab('stats')}
        className={currentTab === 'stats' ? styles.active : ''}
      />
      <BiCog
        onClick={() => setCurrentTab('settings')}
        className={currentTab === 'settings' ? styles.active : ''}
      />
    </div>
  );
}

export default ChildTabBar;
