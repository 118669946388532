import { CommonDayParameters, CommonWeekParameters, Day } from '@codidae/common-types';
import { useMutation, useQueries, useQueryClient } from '@tanstack/react-query';
import Button from 'components/button/button';
import Flex from 'components/flex/flex';
import LabeledSwitch from 'components/labeled-switch/labeled-switch';
import Select from 'components/select/select';
import TimePicker from 'components/time-picker/time-picker';
import config from 'config';
import usePaywallRedirect from 'hooks/paywall-redirect';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { BiEdit } from 'react-icons/bi';
import { getChildren, updateWeekControlParameters } from 'services/childrens';
import styles from './parent-settings-parental-control.module.scss';

/* eslint-disable-next-line */
export interface ParentSettingsParentalControlProps {
  childrenId: string;
}

const DAYS = [
  { label: 'L', id: 1, text: 'Lundi' },
  { label: 'M', id: 2, text: 'Mardi' },
  { label: 'M', id: 3, text: 'Mercredi' },
  { label: 'J', id: 4, text: 'Jeudi' },
  { label: 'V', id: 5, text: 'Vendredi' },
  { label: 'S', id: 6, text: 'Samedi' },
  { label: 'D', id: 0, text: 'Dimanche' },
];

export function ParentSettingsParentalControl({ childrenId }: ParentSettingsParentalControlProps) {
  const [{ data: children, refetch }] = useQueries({
    queries: [
      {
        queryKey: ['children', childrenId],
        queryFn: () => getChildren(childrenId),
      },
    ],
  });
  const [selectedDays, setSelectedDays] = useState<Day[]>([]);
  const [preventUninstall, setPreventUninstall] = useState(children?.preventUninstall || false);
  const [editTimePicker, setEditTimePicker] = useState<number | null>(null);
  const [contentFilterLevel, setContentFilterLevel] = useState(children?.contentFilterLevel || 0);
  const [weekSettings, setWeekSettings] = useState<Partial<CommonWeekParameters>>({});
  const queryClient = useQueryClient();
  const redirectPaywall = usePaywallRedirect(0.15);

  const updateSettingsMutation = useMutation({
    mutationFn: updateWeekControlParameters,
    onSuccess: () => {
      setSelectedDays([]);
      refetch();
      queryClient.invalidateQueries({
        queryKey: ['getCurrentParentalControlSettings', childrenId],
      });
      alert('Les changements ont été enregistrés !');
      redirectPaywall();
    },
  });

  useEffect(() => {
    if (children) {
      setContentFilterLevel(children.contentFilterLevel);
      setPreventUninstall(children.preventUninstall);
      setWeekSettings(children.weekControlParameters || {});
    }
  }, [children]);

  const getSettingsOfDay = useCallback(
    (day: Day) =>
      weekSettings[day] || {
        maxDailyTime: children?.maxDailyTime,
        initialDailyTime: children?.initialDailyTime,
        logoutRangeMorning: children?.logoutRangeMorning,
        logoutRangeEvening: children?.logoutRangeEvening,
        isLogoutRangeEnabled: children?.isLogoutRangeEnabled,
        earnedTimeBase: 1,
        awardedTime: 0,
      },
    [weekSettings, children],
  );

  const getSavedSettingsOfDay = useCallback(
    (day: Day) =>
      children?.weekControlParameters?.[day] || {
        maxDailyTime: children.maxDailyTime,
        initialDailyTime: children.initialDailyTime,
        logoutRangeMorning: children.logoutRangeMorning,
        logoutRangeEvening: children.logoutRangeEvening,
        isLogoutRangeEnabled: children.isLogoutRangeEnabled,
        earnedTimeBase: 1,
        awardedTime: 0,
      },
    [children],
  );

  const handleUpdateField = useCallback(
    (field: string, value: number | boolean) => {
      const newWeekSettings = { ...weekSettings };
      selectedDays.forEach((day) => {
        newWeekSettings[day] = { ...getSettingsOfDay(selectedDays[0]), [field]: value };
      });
      setWeekSettings(newWeekSettings);
    },
    [getSettingsOfDay, selectedDays, weekSettings],
  );

  const daySettingsEqual = useCallback(
    (d1: Day, d2: Day, getSettingsFn: (day: Day) => CommonDayParameters = getSettingsOfDay) => {
      const s1 = getSettingsFn(d1);
      const s2 = getSettingsFn(d2);
      return (
        s1.maxDailyTime === s2.maxDailyTime &&
        s1.initialDailyTime === s2.initialDailyTime &&
        s1.logoutRangeMorning === s2.logoutRangeMorning &&
        s1.logoutRangeEvening === s2.logoutRangeEvening &&
        s1.isLogoutRangeEnabled === s2.isLogoutRangeEnabled &&
        s1.earnedTimeBase === s2.earnedTimeBase
      );
    },
    [getSettingsOfDay],
  );

  const groupedDays = useMemo(
    () =>
      DAYS.reduce((acc, day) => {
        const found = acc.find((group) => daySettingsEqual(group[0], day.id));
        if (found) {
          found.push(day.id);
        } else {
          acc.push([day.id]);
        }
        return acc;
      }, [] as Day[][]),
    [daySettingsEqual],
  );

  const getGroupName = useCallback((group: Day[]) => {
    if (group.length === 1) {
      return DAYS.find((d) => d.id === group[0])?.text;
    }
    if (group.length === 7) {
      return 'Tous les jours';
    }
    if (
      group.includes(1) &&
      group.includes(2) &&
      group.includes(3) &&
      group.includes(4) &&
      group.includes(5) &&
      group.length === 5
    ) {
      return 'Lundi à Vendredi';
    }
    if (group.includes(0) && group.includes(6) && group.length === 2) {
      return 'Week-end';
    }
    return group.map((day) => DAYS.find((d) => d.id === day)?.text).join(', ');
  }, []);

  const minutesToHours = useCallback((minutes: number) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours}h${mins === 0 ? '00' : mins}`;
  }, []);

  const getSettingsAsString = useCallback(
    (day: Day) => {
      const settings = getSettingsOfDay(day);
      const timeSettings = `Initial: ${minutesToHours(settings.initialDailyTime)} - Max: ${minutesToHours(settings.maxDailyTime)}`;

      if (settings.isLogoutRangeEnabled) {
        return (
          <>
            Mode jour/nuit: {minutesToHours(settings.logoutRangeEvening)} -{' '}
            {minutesToHours(settings.logoutRangeMorning)}
            <br />
            {timeSettings}
          </>
        );
      }
      return (
        <>
          Mode jour/nuit: désactivé
          <br />
          {timeSettings}
        </>
      );
    },
    [getSettingsOfDay, minutesToHours],
  );

  return (
    <Flex width="100%" direction="column" gap={15}>
      <Flex width="100%" direction="column" gap={20} className={styles.weekSettings}>
        <Flex direction="row" gap={10} align="center" justify="space-between" width="100%">
          {DAYS.map((day) => (
            <div
              key={day.id}
              className={
                styles.dayButton +
                (selectedDays.includes(day.id) ? ` ${styles.active}` : '') +
                (selectedDays.includes(day.id) && daySettingsEqual(selectedDays[0], day.id, getSavedSettingsOfDay)
                  ? ` ${styles.first}`
                  : '')
              }
              onClick={() => {
                if (selectedDays.includes(day.id)) {
                  setSelectedDays(selectedDays.filter((d) => d !== day.id));
                } else {
                  setSelectedDays([...selectedDays, day.id]);
                }
              }}
            >
              {day.label}
            </div>
          ))}
        </Flex>
        {selectedDays.length > 0 ? (
          <>
            <TimePicker
              value={getSettingsOfDay(selectedDays[0]).initialDailyTime}
              onChange={(v) => handleUpdateField('initialDailyTime', v)}
              labeled
              placeholder="Temps initial chaque jour"
              options={config.initial_daily_time}
              onEdit={() => setEditTimePicker(3)}
              editMode={editTimePicker === 3}
              tooltipInfo={
                <div className={styles.textToolTip}>Temps disponible avant de devoir faire un premier quiz.</div>
              }
            />
            <TimePicker
              value={getSettingsOfDay(selectedDays[0]).maxDailyTime}
              onChange={(v) => handleUpdateField('maxDailyTime', v)}
              labeled
              placeholder="Temps maximum débloquable"
              options={config.max_daily_time}
              onEdit={() => setEditTimePicker(0)}
              editMode={editTimePicker === 0}
              tooltipInfo={
                <div className={styles.textToolTip}>
                  Temps maximum auquel votre enfant à accès à ses applications chaque jour.
                </div>
              }
            />
            <LabeledSwitch
              label="Activer le mode jour / nuit"
              checked={getSettingsOfDay(selectedDays[0]).isLogoutRangeEnabled}
              onChange={(v) => handleUpdateField('isLogoutRangeEnabled', v)}
              tooltipInfo={<div className={styles.textToolTip}>Permet de définir un mode Jour et un mode Nuit.</div>}
            />
            {getSettingsOfDay(selectedDays[0]).isLogoutRangeEnabled && (
              <>
                <TimePicker
                  value={getSettingsOfDay(selectedDays[0]).logoutRangeMorning}
                  onChange={(v) => handleUpdateField('logoutRangeMorning', v)}
                  labeled
                  placeholder="Fin du mode nuit (matin)"
                  options={config.logout_range_time}
                  onEdit={() => setEditTimePicker(1)}
                  editMode={editTimePicker === 1}
                  displayValue={minutesToHours}
                  tooltipInfo={
                    <div className={styles.textToolTip}>
                      Permet de définir l&apos;heure de réactivation du téléphone de votre enfant.
                    </div>
                  }
                />
                <TimePicker
                  value={getSettingsOfDay(selectedDays[0]).logoutRangeEvening}
                  onChange={(v) => handleUpdateField('logoutRangeEvening', v)}
                  labeled
                  placeholder="Début du mode nuit (soir)"
                  options={config.logout_range_time}
                  onEdit={() => setEditTimePicker(2)}
                  editMode={editTimePicker === 2}
                  displayValue={minutesToHours}
                  tooltipInfo={
                    <div className={styles.textToolTip}>
                      Permet de définir l&apos;heure de désactivation du téléphone de votre enfant.
                    </div>
                  }
                />
              </>
            )}
            <Select
              options={[
                {
                  value: '3',
                  label: 'Facile',
                },
                {
                  value: '2',
                  label: 'Moyenne',
                },
                {
                  value: '1',
                  label: 'Difficile',
                },
              ]}
              // defaultValue=
              defaultValue={getSettingsOfDay(selectedDays[0]).earnedTimeBase?.toString() ?? '2'}
              placeholder="Difficulté des récompenses"
              onChange={(value) => {
                handleUpdateField('earnedTimeBase', Number(value));
              }}
              labeled
              tooltipInfo={
                <div className={styles.textToolTip}>
                  {`Avec des récompenses plus difficiles, votre enfant devra répondre à plus de questions sur l'application Altus pour débloquer du temps d'écran.`}
                </div>
              }
            />
          </>
        ) : (
          <div className={styles.noDaySelected}>
            Veuillez sélectionner un ou plusieurs jours afin de modifier les paramètres pour ces journées
            {groupedDays.map((group, index) => (
              <div key={index} className={styles.dayGroup}>
                <div className={styles.dayGroupInfos}>
                  <div className={styles.dayGroupTitle}>{getGroupName(group)}</div>
                  <div className={styles.dayGroupContent}>{getSettingsAsString(group[0])}</div>
                </div>
                <div className={styles.dayGroupButton} onClick={() => setSelectedDays(group)}>
                  <BiEdit />
                </div>
              </div>
            ))}
          </div>
        )}
      </Flex>
      {children?.phonePlatform === 'ios' && (
        <>
          <Select
            labeled
            onChange={(v) => setContentFilterLevel(Number(v))}
            options={[
              { label: 'Désactivé', value: '0' },
              { label: 'Filtre modéré', value: '1' },
              { label: 'Filtre fort', value: '2' },
            ]}
            placeholder="Filtre de contenu"
            tooltipInfo={
              <div className={styles.textToolTip}>
                Filtrage du contenu web :
                <br />- Modéré: retire le contenu érotique.
                <br />- Fort: retire le contenu violent, jeux en multi-joueurs et érotique.
              </div>
            }
            defaultValue={children?.contentFilterLevel?.toString()}
          />
          <LabeledSwitch
            label="Empêcher la désinstallation d'applications"
            checked={preventUninstall}
            onChange={(v) => setPreventUninstall(v)}
            tooltipInfo={
              <div className={styles.textToolTip}>
                Votre enfant ne pourra plus désinstaller d’applications. Évite la désinstallation d&apos;Altus.
              </div>
            }
          />
        </>
      )}
      <div style={{ height: 10 }} />
      <Button
        onClick={() => {
          updateSettingsMutation.mutate({
            id: childrenId,
            contentFilterLevel,
            preventUninstall,
            weekSettings,
          });
        }}
        loading={updateSettingsMutation.isPending}
      >
        VALIDER LES CHANGEMENTS
      </Button>
      <Button
        onClick={() => {
          setContentFilterLevel(children?.contentFilterLevel || 0);
          setPreventUninstall(children?.preventUninstall || false);
          setWeekSettings(children?.weekControlParameters || {});
          setSelectedDays([]);
        }}
        outline
      >
        ANNULER
      </Button>
    </Flex>
  );
}

export default ParentSettingsParentalControl;
