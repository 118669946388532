import { CommonSubscription, CommonSuccess } from '@codidae/common-types';
import HTTPClient from 'utils/HTTPClient';

export async function getMySubscription(): Promise<CommonSubscription | null> {
  return await HTTPClient.get('/subscription').then((data) => data.data);
}

export async function updatePremiumChildren({ studentIds }: { studentIds: number[] }): Promise<CommonSuccess> {
  return await HTTPClient.patch('/subscription/children', {
    studentIds,
  }).then((data) => data.data);
}

export async function handleSubscriptionInterest(): Promise<void> {
  return await HTTPClient.post('/subscription/interested').then((data) => data.data);
}
