import { Haptics, ImpactStyle } from '@capacitor/haptics';
import offline from 'assets/icons/offline.svg';
import { FaSpinner } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { UIColor } from 'utils/colors';

import { useOnlineContext } from 'contexts/online-context';
import { CSSProperties, useMemo } from 'react';
import styles from './button.module.scss';

export type ButtonColor = 'primary' | 'grey' | 'black' | 'dark' | UIColor;

/* eslint-disable-next-line */
export interface ButtonProps {
  children: React.ReactNode;
  outline?: boolean;
  text?: boolean;
  href?: string | number;
  onClick?: () => void;
  color?: ButtonColor;
  disabled?: boolean;
  loading?: boolean;
  requiresConnection?: boolean;
  className?: string;
  delay?: number;
  style?: CSSProperties;
}

export function Button({
  children,
  outline,
  text,
  href,
  onClick,
  color,
  disabled,
  loading,
  requiresConnection,
  className,
  delay,
  style,
}: ButtonProps) {
  const navigate = useNavigate();
  const { isOnline } = useOnlineContext();
  const blockedOffline = useMemo(() => requiresConnection && !isOnline, [requiresConnection, isOnline]);

  const onPress = () => {
    if (blockedOffline && (onClick || href)) {
      window.alert('Veuillez vous connecter à internet pour effectuer cette action.');
      return;
    }

    if (onClick) {
      onClick();
    } else if (href) {
      if (href === -1) {
        navigate(-1);
      } else if (typeof href === 'string') {
        navigate(href);
      }
    }
  };

  return (
    <button
      type="button"
      className={`${styles.button} ${outline ? styles.outline : ''} ${text ? styles.text : ''}${color ? ` ${styles[color]}` : ''} ${
        blockedOffline ? styles.blocked : ''
      }} ${className ?? ''} ${delay ? styles.delay : ''}`}
      onClick={onPress}
      onTouchStart={() => {
        Haptics.impact({ style: ImpactStyle.Light });
      }}
      onTouchEnd={() => {
        Haptics.impact({ style: ImpactStyle.Light });
      }}
      style={{
        ...(delay
          ? {
              animationDuration: `${delay}ms`,
            }
          : {}),
        ...style,
      }}
      disabled={disabled || loading}
    >
      {loading && <FaSpinner className={styles.spinner} />}
      {children}
      {blockedOffline && (
        <div className={styles.offlineOverlay}>
          <img src={offline} alt="Hors ligne" />
        </div>
      )}
    </button>
  );
}

export default Button;
