import { Preferences } from '@capacitor/preferences';
import { CommonQuestion } from '@codidae/common-types';
import { useMutation } from '@tanstack/react-query';
import { useUserContext } from 'contexts/user-context';
import { createContext, useContext, useEffect, useState } from 'react';
import { createOfflineSessionFromChildren } from 'services/session';

interface OfflineQuizContextProps {
  setAsUsed: VoidFunction;
  question?: CommonQuestion;
  initialQuestionCount?: number;
}

const OfflineQuizContext = createContext<OfflineQuizContextProps>({
  setAsUsed() {},
});

interface OfflineQuizProviderProps {
  children: React.ReactNode;
}

export default function OfflineQuizProvider({ children }: OfflineQuizProviderProps) {
  const [quizQuestions, setQuizQuestions] = useState<CommonQuestion[]>([]);
  const [initialLen, setInitialLen] = useState<number>();
  const createOfflineQuizMutation = useMutation({
    mutationFn: createOfflineSessionFromChildren,
    onSuccess(data) {
      setQuizQuestions(data);
      Preferences.set({ key: 'offline-quiz', value: JSON.stringify(data) });
      setInitialLen(data.length);
    },
  });
  const { child } = useUserContext();
  const updateQuestions = async () => {
    try {
      const questions = JSON.parse((await Preferences.get({ key: 'offline-quiz' })).value ?? '[]') as CommonQuestion[];
      if (questions.length) {
        setQuizQuestions(questions);
        setInitialLen(questions.length);
      }
      if (
        questions.length !== 10 &&
        (!child.lastOfflineSession || child.lastOfflineSession.getTime() + 3600000 < Date.now())
      ) {
        createOfflineQuizMutation.mutate(child.id);
      }
    } catch {
      /* do nothing */
    }
  };

  useEffect(() => {
    if (!child || quizQuestions.length === 10) return;
    const interval = setInterval(() => {
      updateQuestions();
    }, 600000); // try every 10 minutes

    // eslint-disable-next-line consistent-return
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [child, quizQuestions]);

  useEffect(() => {
    if (!child) return;
    updateQuestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [child]);

  return (
    <OfflineQuizContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        setAsUsed() {
          if (!quizQuestions.length) return;
          const newQuestions = quizQuestions.slice(1);
          setQuizQuestions(newQuestions);
          Preferences.set({ key: 'offline-quiz', value: JSON.stringify(newQuestions) });
        },
        question: quizQuestions.at(0),
        initialQuestionCount: initialLen,
      }}
    >
      {children}
    </OfflineQuizContext.Provider>
  );
}

export function useOfflineQuizContext() {
  return useContext(OfflineQuizContext);
}

export { OfflineQuizProvider };
