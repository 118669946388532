import { registerPlugin } from '@capacitor/core';
import { Application } from 'views/blocker/android';

const BlockerPlugin = registerPlugin('BlockerPlugin', {
  web: () => import('plugins/blocker/web').then((m) => new m.BlockerPlugin()),
}) as {
  pauseMonitoring(): Promise<void>;
  addMinutesToMonitoring({ minutes }: { minutes: number }): Promise<void>;
  requestTracking(): Promise<void>;
  getLockTimeLeft(): Promise<{ timeLeft: number }>;

  getApplicationList(): Promise<{ applications: Application[] }>;
  checkUsageStatsPermission(): Promise<{ isGranted: boolean }>;
  checkLocationPermission(): Promise<{ isGranted: boolean }>;
  requestUsageStatsPermission(): Promise<void>;
  checkDrawOverlayPermission(): Promise<{ isGranted: boolean }>;
  requestDrawOverlayPermission(): Promise<void>;
  checkAutoStartPermission(): Promise<{ isGranted: boolean }>;
  requestAutoStartPermission(): Promise<void>;

  askScreenTimePermission: () => Promise<void>;
  isScreenTimeEnabled: () => Promise<{ enabled: boolean }>;
  allAppsSelector: () => Promise<void>;
  entertainmentAppsSelector: () => Promise<void>;

  showActivityReport: () => Promise<void>;
  hideActivityReport: () => Promise<void>;

  turnOnBlocker: () => Promise<void>;

  metaInAppEvent({ eventName }: { eventName: string }): Promise<void>;

  getLockTimeLeft(): Promise<{ timeLeft: number }>;

  checkIsRedirected(): Promise<{ redirected: boolean }>;

  getDailyUsage(): Promise<{ usage: number }>;

  propagateParentalControlSettings({
    maxDailyTime,
    isLogoutRangeEnabled,
    logoutRangeMorning,
    logoutRangeEvening,
    contentFilterLevel,
    preventUninstall,
    initialDailyTime,
    awardedTime,
  }: {
    maxDailyTime: number;
    isLogoutRangeEnabled: boolean;
    logoutRangeMorning: number;
    logoutRangeEvening: number;
    contentFilterLevel: number;
    preventUninstall: boolean;
    initialDailyTime: number;
    awardedTime: number;
  }): Promise<void>;

  askForLocationPermission(): Promise<void>;
};

export interface IBlockerPlugin {
  pauseMonitoring(): Promise<void>;
  addMinutesToMonitoring({ minutes }: { minutes: number }): Promise<void>;
  requestTracking(): Promise<void>;
  getLockTimeLeft(): Promise<{ timeLeft: number }>;

  getApplicationList(): Promise<{ applications: Application[] }>;
  checkUsageStatsPermission(): Promise<{ isGranted: boolean }>;
  checkLocationPermission(): Promise<{ isGranted: boolean }>;
  requestUsageStatsPermission(): Promise<void>;
  checkDrawOverlayPermission(): Promise<{ isGranted: boolean }>;
  requestDrawOverlayPermission(): Promise<void>;
  checkAutoStartPermission(): Promise<{ isGranted: boolean }>;
  requestAutoStartPermission(): Promise<void>;

  askScreenTimePermission: () => Promise<void>;
  isScreenTimeEnabled: () => Promise<{ enabled: boolean }>;
  allAppsSelector: () => Promise<void>;
  entertainmentAppsSelector: () => Promise<void>;

  showActivityReport: () => Promise<void>;
  hideActivityReport: () => Promise<void>;

  turnOnBlocker: () => Promise<void>;

  metaInAppEvent({ eventName }: { eventName: string }): Promise<void>;

  getLockTimeLeft(): Promise<{ timeLeft: number }>;

  checkIsRedirected(): Promise<{ redirected: boolean }>;

  getDailyUsage(): Promise<{ usage: number }>;

  propagateParentalControlSettings({
    maxDailyTime,
    isLogoutRangeEnabled,
    logoutRangeMorning,
    logoutRangeEvening,
    contentFilterLevel,
    preventUninstall,
    initialDailyTime,
    awardedTime,
  }: {
    maxDailyTime: number;
    isLogoutRangeEnabled: boolean;
    logoutRangeMorning: number;
    logoutRangeEvening: number;
    contentFilterLevel: number;
    preventUninstall: boolean;
    initialDailyTime: number;
    awardedTime: number;
  }): Promise<void>;

  askForLocationPermission(): Promise<void>;
}

export default BlockerPlugin;
