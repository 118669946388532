import { Preferences } from '@capacitor/preferences';
import { useQueries } from '@tanstack/react-query';
import ChildrenSelection from 'components/children-selection/children-selection';
import LoadingScreen from 'components/loading-screen/loading-screen';
import NoChildren from 'components/no-children/no-children';
import Page from 'components/page/page';
import { useEffect, useState } from 'react';
import { getProfile } from 'services/profile';
import { getMySubscription } from 'services/subscription';
import ChildrenDashboard from './children/dashboard';

/* eslint-disable-next-line */
export interface DashboardProps {}

export function Dashboard(props: DashboardProps) {
  const [childMode, setChildMode] = useState(null);
  const [childModeSetted, setChildModeSetted] = useState(false);
  const [
    { data: dataProfile, isLoading: isLoadingProfile },
    { data: dataSubscription, isLoading: isLoadingSubscription },
  ] = useQueries({
    queries: [
      { queryKey: ['getProfile'], queryFn: getProfile },
      { queryKey: ['getMySubscription'], queryFn: getMySubscription, staleTime: 3600000 },
    ],
  });

  useEffect(() => {
    Preferences.get({ key: 'child_mode' }).then(({ value }) => {
      if (value && value !== 'null') setChildMode(value);
      setChildModeSetted(true);
    });
  }, []);
  if (isLoadingProfile || !childModeSetted || isLoadingSubscription) {
    return <LoadingScreen />;
  }
  if (childMode) {
    return <ChildrenDashboard childrenId={childMode} />;
  }
  if (dataProfile?.childrens && dataProfile.childrens.length === 0) {
    return <NoChildren />;
  }
  return (
    <Page>
      <ChildrenSelection childrens={dataProfile?.childrens} maxChildren={dataSubscription?.childrenCount || 4} />
    </Page>
  );
}

export default Dashboard;
