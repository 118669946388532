import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { CommonStudent } from '@codidae/common-types';
import { useQuery } from '@tanstack/react-query';
import Button from 'components/button/button';
import Card from 'components/card/card';
import TimeRequestButton from 'components/children-home/time-request-button';
import EnterPinView from 'components/enter-pin-view/enter-pin-view';
import Flex from 'components/flex/flex';
import StartSessionButton from 'components/start-session-button/start-session-button';
import { useUserContext } from 'contexts/user-context';
import { useEffect, useMemo, useState } from 'react';
import { FaExclamationTriangle, FaEye } from 'react-icons/fa';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getTimeRequests } from 'services/time-request';
import BlockerPlugin from 'utils/BlockerPlugin';
import styles from './children-home.module.scss';

/* eslint-disable-next-line */
export interface ChildrenHomeProps {
  children: CommonStudent;
  blockerConfigured?: boolean;
}

export function ChildrenHome({ children, blockerConfigured }: ChildrenHomeProps) {
  const [timeLeft, setTimeLeft] = useState(0);
  const [dailyUsage, setDailyUsage] = useState<number | undefined>(undefined);
  const { data: timeRequests, refetch } = useQuery({
    queryKey: ['getTimeRequests'],
    queryFn: () => getTimeRequests(),
    refetchInterval: 60000,
    staleTime: 60000,
  });
  const [parentWarning, setParentWarning] = useState(false);
  const [searchParams] = useSearchParams();
  useEffect(() => {
    if (searchParams.get('from_parent') === 'true') {
      setParentWarning(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { parentalControlSettings } = useUserContext();

  const maxDailyTime = useMemo(
    () =>
      (parentalControlSettings?.maxDailyTime ?? 0) +
      (timeRequests?.reduce((acc, tr) => acc + (tr.awardedMinutes ?? 0), 0) ?? 0),
    [parentalControlSettings, timeRequests],
  );

  useEffect(() => {
    BlockerPlugin.getLockTimeLeft().then((res) => {
      setTimeLeft(res.timeLeft);
    });

    const listener = App.addListener('resume', () => {
      BlockerPlugin.getLockTimeLeft().then((res) => {
        setTimeLeft(res.timeLeft);
      });
      refetch();
    });

    BlockerPlugin.getDailyUsage().then((res) => {
      setDailyUsage(res.usage);
    });

    return () => {
      listener.remove();
    };
  }, [refetch]);

  const maxDailyTimeReached = useMemo(
    () => dailyUsage >= maxDailyTime && maxDailyTime !== 0,
    [dailyUsage, maxDailyTime],
  );

  const [accessBlockerPopup, setAccessBlockerPopup] = useState(false);
  const navigate = useNavigate();

  if (accessBlockerPopup) {
    return (
      <EnterPinView
        onValidate={() => {
          if (Capacitor.getPlatform() === 'ios') {
            navigate(`/blocker/ios/${children.id}`);
          } else {
            navigate(`/blocker/android/${children.id}`);
          }
        }}
        onCancel={() => {
          setAccessBlockerPopup(false);
        }}
      />
    );
  }

  return (
    <Flex
      direction="column"
      justify="start"
      align="center"
      width="100%"
      height="100%"
      pad={20}
      style={{ paddingTop: 110, overflowY: 'scroll', paddingBottom: 100 }}
      gap={20}
    >
      {blockerConfigured === false && !parentWarning && (
        <Card className={`${styles.card} ${styles.warningCard}`}>
          <Flex width="100%" align="center" gap={15} style={{ marginBottom: 10 }}>
            <FaExclamationTriangle className={styles.exclamationTriangle} />
            <div className={styles.warningText}>{`Le bloqueur n'est pas configuré !`}</div>
          </Flex>
          <Button color="black" onClick={() => setAccessBlockerPopup(true)}>
            CONFIGURER MAINTENANT
          </Button>
        </Card>
      )}

      {parentWarning && (
        <Card className={`${styles.card} ${styles.parentWarningCard}`}>
          <Flex width="100%" align="center" gap={15} style={{ marginBottom: 10 }}>
            <FaEye className={styles.icon} />
            <div className={styles.warningText}>Vous visitez le mode enfant</div>
          </Flex>
          <Button color="purple" onClick={() => navigate(`/children/${children.id}`, { replace: true })}>
            REVENIR AU MODE PARENT
          </Button>
          <Flex gap={10} direction="column" width="100%" style={{ marginTop: 20 }}>
            <div className={styles.warningText}>{`C'est le téléphone de votre enfant ?`}</div>
            <Button color="black" onClick={() => setAccessBlockerPopup(true)}>
              CONFIGURER LE BLOQUEUR
            </Button>
          </Flex>
        </Card>
      )}

      <Card className={styles.card}>
        <div className={styles.timeLeft}>
          {timeLeft} <span style={{ fontSize: 15 }}>/ {children?.lockTime}</span>
        </div>
        <div className={styles.timeLeftLabel}>minutes restantes</div>
      </Card>
      {dailyUsage !== undefined && maxDailyTime !== 0 && (
        <Card style={{ width: '100%', padding: 20, flexShrink: 0 }}>
          <div className={styles.maxDailyLabel}>Temps maximum aujourd&apos;hui</div>
          <div className={styles.maxDailyProgress}>
            <div
              className={styles.maxDailyProgressFill}
              style={{
                width: `${Math.round((dailyUsage / maxDailyTime) * 100)}%`,
              }}
            />
            <div className={styles.maxDailyProgressLabel}>
              {dailyUsage} / {maxDailyTime} minutes
            </div>
          </div>
        </Card>
      )}
      {maxDailyTimeReached && <TimeRequestButton childId={children.id} />}
      <StartSessionButton
        childrenId={children?.id.toString()}
        disabled={maxDailyTimeReached || timeLeft >= children?.lockTime}
      />
    </Flex>
  );
}

export default ChildrenHome;
