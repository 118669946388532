import AltusTalk from 'components/altus-talk/altus-talk';
import BackHeader from 'components/back-header/back-header';
import Button from 'components/button/button';
import Flex from 'components/flex/flex';
import InputText from 'components/input-text/input-text';
import Page from 'components/page/page';
import Select from 'components/select/select';
import config from 'config';
import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getClassLevels } from 'services/chapters';
import { createChildren } from 'services/childrens';
import BlockerPlugin from 'utils/BlockerPlugin';

/* eslint-disable-next-line */
export interface CreateChildrenProps {}

export function CreateChildren(props: CreateChildrenProps) {
  const navigate = useNavigate();
  const { data: cycles } = useQuery({
    queryKey: ['cycles'],
    queryFn: getClassLevels,
    staleTime: 24 * 3600000 * 21,
    gcTime: 24 * 3600000 * 21,
  });
  const queryClient = useQueryClient();
  const createChildrenMutation = useMutation({
    mutationFn: createChildren,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ['getProfile'],
      });
      BlockerPlugin.metaInAppEvent({ eventName: 'child_created' });
      navigate('/dashboard');
    },
  });
  const [firstname, setFirstname] = useState<string>('');
  const [cycle, setCycle] = useState<number>(null);

  const submit = async () => {
    createChildrenMutation.mutate({
      firstname,
      lastname: '',
      cycleId: cycle,
      lockTime: 20,
      // lockTime: Number(lockTime.current),
    });
  };

  return (
    <Page>
      <Flex
        direction="column"
        justify="space-between"
        align="center"
        width="100%"
        height="100%"
        style={{ paddingTop: 100 }}
      >
        <BackHeader href="/dashboard" title="Ajouter un enfant" />
        <Flex direction="column" gap={20} pad={20} width="100%">
          <AltusTalk>Donnez moi quelques informations sur votre enfant pour continuer !</AltusTalk>
          <InputText
            placeholder="Son prénom"
            onChange={(v) => {
              if (v.length < 15) setFirstname(v);
            }}
            value={firstname}
          />
          {/* <Select
            onChange={(v) => {
              lockTime.current = v;
            }}
            options={[
              { label: '10 minutes', value: '10' },
              { label: '12 minutes', value: '12' },
              { label: '14 minutes', value: '14' },
              { label: '16 minutes', value: '16' },
              { label: '18 minutes', value: '18' },
              { label: '20 minutes', value: '20' },
              { label: '22 minutes', value: '22' },
              { label: '24 minutes', value: '24' },
              { label: '26 minutes', value: '26' },
              { label: '28 minutes', value: '28' },
              { label: '30 minutes', value: '30' },
              { label: '32 minutes', value: '35' },
              { label: '34 minutes', value: '34' },
              { label: '36 minutes', value: '36' },
              { label: '38 minutes', value: '38' },
              { label: '40 minutes', value: '40' },
            ]}
            placeholder="Temps maximum débloquable"
          /> */}
          <Select
            onChange={(v) => setCycle(Number(v))}
            options={
              cycles
                ? cycles
                    .filter((c) => config.accepted_cycles.includes(c.id))
                    .map((c) => ({
                      label: c.name,
                      value: String(c.id),
                    }))
                : []
            }
            placeholder="Son niveau scolaire"
          />
        </Flex>
        <Flex direction="column" gap={20} pad={20} width="100%">
          <Button color="primary" onClick={submit} disabled={!firstname || !cycle || createChildrenMutation.isPending}>
            C&apos;EST PARTI !
          </Button>
        </Flex>
      </Flex>
    </Page>
  );
}

export default CreateChildren;
