import InfoTooltip from 'components/info-tooltip/info-tooltip';
import { useUserContext } from 'contexts/user-context';
import { Line } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import { getChartLabel, getDaysFromTimePeriod } from 'utils/date';
import styles from './new-exercises-chart.module.scss';

/* eslint-disable-next-line */
export interface NewExercisesChartProps {
  data: { date: Date; count: number }[];
  timeRangeName: string;
  timePeriod: { start: Date; end: Date };
  childId: number;
}

export function NewExercisesChart({ data, timePeriod, timeRangeName, childId }: NewExercisesChartProps) {
  const navigate = useNavigate();
  const groupBy = timePeriod.end.getTime() - timePeriod.start.getTime() < 1000 * 60 * 60 * 24 * 33 ? 1 : 30;
  const { getSubscriptionStatus } = useUserContext();

  return (
    <>
      <div className={styles.inputWrapper}>
        <span className={styles.label}>
          <div style={{ fontSize: 14, fontWeight: 500 }}>Nouvelles questions {timeRangeName.toLowerCase()}</div>
          <InfoTooltip>
            <div className={styles.textToolTip}>
              Nombre de nouvelle questions vues au cours d&apos;un quiz par jour.
              <br />
              <br />
              En recontrant de nouvelles questions, votre enfant progressera plus rapidement.
            </div>
          </InfoTooltip>
        </span>
      </div>
      <Line
        data={{
          labels: getDaysFromTimePeriod(timePeriod)
            .map((date) => getChartLabel(timePeriod, date))
            .filter((_, index) => index % groupBy === 0),
          datasets: [
            {
              label: 'Nouvelles questions',
              data: getDaysFromTimePeriod(timePeriod)
                .filter((_, index) => index % groupBy === 0)
                .map((date) => {
                  if (groupBy === 1) {
                    const result = data.find(
                      (res) =>
                        new Date(res.date).getDate() === date.getDate() &&
                        new Date(res.date).getMonth() === date.getMonth() &&
                        new Date(res.date).getFullYear() === date.getFullYear(),
                    );
                    return (result?.count || 0) * 100;
                  }
                  const from = new Date(date);
                  from.setDate(from.getDate() - groupBy);
                  const to = new Date(date);
                  const result = data?.filter((res) => new Date(res.date) >= from && new Date(res.date) <= to);

                  const sum = result?.reduce((acc, curr) => acc + curr.count, 0) || 0;
                  return (sum / groupBy) * 100;
                }),
              backgroundColor: '#55a4f7',
              pointRadius: 0,
              borderColor: '#55a4f7',
              yAxisID: 'y',
            },
            {
              label: 'Avec Altus Plus',
              data: getDaysFromTimePeriod(timePeriod)
                .filter((_, index) => index % groupBy === 0)
                .map((date, index) => {
                  const max = 80;
                  return Math.round(max - Math.random() * 8 + 4);
                }),
              backgroundColor: '#7554f7',
              pointRadius: 0,
              borderColor: '#7554f7',
              borderDash: [5, 5],
              hidden: getSubscriptionStatus(childId) === 'premium',
            },
          ],
        }}
        options={{
          elements: {
            line: {
              tension: 0.2,
            },
          },
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                callback: (value) => `${value}%`,
              },
            },
          },
          plugins: {
            title: {
              display: false,
            },
            legend: {
              display: getSubscriptionStatus(childId) !== 'premium',
              position: 'bottom',
              onClick: () => {
                navigate('/subscribe');
              },
            },
            tooltip: {
              callbacks: {
                title: (tooltipItem) => ``,
              },
            },
          },
        }}
      />
    </>
  );
}

export default NewExercisesChart;
