import { useEffect } from 'react';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { useNavigate } from 'react-router-dom';

/* eslint-disable-next-line */
export interface AppUrlListenerProps {}

export function AppUrlListener(props: AppUrlListenerProps) {
  const navigate = useNavigate();
  useEffect(() => {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      if (event.url.includes('/auth/token/')) {
        const token = event.url.split('/auth/token/')[1].split('?')[0];
        const email = event.url.split('email=')[1];
        navigate(`/auth/token/${token}?email=${email}`);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}

export default AppUrlListener;
