import { CommonChapter } from '@codidae/common-types';
import closeIcon from 'assets/icons/close.svg';
import selectedIcon from 'assets/icons/selected.svg';
import { useEffect, useState } from 'react';
import { getColorFromFieldId } from 'utils/colors';
import styles from './chapter-card.module.scss';

/* eslint-disable-next-line */
export interface ChapterCardProps {
  chapter: CommonChapter;
  childrenId: number;
  selected?: number[];
  onDelete?: () => void;
  onChange?: (selected: number[]) => void;
  onClose?: () => void;
  limitTimestamp?: number;
  isSelected?: boolean;
  setSelected?: (selected: boolean) => void;
  currentIndex?: number;
  refetch?: () => void;
}

export function ChapterCard({
  childrenId,
  chapter,
  currentIndex,
  isSelected,
  limitTimestamp,
  onChange,
  onClose,
  onDelete,
  refetch,
  selected,
  setSelected,
}: ChapterCardProps) {
  const [selectedSubChapters, setSelectedSubChapters] = useState<number[]>(
    selected || [],
  );
  useEffect(() => {
    if (selected?.length) setSelectedSubChapters(selected);
  }, [selected]);

  useEffect(() => {
    if (selected !== selectedSubChapters && onChange)
      onChange(selectedSubChapters);
  }, [selectedSubChapters, onChange, selected]);

  const addFocusChapter = () => {
    if (setSelected) setSelected(!isSelected);
  };

  return (
    <div
      className={styles.card}
      style={{
        backgroundColor: chapter.field
          ? getColorFromFieldId(chapter.field.id)
          : undefined,
        boxShadow: chapter.field
          ? `0px 4px 0px  0px ${getColorFromFieldId(chapter.field.id)}66`
          : undefined,
      }}
    >
      {onDelete && (
        <button
          className={styles.deleteButton}
          onClick={onDelete}
          type="button"
        >
          <img src={closeIcon} alt="Close Icon" />
        </button>
      )}
      <div
        className={styles.header}
        onClick={() => {
          addFocusChapter();
        }}
      >
        <div>
          <div>{chapter?.name}</div>
          {limitTimestamp !== undefined && (
            <div className={styles.limit}>
              {chapter?.field ? `${chapter?.field?.name} - ` : ''}
              {chapter.cycles?.length !== 0 && (
                <>{chapter.cycles?.map((cycle) => cycle.name).join(', ')} </>
              )}
            </div>
          )}
        </div>
        {!onDelete && (
          <div
            className={`${styles.select} ${isSelected ? styles.selected : ''}`}
            onClick={() => {
              addFocusChapter();
            }}
          >
            <img src={selectedIcon} alt="Selected" />
          </div>
        )}
      </div>
    </div>
  );
}

export default ChapterCard;
