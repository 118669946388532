import { CommonSessionResult } from '@codidae/common-types';
import styles from './stats-session-result.module.scss';

/* eslint-disable-next-line */
export interface StatsSessionResultProps {
  result: CommonSessionResult;
}

export function StatsSessionResult({ result }: StatsSessionResultProps) {
  return (
    <div className={styles.result}>
      <div className={styles.left}>
        Le {new Date(result.createdAt).toLocaleDateString()} à{' '}
        {new Date(result.createdAt).toLocaleTimeString()}
      </div>
      <div className={styles.right}>
        {/* {Math.round((result.correctAnswered / result.totalAnswered) * 100)} % */}
        {result.correctAnswered} / {result.totalAnswered}
      </div>
    </div>
  );
}

export default StatsSessionResult;
