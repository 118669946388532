import { MutableRefObject, useEffect, useState } from 'react';
import { BiChevronDown } from 'react-icons/bi';
import styles from './faq-question.module.scss';

/* eslint-disable-next-line */
export interface FaqQuestionProps {
  question: {
    question: string;
    answer: string;
  };
  defaultOpen?: boolean;
  ref?: MutableRefObject<HTMLDivElement>;
  className?: string;
}

export function FaqQuestion({ question, defaultOpen, ref, className }: FaqQuestionProps) {
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (defaultOpen) {
      setOpen(true);
    }
  }, [defaultOpen]);

  return (
    <div className={`${styles.faqQuestion} ${className ?? ''}`} ref={ref}>
      <div className={styles.question} onClick={() => setOpen(!open)}>
        <div>{question.question}</div>
        <BiChevronDown size={25} transform={open ? 'rotate(180)' : ''} className={styles.icon} />
      </div>
      <div
        className={styles.answer + (open ? ` ${styles.open}` : '')}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: question.answer }}
      />
    </div>
  );
}

export default FaqQuestion;
