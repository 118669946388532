import { Preferences } from '@capacitor/preferences';
import { CommonQuestion, CommonQuestionResult } from '@codidae/common-types';
import { useMutation, useQuery } from '@tanstack/react-query';
import Button from 'components/button/button';
import CorrectAnswer from 'components/correct-answer/correct-answer';
import Dialog from 'components/dialog/dialog';
import DisplayQuestion from 'components/display-question/display-question';
import Flex from 'components/flex/flex';
import IncorrectAnswer from 'components/incorrect-answer/incorrect-answer';
import LoadingScreen from 'components/loading-screen/loading-screen';
import SessionHeader from 'components/session-header/session-header';
import SessionOver from 'components/session-over/session-over';
import config from 'config';
import { useUserContext } from 'contexts/user-context';
import useDisableBackButton from 'hooks/disable-back-button';
import { useEffect, useMemo, useState } from 'react';
import { BsFillLightningChargeFill } from 'react-icons/bs';
import { useParams } from 'react-router-dom';
import { SegmentEventType, track } from 'segment';
import { answerQuestion, getNextQuestion, getQuestionMetadata, getSession, revealAnswer } from 'services/session';

/* eslint-disable-next-line */
export interface SessionProps {}

const INIT_LIFES = 3;

export function Session(props: SessionProps) {
  useDisableBackButton();

  const { id } = useParams();
  const [currentQuestion, setCurrentQuestion] = useState<CommonQuestion>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCorrect, setIsCorrect] = useState<boolean>(false);
  const [result, setResult] = useState<CommonQuestionResult>(null);
  const [isIncorrect, setIsIncorrect] = useState<boolean>(false);
  const [sessionOver, setSessionOver] = useState<boolean>(false);
  // const [introduction, setIntroduction] = useState<boolean>(true);
  const [earnedTime, setEarnedTime] = useState<number>(0);
  const [childrenId, setChildrenId] = useState(0);
  const [lifes, setLifes] = useState<number>(INIT_LIFES);
  const [questionNb, setQuestionNb] = useState(0);
  const { parentalControlSettings, child } = useUserContext();
  const { data: session, refetch } = useQuery({
    queryKey: ['getSession', id],
    queryFn: () => getSession(id),
  });
  const [streakExtraPoints, setStreakPoints] = useState(0);
  const [currentStreak, setCurrentStreak] = useState(0);

  const earnedTimeBase = useMemo(() => parentalControlSettings?.earnedTimeBase ?? 1, [parentalControlSettings]);
  const revealAnswerMutation = useMutation({
    mutationFn: revealAnswer,
    onSuccess() {
      refetch();
      setDialog(false);
    },
  });
  const [dialog, setDialog] = useState(false);
  const { data: questionMetadata } = useQuery({
    queryKey: ['getQuestionMetadata', currentQuestion?.id],
    queryFn: () => getQuestionMetadata(currentQuestion?.id),
  });

  const nextQuestionMutation = useMutation({
    mutationFn: getNextQuestion,
    onSuccess: (data) => {
      if (data.type === 'end') {
        setSessionOver(true);
      } else {
        setSessionOver(false);
      }
      setCurrentQuestion(data);
      setIsCorrect(false);
      setIsIncorrect(false);
      setIsLoading(false);
      setQuestionNb(questionNb + 1);
    },
  });
  const answerQuestionMutation = useMutation({
    mutationFn: answerQuestion,
    onSuccess: (data) => {
      setIsLoading(false);
      setResult(data);
      track(SegmentEventType.ANSWER_QUESTION, {
        correct: data.correct,
      });
      if (data.correct) {
        setCurrentStreak(currentStreak + 1);
        const hasStreakPoint = currentStreak + 1 >= config.min_streak_count;
        const timeToEarn = earnedTimeBase + (hasStreakPoint ? 1 : 0);

        if (child && earnedTime + timeToEarn <= child.lockTime) setEarnedTime(earnedTime + earnedTimeBase);
        setIsCorrect(true);
        if (currentStreak + 1 >= config.min_streak_count) {
          setStreakPoints(streakExtraPoints + 1);
        }
      } else {
        setCurrentStreak(0);
        setIsIncorrect(true);
      }
    },
  });

  useEffect(() => {
    Preferences.get({ key: 'child_mode' }).then(({ value }) => {
      if (value && value !== 'null') setChildrenId(parseInt(value, 10));
    });
  }, []);

  useEffect(() => {
    nextQuestionMutation.mutate({ sessionId: id });
    setQuestionNb(0);
    setCurrentQuestion(null);
    setIsCorrect(false);
    setIsIncorrect(false);
    setSessionOver(false);
    setResult(null);
    setIsLoading(true);
    setStreakPoints(0);
    setCurrentStreak(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const nextQuestion = () => {
    setIsLoading(true);
    nextQuestionMutation.mutate({ sessionId: id });
    revealAnswerMutation.reset();
  };

  const loadingLock = isLoading || nextQuestionMutation.isPending || answerQuestionMutation.isPending;

  if (sessionOver) return <SessionOver sessionId={id} earnedTime={earnedTime + streakExtraPoints} />;
  if (!currentQuestion) return <LoadingScreen />;
  if (child && lifes <= 0) {
    return <SessionOver sessionId={id} earnedTime={earnedTime + streakExtraPoints} outOfLifes />;
  }

  return (
    <>
      <Dialog open={dialog} onClose={() => setDialog(false)}>
        <div
          style={{
            fontSize: 24,
            fontWeight: 600,
            marginBottom: 12,
          }}
        >
          <BsFillLightningChargeFill style={{ marginRight: 8, height: 19 }} />
          Voir la réponse ?
        </div>
        <div>Ce bonus sera réinitialisé pour votre prochain quiz</div>
        <div style={{ height: 20 }} />

        <Flex gap={12} direction="column" style={{ marginTop: 12 }}>
          <Button outline onClick={() => setDialog(false)}>
            ANNULER
          </Button>
          <Button
            onClick={() => {
              track(SegmentEventType.REVEAL_ANSWER, {
                currentStreak,
                earnedTime,
                streakExtraPoints,
              });
              revealAnswerMutation.mutate({
                sessionId: id,
              });
            }}
            loading={revealAnswerMutation.isPending}
          >
            UTILISER
          </Button>
        </Flex>
      </Dialog>

      <SessionHeader
        questionNumber={questionNb}
        totalQuestions={session?.exercices?.length ?? questionNb}
        earnedTime={earnedTime + streakExtraPoints}
        lifes={lifes - (isIncorrect ? 1 : 0)}
      />
      <Flex direction="column" height="100%" width="100%">
        <DisplayQuestion
          question={currentQuestion}
          loading={loadingLock}
          onAnswer={(answer) => {
            if (answer === null) nextQuestionMutation.mutate({ sessionId: id });
            else answerQuestionMutation.mutate({ sessionId: id, answer });
          }}
          metadata={questionMetadata}
          sessionAvailableAnswers={session.availableFreeAnswers}
          revealAnswer={() => setDialog(true)}
          answerData={result?.question.data ?? revealAnswerMutation.data?.data}
        />
        {isCorrect && (
          <CorrectAnswer
            result={result}
            next={nextQuestion}
            earnedTime={earnedTime + streakExtraPoints}
            loading={loadingLock}
            streak={currentStreak}
          />
        )}
        {isIncorrect && (
          <IncorrectAnswer
            result={result}
            next={() => {
              setLifes(lifes - 1);
              nextQuestion();
            }}
            loading={loadingLock}
            earnedTime={childrenId ? earnedTime : 0}
          />
        )}
      </Flex>
    </>
  );
}

export default Session;
