export const nowInLogoutRange = (start: number, end: number) => {
  const now = new Date();
  const nowMinutes = now.getHours() * 60 + now.getMinutes();
  return (
    (nowMinutes >= end && end > start) ||
    (nowMinutes <= start && end > start) ||
    (nowMinutes >= end && nowMinutes <= start && end < start)
  );
};

export const timeStringToNumber = (time: string) => {
  const [hour, minute] = time.split(':');
  return Number(hour) * 60 + Number(minute);
};

export const numberToTimeString = (number: number) => {
  const hour = Math.floor(number / 60);
  const minute = number % 60;

  return `${hour < 10 ? `0${hour}` : hour}:${minute < 10 ? `0${minute}` : minute}`;
};

export const getLastDays = (days: number) => {
  const dates = [];
  for (let i = 0; i < days; i += 1) {
    const date = new Date();
    date.setDate(date.getDate() - i);
    dates.push(date);
  }
  return dates;
};

export const getDayName = (date: Date) => {
  const days = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
  return days[date.getDay()];
};

export const getCurrentTimeRangeName = (
  timeRange: 'week' | 'month' | 'year',
  timePeriod: { start: Date; end: Date } | null,
) => {
  switch (timeRange) {
    case 'week': {
      if (timePeriod === null) return 'Cette semaine';
      return `Du ${timePeriod.start.toLocaleString('fr-FR', { day: 'numeric', month: 'short' })} au ${timePeriod.end.toLocaleString('fr-FR', { day: 'numeric', month: 'short' })}`;
    }
    case 'month':
      if (timePeriod === null) return 'Ce mois-ci';
      return `En ${timePeriod.start.toLocaleString('fr-FR', { month: 'long' })} - ${timePeriod.end.toLocaleString('fr-FR', { month: 'long' })}`;
    case 'year':
      if (timePeriod === null) return 'Cette année';
      return `En ${timePeriod.start.getFullYear()} - ${timePeriod.end.getFullYear()}`;
    default:
      return '';
  }
};

export const defaultTimePeriod = {
  start: new Date(new Date().setDate(new Date().getDate() - 6)),
  end: new Date(),
};

export const getDaysFromTimePeriod = (timePeriod: { start: Date; end: Date }) => {
  const days = [];
  for (let date = new Date(timePeriod.start); date <= timePeriod.end; date.setDate(date.getDate() + 1)) {
    days.push(new Date(date));
  }

  return days;
};

export const getChartLabel = (timePeriod: { start: Date; end: Date }, date: Date) => {
  if (timePeriod.end.getTime() - timePeriod.start.getTime() < 7 * 24 * 60 * 60 * 1000) {
    return getDayName(date).substring(0, 3);
  }
  if (timePeriod.end.getTime() - timePeriod.start.getTime() > 31 * 24 * 60 * 60 * 1000) {
    return date.toLocaleString('fr-FR', { month: 'short' });
  }
  return date.toLocaleString('fr-FR', { day: 'numeric', month: 'short' });
};

export const getMidnight = (date: Date) => {
  const midnight = new Date(date);
  midnight.setHours(0, 0, 0, 0);
  return midnight;
};
