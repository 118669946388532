import {
  BiCheckbox,
  BiSolidCheckboxChecked,
  BiSolidCheckboxMinus,
} from 'react-icons/bi';
import styles from './checkbox.module.scss';

/* eslint-disable-next-line */
export interface CheckboxProps {
  checked?: boolean;
  intermidiate?: boolean;
  onChange: (isChecked: boolean) => void;
  className?: string;
}

const GetIcon = ({
  checked,
  intermidiate,
}: {
  checked?: boolean;
  intermidiate?: boolean;
}) => {
  if (checked) {
    return <BiSolidCheckboxChecked className={styles.checkbox} />;
  }
  if (intermidiate) {
    return <BiSolidCheckboxMinus className={styles.checkbox} />;
  }
  return <BiCheckbox className={styles.checkboxEmpty} />;
};

export function Checkbox({
  checked,
  intermidiate,
  onChange,
  className,
}: CheckboxProps) {
  return (
    <button
      className={`${styles.button} ${className ?? ''}`}
      onClick={() => onChange(!checked)}
      type="button"
    >
      <GetIcon checked={checked} intermidiate={intermidiate} />
    </button>
  );
}

export default Checkbox;
