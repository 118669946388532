import { Capacitor } from '@capacitor/core';
import { CapacitorUpdater } from '@capgo/capacitor-updater';
import AltusTalk from 'components/altus-talk/altus-talk';
import AndroidRedirectionPopup from 'components/android-redirection-popup/android-redirection-popup';
import AppUrlListener from 'components/app-url-listener/app-url-listener';
import Dialog from 'components/dialog/dialog';
import Flex from 'components/flex/flex';
import Loader from 'components/loader/loader';
import { useUserContext } from 'contexts/user-context';
import { Transition, Variants, motion } from 'framer-motion';
import { useStatusBarHeight } from 'hooks/use-status-bar-height';
import { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import { page } from 'segment';
import styles from './page.module.scss';

/* eslint-disable-next-line */
export interface PageProps {
  children: React.ReactNode;
  transition?: Transition;
  variants?: Variants;
  parentMode?: boolean;
  className?: string;
}

export function Page({ children, parentMode, transition, variants, className }: PageProps) {
  const statusBarHeight = useStatusBarHeight();
  const location = useLocation();
  const { updateChildId } = useUserContext();
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    page(location.pathname);

    // childId changes with URL, and userContext cannot use useLocation
    updateChildId();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    const listener1 = CapacitorUpdater.addListener('download', () => setUpdating(true));
    const linstener2 = CapacitorUpdater.addListener('downloadFailed', () => setUpdating(false));
    const linstener3 = CapacitorUpdater.addListener('appReloaded', () => {
      toast('Altus a été mis à jour!', { icon: '✅' });
      setUpdating(false);
    });

    return (async () => {
      await listener1.remove();
      await linstener2.remove();
      await linstener3.remove();
    }) as VoidFunction;
  }, []);

  return (
    <motion.div initial="initial" animate="in" exit="out" variants={variants} transition={transition}>
      <AppUrlListener />
      <AndroidRedirectionPopup />
      <div
        className={`${styles.page} ${parentMode ? 'parent-mode' : ''} ${className ?? ''}`}
        style={{
          paddingTop: statusBarHeight,
          paddingBottom: Capacitor.getPlatform() === 'ios' ? 20 : 0,
        }}
      >
        {children}
        <Dialog open={updating} onClose={() => setUpdating(false)}>
          <div className={styles.updateTitle}>Mise à jour</div>

          <AltusTalk>
            <Flex width="100%" gap={10} align="center">
              <Loader className={styles.loader} size={32} />
              <div>Veuillez patienter quelques secondes...</div>
            </Flex>
          </AltusTalk>
        </Dialog>
        <Toaster
          position="top-center"
          toastOptions={{
            style: {
              width: '100vw',
            },
          }}
        />
      </div>
    </motion.div>
  );
}

export default Page;
