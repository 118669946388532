import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';
import { useMutation, useQueries } from '@tanstack/react-query';
import { RateApp } from 'capacitor-rate-app';
import SubscribeBanner from 'components/altus-plus/subscribe-banner';
import BackHeader from 'components/back-header/back-header';
import ChildrenDayActivity from 'components/children-day-activity/children-day-activity';
import ChildrenDayRecap from 'components/children-day-recap/children-day-recap';
import Flex from 'components/flex/flex';
import Page from 'components/page/page';
import TabBar from 'components/tab-bar/tab-bar';
import { useUserContext } from 'contexts/user-context';
import useDisableBackButton from 'hooks/disable-back-button';
import usePaywallRedirect from 'hooks/paywall-redirect';
import { useEffect } from 'react';
import { BiHelpCircle } from 'react-icons/bi';
import { PiSquaresFourFill } from 'react-icons/pi';
import { useNavigate, useParams } from 'react-router-dom';
import { getChildren, updatePlatform } from 'services/childrens';
import { nowInLogoutRange } from 'utils/date';
import { registerPushNotifications } from 'utils/notifications';
import styles from './child-dashboard.module.scss';

/* eslint-disable-next-line */
export interface ChildrenProps {}

export function Children(props: ChildrenProps) {
  useDisableBackButton();
  const { id } = useParams();
  const updateParentPlatformMutation = useMutation({
    mutationFn: updatePlatform,
  });
  const navigate = useNavigate();

  const { userSubscriptionStatus, user, getSubscriptionStatus, parentalControlSettings } = useUserContext();

  const [{ data: children }] = useQueries({
    queries: [
      {
        queryKey: ['children', id],
        queryFn: () => getChildren(id),
      },
    ],
  });

  useEffect(() => {
    if (children?.lockTime) {
      Preferences.set({
        key: 'lock_time',
        value: children?.lockTime?.toString(),
      });
    } else {
      Preferences.get({ key: 'lock_time' }).then((res) => {
        if (res.value === null) {
          Preferences.set({
            key: 'lock_time',
            value: '15',
          });
        }
      });
    }
  }, [children]);

  useEffect(() => {
    if (children && children.phonePlatform !== Capacitor.getPlatform()) {
      Preferences.get({ key: 'phone_owner' }).then((res) => {
        if (res.value === 'child') return;
        updateParentPlatformMutation.mutate({
          platform: Capacitor.getPlatform(),
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children]);

  useEffect(() => {
    const displayRate = async () => {
      const firstLogin = await Preferences.get({ key: 'rate_asked' });
      if (firstLogin.value === 'true') return;
      if (!user?.createdAt) return;
      const createdAt = new Date(user.createdAt);
      const today = new Date();
      const diffTime = Math.abs(today.getTime() - createdAt.getTime());
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays < 2) return;
      await Preferences.set({
        key: 'rate_asked',
        value: 'true',
      });
      RateApp.requestReview();
    };
    displayRate();
  }, [user]);

  useEffect(() => {
    if (!children) return;
    registerPushNotifications(children.id);
  }, [children]);

  const childName =
    children?.firstname?.length > 12 ? `${children?.firstname?.slice(0, 12) ?? ''}...` : children?.firstname;
  const redirectPaywall = usePaywallRedirect(0.3);

  return (
    <Page parentMode>
      <Flex
        direction="column"
        width="100%"
        height="100%"
        align="center"
        justify="space-between"
        style={{ paddingTop: 68 }}
      >
        <BackHeader
          icon={<PiSquaresFourFill size={23} color="white" />}
          title={
            <div className={styles.title}>
              <div>
                {children?.firstname && children?.cycle?.name
                  ? `Profil de ${childName} - ${children?.cycle?.name}`
                  : ''}
              </div>
              {getSubscriptionStatus(Number(id)) === 'premium' && <div className={styles.plus}>Plus</div>}
            </div>
          }
          onClick={() => {
            navigate('/dashboard');
            redirectPaywall();
          }}
          action={<BiHelpCircle size={25} color="white" onClick={() => navigate('/help/')} />}
        />
        {userSubscriptionStatus === 'freemium' && <SubscribeBanner />}
        {parentalControlSettings &&
        parentalControlSettings.isLogoutRangeEnabled &&
        nowInLogoutRange(parentalControlSettings.logoutRangeMorning, parentalControlSettings.logoutRangeEvening) ? (
          <ChildrenDayRecap childrenId={id} />
        ) : (
          <ChildrenDayActivity childId={id} />
        )}
        <TabBar childrenId={id} />
      </Flex>
    </Page>
  );
}

export default Children;
