import { App as CapacitorApp } from '@capacitor/app';
import { Preferences } from '@capacitor/preferences';
import { SplashScreen } from '@capacitor/splash-screen';
import { CapacitorPurchases } from '@capgo/capacitor-purchases';
import { CapacitorUpdater } from '@capgo/capacitor-updater';
import { Drivers, Storage } from '@ionic/storage';
import * as Sentry from '@sentry/capacitor';
import * as SentryReact from '@sentry/react';
import { QueryClient } from '@tanstack/react-query';
import {
  PersistedClient,
  Persister,
  PersistQueryClientProvider,
  persistQueryClientRestore,
} from '@tanstack/react-query-persist-client';
import config from 'config';
import AndroidRedirectionProvider from 'contexts/android-redirection-context';
import OfflineQuizProvider from 'contexts/offline-quiz-context';
import OnlineProvider from 'contexts/online-context';
import UserProvider from 'contexts/user-context';
import { initializeApp } from 'firebase/app';
import { AnimatePresence } from 'framer-motion';
import CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { useEffect, useMemo, useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import { router } from 'router';

const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG ?? '{}');

initializeApp(firebaseConfig);

Sentry.init(
  {
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new SentryReact.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', process.env.REACT_APP_API_BASEURL],
      }),
      new SentryReact.Replay(),
    ],
    environment: process.env.NODE_ENV,
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  },
  // Forward the init method from @sentry/react
  SentryReact.init,
);

CapacitorUpdater.addListener('appReady', () => {
  // Hide splash
  SplashScreen.hide();
});

const store = new Storage({
  driverOrder: [CordovaSQLiteDriver._driver, Drivers.IndexedDB, Drivers.LocalStorage],
});

CapacitorUpdater.notifyAppReady();

export function createPersister(key: IDBValidKey = 'reactQuery') {
  return {
    persistClient: async (client: PersistedClient) => {
      await store.set(key.toString(), client);
    },
    // eslint-disable-next-line arrow-body-style
    restoreClient: async () => {
      const version = await Preferences.get({ key: 'appVersion' });
      if (version.value !== config.version) {
        // TODO create a less strict version, keeping some of the cache
        console.log('Clearing cache');
        await store.clear();
        await Preferences.set({
          key: 'appVersion',
          value: config.version,
        });
      }

      // await store.clear();
      return (await store.get(key.toString())) as PersistedClient;
    },
    removeClient: async () => {
      await store.remove(key.toString());
    },
  } as Persister;
}

const persister = createPersister();

CapacitorPurchases.setDebugLogsEnabled({ enabled: false });

function App(props: any) {
  const [cacheLoaded, setCacheLoaded] = useState(false);
  const queryClient = useMemo(() => {
    const qc = new QueryClient({
      defaultOptions: {
        queries: {
          gcTime: 1000 * 60 * 60 * 24, // 24 hours,
          staleTime: 1000 * 60 * 60 * 24,
        },
      },
    });
    return qc;
  }, []);

  useEffect(() => {
    store.create().then(async () => {
      await persistQueryClientRestore({
        queryClient,
        persister,
        maxAge: 1000 * 60 * 60 * 24, // 24 hours
        buster: '',
        hydrateOptions: undefined,
      });
      setCacheLoaded(true);
    });
    setTimeout(() => {
      setCacheLoaded(true);
    }, 2000);
  }, [queryClient]);

  useEffect(() => {
    if (process.env.REACT_APP_REDIRECT_URL) {
      const { userAgent } = navigator;
      if (/android/i.test(userAgent)) {
        setTimeout(() => {
          window.location.href = 'https://play.google.com/store/apps/details?id=com.altus.codidae';
        }, 5000);
      } else if (/iPad|iPhone|iPod/i.test(userAgent)) {
        setTimeout(() => {
          window.location.href = 'https://apps.apple.com/fr/app/altus/id6449283512';
        }, 5000);
      }
      // else {
      //   window.location.href = process.env.REACT_APP_REDIRECT_URL;
      // }
    }

    CapacitorApp.addListener('appUrlOpen', (event) => {
      console.log('appUrlOpen', event);
    });
  }, []);

  if (process.env.REACT_APP_REDIRECT_URL || !cacheLoaded) {
    return null;
  }

  return (
    <AnimatePresence>
      <PersistQueryClientProvider
        client={queryClient}
        onSuccess={() => {
          console.log('Persist cache success');
        }}
        persistOptions={{ persister }}
      >
        <AndroidRedirectionProvider>
          <OnlineProvider>
            <UserProvider>
              <OfflineQuizProvider>
                <RouterProvider router={router} />
              </OfflineQuizProvider>
            </UserProvider>
          </OnlineProvider>
        </AndroidRedirectionProvider>
      </PersistQueryClientProvider>
    </AnimatePresence>
  );
}

export default App;
