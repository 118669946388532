import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { Preferences } from '@capacitor/preferences';
import { CommonStudent } from '@codidae/common-types';
import { useMutation } from '@tanstack/react-query';
import AltusTalk from 'components/altus-talk/altus-talk';
import Altus from 'components/altus/altus';
import Button from 'components/button/button';
import Flex from 'components/flex/flex';
import InputPin from 'components/input-pin/input-pin';
import InputText from 'components/input-text/input-text';
import LabeledSwitch from 'components/labeled-switch/labeled-switch';
import OnboardingCycleSelection from 'components/onboarding-cycle-selection/onboarding-cycle-selection';
import OnboardingExplanations from 'components/onboarding-explanations/onboarding-explanations';
import OnboardingHeader from 'components/onboarding-header/onboarding-header';
import OnboardingInitialTime from 'components/onboarding-initial-time/onboarding-initial-time';
import OnboardingLayout from 'components/onboarding-layout/onboarding-layout';
import OrSeparator from 'components/or-separator/or-separator';
import Page from 'components/page/page';
import PairChildPhone from 'components/pair-child-phone/pair-child-phone';
import QRCodeAuth from 'components/qrcode-auth/qrcode-auth';
import TimePicker from 'components/time-picker/time-picker';
import config from 'config';
import React, { useEffect, useState } from 'react';
import { BsGoogle } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { SegmentEventType, identify, track, trackOnboardingStep } from 'segment';
import { preregister, preregisterWithFirebaseToken, register } from 'services/auth';
import { createChildren } from 'services/childrens';
import BlockerPlugin from 'utils/BlockerPlugin';

/* eslint-disable-next-line */
export interface MailRegisterProps {}

export function MailRegister(props: MailRegisterProps) {
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const preregisterMutation = useMutation({
    mutationFn: preregister,
    onSuccess: async (data) => {
      track(SegmentEventType.PREREGISTER_COMPLETED, {
        data,
        authMethod: 'email',
      });
      setStep(1);
    },
    onError: async (error) => {
      track(SegmentEventType.PREREGISTER_ERROR, {
        error,
      });
      alert('Votre email ou votre numéro de téléphone est déjà utilisé');
    },
  });
  const preregisterFirebaseMutation = useMutation({
    mutationFn: preregisterWithFirebaseToken,
    onSuccess: async (data) => {
      track(SegmentEventType.PREREGISTER_COMPLETED, {
        data,
        authMethod: 'oauth',
      });
      setStep(1);
      FirebaseAuthentication.signOut();
    },
    onError: async (error) => {
      track(SegmentEventType.PREREGISTER_ERROR, {
        error,
      });
      alert('Votre email ou votre numéro de téléphone est déjà utilisé');
      FirebaseAuthentication.signOut();
    },
  });
  const registerMutation = useMutation({
    mutationFn: register,
    onSuccess: async (data) => {
      track(SegmentEventType.REGISTERED, {
        data,
      });
      if (data.access_token.length) {
        await Preferences.set({
          key: 'access_token',
          value: data.access_token,
        });
        await Preferences.set({
          key: 'child_mode',
          value: null,
        });
        await createChildren({
          firstname: childFirstname,
          lastname: '',
          cycleId: cycle,
          lockTime: 20,
          maxDailyTime,
          isLogoutRangeEnabled,
          logoutRangeMorning,
          logoutRangeEvening,
        }).then(async (children) => {
          track(SegmentEventType.CHILD_CREATED, {
            children,
          });
          BlockerPlugin.metaInAppEvent({ eventName: 'register' });
          await Preferences.set({
            key: 'onboarding',
            value: 'true',
          });
          await Preferences.remove({ key: 'onboarding_data' });
          setNewChildren(children);
          setTimeout(() => {
            setStep(12);
          }, 5000);
        });
      }
    },
    onError: async (error) => {
      alert("Erreur lors de l'inscription");
      navigate('/');
    },
  });
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [childFirstname, setChildFirstname] = useState<string>('');
  const [cycle, setCycle] = useState<number>(null);
  const [maxDailyTime, setMaxDailyTime] = useState<number>(120);
  const [initialTime, setInitialTime] = useState<number>(60);
  const [newChildren, setNewChildren] = useState<CommonStudent>(null);
  const [isLogoutRangeEnabled, setIsLogoutRangeEnabled] = useState(true);
  const [logoutRangeMorning, setLogoutRangeMorning] = useState(420);
  const [logoutRangeEvening, setLogoutRangeEvening] = useState(1140);
  const [pinCode, setPinCode] = useState<string>('');
  const [phoneOwner, setPhoneOwner] = React.useState<string | null>(null);
  const [editTimePicker, setEditTimePicker] = useState<number | null>(null);

  useEffect(() => {
    const checkPhoneOwner = async () => {
      const po = await Preferences.get({ key: 'phone_owner' });
      setPhoneOwner(po.value === 'parent' || po.value === 'child' ? po.value : null);
    };

    track(SegmentEventType.ONBOARDING_STARTED, {});

    Preferences.get({ key: 'onboarding_data' }).then(({ value }) => {
      const data = JSON.parse(value);
      if (!data) return;
      if (data.step) {
        setStep(data.step);
      }
      if (data.email) {
        setEmail(data.email);
      }
      if (data.phone) {
        setPhone(data.phone);
      }
      if (data.childFirstname) {
        setChildFirstname(data.childFirstname);
      }
      if (data.cycle) {
        setCycle(data.cycle);
      }
      if (data.maxDailyTime) {
        setMaxDailyTime(data.maxDailyTime);
      }
      if (data.initialTime) {
        setInitialTime(data.initialTime);
      }
      if (data.isLogoutRangeEnabled) {
        setIsLogoutRangeEnabled(data.isLogoutRangeEnabled);
      }
      if (data.logoutRangeMorning) {
        setLogoutRangeMorning(data.logoutRangeMorning);
      }
      if (data.logoutRangeEvening) {
        setLogoutRangeEvening(data.logoutRangeEvening);
      }
      if (data.pinCode) {
        setPinCode(data.pinCode);
      }
    });

    checkPhoneOwner();
  }, []);

  useEffect(() => {
    Preferences.set({
      key: 'onboarding_data',
      value: JSON.stringify({
        step,
        email,
        phone,
        childFirstname,
        cycle,
        maxDailyTime,
        initialTime,
        isLogoutRangeEnabled,
        logoutRangeMorning,
        logoutRangeEvening,
        pinCode,
      }),
    });
  }, [
    step,
    email,
    phone,
    childFirstname,
    cycle,
    maxDailyTime,
    initialTime,
    isLogoutRangeEnabled,
    logoutRangeMorning,
    logoutRangeEvening,
    pinCode,
  ]);

  useEffect(() => {
    track(SegmentEventType.ONBOARDING_STEP, {
      step,
    });
    trackOnboardingStep(step);
  }, [step]);

  const transformPhone = (p: string) => {
    if (p.match(/[a-z]/i)) {
      return '';
    }
    if (p.startsWith('+33') && p.length === 12) {
      return p;
    }
    if (p.startsWith('0') && p.length === 10) {
      return `+33${p.substring(1)}`;
    }
    if (p.startsWith('33') && p.length === 11) {
      return `+${p}`;
    }
    if (p.startsWith('6') && p.length === 9) {
      return `+33${p}`;
    }
    return '';
  };

  const submit = async () => {
    registerMutation.mutate({
      email,
      pinCode,
      promoCode: '',
      phone: transformPhone(phone),
      childName: childFirstname,
    });
  };

  const TOTAL_STEPS = 13;
  return (
    <Page>
      {step < TOTAL_STEPS - 1 && (
        <OnboardingHeader
          percentage={(step / TOTAL_STEPS) * 100}
          onClick={() => (step > 0 ? setStep(step - 1) : navigate(-1))}
        />
      )}

      {step === 0 && (
        <OnboardingLayout
          canNext={email.length > 0}
          // onClick={async () => {
          //   preregisterMutation.mutate({
          //     email,
          //     phone: transformPhone(phone),
          //   });
          //   await identify(email, transformPhone(phone));
          //   await track(SegmentEventType.PREREGISTER_STARTED, {
          //     email,
          //     phone: transformPhone(phone),
          //   });
          // }}
          title={
            <span>
              Avant de commencer, <b>faisons connaissance</b>
            </span>
          }
          compact
        >
          <Flex direction="column" gap={10} width="100%" style={{ margin: 'auto' }}>
            <InputText
              key={1}
              defaultValue={email}
              placeholder="Adresse email"
              type="email"
              onChange={(v) => {
                setEmail(v.match(/^[a-z0-9._-]+@[a-z0-9._-]{2,}\.[a-z]{2,4}$/i) ? v : '');
              }}
            />
            <InputText
              key={2}
              type="tel"
              defaultValue={phone}
              placeholder="Téléphone (facultatif)"
              onChange={(v) => {
                setPhone(v);
              }}
            />
          </Flex>
          <Flex direction="column" gap={20} width="100%" align="center">
            <Button
              onClick={async () => {
                preregisterMutation.mutate({
                  email,
                  phone: transformPhone(phone),
                });
                await identify(email, transformPhone(phone));
                await track(SegmentEventType.PREREGISTER_STARTED, {
                  email,
                  phone: transformPhone(phone),
                });
              }}
              disabled={preregisterMutation.isPending}
              loading={preregisterMutation.isPending}
            >
              SUIVANT
            </Button>

            <OrSeparator />
            <Button
              onClick={async () => {
                FirebaseAuthentication.signInWithGoogle().then(async (data) => {
                  preregisterFirebaseMutation.mutate({
                    token: (await FirebaseAuthentication.getIdToken()).token,
                  });
                  setEmail(data.user.email);
                });
              }}
              loading={preregisterFirebaseMutation.isPending}
            >
              <BsGoogle /> SE CONNECTER AVEC GOOGLE
            </Button>
          </Flex>
        </OnboardingLayout>
      )}
      {step === 1 && <OnboardingExplanations step={2} onClick={() => setStep(2)} />}
      {step === 2 && <OnboardingExplanations step={3} onClick={() => setStep(3)} />}
      {step === 3 && <OnboardingExplanations step={4} onClick={() => setStep(4)} />}
      {step === 4 && (
        <OnboardingLayout
          canNext={childFirstname.length > 0}
          onClick={() => setStep(5)}
          title={
            <span>
              <b>Comment s’appelle l’enfant</b> pour qui vous configurez <b>Altus ?</b>
            </span>
          }
          subtitle={
            <span>
              Vous pouvez gérer jusqu’à <b>4 enfants</b> depuis <b>votre compte</b>.
            </span>
          }
        >
          <InputText
            key={5}
            placeholder="Son prénom"
            defaultValue={childFirstname}
            value={childFirstname}
            onChange={(v) => setChildFirstname(v.slice(0, 15))}
          />
        </OnboardingLayout>
      )}
      {step === 5 && (
        <OnboardingLayout
          canNext={!isLogoutRangeEnabled || (isLogoutRangeEnabled && logoutRangeMorning < logoutRangeEvening)}
          onClick={() => setStep(6)}
          title={
            <span style={{ fontSize: 30 }}>
              {!isLogoutRangeEnabled ? (
                <>
                  Souhaitez vous activer le <b>mode jour / nuit</b> pour {childFirstname} ?
                </>
              ) : (
                <>
                  Séléctionnez la <b>plage horaire</b> pendant laquelle {childFirstname} ne pourra <b>pas utiliser</b>{' '}
                  son smartphone
                </>
              )}
            </span>
          }
          subtitle={
            !isLogoutRangeEnabled && (
              <span>Pas de panique, vous pourrez toujours modifier ce paramètre plus tard !</span>
            )
          }
        >
          <LabeledSwitch
            label="Activer le mode jour / nuit"
            checked={isLogoutRangeEnabled}
            onChange={(v) => setIsLogoutRangeEnabled(v)}
          />
          <div />
          {isLogoutRangeEnabled && (
            <>
              {/* <InputText
                placeholder="Fin du mode nuit (matin)"
                labeled
                type="time"
                onChange={(v) => setLogoutRangeMorning(timeStringToNumber(v))}
                defaultValue={numberToTimeString(logoutRangeMorning ?? 0)}
              /> */}
              <TimePicker
                value={logoutRangeMorning}
                onChange={(v) => setLogoutRangeMorning(v)}
                labeled
                placeholder="Fin du mode nuit (matin)"
                options={config.logout_range_time}
                onEdit={() => setEditTimePicker(1)}
                editMode={editTimePicker === 1}
                displayValue={(minutes: number) => {
                  const hours = Math.floor(minutes / 60);
                  const mins = minutes % 60;
                  return `${hours}h${mins === 0 ? '00' : mins}`;
                }}
              />
              <TimePicker
                value={logoutRangeEvening}
                onChange={(v) => setLogoutRangeEvening(v)}
                labeled
                placeholder="Début du mode nuit (soir)"
                options={config.logout_range_time}
                onEdit={() => setEditTimePicker(2)}
                editMode={editTimePicker === 2}
                displayValue={(minutes: number) => {
                  const hours = Math.floor(minutes / 60);
                  const mins = minutes % 60;
                  return `${hours}h${mins === 0 ? '00' : mins}`;
                }}
              />
            </>
          )}
        </OnboardingLayout>
      )}
      {step === 6 && (
        <OnboardingLayout
          canNext={maxDailyTime !== null}
          onClick={() => setStep(7)}
          title={
            <span style={{ fontSize: 30 }}>
              Définissez le <b>temps maximum</b> que {childFirstname} peut passer sur ses apps <b>chaque jour</b>.
            </span>
          }
          subtitle={
            <span>
              Vous définirez les <b>applications</b> sur lesquels cette limite s&apos;applique{' '}
              <b>sur le téléphone de {childFirstname}</b>
            </span>
          }
        >
          <TimePicker
            value={maxDailyTime}
            onChange={(v) => setMaxDailyTime(v)}
            labeled
            placeholder="Temps maximum débloquable"
            options={config.max_daily_time}
            onEdit={() => setEditTimePicker(0)}
            editMode={editTimePicker === 0}
          />
        </OnboardingLayout>
      )}
      {step === 7 && (
        <OnboardingLayout
          canNext
          nextLabel="CONFIGURER ALTUS"
          onClick={() => setStep(8)}
          title={
            <span style={{ fontSize: 30 }}>
              Merci d&apos;avoir configuré la base du <b>contrôle parental</b>
            </span>
          }
          subtitle={
            <span>
              Maintenant, configurons le <b>tuteur intelligent</b> Altus !
            </span>
          }
        >
          <Flex align="center" justify="center" style={{ height: '100%', width: '100%' }}>
            <Altus type="confeti" />
          </Flex>
        </OnboardingLayout>
      )}
      {step === 8 && (
        <OnboardingLayout canNext onClick={() => setStep(9)}>
          <OnboardingInitialTime
            maxDailyTime={maxDailyTime}
            setInitialTime={setInitialTime}
            initialTime={initialTime}
            firstname={childFirstname}
          />
        </OnboardingLayout>
      )}
      {step === 9 && (
        <OnboardingLayout
          canNext={cycle !== null}
          onClick={() => setStep(10)}
          title={
            <span style={{ fontSize: 30 }}>
              Choisissez le <b>niveau scolaire</b> de {childFirstname}
            </span>
          }
        >
          <OnboardingCycleSelection onChange={(v) => setCycle(v)} selectedCycle={cycle} />
        </OnboardingLayout>
      )}
      {step === 10 && (
        <OnboardingLayout
          canNext={pinCode.length === 6 && !registerMutation.isPending}
          onClick={() => {
            setStep(11);
            submit();
          }}
          title={
            <span style={{ fontSize: 30 }}>
              Entrez un <b>code parental</b>
            </span>
          }
          subtitle={
            <span>
              Ce code vous permettra de <b>paramétrer le contrôle parental</b>, à l’abri de vos enfants !
            </span>
          }
        >
          <InputPin onChange={(v) => setPinCode(v)} title="Choisissez un code PIN parental" />
        </OnboardingLayout>
      )}
      {step === 11 && <OnboardingExplanations step={13} onClick={() => setStep(12)} />}
      {step === 12 && (
        <OnboardingLayout
          compact
          noPadding={phoneOwner === 'parent'}
          noHeader
          canNext
          nextLabel="TERMINÉ"
          onClick={async () => {
            track(SegmentEventType.ONBOARDING_COMPLETED, {
              children: newChildren,
            });
            if (phoneOwner === 'child') {
              await Preferences.set({
                key: 'child_mode',
                value: newChildren.id.toString(),
              });
              navigate('/dashboard');
            } else {
              navigate(`/children/${newChildren.id}`);
            }
          }}
        >
          {phoneOwner === 'parent' ? (
            <PairChildPhone embeded childrenId={newChildren?.id.toString()} />
          ) : (
            <>
              <AltusTalk type="hi">
                <div style={{ width: '50vw' }}>
                  <QRCodeAuth simple />
                </div>
              </AltusTalk>
              <div
                style={{
                  textAlign: 'center',
                  fontSize: 20,
                  color: '#55A4F7',
                  fontWeight: 600,
                  marginTop: 30,
                  padding: '0 20px',
                  margin: 'auto',
                }}
              >
                {phoneOwner === 'child' && `Scannez ce QR code avec votre téléphone pour vous connecter`}
              </div>
            </>
          )}
        </OnboardingLayout>
      )}
    </Page>
  );
}

export default MailRegister;
