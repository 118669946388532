import Flex from 'components/flex/flex';
import styles from './or-separator.module.scss';

export default function OrSeparator() {
  return (
    <Flex width="100%" align="center" gap={20}>
      <div className={styles.line} />
      <div className={styles.or}>ou</div>
      <div className={styles.line} />
    </Flex>
  );
}
