import Page from 'components/page/page';
import SubscriptionSuccess from 'components/subscription/subscription-success';

export default function SubscribeSuccessPage() {
  return (
    <Page>
      <SubscriptionSuccess />
    </Page>
  );
}
