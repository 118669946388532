import styles from './onboarding-initial-time.module.scss';

/* eslint-disable-next-line */
export interface OnboardingInitialTimeProps {
  maxDailyTime: number;
  initialTime: number;
  setInitialTime: (time: number) => void;
  firstname: string;
}

export function OnboardingInitialTime({
  maxDailyTime,
  initialTime,
  setInitialTime,
  firstname,
}: OnboardingInitialTimeProps) {
  const percent = 100 - (initialTime / maxDailyTime) * 100;

  const leftTime = Math.round((maxDailyTime * percent) / 100);
  const useTimeRatio = 0.55; // 55% of the left time is used for quiz
  const minutesByChapterPerDay = 5;
  const averageFields = 7;
  const pointsByEveryField = 2;
  const offset = 0.2;
  const earnPoints =
    (leftTime / (minutesByChapterPerDay * averageFields)) *
      pointsByEveryField *
      useTimeRatio +
    offset;

  return (
    <>
      <div className={styles.container}>
        <div className={styles.childName}>{firstname}</div>
        <div className={styles.blueText}>aura</div>
        <div
          className={styles.progressBar}
          style={{
            background: `radial-gradient(closest-side, #F2F7FE 89%, transparent 83% 100%), conic-gradient(#0b2d5b ${percent}%, #3E91E1 0)`,
          }}
        >
          <div className={styles.time}>{initialTime}</div>
          <div className={styles.minutes}>minutes</div>
          <div className={styles.text}>chaque jour</div>
        </div>
        <div className={styles.littleBlueText}>
          puis,
          <br />
          il pourra débloquer
        </div>
        <div className={styles.timeTag}>
          <span className={styles.time}>{leftTime}</span> minutes
        </div>
        <div className={styles.littleBlueText}>
          supplémentaires
          <br />
          avec des révisions Altus.
        </div>
      </div>
      <div className={styles.sliderWrapper}>
        <input
          type="range"
          min="0"
          max="100"
          value={percent}
          onChange={(e) =>
            setInitialTime(
              maxDailyTime -
                Math.round((parseInt(e.target.value, 10) / 100) * maxDailyTime),
            )
          }
          className={styles.slider}
        />
        <div className={styles.sliderText}>
          Ce qui équivaut à <b>+{earnPoints.toFixed(1)} points</b> dans la
          moyenne générale
        </div>
      </div>
    </>
  );
}

export default OnboardingInitialTime;
