import { useQuery } from '@tanstack/react-query';
import Grid from 'components/grid/grid';
import config from 'config';
import { getClassLevels } from 'services/chapters';
import styles from './onboarding-cycle-selection.module.scss';

/* eslint-disable-next-line */
export interface OnboardingCycleSelectionProps {
  onChange: (value: number) => void;
  selectedCycle?: number;
}

export function OnboardingCycleSelection({ onChange, selectedCycle }: OnboardingCycleSelectionProps) {
  const { data: cycles } = useQuery({
    queryKey: ['cycles'],
    queryFn: getClassLevels,
    staleTime: 24 * 3600000 * 21,
    gcTime: 24 * 3600000 * 21,
  });

  return (
    <Grid columns={2} gap={15} className={styles.container} width="100%">
      {cycles
        ?.filter((c) => config.accepted_cycles.includes(c.id))
        .map((cycle, index) => (
          <div
            key={cycle.id}
            className={`${styles.cycle} ${selectedCycle === cycle.id ? styles.selected : ''}`}
            onClick={() => onChange(cycle.id)}
            style={
              config.accepted_cycles.length % 2 === 1 && index === config.accepted_cycles.length - 1
                ? { gridColumn: 'span 2' }
                : {}
            }
          >
            {cycle.name}
          </div>
        ))}
    </Grid>
  );
}

export default OnboardingCycleSelection;
