import { useQueries } from '@tanstack/react-query';
import AltusTalk from 'components/altus-talk/altus-talk';
import AnsweredQuestionsChart from 'components/answered-questions-chart/answered-questions-chart';
import Flex from 'components/flex/flex';
import InfoTooltip from 'components/info-tooltip/info-tooltip';
import NewExercisesChart from 'components/new-exercises-chart/new-exercises-chart';
import ScreenUsageChart from 'components/screen-usage-chart/screen-usage-chart';
import StatsNumber from 'components/stats-number/stats-number';
import StatsSessionResult from 'components/stats-session-result/stats-session-result';
import StatsTimeRangeSelector from 'components/stats-time-range-selector/stats-time-range-selector';
import { useUserContext } from 'contexts/user-context';
import { useState } from 'react';
import { getChildrenNewExercisesChart } from 'services/chart';
import { getChildrenSessionResults, getDailyUsages } from 'services/childrens';
import useChildDayStats from 'utils/childDayStats';
import { defaultTimePeriod } from 'utils/date';
import styles from './parent-stats-progress.module.scss';

/* eslint-disable-next-line */
export interface ParentStatsProgressProps {
  childrenId: string;
}

export function ParentStatsProgress({ childrenId }: ParentStatsProgressProps) {
  const { getSubscriptionStatus } = useUserContext();
  const [{ data: results }, { data: dailyUsages }, { data: newExercisesChart }] = useQueries({
    queries: [
      {
        queryKey: ['results', childrenId],
        queryFn: () => getChildrenSessionResults(childrenId),
        staleTime: 3600000 * 3,
      },
      {
        queryKey: ['dailyUsages', childrenId],
        queryFn: () => getDailyUsages(Number(childrenId)),
        staleTime: 3600000 * 3,
      },
      {
        queryKey: ['getNewExercisesChart', childrenId],
        queryFn: () => getChildrenNewExercisesChart(childrenId),
        staleTime: 3600000 * 3,
      },
    ],
  });
  const [shoudLock, setShouldLock] = useState(false);
  const [timeRangeName, setTimeRangeName] = useState('');
  const [timePeriod, setTimePeriod] = useState<{
    start: Date;
    end: Date;
  }>(defaultTimePeriod);

  const locked = shoudLock && getSubscriptionStatus(Number(childrenId)) === 'freemium';
  const { todaySessions, workedMinutes } = useChildDayStats(childrenId);
  return (
    <>
      <Flex position="static" gap={10} width="100%" style={{ marginBottom: 10 }}>
        <StatsNumber
          number={todaySessions?.length ?? 0}
          text="quiz aujourd'hui"
          tooltipInfo={
            <div className={styles.textToolTip}>Nombre de quiz que votre enfant à fait aujourd&apos;hui.</div>
          }
        />
        <StatsNumber
          number={(Number.isNaN(workedMinutes) ? 0 : workedMinutes)?.toFixed(0) ?? 0}
          text="minutes de travail aujourd'hui"
          tooltipInfo={<div className={styles.textToolTip}>Temps passé sur les quiz aujourd&apos;hui.</div>}
        />
      </Flex>
      <StatsTimeRangeSelector
        onTimePeriodChange={setTimePeriod}
        onShouldLock={setShouldLock}
        onTimeRangeNameChange={setTimeRangeName}
      />
      {newExercisesChart && (
        <NewExercisesChart
          data={newExercisesChart}
          timeRangeName={timeRangeName}
          timePeriod={timePeriod}
          childId={Number(childrenId)}
        />
      )}
      <div style={{ height: 20 }} />
      {!results?.length && !dailyUsages?.length && (
        <>
          <div style={{ height: 30 }} />
          <AltusTalk variant="small" type="hard">
            Je n&apos;ai pas encore reçu de donnée pour vous afficher des statistiques ! Revenez ici dans quelques
            heures
          </AltusTalk>
        </>
      )}
      {!!results?.length && (
        <AnsweredQuestionsChart
          results={results}
          locked={locked}
          timeRangeName={timeRangeName}
          timePeriod={timePeriod}
        />
      )}
      <div style={{ height: 20 }} />
      {!!dailyUsages?.length && (
        <ScreenUsageChart
          dailyUsages={dailyUsages}
          locked={locked}
          timeRangeName={timeRangeName}
          timePeriod={timePeriod}
        />
      )}
      <div style={{ height: 40 }} />
      {!!results?.length && (
        <div className={styles.inputWrapper}>
          <span className={styles.label}>
            <div style={{ fontSize: 14, fontWeight: 500 }}>Les derniers quiz</div>
            <InfoTooltip>
              <div className={styles.textToolTip}>Nombre de bonnes réponses sur le nombre total de questions.</div>
            </InfoTooltip>
          </span>
        </div>
      )}
      {results
        ?.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
        .slice(0, 7)
        .map((result) => <StatsSessionResult key={result.id} result={result} />)}
    </>
  );
}

export default ParentStatsProgress;
