import { CommonSessionResult } from '@codidae/common-types';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  RadialLinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import InfoTooltip from 'components/info-tooltip/info-tooltip';
import PremiumBlurLocker from 'components/premium-blur-locker/premium-blur-locker';
import { Bar } from 'react-chartjs-2';
import { getChartLabel, getDaysFromTimePeriod } from 'utils/date';
import styles from './answered-questions-chart.module.scss';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  RadialLinearScale,
  Title,
  Tooltip,
  Legend,
);

/* eslint-disable-next-line */
export interface AnsweredQuestionsChartProps {
  results: CommonSessionResult[];
  locked?: boolean;
  timeRangeName: string;
  timePeriod: { start: Date; end: Date };
}

export function AnsweredQuestionsChart({ results, locked, timeRangeName, timePeriod }: AnsweredQuestionsChartProps) {
  const groupBy = timePeriod.end.getTime() - timePeriod.start.getTime() < 1000 * 60 * 60 * 24 * 33 ? 1 : 30;
  return (
    <>
      <div className={styles.inputWrapper}>
        <span className={styles.label}>
          <div style={{ fontSize: 14, fontWeight: 500 }}>Questions répondues {timeRangeName.toLowerCase()}</div>
          <InfoTooltip>
            <div className={styles.textToolTip}>Nombre de questions répondues par jour.</div>
          </InfoTooltip>
        </span>
      </div>
      <PremiumBlurLocker locked={locked}>
        <Bar
          data={{
            labels: getDaysFromTimePeriod(timePeriod)
              .map((date) => getChartLabel(timePeriod, date))
              .filter((_, index) => index % groupBy === 0),
            datasets: [
              {
                label: 'Questions répondues',
                data: getDaysFromTimePeriod(timePeriod)
                  .filter((_, index) => index % groupBy === 0)
                  .map((date) => {
                    if (groupBy === 1) {
                      const result = results?.find(
                        (res) =>
                          new Date(res.createdAt).getDate() === date.getDate() &&
                          new Date(res.createdAt).getMonth() === date.getMonth() &&
                          new Date(res.createdAt).getFullYear() === date.getFullYear(),
                      );
                      return result?.totalAnswered;
                    }
                    const from = new Date(date);
                    from.setDate(from.getDate() - groupBy);
                    const to = new Date(date);
                    const result = results?.filter(
                      (res) => new Date(res.createdAt) >= from && new Date(res.createdAt) <= to,
                    );
                    return result?.reduce((acc, res) => acc + res.totalAnswered, 0);
                  }),
                backgroundColor: '#55a4f7',
                borderRadius: 5,
              },
              {
                label: 'Bonnes réponses',
                data: getDaysFromTimePeriod(timePeriod)
                  .filter((_, index) => index % groupBy === 0)
                  .map((date) => {
                    if (groupBy === 1) {
                      const result = results?.find(
                        (res) =>
                          new Date(res.createdAt).getDate() === date.getDate() &&
                          new Date(res.createdAt).getMonth() === date.getMonth() &&
                          new Date(res.createdAt).getFullYear() === date.getFullYear(),
                      );
                      return result?.correctAnswered;
                    }
                    const from = new Date(date);
                    from.setDate(from.getDate() - groupBy);
                    const to = new Date(date);
                    const result = results?.filter(
                      (res) => new Date(res.createdAt) >= from && new Date(res.createdAt) <= to,
                    );
                    return result?.reduce((acc, res) => acc + res.correctAnswered, 0);
                  }),
                backgroundColor: '#94EDC2',
                borderRadius: 5,
              },
            ],
          }}
          options={{
            plugins: {
              title: {
                display: false,
              },
              legend: {
                display: false,
              },
              tooltip: {
                callbacks: {
                  title: (tooltipItem) => ``,
                },
              },
            },
          }}
        />
      </PremiumBlurLocker>
    </>
  );
}

export default AnsweredQuestionsChart;
