import { Preferences } from '@capacitor/preferences';
import { CommonQuestionResult } from '@codidae/common-types';
import CorrectAnswer from 'components/correct-answer/correct-answer';
import DisplayQuestion from 'components/display-question/display-question';
import IncorrectAnswer from 'components/incorrect-answer/incorrect-answer';
import SessionHeader from 'components/session-header/session-header';
import SessionOverUi from 'components/session-over/session-over-ui';
import config from 'config';
import { useOfflineQuizContext } from 'contexts/offline-quiz-context';
import { useUserContext } from 'contexts/user-context';
import useDisableBackButton from 'hooks/disable-back-button';
import { useEffect, useMemo, useState } from 'react';

/* eslint-disable-next-line */
export interface SessionProps {}

const INIT_LIFES = 3;

export function OfflineSession(props: SessionProps) {
  useDisableBackButton();
  const { question, setAsUsed, initialQuestionCount } = useOfflineQuizContext();
  const [isCorrect, setIsCorrect] = useState<boolean>(false);
  const [correctAnswered, setCorrectAnswered] = useState(0);
  const [result, setResult] = useState<CommonQuestionResult>(null);
  const [isIncorrect, setIsIncorrect] = useState<boolean>(false);
  const sessionOver = useMemo(() => !question, [question]);
  const [earnedTime, setEarnedTime] = useState<number>(0);
  const [childrenId, setChildrenId] = useState(0);
  const [lifes, setLifes] = useState<number>(INIT_LIFES);
  const [questionNb, setQuestionNb] = useState(0);
  const { parentalControlSettings, child } = useUserContext();
  const [streakExtraPoints, setStreakPoints] = useState(0);
  const [currentStreak, setCurrentStreak] = useState(0);

  const earnedTimeBase = useMemo(() => parentalControlSettings?.earnedTimeBase ?? 1, [parentalControlSettings]);

  const getNextQuestion = () => {
    setAsUsed();
    setIsCorrect(false);
    setIsIncorrect(false);
    setQuestionNb(questionNb + 1);
    setResult(null);
  };

  const answerQuestion = (answer: boolean[]) => {
    const correct = question.data.choices.every((checked, i) => checked.checked === answer[i]);

    setResult({
      answer: [],
      answerTime: 0,
      askedQuestion: null,
      correct,
      correction: question.correction,
      question,
      xpWon: 0,
    });
    if (correct) {
      setCurrentStreak(currentStreak + 1);
      const hasStreakPoint = currentStreak + 1 >= config.min_streak_count;
      const timeToEarn = earnedTimeBase + (hasStreakPoint ? 1 : 0);
      if (child && earnedTime + timeToEarn <= child.lockTime) setEarnedTime(earnedTime + earnedTimeBase);
      setIsCorrect(true);
      if (currentStreak + 1 >= config.min_streak_count) {
        setStreakPoints(streakExtraPoints + 1);
      }
      setCorrectAnswered(correctAnswered + 1);
    } else {
      setCurrentStreak(0);
      setIsIncorrect(true);
    }
  };

  useEffect(() => {
    Preferences.get({ key: 'child_mode' }).then(({ value }) => {
      if (value && value !== 'null') setChildrenId(parseInt(value, 10));
    });
  }, []);

  if (sessionOver)
    return (
      <SessionOverUi
        earnedTime={earnedTime + streakExtraPoints}
        answeredQuestions={questionNb}
        correctAnswered={correctAnswered}
        exerciceCount={initialQuestionCount}
        unlockSession
      />
    );
  if (child && lifes <= 0) {
    return (
      <SessionOverUi
        outOfLives
        earnedTime={earnedTime + streakExtraPoints}
        answeredQuestions={questionNb}
        correctAnswered={correctAnswered}
        exerciceCount={initialQuestionCount}
        unlockSession
      />
    );
  }

  return (
    <>
      <SessionHeader
        questionNumber={questionNb}
        totalQuestions={initialQuestionCount}
        earnedTime={earnedTime + streakExtraPoints}
        lifes={lifes - (isIncorrect ? 1 : 0)}
      />
      <DisplayQuestion
        question={question}
        onAnswer={(answer) => {
          answerQuestion(answer);
        }}
        sessionAvailableAnswers={0}
        revealAnswer={() => {}}
      />
      {isCorrect && (
        <CorrectAnswer
          result={result}
          next={getNextQuestion}
          earnedTime={earnedTime + streakExtraPoints}
          streak={currentStreak}
        />
      )}
      {isIncorrect && (
        <IncorrectAnswer
          result={result}
          next={() => {
            setLifes(lifes - 1);
            getNextQuestion();
          }}
          earnedTime={childrenId ? earnedTime : 0}
        />
      )}
    </>
  );
}

export default OfflineSession;
