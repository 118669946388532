import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { CommonQuestion, CommonQuestionMetadata } from '@codidae/common-types';
import AltusTalk from 'components/altus-talk/altus-talk';
import Button from 'components/button/button';
import Flex from 'components/flex/flex';
import { useStatusBarHeight } from 'hooks/use-status-bar-height';
import { useEffect, useMemo, useState } from 'react';
import { BsFillLightningChargeFill } from 'react-icons/bs';
import styles from './multiple-choice-question.module.scss';
import { AltusType } from 'components/altus/altus';
import InfoTooltip from 'components/info-tooltip/info-tooltip';

/* eslint-disable-next-line */
export interface MultipleChoiceQuestionProps {
  sessionAvailableAnswers: number;
  revealAnswer: VoidFunction;
  question: CommonQuestion;
  metadata?: CommonQuestionMetadata;
  onAnswer: (answer) => void;
  loading?: boolean;
  answerData?: any;
}

export function MultipleChoiceQuestion({
  onAnswer,
  question,
  loading,
  metadata,
  sessionAvailableAnswers,
  revealAnswer,
  answerData,
}: MultipleChoiceQuestionProps) {
  const [selected, setSelected] = useState<boolean[]>(question.data.choices.map(() => false));
  const statusBarHeight = useStatusBarHeight();

  useEffect(() => {
    setSelected(question.data.choices.map(() => false));
  }, [question, answerData]);

  const altusType: AltusType | undefined = useMemo(() => {
    if (!metadata) return undefined;
    if (metadata.hard) return 'hard';
    if (metadata.easy) return 'cloud';
    if (metadata.firstTime) return 'happy';
    return 'question';
  }, [metadata]);

  return (
    <Flex
      pad="20px 20px 35px 20px"
      direction="column"
      justify="space-between"
      align="center"
      height="100%"
      width="100%"
      gap={20}
      style={{ paddingTop: 100 + statusBarHeight, overflowY: 'scroll' }}
    >
      <div className={styles.altusTalk}>
        {altusType && (
          <>
            <AltusTalk type={altusType} variant="small">
              {question.title}
            </AltusTalk>
            {metadata?.firstTime && <div className={styles.firstTime}>DÉCOUVERTE !</div>}
          </>
        )}
      </div>
      <div className={styles.answers}>
        <div className={styles.revealAnswer}>
          <Button
            color="orange"
            className={styles.revealAnswerButton}
            disabled={!sessionAvailableAnswers || !!answerData}
            onClick={revealAnswer}
          >
            <BsFillLightningChargeFill />
          </Button>
          <div className={styles.indicator}>{sessionAvailableAnswers}</div>
        </div>
        <div className={styles.isUnique}>
          {question.data?.isUnique ? 'Une seule réponse est attendue !' : 'Plusieurs réponses sont attendues !'}
        </div>
        {question.data?.choices.map((choice, index) => (
          <div
            key={index}
            className={
              styles.answer +
              (selected[index] ? ` ${styles.selected}` : '') +
              (loading ? ` ${styles.disabled}` : '') +
              (answerData?.choices.some((c) => c.text === choice.text && c.checked) ? ` ${styles.green}` : '')
            }
            onTouchStart={() => {
              Haptics.impact({ style: ImpactStyle.Light });
            }}
            onTouchEnd={() => {
              Haptics.impact({ style: ImpactStyle.Light });
            }}
            onClick={() => {
              if (question.data?.isUnique) {
                const sel = question.data?.choices.map(() => false);
                sel[index] = true;
                onAnswer(sel);
              } else {
                const newSelected = [...selected];
                if (question.data?.unique) {
                  newSelected.forEach((_, i) => {
                    newSelected[i] = false;
                  });
                }
                newSelected[index] = !newSelected[index];
                setSelected(newSelected);
              }
            }}
          >
            {choice.text}
          </div>
        ))}
      </div>
      {!question.data?.isUnique && (
        <Button
          onClick={() => {
            onAnswer(selected);
          }}
          disabled={selected.every((choice) => !choice) || loading}
        >
          VALIDER
        </Button>
      )}
    </Flex>
  );
}

export default MultipleChoiceQuestion;
