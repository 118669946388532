import { useEffect, useState } from 'react';
import { IoTimerOutline } from 'react-icons/io5';
import styles from './session-earned-time.module.scss';

/* eslint-disable-next-line */
export interface SessionEarnedTimeProps {
  time: number;
  final?: boolean;
}

export function SessionEarnedTime({ time, final }: SessionEarnedTimeProps) {
  const [folded, setFolded] = useState<boolean>(true);

  useEffect(() => {
    setTimeout(() => {
      setFolded(false);
    }, 500);
  }, []);

  return (
    <div
      className={`${styles.sessionEarnedTime} ${folded ? styles.folded : ''} ${
        final ? styles.final : ''
      } ${time === 0 ? styles.zero : ''}`}
    >
      <div className={styles.icon}>
        <IoTimerOutline />
      </div>
      <div className={styles.label}>
        + {time} minute{time > 1 ? 's' : ''} {time === 0 ? '' : '!'}
      </div>
    </div>
  );
}

export default SessionEarnedTime;
