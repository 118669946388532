export const getProgressFromElo = (elo: number, count: number) => {
  const progress = (elo - 350) / ((500 - 350) / 100);

  const countRatio = (count > 10 ? 10 : count) / 10;

  if (progress < 0) {
    return 0;
  }
  if (progress > 100) {
    return 100;
  }
  return progress * countRatio;
};
