import styles from './subscription-time-button.module.scss';

/* eslint-disable-next-line */
export interface SubscriptionTimeButtonProps {
  onClick: () => void;
  time: string;
  price: number;
  per: string;
  realPrice: number;
  promo: number;
}

export function SubscriptionTimeButton({
  onClick,
  time,
  price,
  per,
  realPrice,
  promo,
}: SubscriptionTimeButtonProps) {
  return (
    <button
      type="button"
      className={styles.subscriptionTimeButton}
      onClick={onClick}
    >
      <div className={styles.title}>
        {time} {promo && <div className={styles.percentage}>-{promo}%</div>}
      </div>
      <div className={styles.price}>
        {price.toFixed(2)} € <div className={styles.perMonth}>/ mois</div>
      </div>
      {realPrice && (
        <div className={styles.realPrice}>
          soit {realPrice.toFixed(2)} € par {per}
        </div>
      )}
    </button>
  );
}

export default SubscriptionTimeButton;
