import LoadingScreen from 'components/loading-screen/loading-screen';
import Page from 'components/page/page';
import Subscription1 from 'components/subscription/subscription-1';
import { useUserContext } from 'contexts/user-context';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Subscribe1() {
  const { user, userSubscriptionStatus } = useUserContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (userSubscriptionStatus === 'premium') navigate('/dashboard');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSubscriptionStatus]);

  if (!user || userSubscriptionStatus === 'loading') {
    return <LoadingScreen />;
  }

  return (
    <Page>
      <Subscription1 />
    </Page>
  );
}
