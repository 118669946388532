import Flex from 'components/flex/flex';
import AddNewButton from 'components/add-new-button/add-new-button';
import styles from './add-chapter.module.scss';

/* eslint-disable-next-line */
export interface AddChapterButtonProps {
  onClick: () => void;
}

export function AddChapterButton({ onClick }: AddChapterButtonProps) {
  return (
    <Flex
      className={styles.bottom}
      direction="column"
      gap={50}
      align="center"
      justify="center"
      width="100%"
      pad={40}
    >
      <AddNewButton
        requiresConnection
        text="Ajouter un chapitre"
        onClick={onClick}
      />
    </Flex>
  );
}

export default AddChapterButton;
