import { useEffect, useState } from 'react';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import { getCurrentTimeRangeName } from 'utils/date';
import styles from './stats-time-range-selector.module.scss';

/* eslint-disable-next-line */
export interface StatsTimeRangeSelectorProps {
  onTimePeriodChange: (timePeriod: { start: Date; end: Date }) => void;
  onTimeRangeNameChange: (timeRangeName: string) => void;
  onShouldLock: (shouldLock: boolean) => void;
}

export function StatsTimeRangeSelector({
  onTimePeriodChange,
  onShouldLock,
  onTimeRangeNameChange,
}: StatsTimeRangeSelectorProps) {
  const [timeRange, setTimeRange] = useState<'week' | 'month' | 'year'>('week');
  const [timePeriod, setTimePeriod] = useState<{
    start: Date;
    end: Date;
  } | null>(null);

  const updateTimePeriodOnTimeRangeChange = () => {
    const now = new Date();
    const from = new Date(now);
    from.setDate(from.getDate() - getTimeRangeSize());
    onTimePeriodChange({ start: from, end: now });
  };

  useEffect(() => {
    setTimePeriod(null);
    updateTimePeriodOnTimeRangeChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeRange]);

  useEffect(() => {
    let finalTimePeriod = timePeriod;
    if (timePeriod === null) {
      const now = new Date();
      const from = new Date(now);
      from.setDate(from.getDate() - getTimeRangeSize());
      finalTimePeriod = { start: from, end: now };
    }
    onTimePeriodChange(finalTimePeriod);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timePeriod]);

  useEffect(() => {
    let shouldLock = false;
    if (timeRange === 'month' || timeRange === 'year') {
      shouldLock = true;
    }
    if (timePeriod !== null) {
      shouldLock = true;
    }
    onShouldLock(shouldLock);
    onTimeRangeNameChange(getCurrentTimeRangeName(timeRange, timePeriod));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeRange, timePeriod]);

  const getTimeRangeSize = () => {
    switch (timeRange) {
      case 'week':
        return 6;
      case 'month':
        return 30;
      case 'year':
        return 365;
      default:
        return 7;
    }
  };

  const previousTimePeriod = () => {
    let from = new Date(timePeriod?.start);
    from.setDate(from.getDate() - getTimeRangeSize());
    if (timePeriod === null) {
      from = new Date();
      from.setDate(from.getDate() - getTimeRangeSize() * 2);
    }
    const to = new Date(from);
    to.setDate(to.getDate() + getTimeRangeSize());
    setTimePeriod({ start: from, end: to });
  };

  const nextTimePeriod = () => {
    const from = new Date(timePeriod?.start);
    from.setDate(from.getDate() + getTimeRangeSize());
    if (timePeriod === null) {
      return;
    }
    const to = new Date(from);
    to.setDate(to.getDate() + getTimeRangeSize());
    const toCompare = new Date();
    toCompare.setDate(toCompare.getDate() - getTimeRangeSize());
    if (to > toCompare) {
      setTimePeriod(null);
      return;
    }
    setTimePeriod({ start: from, end: to });
  };

  const canNext = timePeriod !== null;

  return (
    <div className={styles.statsTimeRangeSelector}>
      <div className={styles.timeRangeSelector}>
        <button
          className={styles.button + (timeRange === 'week' ? ` ${styles.active}` : '')}
          type="button"
          onClick={() => setTimeRange('week')}
        >
          Semaine
        </button>
        <button
          className={styles.button + (timeRange === 'month' ? ` ${styles.active}` : '')}
          type="button"
          onClick={() => setTimeRange('month')}
        >
          Mois <div className={styles.plus}>Plus</div>
        </button>
        <button
          className={styles.button + (timeRange === 'year' ? ` ${styles.active}` : '')}
          type="button"
          onClick={() => setTimeRange('year')}
        >
          Année <div className={styles.plus}>Plus</div>
        </button>
      </div>
      <div className={styles.timePeriodSelector}>
        <button className={styles.previousButton} type="button" onClick={previousTimePeriod}>
          <BiChevronLeft />
        </button>
        <div className={styles.timePeriod}>{getCurrentTimeRangeName(timeRange, timePeriod)}</div>
        <button
          className={styles.nextButton + (canNext ? '' : ` ${styles.disabled}`)}
          type="button"
          onClick={nextTimePeriod}
          disabled={!canNext}
        >
          <BiChevronRight />
        </button>
      </div>
    </div>
  );
}

export default StatsTimeRangeSelector;
