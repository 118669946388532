import InfoTooltip from 'components/info-tooltip/info-tooltip';
import React from 'react';
import styles from './select.module.scss';

/* eslint-disable-next-line */
export interface SelectProps {
  options: { value: string; label: string }[];
  onChange?: (value: string) => void;
  placeholder?: string;
  defaultValue?: string;
  labeled?: boolean;
  tooltipInfo?: React.ReactNode;
}

export function Select({
  options,
  defaultValue,
  labeled,
  onChange,
  placeholder,
  tooltipInfo,
}: SelectProps) {
  return (
    <div className={styles.inputWrapper}>
      {labeled && (
        <span className={styles.label}>
          {placeholder}
          {tooltipInfo && <InfoTooltip>{tooltipInfo}</InfoTooltip>}
        </span>
      )}
      <select
        className={styles.select}
        key={defaultValue}
        onChange={(e) => onChange?.(e.target.value)}
        defaultValue={defaultValue ?? ''}
      >
        <option value="" disabled>
          {placeholder}
        </option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
}

export default Select;
