import { useMutation, useQueries } from '@tanstack/react-query';
import FieldCard from 'components/field-card/field-card';
import Flex from 'components/flex/flex';
import Loader from 'components/loader/loader';
import Page from 'components/page/page';
import TabBar from 'components/tab-bar/tab-bar';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SegmentEventType, track } from 'segment';
import { getChapters, getFields } from 'services/chapters';
import { getChildren, setChildrenFields } from 'services/childrens';

/* eslint-disable-next-line */
export interface ChildrenChaptersProps {}

export function ChildrenChapters(props: ChildrenChaptersProps) {
  const [selectedFields, setSelectedFields] = useState<number[]>([]);
  const setChildrenFieldsMutation = useMutation({
    mutationFn: setChildrenFields,
  });
  const { id } = useParams();
  const [{ data: children, refetch }, { data: fields }, { data: chapters }] = useQueries({
    queries: [
      {
        queryKey: ['children', id],
        queryFn: () => getChildren(id),
      },
      {
        queryKey: ['fields'],
        queryFn: getFields,
        staleTime: 24 * 3600000 * 21,
        gcTime: 24 * 3600000 * 21,
      },
      {
        queryKey: ['chapters'],
        queryFn: getChapters,
        staleTime: 24 * 3600000 * 21,
        gcTime: 24 * 3600000 * 21,
      },
    ],
  });

  useEffect(() => {
    if (!children?.fields) return;
    if (children?.fields.length === 0) return;
    setSelectedFields(children.fields.map((f) => f.id));
  }, [children]);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page parentMode>
      <Flex direction="column" justify="start" align="center" width="100%" height="100%">
        <Flex
          direction="column"
          gap={15}
          pad={20}
          width="100%"
          style={{
            overflowY: 'auto',
            flexGrow: 1,
          }}
        >
          <div
            style={{
              textAlign: 'center',
              width: '100%',
              fontSize: 18,
              fontWeight: 600,
              color: '#1b0d54',
              marginBottom: 10,
            }}
          >
            Choix des matières travaillées par {children?.firstname}
          </div>
          {!chapters ||
            (!fields && (
              <Flex justify="center" align="center" width="100%">
                <Loader />
              </Flex>
            ))}
          {chapters &&
            fields
              ?.filter((c) =>
                chapters.some(
                  (chapter) =>
                    chapter.field.id === c.id &&
                    chapter.parent === null &&
                    chapter.cycles?.some((cycle) => cycle.id === children?.cycle?.id),
                ),
              )
              .map((field) => (
                <FieldCard
                  key={field.id}
                  field={field}
                  selected={selectedFields.includes(field.id)}
                  toggleSelect={() => {
                    const newFields = [...selectedFields];
                    if (newFields.includes(field.id)) {
                      newFields.splice(newFields.indexOf(field.id), 1);
                      track(SegmentEventType.REMOVE_FIELD);
                    } else {
                      newFields.push(field.id);
                      track(SegmentEventType.SELECT_FIELD);
                    }

                    setSelectedFields(newFields);

                    setChildrenFieldsMutation.mutate({
                      fields: newFields,
                      id: Number(id),
                    });
                  }}
                />
              ))}
        </Flex>
        <TabBar childrenId={id} />
      </Flex>
    </Page>
  );
}

export default ChildrenChapters;
