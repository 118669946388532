/* eslint-disable-next-line */
export interface FlexProps {
  children?: React.ReactNode;
  justify?: 'center' | 'space-between' | 'space-around' | 'start' | 'end';
  align?: 'center' | 'flex-start' | 'flex-end';
  direction?: 'row' | 'column';
  wrap?: 'wrap' | 'nowrap';
  position?: 'relative' | 'absolute' | 'fixed' | 'static' | 'unset';
  top?: string;
  left?: string;
  right?: string;
  bottom?: string;
  gap?: number;
  pad?: number | string;
  height?: string;
  width?: string;
  className?: string;
  style?: React.CSSProperties;
}

export function Flex(props: FlexProps) {
  const {
    justify,
    align,
    direction,
    wrap,
    position,
    top,
    left,
    right,
    bottom,
    gap,
    pad,
    height,
    width,
    className,
    style,
    children,
  } = props;
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: justify || 'start',
        alignItems: align || 'flex-start',
        flexDirection: direction || 'row',
        flexWrap: wrap || 'nowrap',
        position: position || 'relative',
        top: top || 'auto',
        left: left || 'auto',
        right: right || 'auto',
        bottom: bottom || 'auto',
        gap: gap || 0,
        padding: pad || undefined,
        height: height || 'auto',
        width: width || 'auto',
        ...style,
      }}
      className={className ?? undefined}
    >
      {children}
    </div>
  );
}

export default Flex;
