import SubscribeButtons from 'components/altus-plus/subscribe-buttons';
import SubscriptionBackHeader from 'components/altus-plus/subscription-back-header';
import Flex from 'components/flex/flex';
import { useMemo } from 'react';
import { FaCheck } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { SegmentEventType, track } from 'segment';
import styles from './subscription.module.scss';

const ALTUS_FREE_POINTS = ['Contrôle parental', 'Localisez vos enfants'];

const ALTUS_PREMIUM_POINTS = [
  '100 000+ questions',
  'Rapports Détaillés',
  'Choix des chapitres',
  'Support prioritaire',
  'Système de récompenses',
];

export default function Subscription1() {
  const freePoint = useMemo(() => ALTUS_FREE_POINTS[Math.floor(Math.random() * ALTUS_FREE_POINTS.length)], []);
  const premium1 = useMemo(() => ALTUS_PREMIUM_POINTS[Math.floor(Math.random() * ALTUS_PREMIUM_POINTS.length)], []);
  const premium2 = useMemo(() => {
    let point = '';
    do {
      point = ALTUS_PREMIUM_POINTS[Math.floor(Math.random() * ALTUS_PREMIUM_POINTS.length)];
    } while (point === premium1);
    return point;
  }, [premium1]);
  const premium3 = useMemo(() => {
    let point = '';
    do {
      point = ALTUS_PREMIUM_POINTS[Math.floor(Math.random() * ALTUS_PREMIUM_POINTS.length)];
    } while (point === premium1 || point === premium2);
    return point;
  }, [premium1, premium2]);
  const premium4 = useMemo(() => {
    let point = '';
    do {
      point = ALTUS_PREMIUM_POINTS[Math.floor(Math.random() * ALTUS_PREMIUM_POINTS.length)];
    } while (point === premium1 || point === premium2 || point === premium3);
    return point;
  }, [premium1, premium2, premium3]);
  const navigate = useNavigate();

  return (
    <div className={styles.background}>
      <Flex direction="column" align="center" width="100%" height="100%" pad="20px" justify="space-between">
        <SubscriptionBackHeader
          onBack={() => {
            navigate(-2);
            track(SegmentEventType.EXIT_PAYMENT_FLOW);
          }}
        />
        <div className={styles.subscriptionText}>
          Votre enfant apprendra les <b>mathématiques</b> plus rapidement avec Altus Plus
        </div>

        <div className={styles.comparison}>
          <div className={styles.headerFree}>Gratuit</div>
          <div className={styles.headerPlus}>Plus</div>
          <img className={styles.altusHold} src="/altus-smile-hold.svg" alt="Altus" />

          {[freePoint, premium1, premium2, premium3, premium4].map((point, i) => (
            <div className={styles.point} key={point} style={{ gridRow: i + 2 }}>
              {point}
            </div>
          ))}
          <div className={styles.lastColumnBg} />
          <div className={styles.sep1} />
          <div className={styles.sep2} />
          <div className={styles.sep3} />
          <div className={styles.sep4} />
          <FaCheck className={styles.freePoint} />
          <FaCheck className={styles.plusPoint1} />
          <FaCheck className={styles.plusPoint2} />
          <FaCheck className={styles.plusPoint3} />
          <FaCheck className={styles.plusPoint4} />
          <FaCheck className={styles.plusPoint5} />
        </div>
        <SubscribeButtons
          trackingEvent={SegmentEventType.PAYMENT_ARGUMENTS_1}
          trackingProperties={{
            freePoint,
            premiumPoint1: premium1,
            premiumPoint2: premium2,
          }}
          backAmount={-2}
          nextHref="/subscribe/arguments/2"
          nextText="COMMENCER MON ESSAI"
        />
      </Flex>
    </div>
  );
}
