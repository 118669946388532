import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';
import { CommonStudent } from '@codidae/common-types';
import Altus from 'components/altus/altus';
import Button from 'components/button/button';
import Card from 'components/card/card';
import Dialog from 'components/dialog/dialog';
import EnterPinView from 'components/enter-pin-view/enter-pin-view';
import Flex from 'components/flex/flex';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { SegmentEventType, track } from 'segment';
import BlockerPlugin from 'utils/BlockerPlugin';
import styles from './children-settings.module.scss';

/* eslint-disable-next-line */
export interface ChildrenSettingsProps {
  children: CommonStudent;
}

export function ChildrenSettings({ children }: ChildrenSettingsProps) {
  const navigate = useNavigate();
  const [switchParentPopup, setSwitchParentPopup] = useState(false);
  const [accessBlockerPopup, setAccessBlockerPopup] = useState(false);
  const [parentQRCodePopup, setParentQRCodePopup] = useState(false);
  const [needLocation, setNeedLocation] = useState<boolean>();
  const [locationDialog, setLocationDialog] = useState(false);

  useEffect(() => {
    if (needLocation === undefined) {
      BlockerPlugin.checkLocationPermission().then(({ isGranted }) => setNeedLocation(!isGranted));
    }
  }, [needLocation]);

  useEffect(() => {
    const test = App.addListener('appStateChange', () => {
      BlockerPlugin.checkLocationPermission().then(({ isGranted }) => {
        if (isGranted && needLocation === true) {
          toast.success('La localisation est désormais activée !', {
            icon: '✅',
          });
        }
        setNeedLocation(!isGranted);
      });
    });
    return () => {
      test.remove();
    };
  }, [needLocation]);

  if (switchParentPopup) {
    return (
      <EnterPinView
        onValidate={async () => {
          await Preferences.set({ key: 'phone_owner', value: 'parent' });
          Preferences.set({ key: 'child_mode', value: null }).then(() => {
            navigate(`/children/${children.id}`);
          });
          track(SegmentEventType.SWITCH_PARENT_MODE, {
            children_id: children.id,
          });
        }}
        onCancel={() => setSwitchParentPopup(false)}
      />
    );
  }
  if (accessBlockerPopup) {
    return (
      <EnterPinView
        onValidate={() => {
          if (Capacitor.getPlatform() === 'ios') {
            navigate(`/blocker/ios/${children.id}`);
          } else {
            navigate(`/blocker/android/${children.id}`);
          }
        }}
        onCancel={() => setAccessBlockerPopup(false)}
      />
    );
  }
  if (parentQRCodePopup) {
    return (
      <EnterPinView
        onValidate={() => {
          navigate('/auth/qr/parent/');
        }}
        onCancel={() => setParentQRCodePopup(false)}
      />
    );
  }
  return (
    <Flex
      direction="column"
      justify="start"
      align="center"
      width="100%"
      height="100%"
      pad={20}
      gap={20}
      style={{
        overflowY: 'scroll',
        paddingBottom: 110,
      }}
    >
      <Card
        style={{
          width: '100%',
          padding: 20,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          flexShrink: 0,
        }}
        onClick={() => setAccessBlockerPopup(true)}
        requiresConnection
      >
        <div>
          <Altus type="builder" width={80} />
        </div>
        <div className={styles.label}>RECONFIGURER LE BLOQUEUR</div>
      </Card>
      {needLocation === true && (
        <Card
          style={{
            width: '100%',
            padding: 20,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            flexShrink: 0,
          }}
          onClick={() => setLocationDialog(true)}
          requiresConnection
        >
          <div>
            <img src="/map-gps.svg" alt="Localisation" />
            {/* <Altus type="builder" width={80} /> */}
          </div>
          <div className={styles.label}>ACTIVER LA LOCALISATION</div>
        </Card>
      )}
      <Card
        style={{
          width: '100%',
          padding: 20,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          flexShrink: 0,
        }}
        onClick={() => setParentQRCodePopup(true)}
        requiresConnection
      >
        <div>
          <Altus type="qr_code" width={170} />
        </div>
        <div className={styles.label}>AFFICHER LE QR CODE PARENT</div>
      </Card>
      <Card
        style={{
          width: '100%',
          padding: 20,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          flexShrink: 0,
        }}
        onClick={() => {
          if (window.confirm(`Attention ! Êtes-vous sur de vouloir basculer ce téléphone en mode parent ?`)) {
            setSwitchParentPopup(true);
          }
        }}
        requiresConnection
      >
        <div>
          <Altus type="look_at_key" width={80} />
        </div>
        <div className={styles.label}>BASCULER AU MODE PARENT</div>
      </Card>
      <Card
        style={{
          width: '100%',
          padding: 20,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          flexShrink: 0,
        }}
        onClick={() => {
          navigate(`/support?type=child=true`);
        }}
        requiresConnection
      >
        <div>
          <Altus type="question" width={80} />
        </div>
        <div className={styles.label}>UNE QUESTION ? UN BUG ?</div>
      </Card>

      <Dialog open={locationDialog} onClose={() => setLocationDialog(false)}>
        <h4>Voulez-vous activer la localisation ?</h4>
        <p>
          En activant la localisation, vous permettez à Altus de récuperer votre position.
          {` Celle-ci sera affichée sur la page Parent du profile de l'enfant`}.
        </p>
        <p>
          Vous pouvez optionnellement autoriser Altus à récupérer votre position lorsque {`l'application`} est fermée.
        </p>
        <Flex direction="column" gap={10}>
          <Button outline onClick={() => setLocationDialog(false)}>
            ANNULER
          </Button>
          <Button
            onClick={() => {
              BlockerPlugin.askForLocationPermission();
              setLocationDialog(false);
            }}
          >
            AUTORISER
          </Button>
        </Flex>
      </Dialog>
    </Flex>
  );
}

export default ChildrenSettings;
