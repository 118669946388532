import AltusPlus from 'components/altus-plus/altus-plus';
import { useMemo } from 'react';
import { BiChevronRight } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { SegmentEventType, track } from 'segment';
import styles from './altus-plus.module.scss';

const SUBSCRIBE_PHRASES = [`Débloquez le potentiel éducatif d'Atlus`, `Débloquez le potentiel éducatif d'Atlus`];

export default function SubscribeBanner() {
  const subscribePhrase = useMemo(() => SUBSCRIBE_PHRASES[Math.floor(Math.random() * SUBSCRIBE_PHRASES.length)], []);
  const navigate = useNavigate();

  return (
    <div
      className={styles.banner}
      onClick={() => {
        track(SegmentEventType.CLICK_SUBSCRIBE_BANNER, {
          phrase: subscribePhrase,
        });
        navigate('/subscribe');
      }}
    >
      <div className={styles.bannerText}>
        <div className={styles.bannerTitle}>
          Essayez <AltusPlus />
        </div>
        <div>{subscribePhrase}</div>
      </div>
      <div>
        <BiChevronRight className={styles.chevronRight} />
      </div>
    </div>
  );
}
