import { CommonWorkedChapter } from '@codidae/common-types';
import { getIconFromFieldId } from 'utils/fieldIcons';
import { useState } from 'react';
import { getProgressFromElo } from 'utils/elo_convertion';
import Altus, { AltusType } from 'components/altus/altus';
import Button from 'components/button/button';
import { useMutation } from '@tanstack/react-query';
import { createSessionFromChapter } from 'services/session';
import { useNavigate } from 'react-router-dom';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { SegmentEventType, track } from 'segment';
import styles from './children-worked-chapter.module.scss';

/* eslint-disable-next-line */
export interface ChildrenWorkedChapterProps {
  chapter: CommonWorkedChapter;
  index: number;
  open: boolean;
  openPopup: () => void;
  notWorked?: boolean;
  childrenId: number;
  color: string;
}

const StartIcon = ({ fill }: { fill: string }) => (
  <svg width="63" height="60" viewBox="0 0 63 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M29.6176 52.0039L16.4636 59.0194C13.4834 60.6089 9.9965 58.0175 10.6589 54.7056L13.5592 40.204C13.8329 38.8353 13.3734 37.4229 12.3468 36.4773L1.55318 26.5358C-0.9857 24.1974 0.430497 19.957 3.86506 19.6135L17.8063 18.2194C19.1719 18.0828 20.3722 17.2556 20.986 16.0281L27.8424 2.31512C29.331 -0.662059 33.5925 -0.624685 35.0287 2.37815L41.5267 15.965C42.1309 17.2282 43.3515 18.0835 44.745 18.2201L59.2663 19.6438C62.6802 19.9785 64.1168 24.1733 61.6248 26.5306L51.071 36.5139C50.0907 37.4412 49.6441 38.8003 49.8831 40.1284L52.5382 54.8788C53.1302 58.1676 49.6676 60.6894 46.7191 59.1169L33.3824 52.0039C32.2059 51.3765 30.7941 51.3765 29.6176 52.0039Z"
      fill={fill}
    />
  </svg>
);

export function ChildrenWorkedChapter({
  chapter,
  childrenId,
  color,
  index,
  open,
  openPopup,
  notWorked,
}: ChildrenWorkedChapterProps) {
  const navigate = useNavigate();
  const createSessionMutation = useMutation({
    mutationFn: createSessionFromChapter,
    onSuccess: (data) => {
      navigate(`/children/session/${data.id}`);
    },
  });
  const [altusType] = useState<AltusType>(() => {
    const random = Math.floor(Math.random() * 13);
    if (random === 0) return 'hi';
    if (random === 1) return 'happy';
    if (random === 2) return 'animated_hi';
    if (random === 3) return 'sad';
    if (random === 4) return 'question';
    if (random === 5) return 'animated_fire';
    if (random === 6) return 'builder';
    if (random === 7) return 'cloud';
    if (random === 8) return 'confeti';
    if (random === 9) return 'look_at_key';
    if (random === 10) return 'parent_phone';
    if (random === 11) return 'green_phone';
    if (random === 12) return 'red_phone';
    return 'hi';
  });
  let offset = (index % 8) - 2;
  if (offset > 2) offset = 4 - offset;
  if (Math.abs(offset) === 2) offset = offset > 0 ? 1.4 : -1.4;

  return (
    <div className={styles.wrapper}>
      <div
        className={`${styles.workedChapter} ${notWorked ? styles.notWorked : ''} ${
          !notWorked && chapter.tryCount === 0 ? styles.noTry : ''
        } ${chapter.elo >= 600 ? styles.maxElo : ''}`}
        style={{
          transform: `translateX(${offset * 60}%)`,
          backgroundColor: color,
          boxShadow: `0px 7px 0 0 ${color}80`,
        }}
        onClick={(e) => {
          openPopup();
          e.stopPropagation();
        }}
        onTouchStart={() => {
          Haptics.impact({ style: ImpactStyle.Light });
        }}
        onTouchEnd={() => {
          Haptics.impact({ style: ImpactStyle.Light });
        }}
      >
        {getIconFromFieldId(chapter.chapter.field.id)}
        <div className={styles.stars}>
          {[0, 1, 2].map((_, i) => {
            let isYellow = false;
            if (i === 0 && chapter.elo >= 350 && chapter.tryCount > 0) isYellow = true;
            if (i === 1 && chapter.elo >= 500) isYellow = true;
            if (i === 2 && chapter.elo >= 600) isYellow = true;
            return <StartIcon key={`${index}-${i}`} fill={isYellow ? '#FFD700' : '#dddddd'} />;
          })}
        </div>
      </div>
      {Math.abs(offset) > 1 && (
        <div className={`${styles.workedChapterAltus} ${offset < 1 ? styles.left : styles.right}`}>
          <Altus width={100} type={altusType} />
        </div>
      )}
      {open && (
        <div
          className={`${styles.workedChapterPopup} ${
            // eslint-disable-next-line no-nested-ternary
            offset < 0 ? styles.left : offset > 0 ? styles.right : ''
          }`}
        >
          <div className={styles.field}>{chapter.chapter.field.name}</div>
          <div className={styles.chapter}>{chapter.chapter.name}</div>
          <div className={styles.progress}>
            <div
              className={styles.progressValue}
              style={{
                width: `${getProgressFromElo(chapter.elo, chapter.tryCount)}%`,
              }}
            />
          </div>
          <Button
            outline
            onClick={() => {
              track(SegmentEventType.START_QUIZ_FROM_CHAPTER, {
                children_id: childrenId,
                chapter_id: chapter.chapter.id,
              });
              createSessionMutation.mutate({
                chapterId: chapter.chapter.id,
                childrenId,
              });
            }}
            requiresConnection
          >
            RÉVISER CE CHAPITRE
          </Button>
        </div>
      )}
    </div>
  );
}

export default ChildrenWorkedChapter;
