import { Preferences } from '@capacitor/preferences';
import { useMutation, useQueries } from '@tanstack/react-query';
import Button from 'components/button/button';
import Card from 'components/card/card';
import ChildMap from 'components/child-map/child-map';
import CircularProgressBar from 'components/circular-progress-bar/circular-progress-bar';
import Flex from 'components/flex/flex';
import InfoTooltip from 'components/info-tooltip/info-tooltip';
import Loader from 'components/loader/loader';
import RangeProgressBar from 'components/range-progress-bar/range-progress-bar';
import StatsNumber from 'components/stats-number/stats-number';
import TimePicker from 'components/time-picker/time-picker';
import config from 'config';
import { useUserContext } from 'contexts/user-context';
import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { BiRefresh } from 'react-icons/bi';
import { FaExclamationTriangle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { SegmentEventType, track } from 'segment';
import { getChildren, getYersterdayHardestSession } from 'services/childrens';
import { createSessionFromSession } from 'services/session';
import { answerTimeRequest, getTimeRequests } from 'services/time-request';
import useChildDayStats from 'utils/childDayStats';
import styles from './children-day-activity.module.scss';

export interface ChildrenDayActivityProps {
  childId: string;
}

export function ChildrenDayActivity({ childId }: ChildrenDayActivityProps) {
  const now = new Date();
  const { parentalControlSettings } = useUserContext();
  const [
    { data: child, refetch: refetchChildren },
    { data: hardestSession },
    { data: timeRequests, refetch: refetchTimeRequests },
  ] = useQueries({
    queries: [
      {
        queryKey: ['children', childId],
        queryFn: () => getChildren(childId),
        staleTime: 60000,
        refetchInterval: 60000,
      },
      {
        queryKey: ['hardestSession', childId],
        queryFn: () => getYersterdayHardestSession(childId),
      },
      {
        queryKey: ['getTimeRequests'],
        queryFn: () => getTimeRequests(),
        refetchInterval: 60000,
        staleTime: 60000,
      },
    ],
  });

  const [refreshing, setRefreshing] = useState(false);

  const answerTimeRequestMutation = useMutation({
    mutationFn: answerTimeRequest,
    onSuccess: () => {
      refetchTimeRequests();
      refetchChildren();
    },
  });

  useEffect(() => {
    if (!refreshing) return () => {};
    const timeout = setTimeout(() => {
      setRefreshing(false);
    }, 500);
    return () => clearTimeout(timeout);
  }, [refreshing]);

  const createSessionMutation = useMutation({
    mutationFn: createSessionFromSession,
    onSuccess: (data) => {
      track(SegmentEventType.START_HARDEST_CHILDREN_QUIZ, {
        children_id: childId,
      });
      navigate(`/children/session/${data.id}?parent=true&unlock=false`);
    },
  });
  const providedLocation = useMemo(() => child?.lastLocationUpdate != null ?? false, [child]);

  const navigate = useNavigate();

  const [showLocalization, setShowLocalization] = useState(true);

  useEffect(() => {
    Preferences.get({ key: 'showLocalization' }).then((v) => setShowLocalization(v.value !== 'false'));
  }, []);

  const toggleLocalization = () => {
    setShowLocalization(!showLocalization);
    Preferences.set({ key: 'showLocalization', value: !showLocalization ? 'true' : 'false' });
  };

  const localizationLastUpdate = useMemo(() => {
    if (child?.lastLocationUpdate == null) return null;
    const date = new Date(child.lastLocationUpdate).setHours(0, 0, 0, 0);
    const today = new Date().setHours(0, 0, 0, 0);
    const hour = `${child.lastLocationUpdate.getHours()}h${child.lastLocationUpdate.getMinutes().toString().padStart(2, '0')}`;

    if (date === today) return `Ajourd'hui à ${hour}`;
    return `${child.lastLocationUpdate.toLocaleDateString('fr-FR')} à ${hour}`;
  }, [child]);

  const localizationOutOfDate = useMemo(() => {
    if (child?.lastLocationUpdate == null) return false;
    return Date.now() - child.lastLocationUpdate.getTime() > 3600000; // if greater than 1 hour, is out of date
  }, [child]);
  const [timeRequestTime, setTimeRequestTime] = useState(20);
  const timeRequest = useMemo(
    () =>
      timeRequests?.find(
        (tr) => Date.now() - tr.createdAt.getTime() < 3600000 && typeof tr.awardedMinutes !== 'number',
      ),
    [timeRequests],
  );

  const canDisplayHardestSession = useMemo(() => {
    const currentDate = new Date();
    return currentDate.getHours() >= 16;
  }, []);
  const { workedMinutes, usage, answeredQuestions } = useChildDayStats(childId);
  return (
    <Flex
      direction="column"
      height="100%"
      style={{ overflowY: 'scroll', paddingTop: 28, marginTop: 4 }}
      gap={16}
      pad="16px"
    >
      <div className={styles.statsGrid}>
        <Flex
          gap={16}
          width="100%"
          justify="center"
          align="center"
          direction="column"
          className={`${styles.card} ${styles.time}`}
        >
          <div className={styles.title}>Temps d’écran restant</div>
          <CircularProgressBar
            value={usage}
            max={parentalControlSettings?.maxDailyTime ?? 100}
            size={130}
            strokeWidth={8}
          >
            <div className={styles.textTitleNbr}>
              {Math.max(0, Math.floor((parentalControlSettings?.maxDailyTime ?? 0) - usage))}
              <div className={styles.textTitle}>minutes</div>
            </div>
          </CircularProgressBar>
        </Flex>
        <StatsNumber
          number={`${answeredQuestions} questions`}
          text="complétées aujourd'hui"
          className={styles.stats1}
          small
        />
        <StatsNumber
          number={`${(Number.isNaN(workedMinutes) ? 0 : workedMinutes)?.toFixed(0) ?? 0} minutes`}
          text="de travail aujourd'hui"
          className={styles.stats2}
          small
        />
      </div>
      {parentalControlSettings?.isLogoutRangeEnabled && (
        <Flex gap={16} width="100%" direction="column" className={styles.card}>
          <div className={styles.title}>Plage de déconnexion</div>
          <RangeProgressBar
            progress1={
              parentalControlSettings?.isLogoutRangeEnabled ?? false ? parentalControlSettings?.logoutRangeMorning : 0
            }
            progress2={
              parentalControlSettings?.isLogoutRangeEnabled ?? false
                ? parentalControlSettings?.logoutRangeEvening
                : 24 * 60
            }
            hour={now.getHours() * 60 + now.getMinutes()}
          />
        </Flex>
      )}
      {hardestSession && canDisplayHardestSession && (
        <Flex gap={16} width="100%" direction="column" className={styles.card}>
          <div className={styles.title}>
            Le quiz compliqué d&apos;hier
            <InfoTooltip>
              <div className={styles.tooltip}>
                Vous pouvez défier votre enfant en réalisant le quiz le plus compliqué qu&apos;il ait fait hier. Bon
                courage !
              </div>
            </InfoTooltip>
          </div>
          <div className={styles.hardestSession}>
            {child?.firstname} à réalisé son quiz le plus dur à{' '}
            <b>
              {new Date(hardestSession.createdAt).toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' })}
            </b>
            , avec un score de{' '}
            <b>
              {hardestSession.correctAnswered} / {hardestSession.totalAnswered}
            </b>
            . Pouvez vous faire mieux ?
          </div>
          <Button
            color="primary"
            outline
            loading={createSessionMutation.isPending}
            onClick={() => {
              createSessionMutation.mutate(hardestSession.id);
            }}
          >
            LANCER LE QUIZ
          </Button>
        </Flex>
      )}
      <Flex gap={16} width="100%" direction="column">
        <Flex justify="space-between" width="100%">
          <div className={styles.title}>Localisation</div>
          <div className={styles.toggleLocalizationButton} onClick={toggleLocalization}>
            {showLocalization ? 'Masquer' : 'Afficher'}
          </div>
        </Flex>

        {showLocalization && !providedLocation && (
          <Card
            style={{ width: '100%' }}
            onClick={() => {
              if (
                window.confirm(
                  `Nous n’avons reçu aucune donnée de localisation de la part du téléphone de ${child?.firstname}.\n\nVoulez-vous voir un tutoriel qui explique comment configurer le téléphone de votre enfant ?`,
                )
              ) {
                navigate('/help/?tutorial_id=activate-child-gps-tracking');
              }
            }}
          >
            <div className={styles.localizationCard}>
              <div className={styles.title}>LOCALISER MON ENFANT</div>
              <img src="/map-gps-purple.svg" width="100" alt="Carte" />
            </div>
          </Card>
        )}
        {showLocalization && providedLocation && (
          <div
            className={styles.mapSection}
            onClick={() => {
              track(SegmentEventType.GOTO_LOCATION_PAGE);
              navigate(`/children/map/${childId}`);
            }}
          >
            <div className={styles.map}>
              <ChildMap childId={childId} dragging={false} />
            </div>
            <div className={styles.mapUpdateText}>
              {localizationOutOfDate && <FaExclamationTriangle className={styles.warningTriangle} />}
              <div>Dernière mise à jour: {localizationLastUpdate}</div>
            </div>
          </div>
        )}
      </Flex>
      <Flex gap={16} width="100%" direction="column">
        <Flex justify="space-between" width="100%">
          <div className={styles.title}>Demande de temps</div>
          <div
            className={styles.refreshButton}
            style={{
              pointerEvents: refreshing ? 'none' : undefined,
            }}
            onClick={() => {
              setRefreshing(true);
              refetchTimeRequests();
            }}
          >
            {refreshing ? <Loader size={20} /> : <BiRefresh />}
          </div>
        </Flex>
        {timeRequest === undefined && (
          <div className={styles.text}>{`Vous n'avez aucune demande de temps supplémentaire`}</div>
        )}
        {timeRequest !== undefined && (
          <>
            <TimePicker
              value={timeRequestTime}
              onChange={(v) => setTimeRequestTime(v)}
              labeled
              options={config.request_time}
              onEdit={() => {}}
              editMode
            />

            <Flex width="100%" gap={16}>
              <Button
                color="red"
                outline
                onClick={() =>
                  answerTimeRequestMutation
                    .mutateAsync({
                      awardedTime: 0,
                      requestId: timeRequest.id,
                    })
                    .then(() => {
                      toast('Demande de temps refusée !', { icon: '✅' });
                    })
                }
              >
                Refuser
              </Button>
              <Button
                disabled={timeRequestTime === 0}
                onClick={() =>
                  answerTimeRequestMutation
                    .mutateAsync({
                      awardedTime: timeRequestTime,
                      requestId: timeRequest.id,
                    })
                    .then(() => {
                      toast('Demande de temps acceptée !', { icon: '✅' });
                    })
                }
              >
                Accepter
              </Button>
            </Flex>
          </>
        )}
      </Flex>
    </Flex>
  );
}

export default ChildrenDayActivity;
