import { CommonSuccess } from "@codidae/common-types";
import HTTPClient from "utils/HTTPClient";

export function createTicket({
  type,
  reason,
  description,
  meta
}): Promise<CommonSuccess> {
  return HTTPClient.post('/ticket', {
    type,
    reason,
    description,
    meta
  }).then((data) => data.data);
}
