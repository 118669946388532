import { useMutation, useQuery } from '@tanstack/react-query';
import AltusTalk from 'components/altus-talk/altus-talk';
import Altus from 'components/altus/altus';
import BackHeader from 'components/back-header/back-header';
import Button from 'components/button/button';
import Card from 'components/card/card';
import Flex from 'components/flex/flex';
import Page from 'components/page/page';
import React, { Fragment, useEffect, useState } from 'react';
import { BiHelpCircle } from 'react-icons/bi';
import QRCode from 'react-qr-code';
import { useNavigate, useParams } from 'react-router-dom';
import { getLoginPairCode, getLoginToken } from 'services/auth';
import { getChildren } from 'services/childrens';
import { getProfile } from 'services/profile';
import styles from './pair-child-phone.module.scss';

/* eslint-disable-next-line */
export interface PairChildPhoneProps {
  cta?: React.ReactNode;
  embeded?: boolean;
  childrenId?: string;
}

export function PairChildPhone({ cta, embeded, childrenId }: PairChildPhoneProps) {
  const BASE_URL = process.env.REACT_APP_APP_BASEURL;
  const [token, setToken] = useState<string>('');
  const [pairCode, setPairCode] = useState<string>('');
  const navigate = useNavigate();
  const { id: queryId } = useParams();
  const id = childrenId || queryId;
  const getTokenMutation = useMutation({
    mutationFn: getLoginToken,
    onSuccess: (data) => {
      setToken(data);
    },
  });
  const { data: children } = useQuery({
    queryKey: ['children', id],
    queryFn: () => getChildren(id),
  });
  const getLoginPairCodeMutation = useMutation({
    mutationFn: getLoginPairCode,
    onSuccess: (data) => {
      setPairCode(data);
    },
  });
  const [method, setMethod] = useState<'qr' | 'pair-code' | null>(null);
  const { data: profile } = useQuery({
    queryKey: ['getProfile'],
    queryFn: getProfile,
  });

  useEffect(() => {
    getTokenMutation.mutate();
    getLoginPairCodeMutation.mutate({
      childrenId: id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Wrapper = embeded ? Fragment : Page;

  return (
    <Wrapper>
      <BackHeader
        onClick={() => {
          setMethod(null);
        }}
        hideButton={method === null}
        title="Associer un téléphone"
        action={<BiHelpCircle size={25} color="white" onClick={() => navigate('/help/')} />}
      />
      <Flex
        direction="column"
        justify="space-between"
        align="center"
        width="100%"
        height="100%"
        style={{ paddingTop: 68, overflowY: 'scroll' }}
      >
        {method === null && (
          <Flex direction="column" gap={20} pad={20} width="100%">
            <div className={styles.title}>Comment souhaitez-vous associer le téléphone de {children?.firstname} ?</div>
            <Card className={styles.customCard} onClick={() => setMethod('qr')} requiresConnection>
              <div>
                <Altus type="qr_code" width={170} />
              </div>
              <div className={styles.cardLabel}>PAR QR CODE</div>
            </Card>
            <div className={styles.or}>OU</div>
            <Card className={styles.customCard} onClick={() => setMethod('pair-code')} requiresConnection>
              <div>
                <Altus type="look_at_key" width={80} />
              </div>
              <div className={styles.cardLabel}>PAR CODE D&apos;APPAIRAGE</div>
            </Card>
          </Flex>
        )}
        {method === 'qr' && (
          <>
            <div style={{ height: 30 }} />
            <AltusTalk type="green_phone">
              {token && profile && (
                <QRCode
                  value={`${BASE_URL}/auth/token/${token}?email=${profile?.email}${id ? `&childrenId=${id}` : ''}`}
                />
              )}
            </AltusTalk>
            <div className={styles.scanQrCode}>Scannez ce QR code avec l&apos;appareil de votre enfant</div>
          </>
        )}
        {method === 'pair-code' && (
          <>
            <div style={{ height: 30 }} />
            <AltusTalk type="green_phone">
              <div style={{ fontSize: 50, fontWeight: 600 }}>{pairCode}</div>
            </AltusTalk>
            <ul className={styles.instructions}>
              <li>Ouvrez l&apos;application Altus sur le téléphone de votre enfant</li>
              <li>Séléctionnez &quot;Celui que je souhaite protéger&quot;</li>
              <li>Séléctionnez &quot;Oui&quot;</li>
              <li>Séléctionnez &quot;Entrer un code d&apos;appairage&quot;</li>
              <li>Entrez le code ci-dessus</li>
            </ul>
          </>
        )}
        {!embeded && (
          <Flex direction="column" gap={10} pad={20} width="100%">
            {cta || <Button href={-1}>RETOUR</Button>}
          </Flex>
        )}
      </Flex>
    </Wrapper>
  );
}

export default PairChildPhone;
