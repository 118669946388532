import { CommonSuccess, CommonUser } from '@codidae/common-types';
import HTTPClient from '../utils/HTTPClient';

export function getProfile(): Promise<CommonUser> {
  return HTTPClient.get('/user/me').then((data) => data.data);
}

export function editProfile({
  oldPinCode,
  pinCode,
}: {
  oldPinCode?: string;
  pinCode?: string;
}): Promise<CommonSuccess> {
  return HTTPClient.post('/user/edit', {
    pinCode,
    oldPinCode,
    fields: [],
  }).then((data) => data.data);
}

export function registerParentPushToken(token: string): Promise<CommonSuccess> {
  return HTTPClient.post('/parent/token/parent', {
    token,
  }).then((data) => data.data);
}

export function registerChildrenPushToken(id: number, token: string): Promise<CommonSuccess> {
  return HTTPClient.post(`/parent/token/children/${id}`, {
    token,
  }).then((data) => data.data);
}
