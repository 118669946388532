import { useQueries } from '@tanstack/react-query';
import BackHeader from 'components/back-header/back-header';
import ChildMap from 'components/child-map/child-map';
import Flex from 'components/flex/flex';
import Page from 'components/page/page';
import TabBar from 'components/tab-bar/tab-bar';
import usePaywallRedirect from 'hooks/paywall-redirect';
import { useNavigate, useParams } from 'react-router-dom';
import { getChildren } from 'services/childrens';

/* eslint-disable-next-line */
export interface ChildrenMapProps {}

export function ChildrenMap(props: ChildrenMapProps) {
  const { id } = useParams();
  const navigate = useNavigate();
  const [{ data: children }] = useQueries({
    queries: [
      {
        queryKey: ['children', id],
        queryFn: () => getChildren(id),
      },
    ],
  });
  const redirectPaywall = usePaywallRedirect(0.3, true);

  return (
    <Page parentMode>
      <BackHeader
        title={`Position de ${children?.firstname}`}
        onClick={() => {
          if (!redirectPaywall()) {
            navigate(-1);
          }
        }}
      />
      <Flex direction="column" justify="start" align="center" width="100%" height="100%">
        <ChildMap childId={id} />
        <TabBar childrenId={id} />
      </Flex>
    </Page>
  );
}

export default ChildrenMap;
