import { Navigate } from 'react-router-dom';

/* eslint-disable-next-line */
export interface NotFoundPageProps {}

export function NotFoundPage(props: NotFoundPageProps) {
  if (process.env.NODE_ENV === 'development') return null;
  return <Navigate to="/" />;
}

export default NotFoundPage;
