import InfoTooltip from 'components/info-tooltip/info-tooltip';
import { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import styles from './input-text.module.scss';

/* eslint-disable-next-line */
export interface InputTextProps {
  placeholder?: string;
  onChange?: (value: string) => void;
  type?: string;
  defaultValue?: string;
  labeled?: boolean;
  multiline?: boolean;
  value?: string;
  tooltipInfo?: React.ReactNode;
}

export function InputText({
  defaultValue,
  labeled,
  multiline,
  onChange,
  placeholder,
  type,
  value,
  tooltipInfo,
}: InputTextProps) {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className={styles.inputWrapper}>
      {labeled && (
        <span className={styles.label}>
          {placeholder}
          {tooltipInfo && <InfoTooltip>{tooltipInfo}</InfoTooltip>}
        </span>
      )}
      {!multiline ? (
        <input
          className={styles.inputText}
          placeholder={placeholder}
          onChange={(e) => onChange?.(e.target.value)}
          type={showPassword ? 'text' : type ?? 'text'}
          defaultValue={defaultValue}
          value={value}
        />
      ) : (
        <textarea
          className={styles.inputText}
          placeholder={placeholder}
          onChange={(e) => onChange?.(e.target.value)}
          defaultValue={defaultValue}
          rows={5}
          value={value}
        />
      )}
      {type === 'password' && (
        <button
          type="button"
          className={styles.showPassword}
          onClick={(e) => {
            e.preventDefault();
            setShowPassword(!showPassword);
          }}
        >
          {!showPassword ? <FaEye /> : <FaEyeSlash />}
        </button>
      )}
    </div>
  );
}

export default InputText;
