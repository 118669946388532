import AltusTalk from 'components/altus-talk/altus-talk';
import BackHeader from 'components/back-header/back-header';
import Button from 'components/button/button';
import Flex from 'components/flex/flex';
import InputText from 'components/input-text/input-text';
import Page from 'components/page/page';
import { useRef } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { SegmentEventType, track } from 'segment';
import { forgotPassword } from '../services/auth';

/* eslint-disable-next-line */
export interface ForgotPasswordProps {}

export function ForgotPassword(props: ForgotPasswordProps) {
  const navigate = useNavigate();

  const mutation = useMutation<object, object, object>({
    mutationFn: forgotPassword,
    onSuccess: (data) => {
      track(SegmentEventType.FORGOT_PASSWORD, {
        email: emailRef.current,
      });
      setTimeout(() => {
        navigate('/login/mail');
      }, 5000);
    },
  });
  const emailRef = useRef<string>();

  const submit = async () => {
    if (!emailRef.current) return;
    mutation.mutate({
      email: emailRef.current,
    });
  };

  let altusMessage = null;
  if (mutation.isError) {
    altusMessage = 'Email ou mot de passe incorrect !';
  } else if (mutation.isSuccess) {
    altusMessage =
      'Un email vous a été envoyé pour réinitialiser votre mot de passe';
  } else {
    altusMessage = 'Quelle est votre adresse email ?';
  }
  return (
    <Page>
      <Flex
        direction="column"
        justify="space-between"
        align="center"
        width="100%"
        height="100%"
        style={{ paddingTop: 100 }}
      >
        <BackHeader title="Mot de passe oublié" href="/login/mail" />
        <Flex direction="column" gap={30} pad={20} width="100%">
          <AltusTalk type={mutation.isError ? 'sad' : 'hi'}>
            {altusMessage}
          </AltusTalk>
          <Flex direction="column" gap={10} width="100%">
            <InputText
              placeholder="Votre email"
              onChange={(v) => {
                emailRef.current = v;
              }}
            />
          </Flex>
        </Flex>
        <Flex direction="column" gap={20} pad={20} width="100%">
          <Button onClick={submit} disabled={mutation.isPending}>
            M&apos;ENVOYER UN EMAIL
          </Button>
        </Flex>
      </Flex>
    </Page>
  );
}

export default ForgotPassword;
