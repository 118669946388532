// import React, { useState, useEffect } from "react";
import React from 'react';
import moon from 'assets/icons/moon.svg';
import sun from 'assets/icons/sun.svg';
import styles from './range-progress-bar.module.scss';

interface RangeProgressBarProps {
  progress1: number;
  progress2: number;
  hour: number;
  darkMode?: boolean;
}

const RangeProgressBar: React.FC<RangeProgressBarProps> = ({
  progress1,
  progress2,
  hour,
  darkMode,
}) => {
  const total = 24 * 60;

  const colorNight = darkMode ? '#1B4086' : '#0A2250';
  const colorDay = darkMode ? '#F0F7FF' : '#FFC98B';

  const padHour = (h: number) => (h < 10 ? `0${h}` : h);

  const percent1 =
    progress1 !== progress2
      ? ((progress1 < progress2 ? progress1 : progress2) / total) * 100
      : 50;
  const percent2 =
    progress1 !== progress2
      ? ((progress1 < progress2 ? progress2 : progress1) / total) * 100
      : 50;
  const hourLeft = Math.floor(
    (progress1 < progress2 ? progress1 : progress2) / 60,
  );
  const minutesLeft = Math.floor(
    (progress1 < progress2 ? progress1 : progress2) % 60,
  );
  const hourRight = Math.floor(
    (progress1 < progress2 ? progress2 : progress1) / 60,
  );
  const minutesRight = Math.floor(
    (progress1 < progress2 ? progress2 : progress1) % 60,
  );
  const posPointer = (hour / total) * 100;

  return (
    <div
      style={{ width: '100%', margin: '0 auto' }}
      className={darkMode ? styles.darkMode : ''}
    >
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: `${percent1}% ${percent2 - percent1}% ${100 - percent2}%`,
        }}
      >
        {percent1 > 0 && percent1 !== percent2 && (
          <img
            className={styles.leftIcon}
            src={progress1 < progress2 ? moon : sun}
            alt="icon left"
          />
        )}
        {percent1 !== percent2 ? (
          <img
            className={styles.middleIcon}
            src={progress1 < progress2 ? sun : moon}
            alt="icon middle"
          />
        ) : (
          <img className={styles.middleIcon} src={moon} alt="icon middle" />
        )}
        {percent2 < 100 && percent1 !== percent2 && (
          <img
            className={styles.rightIcon}
            src={progress1 < progress2 ? moon : sun}
            alt="icon right"
          />
        )}
      </div>
      <div className={styles.progressBar}>
        <div className={styles.pointer} style={{ left: `${posPointer}%` }} />
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: `${percent1}% ${percent2 - percent1}% ${100 - percent2}%`,
          }}
        >
          <div
            className={styles.leftSegment}
            style={{
              backgroundColor: progress1 < progress2 ? colorNight : colorDay,
            }}
          />
          <div
            className={styles.middleSegment}
            style={{
              backgroundColor: progress1 < progress2 ? colorDay : colorNight,
            }}
          />
          <div
            className={styles.rightSegment}
            style={{
              backgroundColor: progress1 < progress2 ? colorNight : colorDay,
            }}
          />
        </div>
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: `${percent1}% ${percent2 - percent1}% ${100 - percent2}%`,
        }}
      >
        <div className={styles.timeLeft}>
          {progress1 !== progress2 &&
            Math.abs(progress1 - progress2) > 60 * 4 - 1 &&
            percent1 > 0 && (
              <p className={styles.text}>
                {padHour(hourLeft)}h{minutesLeft > 0 && padHour(minutesLeft)}
              </p>
            )}
        </div>
        <div className={styles.timeMiddle}>
          {Math.abs(progress1 - progress2) < 60 * 4 && percent1 >= 0 && (
            <p className={styles.text}>
              {padHour(hourLeft)}h{minutesLeft > 0 && padHour(minutesLeft)}-
              {padHour(hourRight)}h{minutesRight > 0 && padHour(minutesRight)}
            </p>
          )}
        </div>
        <div className={styles.timeRight}>
          {progress1 !== progress2 &&
            Math.abs(progress1 - progress2) > 60 * 4 - 1 &&
            percent2 < 100 && (
              <p className={styles.text}>
                {padHour(hourRight)}h{minutesRight > 0 && padHour(minutesRight)}
              </p>
            )}
        </div>
      </div>
    </div>
  );
};

export default RangeProgressBar;
