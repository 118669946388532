import { useQueries } from '@tanstack/react-query';
import { useMemo } from 'react';
import { getChildrenSessionResults, getDailyUsages } from 'services/childrens';

export default function useChildDayStats(childId: string) {
  const [{ data: results }, { data: dailyUsages }] = useQueries({
    queries: [
      {
        queryKey: ['results', childId],
        queryFn: () => getChildrenSessionResults(childId),
      },
      {
        queryKey: ['dailyUsages', childId],
        queryFn: () => getDailyUsages(Number(childId)),
      },
    ],
  });

  return useMemo(() => {
    const todaySessions = results?.filter(
      (result) =>
        new Date(result.createdAt).getDate() === new Date().getDate() &&
        new Date(result.createdAt).getMonth() === new Date().getMonth() &&
        new Date(result.createdAt).getFullYear() === new Date().getFullYear(),
    );
    const workedMinutes =
      (todaySessions?.reduce((acc, result) => {
        const time = result.answeredQuestions.reduce((a, question) => a + question.answerTime, 0);
        return acc + time / 1000;
      }, 0) ?? 0) / 60;
    const answeredQuestions = todaySessions?.reduce((acc, result) => acc + result.answeredQuestions.length, 0) ?? 0;
    const date = new Date();
    const usage = Math.max(
      0,
      ...(dailyUsages
        ?.filter(
          (use) =>
            new Date(use.createdAt).getDate() === date.getDate() &&
            new Date(use.createdAt).getMonth() === date.getMonth() &&
            new Date(use.createdAt).getFullYear() === date.getFullYear(),
        )
        .map((du) => du.usage) ?? [0]),
    );
    return { todaySessions, workedMinutes, usage, answeredQuestions };
  }, [dailyUsages, results]);
}
