import { Preferences } from '@capacitor/preferences';
import { useMutation } from '@tanstack/react-query';
import fuzeIcon from 'assets/altus/fuze.svg';
import heartIcon from 'assets/icons/heart_break.svg';
import Card from 'components/card/card';
import config from 'config';
import { useOfflineQuizContext } from 'contexts/offline-quiz-context';
import { useOnlineContext } from 'contexts/online-context';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SegmentEventType, track } from 'segment';
import { createSessionFromChildren } from 'services/session';
import styles from './start-session-button.module.scss';

/* eslint-disable-next-line */
export interface StartSessionButtonProps {
  childrenId: string;
  disabled?: boolean;
}

export function StartSessionButton({ childrenId, disabled }: StartSessionButtonProps) {
  const navigate = useNavigate();
  const createSessionMutation = useMutation({
    mutationFn: createSessionFromChildren,
    onSuccess: (data) => {
      track(SegmentEventType.START_QUIZ, {
        children_id: childrenId,
      });
      navigate(`/children/session/${data.id}?unlock=true`);
    },
  });
  const [secondsBeforeUnlock, setSecondsBeforeUnlock] = useState<number>();
  const { isOnline } = useOnlineContext();
  const { question } = useOfflineQuizContext();

  useEffect(() => {
    function setUnlockTime() {
      Preferences.get({ key: 'last_out_of_lifes_session' })
        .then((res) => {
          if (res.value) {
            const lastSession = parseInt(res.value, 10);
            const now = Date.now();
            const diff = now - lastSession;
            setSecondsBeforeUnlock(Math.max(0, Math.round(config.time_after_out_of_time * 60 - diff / 1000)));
          } else {
            setSecondsBeforeUnlock(0);
          }
        })
        .catch(() => {
          setSecondsBeforeUnlock(0);
        });
    }

    setUnlockTime();
    const i = setInterval(setUnlockTime, 1000);
    return () => {
      clearInterval(i);
    };
  }, []);

  const isDisabled = secondsBeforeUnlock === undefined || disabled || createSessionMutation.isPending;

  const launchQuiz = () => {
    if (isOnline) createSessionMutation.mutate(childrenId);
    else navigate('/children/session/offline?unlock=true');
  };

  if (secondsBeforeUnlock > 0) {
    const minutes = Math.floor(secondsBeforeUnlock / 60);
    const seconds = secondsBeforeUnlock % 60;
    const minutesStr = minutes < 10 ? `0${minutes}` : minutes;
    const secondsStr = seconds < 10 ? `0${seconds}` : seconds;

    return (
      <Card
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 20,
          flexShrink: 0,
        }}
      >
        <img src={heartIcon} alt="start session" style={{ width: 100 }} />
        <div className={styles.startSessionLabel}>TU AS ÉPUISÉ TES VIES !</div>
        <div className={styles.startSessionTime}>
          {minutesStr}:{secondsStr}
        </div>
        <div className={styles.startSessionExplanation}>avant de pouvoir refaire un quiz</div>
      </Card>
    );
  }
  return (
    <Card
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20,
        backgroundColor: 'white',
        filter: isDisabled ? 'grayscale(1)' : undefined,
        flexShrink: 0,
      }}
      onClick={isDisabled ? undefined : launchQuiz}
      requiresConnection={!isOnline && question === undefined}
    >
      <div className={styles.startSessionLabel}>DÉBLOQUER DU TEMPS !</div>
      <img src={fuzeIcon} alt="start session" />
    </Card>
  );
}

export default StartSessionButton;
