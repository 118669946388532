import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { useMutation, useQueries } from '@tanstack/react-query';
import BackHeader from 'components/back-header/back-header';
import Button from 'components/button/button';
import ChapterCard from 'components/chapter-card/chapter-card';
import Flex from 'components/flex/flex';
import Grid from 'components/grid/grid';
import { useEffect, useState } from 'react';
import { SegmentEventType, track } from 'segment';
import { getChapters, getFields } from 'services/chapters';
import { getChildren, setChildrenFocus } from 'services/childrens';
import { getColorFromFieldId } from 'utils/colors';
import { getIconFromFieldId } from 'utils/fieldIcons';
import styles from './add-chapter-page.module.scss';

/* eslint-disable-next-line */
export interface AddChapterPageProps {
  onClose: () => void;
  childrenId: number;
}

export function AddChapterPage({ onClose, childrenId }: AddChapterPageProps) {
  const [selectedField, setSelectedField] = useState<number | null>(null);
  const [{ data: chapters }, { data: fields }, { data: children, refetch: refetchChildren }] = useQueries({
    queries: [
      {
        queryKey: ['chapters'],
        queryFn: getChapters,
        staleTime: 24 * 3600000 * 21,
        gcTime: 24 * 3600000 * 21,
      },
      {
        queryKey: ['fields'],
        queryFn: getFields,
        staleTime: 24 * 3600000 * 21,
        gcTime: 24 * 3600000 * 21,
      },
      {
        queryKey: ['children', childrenId],
        queryFn: () => getChildren(childrenId),
      },
    ],
  });
  const setChildrenFocusMutation = useMutation({
    mutationFn: setChildrenFocus,
    onSuccess: async () => {
      await refetchChildren();
    },
  });

  const [selectedChapters, setSelectedChapters] = useState<number[]>([]);

  useEffect(() => {
    setSelectedChapters(
      chapters
        ?.filter((chapter) => children?.focusedChapters?.some((c) => c.parent === chapter.id))
        ?.map((chapter) => chapter.id),
    );
  }, [chapters, children]);

  return (
    <Flex
      direction="column"
      justify="space-between"
      align="center"
      width="100%"
      height="100%"
      style={{ paddingTop: 68, paddingBottom: 100 }}
    >
      <BackHeader
        title="Ajout d'un chapitre"
        backgroundColor={selectedField ? getColorFromFieldId(selectedField) : undefined}
        onClick={() => {
          if (selectedField === null) {
            onClose();
          } else {
            setSelectedField(null);
          }
        }}
      />
      <Flex pad={20} direction="column" gap={20} width="100%" style={{ overflowY: 'auto' }}>
        {selectedField === null && (
          <Grid columns={1} gap={15} width="100%">
            {chapters &&
              fields
                ?.filter(
                  (c) =>
                    chapters.some(
                      (chapter) =>
                        chapter.field.id === c.id &&
                        chapter.parent === null &&
                        chapter.cycles.some((cycle) => cycle.id === children?.cycle?.id),
                    ) && children.fields?.map((f) => f.id).includes(c.id),
                )
                ?.map((field, index) => (
                  <button
                    onTouchStart={() => {
                      Haptics.impact({ style: ImpactStyle.Light });
                    }}
                    onTouchEnd={() => {
                      Haptics.impact({ style: ImpactStyle.Light });
                    }}
                    type="button"
                    key={field.id}
                    className={styles.fieldButton}
                    onClick={() => setSelectedField(field.id)}
                    style={{
                      backgroundColor: getColorFromFieldId(field.id),
                      boxShadow: `0px 4px 0px  0px ${getColorFromFieldId(field.id)}44`,
                    }}
                  >
                    <div className={styles.fieldIcon}>{getIconFromFieldId(field.id)}</div>
                    <div className={styles.fieldName}>{field.name}</div>
                  </button>
                ))}
          </Grid>
        )}
        {selectedField !== null &&
          chapters
            ?.filter(
              (c) =>
                c.field.id === selectedField &&
                c.parent === null &&
                c.cycles.some((cycle) => cycle.id === children?.cycle?.id),
            )
            ?.map((chapter) => (
              <ChapterCard
                key={chapter.id}
                chapter={chapter}
                childrenId={childrenId}
                refetch={refetchChildren}
                currentIndex={children?.focusedChapters?.findIndex((c) => c.parent === chapter.id)}
                isSelected={selectedChapters?.includes(chapter.id)}
                setSelected={(value) => {
                  if (value) {
                    setSelectedChapters([...selectedChapters, chapter.id]);
                  } else {
                    setSelectedChapters(selectedChapters.filter((c) => c !== chapter.id));
                  }
                }}
                onClose={() => {
                  onClose();
                }}
              />
            ))}
      </Flex>
      {selectedField !== null && (
        <Flex pad="0px 10px 10px 10px" width="100%">
          <Button
            loading={setChildrenFocusMutation.isPending}
            onClick={async () => {
              await setChildrenFocusMutation.mutateAsync({
                id: childrenId,
                chapterIds: selectedChapters,
                limit: null,
              });
              track(SegmentEventType.SELECT_CHAPTER);
              onClose();
            }}
          >
            VALIDER
          </Button>
        </Flex>
      )}
    </Flex>
  );
}

export default AddChapterPage;
