import { Preferences } from '@capacitor/preferences';
import BackHeader from 'components/back-header/back-header';
import Button from 'components/button/button';
import Flex from 'components/flex/flex';
import InputPin from 'components/input-pin/input-pin';
import Page from 'components/page/page';
import { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { SegmentEventType, track } from 'segment';
import { editProfile } from 'services/profile';
import config from 'config';

/* eslint-disable-next-line */
export interface SettingsProps {}

export function Settings(props: SettingsProps) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [pinCode, setPinCode] = useState<string>('');
  const [oldPinCode, setOldPinCode] = useState<string>('');
  const editProfileMutation = useMutation({
    mutationFn: editProfile,
    onSuccess: () => {
      alert('Votre code parental a bien été modifié');
    },
    onError: (error) => {
      alert('Votre ancien code parental est incorrect');
    },
  });

  return (
    <Page>
      <Flex
        direction="column"
        justify="space-between"
        align="center"
        width="100%"
        height="100%"
        style={{ paddingTop: 70 }}
      >
        <BackHeader title="Paramètres du compte" href="/dashboard" />
        <Flex direction="column" gap={10} pad={20} width="100%">
          <Flex
            direction="column"
            gap={10}
            width="100%"
            pad={20}
            style={{
              border: '1px solid #a2c4ee',
              borderRadius: 10,
            }}
          >
            <InputPin title="Ancien code parental" onChange={(v) => setOldPinCode(v)} id="1" />
            <InputPin title="Nouveau code parental" onChange={(v) => setPinCode(v)} id="2" />
          </Flex>
          <div style={{ height: 10 }} />
          <Button
            disabled={pinCode.length !== 6 || oldPinCode.length !== 6}
            onClick={() => {
              editProfileMutation.mutate({
                pinCode,
                oldPinCode,
              });
            }}
          >
            MODIFIER LE CODE
          </Button>
        </Flex>
        <Flex direction="column" gap={10} pad={20} width="100%">
          <Button href="/support" color="dark" outline>
            UN PROBLÈME AVEC L&apos;APP ?
          </Button>
          <Button
            color="red"
            outline
            onClick={() => {
              Preferences.clear();
              queryClient.clear();
              track(SegmentEventType.LOGOUT);
              navigate('/');
            }}
          >
            SE DÉCONNECTER
          </Button>
          <div
            style={{
              color: '#00000055',
              fontSize: 12,
              margin: 'auto',
              marginTop: 15,
            }}
          >
            Version {config.version}
          </div>
        </Flex>
      </Flex>
    </Page>
  );
}

export default Settings;
