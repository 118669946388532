import { Capacitor } from '@capacitor/core';
import { SafeArea } from 'capacitor-plugin-safe-area';
import { useEffect, useState } from 'react';

export const useStatusBarHeight = () => {
  const [statusBarHeight, setStatusBarHeight] = useState(0);

  useEffect(() => {
    SafeArea.getStatusBarHeight().then(({ statusBarHeight: height }) => {
      if (Capacitor.getPlatform() === 'ios') setStatusBarHeight(height);
    });
  }, []);

  return statusBarHeight;
};
