import AltusPlus from 'components/altus-plus/altus-plus';
import { FaTimes } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import styles from './altus-plus.module.scss';

export default function SubscriptionBackHeader({ onBack }: { onBack?: VoidFunction }) {
  const navigate = useNavigate();

  return (
    <div className={styles.backHeader}>
      <div
        className={styles.backButton}
        onClick={() => {
          if (onBack) onBack();
          else navigate(-1);
        }}
      >
        <FaTimes />
      </div>
      <AltusPlus />
    </div>
  );
}
