import AltusTalk from 'components/altus-talk/altus-talk';
import BackHeader from 'components/back-header/back-header';
import Button from 'components/button/button';
import Flex from 'components/flex/flex';
import Page from 'components/page/page';
import styles from './android-request-permission.module.scss';

/* eslint-disable-next-line */
export interface AndroidRequestPermissionProps {
  text: string;
  requestPermission: () => void;
  childrenId: string;
  imageUrl?: string;
  stepNumber: number;
}

export function AndroidRequestPermission({
  text,
  requestPermission,
  childrenId,
  imageUrl,
  stepNumber,
}: AndroidRequestPermissionProps) {
  return (
    <Page>
      <div className={styles.grid}>
        <Flex
          direction="column"
          width="100%"
          height="100%"
          align="center"
          justify="space-between"
          style={{ paddingTop: 100, overflowY: 'auto' }}
          gap={8}
          className={styles.test}
        >
          <BackHeader title="Demande de permission" href={`/blocker/${childrenId}`} />
          <div className={styles.title}>Étape {stepNumber}</div>
          <AltusTalk width={80}>{text}</AltusTalk>

          {imageUrl && (
            <>
              <div className={styles.text}>
                Comment faire ?
                <br />
                Suivez ces étapes :
              </div>
              <img src={imageUrl} alt="Instructions" height="350" />
            </>
          )}
        </Flex>
        <Flex direction="column" pad={10} width="100%" gap={12}>
          <Button color="primary" onClick={requestPermission}>
            AUTORISER ALTUS
          </Button>
        </Flex>
      </div>
    </Page>
  );
}

export default AndroidRequestPermission;
