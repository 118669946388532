import { Preferences } from '@capacitor/preferences';
import { onlineManager, useQueryClient } from '@tanstack/react-query';
import { createContext, useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { getChapters, getClassLevels, getFields } from 'services/chapters';
import { getChildrenNewExercisesChart } from 'services/chart';
import { getChildren, getChildrenSessionResults, getChildrenWorkedChapters, getDailyUsages } from 'services/childrens';
import { getProfile } from 'services/profile';
import { getMySubscription } from 'services/subscription';

interface OnlineContextProps {
  isOnline: boolean;
}

function getPrefetchQueries(childId: string): {
  queryKey: any[];
  queryFn: VoidFunction;
  staleTime?: number;
  gcTime?: number;
}[] {
  if (!childId || childId === 'null') return [];
  return [
    {
      queryKey: ['getNewExercisesChart', childId],
      queryFn: () => getChildrenNewExercisesChart(childId),
    },
    {
      queryKey: ['chapters'],
      queryFn: getChapters,
      staleTime: 24 * 3600000 * 21,
      gcTime: 24 * 3600000 * 21,
    },
    {
      queryKey: ['fields'],
      queryFn: getFields,
      staleTime: 24 * 3600000 * 21,
      gcTime: 24 * 3600000 * 21,
    },
    {
      queryKey: ['children', childId],
      queryFn: () => getChildren(childId),
    },
    {
      queryKey: ['results', childId],
      queryFn: () => getChildrenSessionResults(childId),
    },
    {
      queryKey: ['dailyUsages', childId],
      queryFn: () => getDailyUsages(+childId),
    },
    {
      queryKey: ['cycles'],
      queryFn: getClassLevels,
      staleTime: 24 * 3600000 * 21,
      gcTime: 24 * 3600000 * 21,
    },
    {
      queryKey: ['workedChapters', childId],
      queryFn: () => getChildrenWorkedChapters(childId),
    },
    { queryKey: ['getProfile'], queryFn: getProfile },
    { queryKey: ['getMySubscription'], queryFn: getMySubscription, staleTime: 3600000 },
  ];
}

const OnlineContext = createContext<OnlineContextProps>({
  isOnline: true,
});

interface OnlineProviderProps {
  children: React.ReactNode;
}

export default function OnlineProvider({ children }: OnlineProviderProps) {
  const [isOnline, setIsOnline] = useState<boolean>(true);
  const [initial, setInitial] = useState<boolean>(true);
  useEffect(() => {
    const unsubscribe = onlineManager.subscribe((online) => {
      setIsOnline(online);
    });

    return unsubscribe;
  }, []);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (initial) {
      setInitial(false);
      setTimeout(() => {
        if (onlineManager.isOnline() !== isOnline) setIsOnline(!isOnline);
      }, 1000);
    } else if (isOnline) {
      toast.success('Connecté !', {
        icon: '🛜',
      });
    } else {
      toast.success('Déconnecté...', {
        icon: '✈️',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnline]);

  useEffect(() => {
    if (isOnline && !initial)
      Preferences.get({ key: 'child_mode' }).then((value) => {
        if (value.value)
          getPrefetchQueries(value.value).forEach((query) => {
            queryClient.prefetchQuery({
              queryKey: query.queryKey,
              queryFn: query.queryFn as VoidFunction,
              staleTime: query.staleTime,
              gcTime: query.gcTime,
            });
          });
      });
  }, [queryClient, isOnline, initial]);

  return (
    <OnlineContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        isOnline,
      }}
    >
      {children}
    </OnlineContext.Provider>
  );
}

export function useOnlineContext() {
  return useContext(OnlineContext);
}

export { OnlineProvider };
