import { Preferences } from '@capacitor/preferences';
import OnboardingChoice from 'components/onboarding-choice/onboarding-choice';

/* eslint-disable-next-line */
export interface OnboardingChoiceDeviceProps {}

export function OnboardingChoiceDevice(props: OnboardingChoiceDeviceProps) {
  return (
    <OnboardingChoice
      title="À qui appartient cet appareil ?"
      backHref='/'
      choices={[
        {
          altusType: 'parent_phone',
          title: 'L’appareil parent',
          link: '/onboarding/choice/login-register',
          onClick: () => {
            Preferences.set({
              key: 'phone_owner',
              value: 'parent',
            });
          }
        },
        {
          altusType: 'child_phone',
          title: 'Celui que je souhaite protéger',
          link: '/onboarding/choice/installed',
          onClick: () => {
            Preferences.set({
              key: 'phone_owner',
              value: 'child',
            });
          }
        },
      ]}
    />
  );
}

export default OnboardingChoiceDevice;
