import styles from './loader.module.scss';

/* eslint-disable-next-line */
export interface LoaderProps {
  size?: number;
  className?: string;
}

export function Loader({ size, className }: LoaderProps) {
  return (
    <div
      className={`${styles['loader']} ${className ?? ''}`}
      style={{ width: size, height: size, borderWidth: size ? Math.max(1, size / 10) : undefined }}
    />
  );
}

export default Loader;
