import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { CommonStudent, CommonWorkedChapter } from '@codidae/common-types';
import { useQueries } from '@tanstack/react-query';
import chevronLeft from 'assets/icons/back-chevron-left.svg';
import AltusTalk from 'components/altus-talk/altus-talk';
import ChildrenSelectField from 'components/children-select-field/children-select-field';
import ChildrenWorkedChapter from 'components/children-worked-chapter/children-worked-chapter';
import LoadingScreen from 'components/loading-screen/loading-screen';
import PaywallText from 'components/paywall-text/paywall-text';
import { useUserContext } from 'contexts/user-context';
import { useContext, useEffect, useState } from 'react';
import { getChapters } from 'services/chapters';
import { getChildrenWorkedChapters } from 'services/childrens';
import { getColorFromFieldId } from 'utils/colors';
import { ChildrenHeaderContext } from 'views/children/dashboard';
import styles from './children-worked-chapters.module.scss';

/* eslint-disable-next-line */
export interface ChildrenWorkedChaptersProps {
  children: CommonStudent;
}

export function ChildrenWorkedChapters({ children }: ChildrenWorkedChaptersProps) {
  const { getSubscriptionStatus } = useUserContext();
  const [selectedField, setSelectedField] = useState<number | null>(null);
  const headerContext = useContext(ChildrenHeaderContext);
  const [
    { data: dataWorkedChapters, isLoading: isLoadingWorkedChapters, refetch: refetchWorkedChapters },
    { data: dataChapters },
  ] = useQueries({
    queries: [
      {
        queryKey: ['workedChapters', children.id],
        queryFn: () => getChildrenWorkedChapters(children.id),
      },
      {
        queryKey: ['chapters'],
        queryFn: () => getChapters(),
        staleTime: 24 * 3600000 * 21,
        gcTime: 24 * 3600000 * 21,
      },
    ],
  });
  const [open, setOpen] = useState<string>('');

  useEffect(
    () => () => {
      headerContext.setColor(null);
    },
    [headerContext],
  );

  useEffect(() => {
    refetchWorkedChapters();
    setOpen('');
  }, [children.id, refetchWorkedChapters]);

  useEffect(() => {
    const wrapper = document.querySelector(`.${styles.wrapper}`);
    const notWorked = document.querySelector(`.${styles.notWorked}`);
    if (!wrapper || !notWorked) return;

    console.log(notWorked.getBoundingClientRect().top);

    wrapper.scrollTo({
      top: notWorked.getBoundingClientRect().top - window.innerHeight + 120,
    });
  }, [dataWorkedChapters]);

  if (isLoadingWorkedChapters || !dataWorkedChapters) {
    return <LoadingScreen />;
  }

  const cycleChapters =
    dataChapters
      ?.filter((chapter) => chapter.cycles.find((cycle) => cycle.id === children.cycle.id))
      .filter((chapter) => chapter.field.id === selectedField)
      .filter((chapter) => !dataWorkedChapters.find((workedChapter) => workedChapter.chapter.id === chapter.id)) || [];

  const groupedByMonth = dataWorkedChapters
    ?.filter((c) => c.chapter.field.id === selectedField)
    .reduce((acc, chapter) => {
      const month = `${new Date(chapter.createdAt).getMonth()}/${new Date(chapter.createdAt).getFullYear()}`;

      if (!acc[month]) {
        acc[month] = [];
      }

      acc[month].push(chapter);

      return acc;
    }, {});

  const getMonthName = (date) => {
    date = new Date(date);
    const today = new Date();

    if (date.getMonth() === today.getMonth()) {
      return 'Chapitres travaillés ce mois-ci';
    }
    const monthNames = [
      'Janvier',
      'Février',
      'Mars',
      'Avril',
      'Mai',
      'Juin',
      'Juillet',
      'Août',
      'Septembre',
      'Octobre',
      'Novembre',
      'Décembre',
    ];
    return `${monthNames[date.getMonth()]} ${date.getFullYear()}`;
  };

  if (dataWorkedChapters.length === 0) {
    return (
      <div style={{ marginTop: 80, paddingTop: 60 }}>
        <AltusTalk type="hi">
          Salut ! Je suis Altus, ton assistant personnel. Je suis là pour t&apos;aider à progresser.
          <br />
          <br />
          Réalise un premier quiz pour débloquer tes applications
        </AltusTalk>
      </div>
    );
  }
  const allFields = dataChapters
    ?.filter((chapter) => chapter.cycles.find((cycle) => cycle.id === children.cycle.id))
    .map((chapter) => chapter.field)
    ?.filter((field, index, self) => self.findIndex((f) => f.id === field.id) === index);
  if (!selectedField) {
    return (
      <ChildrenSelectField
        fields={allFields}
        onSelect={(fieldId) => {
          setSelectedField(fieldId);
          headerContext.setColor(getColorFromFieldId(fieldId));
        }}
        workedChapters={dataWorkedChapters}
        chapters={dataChapters?.filter((chapter) => chapter.cycles.find((cycle) => cycle.id === children.cycle.id))}
      />
    );
  }
  return (
    <>
      <div
        className={styles.fieldTitle}
        style={{
          backgroundColor: getColorFromFieldId(selectedField),
          boxShadow: `0px 3px 0 0 ${getColorFromFieldId(selectedField)}80`,
        }}
      >
        <button
          onTouchStart={() => {
            Haptics.impact({ style: ImpactStyle.Light });
          }}
          onTouchEnd={() => {
            Haptics.impact({ style: ImpactStyle.Light });
          }}
          type="button"
          onClick={() => {
            setSelectedField(null);
            headerContext.setColor(null);
          }}
        >
          <img src={chevronLeft} alt="Back" />
        </button>
        <div className={styles.title}>{allFields.find((field) => field.id === selectedField)?.name}</div>
        <div className={styles.space} />
      </div>
      <div
        className={`${styles.wrapper} ${getSubscriptionStatus(children.id) === 'freemium' ? styles.nonScrollable : ''}`}
        onClick={() => setOpen('')}
      >
        {getSubscriptionStatus(children.id) === 'freemium' && <PaywallText />}

        {Object.values(groupedByMonth)
          ?.sort((a, b) => (new Date(a[0].createdAt) > new Date(b[0].createdAt) ? 1 : -1))
          .map((monthData, monthIndex) => (
            <div key={monthIndex}>
              <div
                className={styles.monthTitle}
                style={{
                  backgroundColor: getColorFromFieldId(selectedField),
                }}
              >
                {getMonthName(monthData[0].createdAt)}
              </div>
              <div className={styles.childrenWorkedChapters}>
                {(monthData as CommonWorkedChapter[])
                  ?.sort((a, b) => (new Date(a.createdAt) > new Date(b.createdAt) ? 1 : -1))
                  .map((chapter, index) => (
                    <ChildrenWorkedChapter
                      key={chapter.id}
                      index={index}
                      chapter={chapter}
                      color={getColorFromFieldId(selectedField)}
                      childrenId={children.id}
                      open={open === `${index}-${monthIndex}`}
                      openPopup={() => setOpen(`${index}-${monthIndex}` === open ? '' : `${index}-${monthIndex}`)}
                    />
                  ))}
              </div>
            </div>
          ))}
        {cycleChapters.length !== 0 && (
          <>
            <div
              className={`${styles.monthTitle} ${styles.notWorked}`}
              style={{
                backgroundColor: getColorFromFieldId(selectedField),
              }}
            >
              Chapitres non travaillés
            </div>
            <div className={styles.childrenWorkedChapters}>
              {cycleChapters?.map((chapter, index) => (
                <ChildrenWorkedChapter
                  key={chapter.id}
                  index={index}
                  childrenId={children.id}
                  color={getColorFromFieldId(selectedField)}
                  chapter={{
                    chapter,
                    elo: 0,
                    createdAt: new Date(),
                    id: 0,
                    tryCount: 0,
                    updatedAt: new Date(),
                  }}
                  notWorked
                  open={open === `cycle-${index}`}
                  openPopup={() => setOpen(`cycle-${index}` === open ? '' : `cycle-${index}`)}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default ChildrenWorkedChapters;
