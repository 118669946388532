import { CommonStudent } from '@codidae/common-types';
import AddNewButton from 'components/add-new-button/add-new-button';
import Button from 'components/button/button';
import ChildrenSelectionIcon from 'components/children-selection-icon/children-selection-icon';
import Flex from 'components/flex/flex';
import config from 'config';
import { useUserContext } from 'contexts/user-context';
import { useNavigate } from 'react-router-dom';
import styles from './children-selection.module.scss';

/* eslint-disable-next-line */
export interface ChildrenSelectionProps {
  childrens: CommonStudent[];
  maxChildren: number;
}

export function ChildrenSelection({ childrens, maxChildren }: ChildrenSelectionProps) {
  const navigate = useNavigate();
  const { userSubscriptionStatus, subscriptionChildCount } = useUserContext();

  return (
    <Flex className={styles.childrenSelection} direction="column" align="center" justify="space-between" height="100%">
      <div className={styles.title}>À quel profil souhaitez vous accéder ?</div>
      <Flex
        gap={20}
        pad={20}
        align="center"
        justify="center"
        wrap="wrap"
        width="100%"
        style={{
          overflowY: 'auto',
          marginTop: 20,
        }}
      >
        {childrens?.map((child, index) => (
          <ChildrenSelectionIcon key={child.id} child={child} disabled={index >= config.max_children_per_account} />
        ))}
      </Flex>
      <Flex className={styles.bottom} direction="column" gap={20} align="center" justify="center" width="100%" pad={20}>
        {childrens?.length < config.max_children_per_account && (
          <AddNewButton text="Ajouter un enfant" onClick={() => navigate('/children/create')} />
        )}
        {childrens?.length >= config.max_children_per_account && (
          <div className={styles.maxChildren}>
            {childrens?.length} enfants sur {config.max_children_per_account} places ajoutés
          </div>
        )}
        {userSubscriptionStatus === 'premium' && childrens?.length > subscriptionChildCount && (
          <Button color="dark" outline href="/settings/subscription">
            {`GÉRER L'ABONNEMENT`}
          </Button>
        )}
        <Button color="dark" outline href="/settings">
          PARAMÈTRES DU COMPTE
        </Button>
      </Flex>
    </Flex>
  );
}

export default ChildrenSelection;
