import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';
import authLocation from 'assets/android-helpers/auth_location.png';
import authSuperpositionGif from 'assets/android-helpers/auth_superposition.gif';
import authUsageGif from 'assets/android-helpers/auth_usage.gif';
import AltusTalk from 'components/altus-talk/altus-talk';
import AndroidApplicationList from 'components/android-application-list/android-application-list';
import AndroidRequestPermission from 'components/android-request-permission/android-request-permission';
import BackHeader from 'components/back-header/back-header';
import Button from 'components/button/button';
import Flex from 'components/flex/flex';
import LoadingScreen from 'components/loading-screen/loading-screen';
import Page from 'components/page/page';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SegmentEventType, track } from 'segment';
import BlockerPlugin from 'utils/BlockerPlugin';

/* eslint-disable-next-line */
export interface BlockerProps {}

export interface Application {
  name: string;
  package: string;
  icon: string;
  category?: number;
}

export function getApplicationCategoryTitle(category?: number): string {
  switch (category) {
    case 0:
      return 'Jeux vidéo';
    case 1:
      return 'Musique et audio';
    case 2:
      return 'Lecteurs vidéo';
    case 3:
      return 'Images';
    case 4:
      return 'Réseaux sociaux / Navigateurs';
    case 5:
      return 'Actualités';
    case 6:
      return 'Cartes et navigation';
    case 7:
      return 'Productivité';
    case 8:
      return 'Accessibilité';
    default:
      return 'Autres';
  }
}

export function BlockerAndroid(props: BlockerProps) {
  const [applicationList, setApplicationList] = useState<Application[]>();
  const [blockedApplications, setBlockedApplications] = useState<Application[]>();
  const [usageStatsPermission, setUsageStatsPermission] = useState<boolean>();
  // const [autoStartPermission, setAutoStartPermission] =
  //   useState<boolean>();
  const [drawOverlayPermission, setDrawOverlayPermission] = useState<boolean>();
  const [gpsPermission, setGpsPermission] = useState<boolean>();
  const { id } = useParams();
  const navigate = useNavigate();

  const [askedForGps, setAskedForGps] = useState(false);

  useEffect(() => {
    const checkPermissions = () => {
      BlockerPlugin.checkUsageStatsPermission().then((res) => {
        setUsageStatsPermission(res.isGranted);
      });
      BlockerPlugin.checkDrawOverlayPermission().then((res) => {
        setDrawOverlayPermission(res.isGranted);
      });
      if (askedForGps) {
        setGpsPermission(true);
      } else {
        BlockerPlugin.checkLocationPermission().then((res) => {
          setGpsPermission(res.isGranted);
        });
      }
    };

    track(SegmentEventType.BLOCKER_CONFIGURATION_STARTED, {
      children_id: id,
    });
    BlockerPlugin.getApplicationList().then((res) => {
      setApplicationList(res.applications);
      Preferences.get({ key: 'blocked_applications' })
        .then((blocked) => {
          if (blocked.value) {
            const apps = JSON.parse(blocked.value);
            setBlockedApplications(res.applications.filter((app) => apps.includes(app.package)));
          } else {
            setBlockedApplications([]);
          }
        })
        .catch(() => {
          setBlockedApplications([]);
        });
    });
    checkPermissions();
    if (Capacitor.getPlatform() !== 'android') {
      // setApplicationList(SIMULATED_APPLICATIONS);
      // setDrawOverlayPermission(true);
      // setUsageStatsPermission(true);
      // setBlockedApplications([]);
    }

    const listener = App.addListener('appStateChange', (event) => {
      checkPermissions();
    });

    return () => {
      listener.remove();
    };
  }, [askedForGps, id]);

  const setPhoneAsConfigured = async () => {
    await Preferences.set({
      key: 'blockerConfigured',
      value: 'true',
    });
    track(SegmentEventType.BLOCKER_CONFIGURATION_COMPLETED, {
      children_id: id,
    });
  };
  const [search, setSearch] = useState<string>('');
  const [selectedCategory, setSelectedCategory] = useState<number>();

  if (!applicationList) {
    return (
      <LoadingScreen altus="animated_hi" text="Nous récupérons la liste de tes applications... cela peut être long !" />
    );
  }
  if (!usageStatsPermission) {
    return (
      <AndroidRequestPermission
        childrenId={id}
        text="J'ai besoin de la permission pour pouvoir bloquer les applications"
        requestPermission={() => {
          BlockerPlugin.requestUsageStatsPermission();
        }}
        imageUrl={authUsageGif}
        stepNumber={1}
      />
    );
  }
  if (!drawOverlayPermission) {
    return (
      <AndroidRequestPermission
        childrenId={id}
        text="J'ai besoin de la permission pour pouvoir afficher le bloqueur"
        requestPermission={() => {
          BlockerPlugin.requestDrawOverlayPermission();
        }}
        imageUrl={authSuperpositionGif}
        stepNumber={2}
      />
    );
  }
  if (!gpsPermission) {
    return (
      <AndroidRequestPermission
        childrenId={id}
        text="J'ai besoin d'accéder aux données GPS en tâche de fond pour vous permettre de suivre la géolocalisation de votre enfant depuis l'espace parent."
        requestPermission={() => {
          BlockerPlugin.askForLocationPermission();
          setAskedForGps(true);
        }}
        imageUrl={authLocation}
        stepNumber={3}
      />
    );
  }
  return (
    <Page>
      <Flex
        direction="column"
        justify="space-between"
        align="center"
        width="100%"
        height="100%"
        style={{ paddingTop: 65, maxWidth: '100%' }}
      >
        <BackHeader
          title="Paramétrage du bloqueur"
          onClick={() => {
            if (search.length || selectedCategory !== undefined) {
              setSearch('');
              setSelectedCategory(undefined);
              return;
            }
            navigate(`/blocker/${id}`);
          }}
        />
        <Flex
          direction="column"
          style={{
            paddingTop: 20,
            overflowY: 'auto',
          }}
        >
          <Flex pad="10px 20px">
            <AltusTalk variant="small">
              Choisis les applications que tu veux bloquer sur cet appareil, puis cliques sur suivant !
            </AltusTalk>
          </Flex>
          {applicationList && blockedApplications && (
            <AndroidApplicationList
              applications={applicationList}
              setBlockedApplications={setBlockedApplications}
              blockedApplications={blockedApplications}
              search={search}
              setSearch={setSearch}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
            />
          )}
        </Flex>
        <Flex
          direction="column"
          gap={10}
          pad={20}
          width="100%"
          style={{
            position: 'fixed',
            bottom: 0,
            left: 0,
          }}
        >
          <Button
            disabled={!blockedApplications}
            color="primary"
            onClick={() => {
              Preferences.set({
                key: 'blocked_applications',
                value: JSON.stringify(blockedApplications.map((app) => app.package)),
              }).then(() => {
                setPhoneAsConfigured().then(() => {
                  navigate(`/blocker/${id}`);
                });
              });
            }}
          >
            BLOQUER
          </Button>
        </Flex>
      </Flex>
    </Page>
  );
}

export default BlockerAndroid;
