import styles from './subscription-button.module.scss';

/* eslint-disable-next-line */
export interface SubscriptionButtonProps {
  count: string;
  minPrice: number;
  background: string;
  onClick: () => void;
  backgroundHeight: number;
  disabled?: boolean;
}

export function SubscriptionButton({
  background,
  backgroundHeight,
  count,
  minPrice,
  onClick,
  disabled,
}: SubscriptionButtonProps) {
  return (
    <button
      type="button"
      className={styles.subscriptionButton}
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: `auto ${backgroundHeight}px`,
      }}
      onClick={onClick}
      disabled={disabled}
    >
      <div className={styles.count}>{count}</div>
      <div className={styles.from}>À partir de</div>
      <div className={styles.price}>{minPrice} €</div>
      <div className={styles.perMonth}>/ mois</div>
    </button>
  );
}

export default SubscriptionButton;
