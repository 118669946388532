import heartIcon from 'assets/icons/heart.svg';
import styles from './session-lose-life.module.scss';

/* eslint-disable-next-line */
export interface SessionLoseLifeProps {}

export function SessionLoseLife(props: SessionLoseLifeProps) {
  return (
    <div className={styles.sessionLoseLife}>
      <img src={heartIcon} alt="heart" />
      <div className={styles.text}>
        -1
      </div>
    </div>
  );
}

export default SessionLoseLife;
