import { CommonChapter, CommonSkillField, CommonWorkedChapter } from '@codidae/common-types';
import Altus from 'components/altus/altus';
import Flex from 'components/flex/flex';
import Loader from 'components/loader/loader';
import { useCallback, useMemo } from 'react';
import { getColorFromFieldId } from 'utils/colors';
import { getIconFromFieldId } from 'utils/fieldIcons';
import styles from './children-select-field.module.scss';

/* eslint-disable-next-line */
export interface ChildrenSelectFieldProps {
  fields: CommonSkillField[];
  workedChapters: CommonWorkedChapter[];
  chapters: CommonChapter[];
  onSelect: (fieldId: number) => void;
}

const Field = ({
  field,
  onSelect,
  workedChapters,
  chapters,
}: {
  field: CommonSkillField;
  onSelect: (fieldId: number) => void;
  workedChapters: CommonWorkedChapter[];
  chapters: CommonChapter[];
}) => {
  const computePercentage = (fieldId: number) => {
    const percent =
      (workedChapters.filter(
        (c) => c.chapter.field.id === fieldId && chapters.find((chapter) => chapter.id === c.chapter.id),
      ).length /
        chapters.filter((c) => c.field.id === fieldId).length) *
      100;

    return percent > 100 ? 100 : percent.toFixed(0);
  };

  return (
    <button
      type="button"
      key={field.id}
      className={styles.fieldButton}
      onClick={() => onSelect(field.id)}
      style={{
        backgroundColor: getColorFromFieldId(field.id),
        boxShadow: `0px 4px 0px  0px ${getColorFromFieldId(field.id)}44`,
      }}
    >
      <div className={styles.fieldIcon}>{getIconFromFieldId(field.id)}</div>
      <div className={styles.fieldName}>{field.name}</div>
      <div className={styles.fieldPercentage}>{computePercentage(field.id)}%</div>
    </button>
  );
};

export function ChildrenSelectField({ chapters, fields, onSelect, workedChapters }: ChildrenSelectFieldProps) {
  const computeFieldScore = useCallback(
    (fieldId: number) => {
      const wc = workedChapters.filter(
        (c) => c.chapter.field.id === fieldId && chapters.find((chapter) => chapter.id === c.chapter.id),
      );
      const tries = wc.reduce((acc, chapter) => acc + chapter.tryCount, 0);
      const averageElo = wc.reduce((acc, chapter) => acc + chapter.elo, 0) / wc.length;

      return tries * (averageElo - 400);
    },
    [chapters, workedChapters],
  );

  const sortedFields = useMemo(
    () =>
      fields?.sort((a, b) => {
        const aScore = computeFieldScore(a.id);
        const bScore = computeFieldScore(b.id);
        return aScore > bScore ? -1 : 1;
      }),
    [computeFieldScore, fields],
  );

  const hasBestField = computeFieldScore(sortedFields?.[0]?.id) > 0;
  const worstFieldsCount = useMemo(() => {
    if (!sortedFields?.length) return 0;
    let count = 0;
    // eslint-disable-next-line no-restricted-syntax
    for (const field of sortedFields) {
      if (computeFieldScore(field.id) < 0) {
        count += 1;
      } else {
        break;
      }
    }
    return count > 3 ? 3 : count;
  }, [computeFieldScore, sortedFields]);

  const bestField = hasBestField ? sortedFields?.reverse().pop() : null;
  const worstFields = sortedFields?.length < 3 ? [] : sortedFields?.splice(0, worstFieldsCount);
  const otherFields = sortedFields;

  return (
    <Flex
      pad={20}
      direction="column"
      gap={20}
      width="100%"
      height="100%"
      style={{ overflowY: 'auto', paddingTop: 100, paddingBottom: 120 }}
    >
      {!sortedFields ? (
        <Flex justify="center" align="center" height="100%" width="100%">
          <Loader />
        </Flex>
      ) : (
        <Flex direction="column" gap={15} width="100%">
          {bestField && (
            <Flex justify="space-between" align="center" className={styles.subtitleWrapper} style={{ width: '100%' }}>
              <div className={styles.subtitle}>Ta meilleure matière</div>
              <Altus type="confeti" width={70} />
            </Flex>
          )}
          {bestField && (
            <Field field={bestField} onSelect={onSelect} workedChapters={workedChapters} chapters={chapters} />
          )}

          {!!worstFields?.length && <div className={styles.subtitle}>Tes matières en difficulté</div>}
          {worstFields?.map((field) => (
            <Field
              key={field.id}
              field={field}
              onSelect={onSelect}
              workedChapters={workedChapters}
              chapters={chapters}
            />
          ))}
          {!!otherFields?.length && <div className={styles.subtitle}>Tes autres matières</div>}
          {otherFields?.map((field) => (
            <Field
              key={field.id}
              field={field}
              onSelect={onSelect}
              workedChapters={workedChapters}
              chapters={chapters}
            />
          ))}
        </Flex>
      )}
    </Flex>
  );
}

export default ChildrenSelectField;
