import styles from './tabs.module.scss';

/* eslint-disable-next-line */
export interface TabsProps {
  currentTab: string;
  tabs: { title: string; slug: string }[];
  changeTab: (slug: string) => void;
}

export function Tabs({ currentTab, tabs, changeTab }: TabsProps) {
  return (
    <div className={styles.tabs}>
      {tabs.map((tab) => (
        <div
          key={tab.slug}
          className={`${styles.tab} ${
            currentTab === tab.slug ? styles.active : ''
          }`}
          onClick={() => changeTab(tab.slug)}
        >
          {tab.title}
        </div>
      ))}
    </div>
  );
}

export default Tabs;
