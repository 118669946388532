/* eslint-disable-next-line */
export interface GridProps {
  columns?: number;
  rows?: number;
  gap?: number;
  pad?: number;
  children: React.ReactNode;
  width?: string;
  className?: string;
}

export function Grid({
  children,
  className,
  columns,
  gap,
  pad,
  rows,
  width,
}: GridProps) {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: `repeat(${columns ?? 1}, 1fr)`,
        gridTemplateRows: `repeat(${rows ?? 1}, 1fr)`,
        gap: gap || 0,
        padding: pad || 0,
        width: width || 'auto',
      }}
      className={`${className ?? ''}`}
    >
      {children}
    </div>
  );
}

export default Grid;
