import { AnalyticsBrowser } from '@segment/analytics-next';
import config from 'config';

export const analytics = AnalyticsBrowser.load({
  writeKey: process.env.REACT_APP_SEGMENT_WRITE_KEY,
});

export const identify = (
  email: string,
  phone?: string,
  childId?: number | null,
  premiumAccount?: boolean,
  premiumChild?: boolean | null,
) =>
  analytics.identify(email, {
    email,
    phone,
    childId,
    premiumAccount,
    premiumChild,
  });

export enum SegmentEventType {
  PREREGISTER_STARTED = 'Preregister started',
  PREREGISTER_COMPLETED = 'Preregister completed',
  PREREGISTER_ERROR = 'Preregister error',
  REGISTERED = 'Registered',
  LOGIN = 'Logged in',
  LOGIN_WITH_QR_CODE = 'Logged in with QR code',
  LOGIN_WITH_PAIR_CODE = 'Logged in with pair code',
  LOGOUT = 'Logged out',
  FORGOT_PASSWORD = 'Forgot password',
  RESET_PASSWORD = 'Reset password mail sent',
  CHILD_CREATED = 'Children created',
  ONBOARDING_COMPLETED = 'Onboarding completed',
  ONBOARDING_STARTED = 'Onboarding started',
  ONBOARDING_STEP = 'Onboarding step',
  BLOCKER_CONFIGURATION_STARTED = 'Blocker configuration started',
  BLOCKER_CONFIGURATION_COMPLETED = 'Blocker configuration completed',
  DASHBOARD_ONBOARDING_STEP = 'Dashboard onboarding step',
  DASHBOARD_TAB = 'Dashboard tab',
  SWITCH_CHILD_MODE = 'Switch child mode',
  SWITCH_PARENT_MODE = 'Switch parent mode',
  START_QUIZ = 'Start quiz',
  START_QUIZ_FROM_CHAPTER = 'Start quiz from chapter',
  ANSWER_QUESTION = 'Answer question',
  SELECT_FIELD = 'Select field',
  REMOVE_FIELD = 'Remove field',
  SELECT_CHAPTER = 'Select chapter',
  REMOVE_CHAPTER = 'Remove chapter',
  CLICK_CHILD_PAYWALL = 'Click child paywall',
  CLICK_PARENT_STATS_PAYWALL = 'Click parent stats paywall',
  CLICK_SUBSCRIBE_BANNER = 'Click subscribe banner',
  RANDOM_PAYWALL_REDIRECTION = 'Random redirect paywall',
  PAYMENT_ARGUMENTS_INITIAL = 'Click subscribe initial page',
  PAYMENT_ARGUMENTS_1 = 'Click subscribe page 1',
  PAYMENT_ARGUMENTS_2 = 'Click subscribe page 2',
  PAYMENT_ARGUMENTS_FINAL = 'Click subscribe final page',
  SUBSCRIBE_CHILDREN_COUNT = 'Click X child offer',
  EXIT_PAYMENT_FLOW = 'Exit payment flow',
  PURCHASE_SUBSCRIPTION = 'Purchase subscription',
  CANCEL_PURCHASE_SUBSCRIPTION = 'Cancel purchase subscription',
  START_HARDEST_CHILDREN_QUIZ = 'Start hardest children quiz',
  GOTO_LOCATION_PAGE = 'Go to location page',
  REVEAL_ANSWER = 'Reveal answer',
}

export const track = (event: SegmentEventType, properties?: object) =>
  analytics.track(event, { ...properties, version: config.version });

export const trackOnboardingStep = (step: number) => analytics.track(`${SegmentEventType.ONBOARDING_STEP} ${step}`);

export const page = (path: string) => analytics.page(path);
