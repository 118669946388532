import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';
import { PushNotifications, Token } from '@capacitor/push-notifications';
import {
  registerChildrenPushToken,
  registerParentPushToken,
} from 'services/profile';
import { FCM } from '@capacitor-community/fcm';

export const registerPushNotifications = async (childrenId: number) => {
  if (Capacitor.getPlatform() === 'web') {
    return;
  }
  PushNotifications.checkPermissions().then((res) => {
    if (res.receive !== 'granted') {
      PushNotifications.requestPermissions().then((r) => {
        if (r.receive !== 'denied') {
          register(childrenId);
        }
      });
    } else {
      register(childrenId);
    }
  });
};

const register = (childrenId: number) => {
  PushNotifications.register();

  PushNotifications.addListener('registration', async (token: Token) => {
    const phoneOwner = await Preferences.get({ key: 'phone_owner' });
    let fcmToken = token.value;

    if (Capacitor.getPlatform() === 'ios') {
      fcmToken = await FCM.getToken().then((res) => res.token);
    }

    if (phoneOwner?.value === 'child') {
      await registerChildrenPushToken(childrenId, fcmToken);
    } else if (phoneOwner?.value === 'parent') {
      await registerParentPushToken(fcmToken);
    }
  });
};
