import { CommonSkillField } from '@codidae/common-types';
import { Radar } from 'react-chartjs-2';

/* eslint-disable-next-line */
export interface FieldsRadarChartProps {
  workedFields: (CommonSkillField & { elo: number })[];
}

export function FieldsRadarChart({ workedFields }: FieldsRadarChartProps) {
  return (
    <Radar
      data={{
        labels: workedFields?.map((field) => field.name),
        datasets: [
          {
            label: 'Users',
            data: workedFields?.map((field) => field.elo),
            backgroundColor: '#7554f7',
          },
        ],
      }}
      options={{
        plugins: {
          title: {
            display: false,
          },
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        scales: {
          r: {
            ticks: {
              display: false,
            },
          },
        },
      }}
    />
  );
}

export default FieldsRadarChart;
