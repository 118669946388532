import { useEffect, useState } from 'react';
import styles from './switch.module.scss';

/* eslint-disable-next-line */
export interface FlexProps {
  checked?: boolean;
  onChange?: (isChecked: boolean) => void;
}

export function Switch({ checked, onChange }: FlexProps) {
  const [localChecked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(checked);
  }, [checked]);

  return (
    <button
      type="button"
      className={`${styles.switch} ${localChecked ? styles.checked : ''}`}
      onClick={() => {
        setChecked(!localChecked);
        onChange?.(!localChecked);
      }}
    />
  );
}

export default Switch;
