import Grid from 'components/grid/grid';
import { useEffect, useState } from 'react';
import styles from './input-pin.module.scss';

/* eslint-disable-next-line */
export interface InputPinProps {
  onChange?: (value: string) => void;
  title?: string;
  id?: string;
}

const PIN_LENGTH = 6;

export function InputPin({ onChange, title, id }: InputPinProps) {
  const [pin, setPin] = useState<string[]>(
    Array.from({ length: PIN_LENGTH }).map(() => ''),
  );

  useEffect(() => {
    onChange?.(pin.join(''));
  }, [onChange, pin]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, i: number) => {
    const { value } = e.target;
    if (Number.isNaN(Number(value))) {
      e.target.value = '';
    }
    if (!Number.isNaN(Number(value)) && value.length <= 1) {
      const newPin = [...pin];
      newPin[i] = value;
      setPin(newPin);

      if (value.length === 1 && i < PIN_LENGTH - 1) {
        const nextInput = document.getElementById(
          `${id ? `${id}-` : ''}input-${i + 1}`,
        );
        nextInput?.focus();
      }
    }
  };

  return (
    <div className={styles.inputPin}>
      {title && <div className={styles.title}>{title}</div>}
      <Grid gap={10} columns={PIN_LENGTH} width="100%">
        {Array.from({ length: PIN_LENGTH }).map((_, i) => (
          <input
            key={i}
            className={styles.input}
            type="tel"
            inputMode="numeric"
            maxLength={1}
            pattern="[0-9]*"
            onChange={(e) => handleChange(e, i)}
            value={pin[i]}
            onPaste={(e) => {
              const pastedData = e.clipboardData
                .getData('text/plain')
                .split('');

              if (pastedData.length !== PIN_LENGTH) {
                return;
              }
              setPin(pastedData);
              const lastInput = document.getElementById(
                `${id ? `${id}-` : ''}input-${PIN_LENGTH - 1}`,
              );
              lastInput?.focus();
            }}
            onKeyDown={(e) => {
              if (e.key === 'Backspace') {
                const previousInput = document.getElementById(
                  `${id ? `${id}-` : ''}input-${i - 1}`,
                );
                const currentInput = document.getElementById(
                  `${id ? `${id}-` : ''}input-${i}`,
                ) as HTMLInputElement;
                if (currentInput && currentInput.value !== '') {
                  currentInput.value = '';
                } else if (previousInput) {
                  previousInput.focus();
                }
                setPin(pin.map((v, j) => (j === i ? '' : v)));
              }
            }}
            id={`${id ? `${id}-` : ''}input-${i}`}
          />
        ))}
      </Grid>
    </div>
  );
}

export default InputPin;
