import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';
import { useMutation } from '@tanstack/react-query';
import AltusTalk from 'components/altus-talk/altus-talk';
import Button from 'components/button/button';
import { useAndroidRedirectionContext } from 'contexts/android-redirection-context';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createSessionFromChildren } from 'services/session';
import styles from './android-redirection-popup.module.scss';

export default function AndroidRedirectionPopup() {
  const { redirected, resetRedirected } = useAndroidRedirectionContext();
  const [childMode, setChildMode] = useState(null);
  const navigate = useNavigate();
  const createSessionMutation = useMutation({
    mutationFn: createSessionFromChildren,
    onSuccess: (data) => {
      resetRedirected();
      navigate(`/children/session/${data.id}?unlock=true`);
    },
  });
  useEffect(() => {
    Preferences.get({ key: 'child_mode' }).then(({ value }) => {
      if (value && value !== 'null') setChildMode(value);
    });
  }, []);

  if (
    Capacitor.getPlatform() !== 'android' ||
    !redirected ||
    childMode === null
  )
    return null;
  return (
    <div className={styles.wrapper}>
      <div className={styles.background} onClick={resetRedirected} />

      <div className={styles.content}>
        <AltusTalk type="default">
          Il est l&apos;heure d&apos;apprendre !
          <br />
          Lance un quiz pour débloquer plus de temps sur tes applications
        </AltusTalk>
        <Button onClick={() => createSessionMutation.mutate(childMode)}>
          LANCER UN QUIZ
        </Button>
        <Button outline onClick={resetRedirected}>
          PEUT-ÊTRE PLUS TARD
        </Button>
      </div>
    </div>
  );
}
