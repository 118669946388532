import { Preferences } from '@capacitor/preferences';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { SegmentEventType, track } from 'segment';
import { loginWithToken } from 'services/auth';

/* eslint-disable-next-line */
export interface TokenAuthProps {}

export function TokenAuth(props: TokenAuthProps) {
  const navigate = useNavigate();
  const { token } = useParams();
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  const childrenId = searchParams.get('childrenId');
  const loginWithTokenMutation = useMutation({
    mutationFn: loginWithToken,
    onSuccess: async (data) => {
      track(SegmentEventType.LOGIN_WITH_QR_CODE, {
        email,
      });
      await Preferences.set({ key: 'phone_owner', value: 'child' });
      await Preferences.set({ key: 'access_token', value: data.access_token });
      if (childrenId) await Preferences.set({ key: 'child_mode', value: childrenId });
      navigate('/dashboard');
    },
    onError: () => {
      navigate('/');
      alert('Une erreur est survenue, veuillez réessayer');
    },
    retry: false,
  });
  const doneRef = useRef(false);

  useEffect(() => {
    if (token && email && !doneRef.current) {
      loginWithTokenMutation.mutate({ token, email });
      doneRef.current = true;
    }
  }, [email, loginWithTokenMutation, token]);

  return null;
}

export default TokenAuth;
