import { useMutation } from '@tanstack/react-query';
import AltusTalk from 'components/altus-talk/altus-talk';
import Button from 'components/button/button';
import Dialog from 'components/dialog/dialog';
import Flex from 'components/flex/flex';
import InputPin from 'components/input-pin/input-pin';
import Page from 'components/page/page';
import { useState } from 'react';
import { validatePinCode } from 'services/auth';
import { sendParentalCode } from 'services/childrens';
import styles from './enter-pin-view.module.scss';

/* eslint-disable-next-line */
export interface EnterPinViewProps {
  onCancel: () => void;
  onValidate: () => void;
}

export function EnterPinView({ onCancel, onValidate }: EnterPinViewProps) {
  const [pinCode, setPinCode] = useState<string>('');
  const validatePinCodeMutation = useMutation({
    mutationFn: validatePinCode,
    onSuccess: async (data) => {
      if (data.state) onValidate();
    },
    onError: async (error) => {
      onCancel();
    },
  });
  const sendParentalCodeMutation = useMutation({
    mutationFn: sendParentalCode,
    onSuccess: async (data) => {
      alert('Votre code parental a bien été envoyé par email');
      setDialog(false);
    },
    onError: async (error) => {
      alert('Une erreur est survenue');
      onCancel();
    },
  });
  const [dialog, setDialog] = useState(false);

  return (
    <Page className={styles.fixed}>
      <Flex
        direction="column"
        pad={20}
        width="100%"
        height="100%"
        align="center"
        justify="space-between"
        style={{ paddingBottom: 120 }}
      >
        <div />
        <AltusTalk type={validatePinCodeMutation.data?.state === false ? 'sad' : 'question'}>
          {validatePinCodeMutation.data?.state === false ? 'Code incorrect' : 'Entrez votre code parental'}
        </AltusTalk>
        <Flex direction="column" gap={10} width="100%">
          <InputPin title="Entrez votre code parental" onChange={(value) => setPinCode(value)} />
        </Flex>
        <Flex direction="column" gap={10} width="100%">
          <Button
            color="primary"
            disabled={pinCode.length !== 6}
            loading={validatePinCodeMutation.isPending}
            onClick={() => {
              validatePinCodeMutation.mutate({ pinCode });
            }}
          >
            VALIDER
          </Button>
          <Button color="primary" outline onClick={() => onCancel()}>
            ANNULER
          </Button>
          <button className={styles.forgot} onClick={() => setDialog(true)} type="button">
            Code parental oublié ?
          </button>
        </Flex>
      </Flex>
      <Dialog open={dialog} onClose={() => setDialog(false)}>
        <Flex direction="column" gap={15} width="100%" pad={10}>
          <AltusTalk variant="small">
            Vous avez oublié votre code parental ?<br />
            Pas de panique, cliquez ici pour le recevoir par email.
          </AltusTalk>
          <div />
          <Button color="primary" onClick={() => setDialog(false)} outline>
            ANNULER
          </Button>
          <Button
            color="primary"
            onClick={() => sendParentalCodeMutation.mutate()}
            loading={sendParentalCodeMutation.isPending}
          >
            RECEVOIR MON CODE
          </Button>
        </Flex>
      </Dialog>
    </Page>
  );
}

export default EnterPinView;
