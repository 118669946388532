import Page from 'components/page/page';
import OnboardingHeader from 'components/onboarding-header/onboarding-header';
import { useNavigate } from 'react-router-dom';
import OnboardingLayout from 'components/onboarding-layout/onboarding-layout';
import Altus from 'components/altus/altus';
import Flex from 'components/flex/flex';
import styles from './onboarding-qrcode.module.scss';

/* eslint-disable-next-line */
export interface OnboardingQRCodeProps {}

export function OnboardingQRCode(props: OnboardingQRCodeProps) {
  const navigate = useNavigate();
  return (
    <Page>
      <OnboardingHeader
        onClick={() => {
          navigate('/onboarding/choice/login-type', { replace: true });
        }}
      />
      <OnboardingLayout
        compact
        title={
          <b>Ouvrez l’appareil photo du téléphone</b>
        }
        canNext={false}
      >
        <Flex direction='column' align='flex-end' justify='space-between' gap={20} width='100%' height='100%'>
        <span />
        <div className={styles.qrCode}>
          <Altus type='qr_code_cutted' width="100%" />
        </div>
        <p className={styles.text}>*Pssst*: Le QR de votre compte se trouve dans vos réglages d’Altus</p>
        </Flex>
      </OnboardingLayout>
    </Page>
  );
}

export default OnboardingQRCode;
