import Altus, { AltusType } from 'components/altus/altus';
import styles from './altus-talk.module.scss';

export type AltusBackground = 'shine';

/* eslint-disable-next-line */
export interface AltusTalkProps {
  children?: React.ReactNode;
  background?: AltusBackground;
  type?: AltusType;
  backgroundColor?: string;
  width?: number;
  variant?: 'default' | 'small' | 'animated';
}

export function AltusTalk({ width = 150, variant = 'default', ...props }: AltusTalkProps) {
  return (
    <div className={`${styles.altusTalk} ${styles[variant]}`}>
      <div className={styles.message}>
        <div>{props.children}</div>
      </div>
      <Altus width={variant === 'small' ? 60 : width} type={props.type} backgroundColor={props.backgroundColor} />
    </div>
  );
}

export default AltusTalk;
