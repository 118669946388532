import Page from 'components/page/page';
import Flex from 'components/flex/flex';
import Button from 'components/button/button';
import Altus from 'components/altus/altus';
import EnterPinView from 'components/enter-pin-view/enter-pin-view';
import { Preferences } from '@capacitor/preferences';
import { useNavigate } from 'react-router-dom';
import { SegmentEventType, track } from 'segment';
import { useState } from 'react';

/* eslint-disable-next-line */
export interface LogoutRangePageProps {}

export function LogoutRangePage(props: LogoutRangePageProps) {
  const navigate = useNavigate();
  const [switchParentPopup, setSwitchParentPopup] = useState(false);
  if (switchParentPopup) {
    return (
      <EnterPinView
        onValidate={async () => {
          const childrenId = (await Preferences.get({ key: 'child_mode' }))
            .value;
          Preferences.set({ key: 'child_mode', value: null }).then(() => {
            navigate(`/children/${childrenId}`);
          });
          track(SegmentEventType.SWITCH_PARENT_MODE, {
            children_id: childrenId,
          });
        }}
        onCancel={() => setSwitchParentPopup(false)}
      />
    );
  }
  return (
    <Page>
      <Flex
        direction="column"
        justify="space-between"
        align="center"
        height="100%"
        width="100%"
        style={{
          backgroundColor: '#031D3D',
        }}
      >
        <div />
        <Altus type="asleep" />
        <div
          style={{
            color: 'white',
            fontSize: '24px',
            fontWeight: 'bold',
            textAlign: 'center',
            marginTop: '20px',
            opacity: '0.8',
          }}
        >
          Il est l&apos;heure de se déconnecter...
        </div>
        <Flex direction="column" pad={20} width="100%">
          <Button color="dark" onClick={() => setSwitchParentPopup(true)}>
            MODE PARENT
          </Button>
        </Flex>
      </Flex>
    </Page>
  );
}

export default LogoutRangePage;
