import { CommonStudent } from '@codidae/common-types';
import fire from 'assets/icons/fire.svg';
import offline from 'assets/icons/offline.svg';
import { useOnlineContext } from 'contexts/online-context';
import { useStatusBarHeight } from 'hooks/use-status-bar-height';
import { useContext } from 'react';
import { newShade } from 'utils/colors';
import { getLevelFromXp, getLevelProgression } from 'utils/gamification';
import { ChildrenHeaderContext } from 'views/children/dashboard';
import styles from './children-header.module.scss';

/* eslint-disable-next-line */
export interface ChildrenHeaderProps {
  children: CommonStudent;
}

export function ChildrenHeader({ children }: ChildrenHeaderProps) {
  const headerContext = useContext(ChildrenHeaderContext);
  const level = getLevelFromXp(children?.xp || 0);
  const progression = getLevelProgression(children?.xp || 0);
  const statusBarHeight = useStatusBarHeight();

  const { isOnline } = useOnlineContext();

  return (
    <div
      className={styles.childrenHeader}
      style={{
        paddingTop: 20 + statusBarHeight,
        backgroundColor: headerContext?.color ?? undefined,
        borderBottom: `2px solid ${newShade(headerContext?.color ?? '#55a4f7', -30)}`,
      }}
    >
      <div style={{ width: 30 }} className={styles.offline}>
        {!isOnline && <img src={offline} alt="offline" />}
      </div>
      <div className={styles.levelBar}>
        <div className={styles.level}>Niveau {level}</div>
        <div
          className={styles.progress}
          style={{
            backgroundColor: newShade(headerContext?.color ?? '#55a4f7', -40),
          }}
        >
          <div
            className={styles.progressValue}
            style={{
              width: `${progression * 100}%`,
            }}
          />
        </div>
      </div>
      <div className={styles.loginStreak}>
        <img src={fire} alt="fire" />
        <div className={styles.loginStreakText}>{children?.loginStreak}</div>
      </div>
    </div>
  );
}

export default ChildrenHeader;
