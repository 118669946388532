import InfoTooltip from 'components/info-tooltip/info-tooltip';
import Switch from 'components/switch/switch';
import styles from './labeled-switch.module.scss';

/* eslint-disable-next-line */
export interface LabeledSwitchProps {
  label: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  tooltipInfo?: React.ReactNode;
}

export function LabeledSwitch({ label, checked, onChange, tooltipInfo }: LabeledSwitchProps) {
  return (
    <div className={styles.labeledSwitch}>
      <span className={styles.label}>
        {label}
        {tooltipInfo && <InfoTooltip>{tooltipInfo}</InfoTooltip>}
      </span>
      <Switch checked={checked} onChange={onChange} />
    </div>
  );
}

export default LabeledSwitch;
