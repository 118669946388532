import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';
import screen3 from 'assets/welcome/screen3.svg';
import Button from 'components/button/button';
import Flex from 'components/flex/flex';
import Page from 'components/page/page';
import { useEffect } from 'react';
import BlockerPlugin from 'utils/BlockerPlugin';

/* eslint-disable-next-line */
export interface IndexProps {}

export function Index(props: IndexProps) {
  useEffect(() => {
    Preferences.get({ key: 'access_token' }).then(({ value }) => {
      if (value && value !== 'null') window.location.href = '/dashboard';
    });
    if (Capacitor.getPlatform() === 'ios') BlockerPlugin.requestTracking();
  }, []);

  return (
    <Page>
      <Flex
        direction="column"
        justify="space-between"
        align="center"
        height="100%"
        width="100%"
        style={{
          backgroundImage: `url(${screen3})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div
          style={{
            fontSize: 20,
            fontWeight: 500,
            textAlign: 'center',
            padding: '0 30px',
            marginTop: 50,
            color: '#1A72C6',
          }}
        >
          Faites du smartphone un allié de l’apprentissage.
        </div>
        <Flex direction="column" gap={10} pad={20} width="100%">
          {/* <Button href="/register/mail">CRÉER UN COMPTE</Button>
          <Button href="/login/mail" outline>
            SE CONNECTER
          </Button> */}
          <Button href="/onboarding/choice/device">C&apos;EST PARTI !</Button>
        </Flex>
      </Flex>
    </Page>
  );
}

export default Index;
