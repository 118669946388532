import BackHeader from 'components/back-header/back-header';
import Flex from 'components/flex/flex';
import Page from 'components/page/page';
import ParentSettingsParentalControl from 'components/parent-settings-parental-control/parent-settings-parental-control';
import TabBar from 'components/tab-bar/tab-bar';
import { BiHelpCircle } from 'react-icons/bi';
import { useNavigate, useParams } from 'react-router-dom';

/* eslint-disable-next-line */
export interface ChildrenSettingsProps {}

export function ChildrenSettings(props: ChildrenSettingsProps) {
  const { id } = useParams();
  const navigate = useNavigate();

  return (
    <Page parentMode>
      <Flex direction="column" justify="start" align="center" width="100%" height="100%">
        <BackHeader
          title="Contrôle parental"
          hideButton
          action={<BiHelpCircle size={25} color="white" onClick={() => navigate('/help/')} />}
        />
        <Flex
          direction="column"
          gap={10}
          width="100%"
          height="100%"
          style={{
            overflowY: 'scroll',
            paddingTop: 70,
            paddingBottom: 20,
          }}
        >
          <Flex
            direction="column"
            gap={10}
            pad={20}
            width="100%"
            style={{
              flexGrow: 1,
            }}
          >
            <ParentSettingsParentalControl childrenId={id} />
          </Flex>
        </Flex>
        <TabBar childrenId={id} />
      </Flex>
    </Page>
  );
}

export default ChildrenSettings;
