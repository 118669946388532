import Flex from 'components/flex/flex';
import AltusTalk from 'components/altus-talk/altus-talk';
import AddNewButton from 'components/add-new-button/add-new-button';
import { useNavigate } from 'react-router-dom';
import Page from 'components/page/page';

/* eslint-disable-next-line */
export interface NoChildrenProps {}

export function NoChildren(props: NoChildrenProps) {
  const navigate = useNavigate();

  return (
    <Page>
      <Flex height='100%' gap={100} justify='center' align='center' direction='column'>
        <AltusTalk>
          Première étape, enregistrez votre enfant !
        </AltusTalk>
        <AddNewButton text="Ajouter un enfant" onClick={() => navigate("/children/create")} />
      </Flex>
    </Page>
  );
}

export default NoChildren;
